package uz.ferro.shop.model

object LocaleEn : AbsLocale() {
    override val all: String = "All"

    override val currentLanguageShort: String = "Eng"
    override val aboutCompany: String = "About Company"
    override val contacts: String = "Contacts"
    override val uzSum: String = "sum"
    override val toCart: String = "Add to Cart"
    override val toFavorite: String = "Add to Favorite"
    override val added: String = "Added"
    override val addedToCart: String = "Added to Cart"
    override val continueShopping: String = "Continue shopping"
    override val goToCart: String = "Go to Cart"
    override val cart: String = "Cart"

    override val admin: String = ""
    override val enterToSystem: String = "Enter to system"
    override val signIn: String = "Sign in"
    override val smsConfirm: String = "SMS Confirmation"
    override val smsCode: String = "SMS code"
    override val smsCodeSentToPhoneTemplate: String = "SMS code sent to number {{phone}}"
    override val login: String = "username"
    override val profile: String = "Profile"
    override val signOut: String = "Sign Out"

    override val buttonDetails: String = "Details"

    override val favorites: String = "Favorites"
    override val fullName: String = ""
    override val feedback: String = "Feedback"

    override val password: String = "Password"
    override val passwordConfirmation: String = ""
    override val role: String = ""
    override val id: String = ""
    override val itemNotAvailable: String = "Not available"
    override val itemAvailable: String = "Available"
    override val name: String = "Name"
    override val productCode: String = "Product code"
    override val sizes: String = "Sizes"
    override val catalog: String = "Catalog"
    override val exit: String = ""
    override val exitConfirm: String = "Do you really want to exit?"
    override val main: String = "Main"
    override val mainPage: String = "Main page"
    override val photo: String = ""
    override val photos: String = ""
    override val products: String = ""
    override val productGroup: String = ""
    override val productsGroups: String = ""
    override val property: String = ""
    override val properties: String = ""
    override val privacyPolicy: String = "Privacy policy"
    override val publicOffer: String = "Public offer"
    override val code: String = ""
    override val remainingCount: String = ""
    override val externalIdSap: String = ""
    override val price: String = "Price"
    override val inDiscount: String = ""
    override val discountPrice: String = ""
    override val mainProductOfGroup: String = ""
    override val mainProduct: String = ""
    override val addNew: String = ""
    override val value: String = ""
    override val done: String = ""
    override val brand: String = ""
    override val users: String = ""
    override val naming: String = "Name"
    override val cancel: String = "Cancel"
    override val save: String = ""
    override val add: String = "Add"
    override val searchBrands: String = ""
    override val measureUnit: String = "Unit"
    override val category: String = "Category"
    override val subcategory: String = "Subcategory"
    override val categories: String = "Categories"
    override val no: String = ""
    override val icon: String = ""
    override val deletion: String = ""
    override val delete: String = "Delete"
    override val confirmDeleteMessage: String = ""
    override val edit: String = ""
    override val gallery: String = ""
    override val group: String = ""
    override val sortOrder: String = ""
    override val search: String = "Search"
    override val inTotal: String = "Total"
    override val status: String = "Status"
    override val statusNew: String = "New"
    override val statusAccepted: String = "Accepted"
    override val statusInProgress: String = "In Progress"
    override val statusShipping: String = "Shipping"
    override val statusShipped: String = "Shipped"
    override val statusCompleted: String = "Completed"
    override val statusCanceled: String = "Canceled"
    override val shippingAddress: String = "Shipping address"
    override val totalAmount: String = "Total amount"
    override val totalBalance: String = "Total balance"
    override val cartIsEmpty: String = "Your cart is empty"
    override val order: String = "Order"
    override val orders: String = "Orders"
    override val date: String = "Date"
    override val sendOrder: String = "Send order"
    override val phoneNumber: String = "Phone number"
    override val close: String = "Close"
    override val orderSuccessMessage: String =
        "Your order has been successfully sent. Our operator will contact you soon."
    override val orderSent: String = "Order sent"
    override val allRightsReserved = "All rights reserved"
    override val sizesCount: String = "Sizes count"
    override val vendorCountry: String = "Manufacture"
    override val makeOrder: String = "Make order"
    override val checkout: String = "Checkout"
    override val deliveryByTashkent: String = "Delivery by Tashkent"
    override val dealers: String = "Dealer stores"
    override val inSocial: String = "Follow us"
    override val productsCount: String = "Number of goods"
    override val termsOfPaymentAndDelivery: String = "Terms of payment and delivery"
    override val headerFasteners: String = "FASTENERS"
    override val headerFastenersShort: String = "FASTENERS"
    override val headerConsumables: String = "POWER TOOL CONSUMABLES"
    override val headerConsumablesShort: String = "CONSUMABLES"
    override val headerInstruments: String = "TOOLS"
    override val headerInstrumentsShort: String = "TOOLS"
    override val workingDays: String = "Mon-Sat"
    override val productNotFound: String = "Product not found :("
    override val quantity: String = "Quantity"
    override val noResult: String = "No results"
    override val amountInPack: String = "Amount in package"
    override val amountInBox: String = "Amount in box"
    override val paymentAndDelivery: String = "Payment and Delivery"
    override val checkoutAndOrderConfirmation: String = "Placing and confirming an order"
    override val confirmCheckout: String = "Checkout and confirm order"
    override val payment: String = "Payment"
    override val delivery: String = "Delivery"
    override val cashPayment: String = "Cash payment"
    override val paymentServicePayment: String = "Payment through Click, Payme"
    override val nonCashPayment: String = "Non cash payment"
    override val deliveryInCity: String = "Courier delivery in Tashkent"
    override val selfPickupFromDealers: String = "Pickup from dealer stores"
    override val marketCheckoutDescription: String =
        "To make a purchase in our store, add the items you are interested in to your cart and fill out the order form."
    override val callCenterDescription: String =
        "Our managers will promptly contact you to confirm the order and select the optimal method of payment and delivery of goods."
    override val emailAndPhoneOrderingDescription: String =
        "We also accept orders by phone: {{phone}} or e-mail: {{email}}"
    override val cashPaymentDescription: String =
        "Payment is made in cash to the courier or in the store upon pickup. Along with the goods, sales and cash receipts, as well as a warranty card, are sent."
    override val paymentServiceDescription: String =
        "You can pay for your order through the Click, Payme applications or through any other payment systems by transferring to the company’s personal account."
    override val nonCashPaymentDescription: String = "We work with VAT, issue an invoice"
    override val deliveryInCityDescription: String =
        "Price — 100${Typography.nbsp}000 sum. Free delivery in orders from 1${Typography.nbsp}000${Typography.nbsp}000 sum. You can get detailed information from our managers."
    override val selfPickupDescription: String =
        "You can purchase a wide range of products through our stores."
    override val mapRoute: String = "Location in map"
    override val menu: String = "Menu"
    override val productsCatalog: String = "Products catalog"
    override val dealersMarkets: String = "Dealers"
    override val getMore: String = "Get more"
    override val resetFilter: String = "Reset"
    override val footerPriceDescription: String =
        "For technical reasons, the prices and characteristics of goods given are for informational purposes only and do not constitute a public offer. " +
                "You can check the cost of the product you are interested in, in advance, before ordering by phone {{phone}}." +
                "If you made an order on the website, the manager will contact you and provide information about availability and price. " +
                "We apologize for any inconvenience caused."
    override val unavailableProducts: String = "Unavailable"
    override val unavailableProductsDescription: String = "You can remove unavailable products and continue ordering"
}