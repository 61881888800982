package uz.ferro.shop.pages.admin.banner.ui

import mui.icons.material.Add
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.InputLabel
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.model.Component
import uz.ferro.shop.ui.banner.BannerGroup
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.pct
import web.cssom.px

external interface AdminBannersComponentProps : Props {
    var title: String?
    var component: Component?
    var fitFirst: Boolean
}

val AdminBannersComponent = FC<AdminBannersComponentProps> { props ->
    val navigator = useContext(NavigationContext)
    val component: Component = props.component ?: return@FC

    Box {
        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                marginTop = 32.px
            }

            InputLabel {
                +props.title.orEmpty()
            }

            Button {
                sx {
                    marginLeft = 200.px
                }
                variant = ButtonVariant.contained
                Add()
                onClick = {
                    navigator.openAdminNewComponentBanner(component.id!!)
                }
            }
        }

        Box {
            sx {
                width = 100.pct
                maxWidth = 800.px
                height = 320.px
                margin = 16.px
            }

            BannerGroup {
                banners = component.banners
                    .orEmpty().sortedBy { it.sort }
                fitFirst = props.fitFirst
                isEditMode = true
            }
        }
    }
}
