package uz.ferro.shop.pages

import emotion.react.css
import kotlinx.browser.window
import mui.icons.material.ArrowUpward
import mui.material.Box
import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.Fab
import mui.material.FabColor
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.system.Breakpoint
import mui.system.Breakpoint.Companion.md
import mui.system.Breakpoint.Companion.xs
import mui.system.responsive
import mui.system.sx
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollToOptions
import react.ChildrenBuilder
import react.Context
import react.FC
import react.Props
import react.createContext
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.CartContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.CatalogIcon32
import uz.ferro.shop.icon.MarketIcon32
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.model.CartManager
import uz.ferro.shop.model.CartProduct
import uz.ferro.shop.model.Product
import uz.ferro.shop.ui.AppTextBoldSmall
import uz.ferro.shop.ui.ImageView
import uz.ferro.shop.ui.category.CategoryMenu
import uz.ferro.shop.ui.category.MobileCategoriesPopup
import uz.ferro.shop.ui.footer.Footer
import web.cssom.AlignItems
import web.cssom.Auto
import web.cssom.ClassName
import web.cssom.Cursor
import web.cssom.FontWeight
import web.cssom.LineStyle
import web.cssom.Position
import web.cssom.integer
import web.cssom.number
import web.cssom.pct
import web.cssom.pt
import web.cssom.px
import web.dom.Element

val MarketContext: Context<MarketCallback> = createContext(object : MarketCallback() {
    override val cartManager: CartManager = CartManager

    override fun onAddedToCart(cartProduct: CartProduct) {
        // no-op
    }

    override fun onCartCountUpdated() {
        // no-op
    }
})

private const val MODAL_STATE = "modalState"

abstract class MarketCallback {

    abstract val cartManager: CartManager

    fun addToCart(product: Product, quantity: Int = 1) {
        val cartProduct = cartManager.addProduct(product.copy(), quantity)
        onAddedToCart(cartProduct)
        onCartCountUpdated()
    }

    fun removeProduct(product: Product) {
        cartManager.removeProduct(product.id!!)
        onCartCountUpdated()
    }

    abstract fun onAddedToCart(cartProduct: CartProduct)

    abstract fun onCartCountUpdated()
}

data class AddedToCartDialogState(
    val isOpen: Boolean = false,
    val cartProduct: CartProduct? = null
)

val MAX_PAGE_AREA = 1312.px

fun MarketPage(
    collapseMainCatalogByDefault: Boolean = false,
    hideCategory: Boolean = false,
    hideMobileCatalog: Boolean = false,
    stretchCategoryHeight: Boolean = false,
    fillWidthMode: Boolean = false,
    leftBlock: ChildrenBuilder.(props: Props) -> Unit = {},
    centerBlock: ChildrenBuilder.(props: Props) -> Unit = {},
    fullBlock: ChildrenBuilder.(props: Props) -> Unit = {},
): FC<Props> {

    val leftElement = FC(leftBlock)
    val centerElement = FC(centerBlock)
    val fullElement = FC(fullBlock)

    return FC {
        val locale = useContext(LocaleContext)
        val navigator = useContext(NavigationContext)
        var addedToCartDialogState by useState(AddedToCartDialogState())
        val cartManagerCtx = useContext(CartContext)
        var cartCountState by useState(cartManagerCtx.cart.products.size)
        val language = LanguageManager.currentLanguage
        var showScrollToTopButton: Boolean by useState(false)
        val appContext = useContext(AppContext)

        var catalogButtonTarget by useState<Element>()
        var isCatalogPopupOpen by useState(false)

        var dealersButtonTarget by useState<Element>()
        var isDealersPopupOpen by useState(false)

        val isMobile = appContext.isMd.not()

        val marketCallback = object : MarketCallback() {
            override val cartManager: CartManager = cartManagerCtx

            override fun onAddedToCart(cartProduct: CartProduct) {
                addedToCartDialogState = AddedToCartDialogState(
                    isOpen = true,
                    cartProduct = cartProduct
                )
                if (window.history.state != MODAL_STATE) {
                    window.history.pushState(MODAL_STATE, "")
                }
            }

            override fun onCartCountUpdated() {
                cartCountState = cartManager.cart.products.size
            }
        }

        fun closeAddedToCartDialog() {
            addedToCartDialogState = addedToCartDialogState.copy(isOpen = false)
        }

        useEffectOnce {
            window.addEventListener("scroll", { _ ->
                val yOffset = window.pageYOffset.toInt()
                showScrollToTopButton = yOffset > 300
            })

            // For avoid history back on android backpress
            window.addEventListener("popstate", { event ->
                if (event.asDynamic().state == MODAL_STATE) {
                    closeAddedToCartDialog()
                }
            })
        }

        MarketContext(marketCallback) {

            Header {
                cartItemsCount = cartCountState
            }

            if (fillWidthMode) {
                Box {
                    css(ClassName("fill-space-height")) {}
                    fullElement()
                }
            }

            Box {
                if (fillWidthMode.not()) {
                    css(ClassName("fill-space-height")) {}
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(16.px)

                    sx {
                        width = 100.pct
                        maxWidth = MAX_PAGE_AREA
                        marginLeft = Auto.auto
                        marginRight = Auto.auto
                        marginTop = 16.px
                        if (stretchCategoryHeight) {
                            alignItems = AlignItems.stretch
                        }
                        paddingLeft = 16.px
                        paddingRight = 16.px
                    }

                    if (isMobile.not() && hideCategory.not()) {
                        Box {
                            sx {
                                width = 320.px
                                flexShrink = number(0.0)
                            }

                            CategoryMenu {
                                stretchHeight = stretchCategoryHeight
                                isToggleMode = collapseMainCatalogByDefault
                                isExpandedByDefault = collapseMainCatalogByDefault.not()
                            }
                        }
                    }

                    Box {
                        sx {
                            flexGrow = number(1.0)
                        }

                        if (isMobile && hideMobileCatalog.not()) {
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(16.px)

                                sx {
                                    width = 100.pct
                                }

                                Stack {
                                    direction = responsive(StackDirection.column)
                                    spacing = responsive(2.px)
                                    sx {
                                        flexGrow = number(1.0)
                                        alignItems = AlignItems.center
                                        borderRadius = 5.px
                                        borderColor = Colors.borderPrimary
                                        borderStyle = LineStyle.solid
                                        borderWidth = 1.px
                                        padding = 10.px
                                        width = 0.px
                                    }

                                    CatalogIcon32()

                                    AppTextBoldSmall {
                                        color = Colors.accentColor
                                        text = locale.productsCatalog
                                        center = true
                                    }

                                    onClick = { event ->
                                        catalogButtonTarget = event.currentTarget
                                        isCatalogPopupOpen = !isCatalogPopupOpen
                                        event.preventDefault()
                                        event.stopPropagation()
                                    }
                                }

                                Stack {
                                    direction = responsive(StackDirection.column)
                                    spacing = responsive(2.px)
                                    sx {
                                        flexGrow = number(1.0)
                                        alignItems = AlignItems.center
                                        borderRadius = 5.px
                                        borderColor = Colors.borderPrimary
                                        borderStyle = LineStyle.solid
                                        borderWidth = 1.px
                                        padding = 10.px
                                        width = 0.px
                                    }

                                    MarketIcon32()

                                    AppTextBoldSmall {
                                        color = Colors.accentColor
                                        text = locale.dealersMarkets
                                        center = true
                                    }

                                    onClick = { event ->
                                        dealersButtonTarget = event.currentTarget
                                        isDealersPopupOpen = !isDealersPopupOpen
                                        event.preventDefault()
                                        event.stopPropagation()
                                    }
                                }
                            }

                            DealersPopup {
                                targetElement = dealersButtonTarget
                                isOpen = isDealersPopupOpen
                                close = {
                                    isDealersPopupOpen = false
                                }
                            }

                            MobileCategoriesPopup {
                                targetElement = catalogButtonTarget
                                isOpen = isCatalogPopupOpen
                                close = {
                                    isCatalogPopupOpen = false
                                }
                            }

                            Box {
                                sx {
                                    height = 20.px
                                }
                            }
                        }

                        centerElement()
                    }
                }

                // spacing
                if (hideCategory.not()) {
                    Box {
                        sx {
                            width = 100.pct
                            height = responsive(
                                xs to 4.px,
                                md to 20.px
                            )
                        }
                    }
                }

                Box {
                    sx {
                        width = 100.pct
                        maxWidth = MAX_PAGE_AREA
                        marginTop = 16.px
                        marginLeft = Auto.auto
                        marginRight = Auto.auto
                        paddingLeft = 16.px
                        paddingRight = 16.px
                    }

                    if (fillWidthMode.not()) {
                        fullElement()
                    }
                }

                // spacing
                Box {
                    sx {
                        height = 48.px
                    }
                }
            }

            Footer()

            if (showScrollToTopButton) {
                Fab {
                    color = FabColor.primary
                    sx {
                        position = Position.fixed
                        bottom = 48.px
                        left = responsive(
                            Breakpoint.xs to 16.px,
                            Breakpoint.md to 48.px
                        )
                        zIndex = integer(9999)
                    }

                    ArrowUpward()

                    onClick = {
                        showScrollToTopButton = false
                        window.scroll(
                            ScrollToOptions(
                                0.0, 0.0, org.w3c.dom.ScrollBehavior.SMOOTH
                            )
                        )
                    }
                }
            }

            Dialog {
                fullWidth = true
                open = addedToCartDialogState.isOpen
                onClose = { _, _ -> closeAddedToCartDialog() }
                DialogTitle {
                    +locale.addedToCart
                }
                DialogContent {

                    Box {
                        sx {
                            padding = 16.px
                        }

                        val cartProduct = addedToCartDialogState.cartProduct

                        ImageView {
                            sx {
                                width = 100.pct
                                height = 200.px
                                cursor = Cursor.pointer
                            }
                            url = cartProduct?.product?.getPrimaryPhotoUrl()
                        }

                        Typography {
                            sx {
                                fontSize = 14.pt
                                fontWeight = FontWeight.bold
                                cursor = Cursor.pointer
                            }
                            +cartProduct?.product?.getLocalizedName()
                        }
                    }

                    DialogActions {
                        Button {
                            onClick = {
                                closeAddedToCartDialog()
                            }
                            +locale.continueShopping
                        }

                        Button {
                            onClick = {
                                closeAddedToCartDialog()
                                navigator.openCart()
                            }
                            +locale.goToCart
                        }
                    }
                }
            }
        }
    }
}
