package uz.ferro.shop.search

import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.model.Product
import uz.ferro.shop.model.SearchResult
import web.url.URLSearchParams

private const val SEARCH_RESULT_LIMIT = 10
private const val PRODUCT_SEARCH_LIMIT = 250

object SearchManager {

    private var searchJob: Job? = null
    private var cachedProducts: List<Product> = emptyList()

    private val searchMatchers = listOf(
        SearchCurrentLocaleMatcher(true),
        SearchStartMatcher(true),
        SearchStartWordMatcher(true),
        SearchAnyWordMatcher(true),
        SearchMultiWordMatcher(true),
        SearchContainsMatcher(true),

        SearchCurrentLocaleMatcher(),
        SearchStartMatcher(),
        SearchStartWordMatcher(),
        SearchAnyWordMatcher(),
        SearchMultiWordMatcher(),
        SearchContainsMatcher()
    )

    fun search(query: String, callback: (List<SearchResult>) -> Unit) {
        searchJob?.cancel()
        if (query.trim().length < 2) {
            callback(emptyList())
            return
        }
        searchJob = MainScope().launch {
            delay(750)
            try {
                val path = "search?" + URLSearchParams("").apply {
                    append("q", query.trim())
                }.toString()
                val result = suspendGet<List<SearchResult>>(path = path)
                val limited = if (result.size > SEARCH_RESULT_LIMIT) {
                    result.subList(0, SEARCH_RESULT_LIMIT)
                } else {
                    result
                }
                callback(limited)
            } catch (e: Exception) {
                // no-op
                e.printStackTrace()
            }
        }
    }

    suspend fun productSearch(query: String): List<Product> {
        if (query.trim().length < 2) {
            return emptyList()
        }

        if (cachedProducts.isEmpty()) {
            loadProducts()
        }

        return localSearch(query.lowercase().trim())
    }

    suspend fun initCache() {
        delay(2000L)
        if (cachedProducts.isEmpty()) {
            loadProducts()
        }
    }

    private suspend fun loadProducts() {
        try {
            cachedProducts = suspendGet<List<Product>>("product/list-searchable")
        } catch (e: Exception) {
            // no-op
        }
    }

    private fun localSearch(query: String): List<Product> {
        val result = mutableSetOf<Product>()

        for (i in searchMatchers.indices) {
            val matcher = searchMatchers[i]
            result.addAll(matcher.search(query, cachedProducts))

            if (result.size >= PRODUCT_SEARCH_LIMIT) {
                break
            }
        }

        return result.toList()
    }
}