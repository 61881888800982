package uz.ferro.shop.search

import uz.ferro.shop.model.Product

fun String?.startWordsMatching(query: String): Boolean {
    val words = this.orEmpty().splitWords()
    if (words.isNotEmpty() && words[0] == query) {
        return true
    }

    return (words.size > 1 && (
            words[1] == query ||
                    words[1].toLatin() == query.toLatin() ||
                    words[1].toCyrillic() == query.toCyrillic()))
}

fun String?.multiWordsMatching(queryChunks: List<String>): Boolean {
    val words = this.orEmpty().splitWords()
    return queryChunks.all { queryChunk ->
        words.any {
            it == queryChunk ||
                    it.toLatin() == queryChunk.toLatin() ||
                    it.toCyrillic() == queryChunk.toCyrillic()
        }
    }
}

fun String?.anyWordsMatching(query: String): Boolean {
    val words = this.orEmpty().splitWords()
    return words.any {
        it == query ||
                it.toLatin() == query.toLatin() ||
                it.toCyrillic() == query.toCyrillic()
    }
}

fun Product.namesLowerCase(): List<String> {
    return listOfNotNull(
        name?.textUzLat.orEmpty().lowercase(),
        name?.textUzCyr.orEmpty().lowercase(),
        name?.textRu.orEmpty().lowercase(),
        name?.textEn.orEmpty().lowercase(),
        searchableName?.lowercase()
    )
}

fun String.splitWords(): List<String> {
    return this.split(Regex("\\s"))
        .mapNotNull {
            if (it.isBlank()) null else it.trim().lowercase()
        }
}

fun String.toLatin(): String {
    var out = this
    latinToCyrillicMap.forEach { (key, value) ->
        out = out.replace(key, value)
    }
    return out
}

fun String.toCyrillic(): String {
    var out = this
    latinToCyrillicMap.forEach { (key, value) ->
        out = out.replace(key, value)
    }
    return out
}

val latinToCyrillicMap = mutableMapOf(
    "ch" to "ч",
    "sh" to "ш",
    "yu" to "ю",
    "ya" to "я",

    "a" to "а",
    "a" to "а",
    "b" to "б",
    "c" to "с",
    "d" to "д",
    "e" to "е",
    "f" to "ф",
    "g" to "г",
    "h" to "х",
    "i" to "и",
    "j" to "ж",
    "k" to "к",
    "l" to "л",
    "m" to "м",
    "n" to "н",
    "o" to "о",
    "p" to "п",
    "q" to "қ",
    "r" to "р",
    "s" to "с",
    "t" to "т",
    "u" to "у",
    "v" to "в",
    "x" to "х",
    "y" to "й",
    "z" to "з",
)

val cyrillicToLatinMap = mutableMapOf(
    "a" to "a",
    "б" to "b",
    "в" to "v",
    "г" to "g",
    "д" to "d",
    "е" to "e",
    "ё" to "yo",
    "ж" to "j",
    "з" to "z",
    "и" to "i",
    "й" to "y",
    "к" to "k",
    "л" to "l",
    "м" to "m",
    "н" to "n",
    "о" to "o",
    "п" to "p",
    "р" to "r",
    "с" to "s",
    "т" to "t",
    "у" to "u",
    "ф" to "f",
    "х" to "h",
    "ҳ" to "h",
    "ц" to "s",
    "ч" to "ch",
    "ш" to "sh",
    "щ" to "sh",
    "ь" to "",
    "ы" to "i",
    "ъ" to "",
    "э" to "e",
    "ю" to "yu",
    "я" to "ya"
)
