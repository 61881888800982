package uz.ferro.shop.api

import uz.ferro.shop.model.DataFile
import web.file.File
import web.http.FormData
import kotlin.js.Promise

fun uploadFile(file: File): Promise<DataFile> {
    val formData = FormData()
    formData.append("file", file)
    return post(path = "file/upload", body = formData)
}