package uz.ferro.shop.pages.admin.customer

import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.FormControlVariant
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.useState
import uz.ferro.shop.manager.CustomerManager
import uz.ferro.shop.model.Customer
import web.cssom.px
import web.html.HTMLInputElement

external interface AdminCustomerDetailsDialogProps : Props {
    var filledName: String?
    var filledPhone: String?
    var onSaveSuccess: (Customer) -> Unit
    var onClose: () -> Unit
}

@Suppress("UNCHECKED_CAST", "UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
val AdminCustomerDetailsDialog = FC<AdminCustomerDetailsDialogProps> { props ->

    val customerManager = CustomerManager
    var customerState by useState(
        Customer(
            id = customerManager.suggestCode(props.filledName.orEmpty()),
            name = props.filledName,
            phone1 = props.filledPhone,
            aliasName = customerManager.suggestNickName(props.filledName.orEmpty())
        )
    )

    val segments = listOf(
        ""
    )

    fun closeDialog() {
        props.onClose()
    }

    fun save() {
        customerManager.addNewCustomer(customerState)
            .then {
                props.onSaveSuccess(it)
            }
            .catch { e ->
                console.log(e)
            }
    }

    Dialog {
        fullWidth = true
        open = true
        onClose = { _, _ -> closeDialog() }
        DialogTitle {
            +"Mijoz"
        }
        DialogContent {

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(16.px)
                Stack {
                    direction = responsive(StackDirection.column)
                    spacing = responsive(16.px)
                    sx {
                        paddingTop = 16.px
                        width = 300.px
                    }

                    TextField {
                        label = ReactNode("Mijoz ismi")
                        autoComplete = "off"
                        variant = FormControlVariant.outlined
                        value = customerState.name.orEmpty()
                        onChange = { event ->
                            event as ChangeEvent<HTMLInputElement>
                            val inputValue = event.target.value
                            if (inputValue != customerState.name) {
                                if (event.isTrusted) {
                                    val suggestedCode = customerManager.suggestCode(inputValue)
                                    val suggestedNickName = customerManager.suggestNickName(inputValue)
                                    customerState = customerState.copy(
                                        id = suggestedCode,
                                        name = inputValue,
                                        aliasName = suggestedNickName
                                    )
                                } else {
                                    customerState = customerState.copy(
                                        name = inputValue
                                    )
                                }
                            }
                        }
                    }

                    TextField {
                        label = ReactNode("Telefon")
                        autoComplete = "off"
                        variant = FormControlVariant.outlined
                        value = customerState.phone1.orEmpty()
                        onChange = { event ->
                            event as ChangeEvent<HTMLInputElement>

                            val phoneInputValue = event.target.value
                            if (phoneInputValue != customerState.phone1) {
                                customerState = customerState.copy(
                                    phone1 = phoneInputValue
                                )
                            }
                        }
                    }

                    TextField {
                        label = ReactNode("Taxallus")
                        autoComplete = "off"
                        variant = FormControlVariant.outlined
                        value = customerState.aliasName.orEmpty()
                        onChange = { event ->
                            event as ChangeEvent<HTMLInputElement>

                            val aliasNameInputValue = event.target.value
                            if (aliasNameInputValue != customerState.aliasName) {
                                customerState = customerState.copy(
                                    aliasName = aliasNameInputValue
                                )
                            }
                        }
                    }
                }

                Stack {
                    direction = responsive(StackDirection.column)
                    spacing = responsive(16.px)
                    sx {
                        paddingTop = 16.px
                        width = 300.px
                    }

                    TextField {
                        label = ReactNode("Mijoz kodi")
                        autoComplete = "off"
                        variant = FormControlVariant.outlined
                        value = customerState.id.orEmpty()

                        onChange = { event ->
                            event as ChangeEvent<HTMLInputElement>
                            val codeInputValue = event.target.value
                            if (codeInputValue != customerState.id) {
                                customerState = customerState.copy(
                                    id = codeInputValue
                                )
                            }
                        }
                    }

//                    Select {
//                        value = selectedItem?.id.orZero()
//                        label = ReactNode(title)
//                        onChange = { evt, _ ->
//                            val id = evt.target.value
//                            val item = allItems.firstOrNull { it.id.toString() == id }
//                            onItemSelect(item)
//                        }
//
//                        MenuItem {
//                            value = 0
//                            +"--"
//                        }
//                        allItems.forEach {
//                            MenuItem {
//                                value = it.id.toString()
//                                +it.name
//                            }
//                        }
//                    }
                }
            }


            DialogActions {
                Button {
                    onClick = {
                        save()
                    }
                    +"Saqlash"
                }
            }
        }
    }
}