package uz.ferro.shop.api

import js.core.Void
import js.core.jso
import tanstack.query.core.QueryKey
import tanstack.react.query.useMutation
import tanstack.react.query.useQuery
import tanstack.react.query.useQueryClient
import uz.ferro.shop.PROPERTIES_ALL_QUERY_KEY
import uz.ferro.shop.model.Property
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import kotlin.js.Promise

fun useAllProperties(): List<Property> {
    val result = useQuery<List<Property>, Error, List<Property>, QueryKey>(
        queryKey = PROPERTIES_ALL_QUERY_KEY,
        queryFn = { getAllProperties() }
    )
    return result.data.orEmpty()
}

private fun addProperty(property: Property): Promise<Property> {
    return post(
        path = "property",
        body = property
    )
}

typealias SaveProperty = (Property) -> Promise<Property>

private fun updateProperty(property: Property): Promise<Property> {
    return put(
        path = "property",
        body = property
    )
}

fun saveProperty(property: Property): Promise<Property> {
    return if (property.id.orZero() > 0) {
        updateProperty(property)
    } else {
        addProperty(property)
    }
}

fun useSaveProperty(): SaveProperty {
    val client = useQueryClient()
    return useMutation<Property, Error, Property, QueryKey>(
        mutationFn = { property ->
            if (property.id.orZero() > 0) {
                updateProperty(property)
            } else {
                addProperty(property)
            }
        },
        options = jso {
            onSuccess = { result, _, _ ->
                client.invalidateQueries<Void>(PROPERTIES_ALL_QUERY_KEY)
                    .then {
                        result
                    }
            }
        }
    ).mutateAsync.unsafeCast<SaveProperty>()
}

fun getAllProperties(): Promise<List<Property>> {
    return get<List<Property>>("property/list")
        .then { data ->
            data.sortedBy { it.name.localized() }
        }
}
