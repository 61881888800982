package uz.ferro.shop.pages.admin.order

import mui.material.Paper
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableHead
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.model.OrderProduct
import web.cssom.px

external interface EditableOrderProductListProps : Props {
    var onDeleteClick: (OrderProduct) -> Unit
}

val EditableOrderProductList = FC<EditableOrderProductListProps> { props ->
    val order = useContext(OrderContext)
    val locale = useContext(LocaleContext)

    Paper {
        sx {
            marginTop = 8.px
        }

        Table {
            TableHead {
                TableRow {
                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +""
                    }

                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +"Kod"
                    }
                    TableCell {
                        +"Nomi"
                    }
                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +"Birlik narxi"
                    }
                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +"Miqdori"
                    }

                    TableCell {
                        sx {
                            width = 80.px
                        }
                        +"Birligi"
                    }

                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +"Narxi"
                    }
                }
            }
            TableBody {
                order.products.forEach { ordProduct ->
                    EditableOrderProductItem {
                        orderProduct = ordProduct
                        onDeleteClick = props.onDeleteClick
                    }
                }
            }
        }
    }
}