package uz.ferro.shop.pages.admin.product

import mui.icons.material.Close
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControl
import mui.material.IconButton
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Paper
import mui.material.Select
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.useState
import uz.ferro.shop.api.savePropertyValue
import uz.ferro.shop.api.useAllProperties
import uz.ferro.shop.model.PropertyValue
import uz.ferro.shop.pages.admin.dict.DictItemEditor
import uz.ferro.shop.util.isDefaultBlank
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.px

external interface PropertyValueEditorProps : Props {
    var onClose: () -> Unit
    var onReady: (PropertyValue?) -> Unit
}

val PropertyValueEditor = FC<PropertyValueEditorProps> { props ->
    val properties = useAllProperties()
    var propertyValue by useState(PropertyValue())
    var addNewProperty by useState(false)

    AddPropertyDialog() {
        isOpen = addNewProperty
        onClose = {
            addNewProperty = false
        }
        onReady = { property ->
            propertyValue = propertyValue.copy(property = property)
        }
    }

    Paper {
        sx {
            display = Display.flex
            flexDirection = FlexDirection.column
            marginTop = 16.px
            padding = 12.px
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                justifyContent = JustifyContent.end
            }
            IconButton {
                onClick = {
                    props.onClose()
                }
                Close()
            }
        }

        Box {
            sx {
                marginTop = 12.px
            }
            val title = "Hususiyat"
            FormControl {
                fullWidth = true
                InputLabel {
                    +title
                }
                Select {
                    value = propertyValue.property?.id.orZero()
                    label = ReactNode(title)
                    onChange = { evt, _ ->
                        val id = evt.target.value
                        val property = properties.firstOrNull { it.id.toString() == id }
                        propertyValue = propertyValue.copy(property = property)
                        if (id == "-100") {
                            addNewProperty = true
                        }
                    }

                    MenuItem {
                        value = 0
                        +"--"
                    }

                    MenuItem {
                        value = "-100"
                        +"Yangi qo'shish"
                    }

                    properties.forEach {
                        MenuItem {
                            value = it.id.toString()
                            +(it.name.localized() + it.unit?.name?.let { unitName ->
                                " ( ${unitName.localized()} )"
                            }.orEmpty())
                        }
                    }
                }
            }
        }

        DictItemEditor {
            title = "Qiymati"
            item = propertyValue.value
            onChange = {
                propertyValue = propertyValue.copy(value = it)
            }
        }

        Button {
            sx {
                marginTop = 12.px
            }
            variant = ButtonVariant.contained
            +"Tayyor"
            onClick = {
                var isValid = true
                if (propertyValue.property == null) {
                    isValid = false
                }

                if (propertyValue.value.isDefaultBlank()) {
                    isValid = false
                }

                if (isValid) {
                    savePropertyValue(propertyValue)
                        .then { result ->
                            props.onReady(result)
                        }
                }
            }
        }
    }
}