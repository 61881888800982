package uz.ferro.shop.pages.admin.brand

import emotion.react.css
import mui.material.FormControl
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.div
import uz.ferro.shop.api.useAllMeasureUnits
import uz.ferro.shop.model.MeasureUnit
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import web.cssom.px

external interface MeasureUnitPickerProps : Props {
    var selectedMeasureUnit: MeasureUnit?
    var onMeasureUnitSelect: (MeasureUnit?) -> Unit
}

val MeasureUnitPicker = FC<MeasureUnitPickerProps> { props ->
    val allItems = useAllMeasureUnits()
    val selectedItem = props.selectedMeasureUnit
    val onItemSelect = props.onMeasureUnitSelect

    div {
        css { marginTop = 20.px }
        if (allItems.isNotEmpty()) {
            val title = "O'lchov birligi"
            FormControl {
                fullWidth = true
                InputLabel {
                    +title
                }
                Select {
                    value = selectedItem?.id.orZero()
                    label = ReactNode(title)
                    onChange = { evt, _ ->
                        val id = evt.target.value
                        val cat = allItems.firstOrNull { it.id.toString() == id }
                        onItemSelect(cat)
                    }

                    MenuItem {
                        value = 0
                        +"--"
                    }
                    allItems.forEach {
                        MenuItem {
                            value = it.id.toString()
                            +it.name.localized()
                        }
                    }
                }
            }
        }
    }
}