package uz.ferro.shop.pages.photo

import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.VFC
import react.router.dom.useSearchParams
import react.useContext
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.model.PHOTO_SIZE_EXTRA_LARGE
import uz.ferro.shop.model.PHOTO_SIZE_SMALL
import uz.ferro.shop.ui.BorderType
import uz.ferro.shop.ui.ImageView
import uz.ferro.shop.util.noScrollBars
import uz.ferro.shop.util.thumbnailSquareUrl
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.number
import web.cssom.pct
import web.cssom.px

val PhotoViewerPage = VFC {
    val params = useSearchParams()
    val urlsParam = params.component1()["urls"].orEmpty()
    val selectedUrl = params.component1()["selectedUrl"]
    val urls: List<String> = if (urlsParam.isNotEmpty()) {
        urlsParam.split(",")
    } else {
        emptyList()
    }

    val appContext = useContext(AppContext)
    var selectedPhotoUrl by useState(selectedUrl)

//    var zoom by useState(1f)

    Stack {
        direction = responsive(StackDirection.column)

        sx {
            position = Position.absolute
            top = 0.px
            right = 0.px
            bottom = 0.px
            left = 0.px
            display = Display.flex
            padding = 16.px
        }

        Box {
            sx {
                flexGrow = number(1.0)
                width = 100.pct
                overflow = Overflow.hidden
            }

            Box {
                sx {
                    width = 100.pct
                    height = 100.pct
                    //transform = scale(zoom)
                }

                ImageView {
                    sx {
                        width = 100.pct
                        height = 100.pct
                    }
                    url = selectedPhotoUrl?.thumbnailSquareUrl(PHOTO_SIZE_EXTRA_LARGE)
//                    onPhotoClick = {
//                        if (zoom > 1f) {
//                            zoom = 1f
//                        } else {
//                            zoom = 2f
//                        }
//                    }
                }
            }
        }

        if (urls.size > 1) {
            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(8.px)

                sx {
                    width = 100.pct
                    height = 100.px
                    marginTop = 10.px
                    overflowX = Overflow.scroll
                    overflowY = Overflow.hidden
                    justifyContent = JustifyContent.center
                }

                noScrollBars()

                urls.forEach { photoUrl ->
                    val isSelected = photoUrl == selectedPhotoUrl
                    ImageView {
                        sx {
                            height = 100.pct
                            aspectRatio = number(1.0)
                            borderRadius = 5.px
                        }
                        borderType = if (isSelected) {
                            BorderType.AccentRadius5
                        } else null
                        url = photoUrl.thumbnailSquareUrl(PHOTO_SIZE_SMALL)

                        onMouseEnter = {
                            if (isSelected.not()) {
                                selectedPhotoUrl = photoUrl
                            }
                        }
                    }
                }
            }
        }
    }
}