package uz.ferro.shop.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.util.formatDMY
import uz.ferro.shop.util.formatDMYHm
import kotlin.js.Date

@Serializable
data class Order(
    @SerialName("id")
    val id: Long? = null,

    @SerialName("status")
    val status: OrderStatus = OrderStatus.NEW,

    @SerialName("customerName")
    val customerName: String? = null,

    @SerialName("customerPhone")
    val customerPhone: String? = null,

    @SerialName("customerExternalId")
    val customerExternalId: String? = null,

    @SerialName("customer")
    val customer: User? = null,

    @SerialName("dueDate")
    val dueDate: String? = null,

    @SerialName("taxDate")
    val taxDate: String? = null,

    @SerialName("uuid")
    val uuid: String? = null,

    @SerialName("externalId")
    val externalId: Long? = null,

    @SerialName("externalDocNum")
    val externalDocNum: String? = null,

    @SerialName("externalCustomerInfo")
    val externalCustomer: Customer? = null,

    @SerialName("totalLocalAmount")
    val totalLocalAmount: Int? = null,

    @SerialName("productsCount")
    val productsCount: Int? = null,

    @SerialName("total")
    val total: Double? = null,

    @SerialName("createdDate")
    val createdDate: String? = null,

    @SerialName("updatedDate")
    val updatedDate: String? = null,

    @SerialName("products")
    var products: List<OrderProduct> = emptyList()
) {

    fun deepCopy(): Order {
        return this.copy(
            products = this.products.map { it.copy() }
        )
    }

    fun displayDate(): String {
        return Date(createdDate.orEmpty()).formatDMYHm()
    }
}