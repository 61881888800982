package uz.ferro.shop.pages.favorite

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Breadcrumbs
import mui.material.Grid
import mui.material.Link
import mui.material.LinkUnderline
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.favorite.FavoriteManager
import uz.ferro.shop.icon.ChevronRightIcon16
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.model.FavoriteUiItem
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.pages.product.ProductItemView
import uz.ferro.shop.ui.AppTextBoldGiant
import uz.ferro.shop.util.lg
import uz.ferro.shop.util.md
import uz.ferro.shop.util.sm
import uz.ferro.shop.util.xs
import web.cssom.px

val FavoritesPage = FC<Props> {
    MarketPage(
        collapseMainCatalogByDefault = true,
        centerBlock = {
            val locale = useContext(LocaleContext)
            Box {
                sx {
                    marginLeft = responsive(
                        Breakpoint.xs to 0.px,
                        Breakpoint.md to 30.px
                    )
                }

                Breadcrumbs {
                    sx {
                        fontSize = 13.px
                    }

                    separator = ChevronRightIcon16.create()
                    Link {
                        color = Colors.textSecondary
                        underline = LinkUnderline.hover
                        key = "1"
                        href = "/"
                        onClick = {

                        }
                        +locale.main
                    }

                    Box {
                        sx {
                            color = Colors.textSecondary
                        }
                        +locale.favorites
                    }
                }

                AppTextBoldGiant {
                    sx {
                        marginTop = 8.px
                        fontSize = responsive(
                            Breakpoint.xs to 25.px,
                            Breakpoint.md to 31.px
                        )
                    }
                    text = locale.favorites
                }
            }

        },
        fullBlock = {
            val language = LanguageManager.currentLanguage
            val locale = useContext(LocaleContext)
            val navigator = useContext(NavigationContext)

            var items by useState(listOf<FavoriteUiItem>())

            fun loadData() {
                MainScope().launch {
                    items = FavoriteManager.getFavoriteItems()
                }
            }

            useEffectOnce {
                loadData()
            }

            Grid {
                sx {
                }
                container = true
                spacing = responsive(12.px)

                items.forEach { favoriteItem ->
                    Grid {
                        item = true
                        xs = 6
                        sm = 6
                        md = 4
                        lg = 3

                        sx {

                        }

                        ProductItemView {
                            this.isFavorite = true
                            this.date = favoriteItem.date
                            this.product = favoriteItem.product
                        }
                    }
                }
            }
        }
    ).invoke()
}