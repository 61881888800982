package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import uz.ferro.shop.util.localized

@Serializable
data class MeasureUnit(
    val id: Int?,
    val name: DictItem?
) {
    fun getLocalizedName(): String = name.localized()
}