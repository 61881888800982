package uz.ferro.shop.pages.order

import mui.material.TableCell
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.model.Order
import uz.ferro.shop.model.displayStatus
import uz.ferro.shop.ui.AppTextBoldSmall
import uz.ferro.shop.util.formatPrice
import web.cssom.Cursor
import web.cssom.LineStyle
import web.cssom.TextAlign
import web.cssom.px


external interface OrderItemViewProps : Props {
    var order: Order
}

val OrderItemView = FC<OrderItemViewProps> { props ->
    val order = props.order
    val navigator = useContext(NavigationContext)

    TableRow {
        hover = true
        key = order.id.toString()

        sx {
            cursor = Cursor.pointer
        }

        onClick = {
            navigator.openOrderDetails(order.id)
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = order.id?.toString()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = order.displayDate()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = order.productsCount?.toString()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = order.total.formatPrice()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = order.displayStatus()
            }
        }
    }
}