package uz.ferro.shop.favorite

import kotlinx.browser.localStorage
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import org.w3c.dom.get
import org.w3c.dom.set
import uz.ferro.shop.KEY_FAVORITES
import uz.ferro.shop.api.jsonSerializer
import uz.ferro.shop.manager.ProductManager
import uz.ferro.shop.model.FavoriteItem
import uz.ferro.shop.model.FavoriteItemsInfo
import uz.ferro.shop.model.FavoriteUiItem
import kotlin.js.Date

object FavoriteManager {

    private var items: MutableList<FavoriteItem> = mutableListOf()

    private var idSet = mutableSetOf<Long>()

    init {
        val json = localStorage[KEY_FAVORITES].orEmpty()
        val favoriteItemsInfo: FavoriteItemsInfo = try {
            jsonSerializer.decodeFromString(json)
        } catch (e: Exception) {
            FavoriteItemsInfo()
        }

        items = favoriteItemsInfo.favoriteItems.toMutableList()
        idSet = items.map { it.productId }.toMutableSet()
    }

    suspend fun getFavoriteItems(): List<FavoriteUiItem> {
        val out = mutableListOf<FavoriteUiItem>()
        items.chunked(250)
            .forEach { list ->
                val idList = list.map { it.productId }
                val productMap = ProductManager.getProductsByIdList(idList).associateBy { it.id!! }
                list.forEach {
                    val product = productMap[it.productId]
                    if (product != null) {
                        out.add(
                            FavoriteUiItem(
                                date = Date(it.date),
                                product = product
                            )
                        )
                    }
                }
            }
        return out.sortedBy { it.date.toISOString() }.reversed()
    }

    private fun saveData() {
        localStorage[KEY_FAVORITES] = jsonSerializer.encodeToString(FavoriteItemsInfo(items))
    }

    fun isFavorite(productId: Long): Boolean {
        return idSet.contains(productId)
    }

    fun setFavorite(productId: Long) {
        idSet.add(productId)
        items.removeAll { it.productId == productId }
        items.add(
            FavoriteItem(
                date = Date().toISOString(),
                productId = productId
            )
        )
        saveData()
    }

    fun removeFavorite(productId: Long) {
        idSet.remove(productId)
        items.removeAll { it.productId == productId }
        saveData()
    }
}