package uz.ferro.shop.pages.admin.brand

import emotion.react.css
import mui.material.Box
import mui.material.FormControlVariant
import mui.material.TextField
import mui.system.sx
import react.*
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.onChange
import uz.ferro.shop.Colors
import uz.ferro.shop.api.useAllBrands
import uz.ferro.shop.api.useSaveBrand
import uz.ferro.shop.model.Brand
import uz.ferro.shop.util.autoComplete
import uz.ferro.shop.util.noValidate
import web.cssom.*
import web.html.HTMLInputElement
import web.html.InputType

val SelectedBrandContext = createContext<Brand>()
val SetSelectedBrandContext = createContext<StateSetter<Brand?>>()

val SelectedBrandModule = FC<PropsWithChildren> { props ->
    val (currentBrand, setCurrentBrand) = useState<Brand>()
    SelectedBrandContext(currentBrand) {
        SetSelectedBrandContext(setCurrentBrand) {
            +props.children
        }
    }
}

external interface BrandItemProps : Props {
    var brand: Brand
    var onBrandSelect: (Brand) -> Unit
}

external interface BrandListProps : Props {
    var searchQuery: String?
}

val AdminBrands = FC<Props> {

    SelectedBrandModule {
        FC<Props> {
            val currentBrand = useContext(SelectedBrandContext)

            div {
                css {
                    padding = 10.px
                    position = Position.absolute
                    top = 0.px
                    bottom = 0.px
                    left = 0.px
                    right = 0.px
                    display = Display.flex
                    flexDirection = FlexDirection.row
                }

                BrandsBlock()

                div {
                    css {
                        marginLeft = 10.px
                        width = 400.px
                        borderLeftWidth = 1.px
                        borderLeftStyle = LineStyle.solid
                        borderLeftColor = Colors.divider
                    }

                    if (currentBrand != null) {
                        BrandEditor {

                        }
                    }
                }
            }
        }()
    }
}

private val BrandsBlock = FC<Props> {
    val setSelectedBrand = useContext(SetSelectedBrandContext)
    var searchQuery by useState("")

    div {
        css {
            width = 400.px
            padding = 10.px
            display = Display.flex
            flexDirection = FlexDirection.column
        }

        div {
            css {
                borderBottomColor = Colors.divider
                borderBottomStyle = LineStyle.solid
                borderBlockWidth = 1.px
                paddingBottom = 10.px
            }
            ReactHTML.button {
                onClick = {
                    setSelectedBrand!!.invoke(
                        Brand()
                    )
                }
                +"Qo'shish"
            }
        }

        div {
            ReactHTML.input {
                css {
                    width = 100.pct
                    marginTop = 10.px
                }

                type = InputType.text
                name = "searchQuery"
                value = searchQuery
                placeholder = "Search brands"
                onChange = { event ->
                    searchQuery = event.target.value
                }
            }
        }


        div {
            css {
                overflowY = Overflow.scroll
                marginTop = 10.px
            }

            BrandsMenu() {
                this.searchQuery = searchQuery
            }
        }
    }
}

private val BrandsMenu = FC<BrandListProps> { props ->
    val brands = useAllBrands()
    val searchQuery = props.searchQuery
    val setSelectedBrand = useContext(SetSelectedBrandContext)

    val filteredBrands: List<Brand> = if (searchQuery.orEmpty().isNotBlank()) {
        brands.filter {
            it.name.orEmpty().lowercase().contains(searchQuery!!.lowercase())
        }
    } else brands

    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
        }
        filteredBrands.forEach { prd ->
            BrandItem {
                brand = prd
                onBrandSelect = {
                    setSelectedBrand!!.invoke(it.copy())
                }
            }
        }
    }
}


private val BrandItem = FC<BrandItemProps> { props ->
    div {
        var isHover: Boolean by useState(false)
        var isDown: Boolean by useState(false)

        val bg: BackgroundColor? = when {
            isDown -> Colors.adminItemPressed
            isHover -> Colors.adminItemHover
            else -> null
        }

        css {
            paddingLeft = 20.px
            paddingRight = 20.px
            alignItems = AlignItems.center
            listStyleType = None.none
            paddingTop = 14.px
            paddingBottom = 14.px
            fontSize = 18.px
            backgroundColor = bg
        }
        onMouseOver = {
            isHover = true
        }

        onMouseOut = {
            isHover = false
            isDown = false
        }

        onClick = {
            props.onBrandSelect.invoke(props.brand)
        }

        onMouseDown = {
            isDown = true
        }

        onMouseUp = {
            isDown = false
        }

        div {
            css {

            }

            +props.brand.name
        }
    }
}


val BrandEditor = FC<BrandListProps> { props ->
    val saveBrand = useSaveBrand()
    val brand = useContext(SelectedBrandContext)
    val setBrand = useContext(SetSelectedBrandContext)

    Box {
        sx {
            width = 300.px
            display = Display.grid
            justifyContent = JustifyContent.left
            padding = 10.px
        }
        component = ReactHTML.form
        noValidate = true
        autoComplete = "off"

        TextField {
            label = ReactNode("Nomi")
            variant = FormControlVariant.outlined
            value = brand?.name.orEmpty()
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                setBrand?.invoke(brand?.copy(name = event.target.value))
            }
        }
    }

    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.row
            marginTop = 20.px
        }


        ReactHTML.button {
            onClick = {
                setBrand?.invoke(null)
            }
            +"Bekor"
        }

        ReactHTML.button {
            onClick = {
                val prd: Brand? = brand
                if (isValidBrand(prd)) {
                    saveBrand(prd!!)
                    setBrand?.invoke(null)
                }
            }

            +"Saqlash"
        }
    }
}

private fun isValidBrand(brand: Brand?): Boolean {
    brand ?: return false
    if (brand.name.orEmpty().isBlank()) return false
    return true
}
