package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class Customer(
    val id: String? = null,
    val uniqueCode: String? = null,
    val name: String? = null,
    val aliasName: String? = null,
    val currency: String? = null,
    val groupId: Long? = null,
    val phone1: String? = null,
    val phone2: String? = null,
    val balance: Double? = null,
    val balanceByUniqueCode: Double? = null,
    val notes: String? = null,
    val segment: String? = null,
    val payInfo: String? = null,
    val addresses: List<CustomerAddress>? = null,
    val deletedAddressNames: List<String>? = null,
    val deleted: Boolean? = false
) {

    @Transient
    var cleanedPhone: String = ""

    @Transient
    var codeWithoutDigit: String = ""

    @Transient
    var codeDigit: Int = 0

    fun shippingAddressLine(): String? {
        return addresses?.firstOrNull { it.type == "SHIPPING" }?.let {
            "${it.city.orEmpty()} ${it.street.orEmpty()} ${it.streetNo.orEmpty()}"
        }
    }
}