package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import uz.ferro.shop.util.localized

@Serializable
data class PropertyValue(
    val id: Int? = null,
    val property: Property? = null,
    val value: DictItem? = null,
    val icon: DataFile? = null
) {

    fun getLocalizedName() = property?.name.localized()
}