package uz.ferro.shop.pages.admin.order

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.CircularProgress
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Paper
import mui.material.Select
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.Context
import react.VFC
import react.createContext
import react.router.useParams
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.error.HttpException
import uz.ferro.shop.manager.CustomerManager
import uz.ferro.shop.manager.OrderManager
import uz.ferro.shop.manager.cleanPhone
import uz.ferro.shop.model.Customer
import uz.ferro.shop.model.Order
import uz.ferro.shop.model.OrderProduct
import uz.ferro.shop.model.OrderStatus
import uz.ferro.shop.model.Product
import uz.ferro.shop.model.Role
import uz.ferro.shop.model.displayStatus
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.pages.admin.customer.AdminCustomerDetailsDialog
import uz.ferro.shop.pages.admin.customer.CustomersPickerDialog
import uz.ferro.shop.pages.admin.customer.MatchedCustomersDialog
import uz.ferro.shop.pages.admin.product.AdminProductPicker
import uz.ferro.shop.ui.AppText
import uz.ferro.shop.ui.AppTextMedium
import uz.ferro.shop.util.formatUzbPhone
import uz.ferro.shop.util.localized
import web.cssom.AlignContent
import web.cssom.AlignItems
import web.cssom.Flex
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.pct
import web.cssom.px

val OrderContext: Context<Order> = createContext(Order())

val AdminOrderDetails = VFC {
    val pathParam = useParams()["orderId"] ?: return@VFC
    val orderId = pathParam.toLongOrNull() ?: return@VFC
    var order by useState(Order())
    val customerManager = CustomerManager
    var showAddCustomerDialog by useState(false)
    var showMatchedCustomersDialog by useState(false)
    var showCustomerPicker by useState(false)
    var showProductPicker by useState(false)
    var editMode by useState(false)
    var deleteConfirmationOrderProduct by useState<OrderProduct>()
    var showSendConfirmation by useState(false)
    var showStatusDialog by useState(false)
    var isOrderUpdateState by useState(false)
    var isB2B = false
    var customerInfo by useState<Customer>()
    var selectedStatus by useState(OrderStatus.NEW)


    val locale = useContext(LocaleContext)
    val alertManager = useContext(AlertManagerContext)

    useEffectOnce {
        MainScope().launch {
            customerManager.getCustomers()
        }
    }

    var savedOrderState = Order()

    fun loadOrder() {
        MainScope().launch {
            try {
                val result = OrderManager.getAdminOrder(orderId)
                savedOrderState = result.deepCopy()
                isB2B = result.customer?.role == Role.B2B_USER
                order = result
                selectedStatus = result.status

                if (result.customerExternalId.orEmpty().isNotBlank()) {
                    try {
                        customerInfo = CustomerManager.getCustomerById(result.customerExternalId.orEmpty())
                    } catch (e: Exception) {
                        e.printStackTrace()
                    }
                }
            } catch (e: Exception) {
                // no-op
            }
        }
    }

    fun reloadPage() {
        order = Order()
        loadOrder()
    }

    fun saveOrder(order: Order, reload: Boolean = false) {
        MainScope().launch {
            try {
                val result = OrderManager.saveOrder(order)
                savedOrderState = result.deepCopy()
                if (reload) {
                    editMode = false
                    reloadPage()
                }
            } catch (e: Exception) {
                //no-op
            }
        }
    }

    useEffectOnce {
        loadOrder()
    }

    fun addCustomer() {
        MainScope().launch {
            try {
                val result = customerManager.getMatchesByPhone(order.customerPhone.orEmpty())
                if (result.isNotEmpty()) {
                    showMatchedCustomersDialog = true
                } else {
                    showAddCustomerDialog = true
                }
            } catch (e: Exception) {
                alertManager.handleError(e)
            }
        }
    }

    fun changeCustomer() {
        showCustomerPicker = true
    }

    fun openProductPicker() {
        showProductPicker = true
    }

    fun setCustomer(customer: Customer) {
        showAddCustomerDialog = false
        showMatchedCustomersDialog = false
        showCustomerPicker = false
        val ord = order.copy(
            customerName = customer.name,
            customerPhone = customer.phone1,
            customerExternalId = customer.id
        )

        order = ord
        saveOrder(ord)
    }

    fun addProduct(product: Product) {
        val ord = order
        val list = ord.products.toMutableList()
        list.add(
            OrderProduct(
                product = product,
                quantity = 1,
                productPrice = product.price,
                discountPercent = product.discountPercent
            )
        )
        order = ord.copy(
            products = list.toList()
        )
        showProductPicker = false
    }

    fun sendOrder() {
        MainScope().launch {
            isOrderUpdateState = true

            try {
                val resultOrder = OrderManager.sendOrder(order)
                order = resultOrder
            } catch (e: Exception) {
                if (e is HttpException) {
                    alertManager.showError(e.errorResponse)
                }
            } finally {
                isOrderUpdateState = false
            }
        }
    }

    fun updateStatus(status: OrderStatus) {
        MainScope().launch {
            isOrderUpdateState = true

            try {
                val resultOrder = OrderManager.updateStatus(order, status)
                order = resultOrder
            } catch (e: Exception) {
                if (e is HttpException) {
                    alertManager.showError(e.errorResponse)
                }
            } finally {
                isOrderUpdateState = false
            }
        }
    }

    if (order.id == null) {
        Stack {
            sx {
                width = 100.pct
                height = 100.pct
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                alignContent = AlignContent.center
            }
            CircularProgress()
        }

        return@VFC
    }

    OrderContext(order) {
        Stack {
            direction = responsive(StackDirection.column)
            sx {
                padding = 16.px
            }

            Box {

            }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(16.px)

                Stack {
                    direction = responsive(StackDirection.column)

                    Box {
                        +"Mijoz"
                    }

                    Paper {
                        sx {
                            marginTop = 8.px
                            padding = 16.px
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                alignItems = AlignItems.end
                            }
                            AppText {
                                text = "Ismi"
                            }

                            AppTextMedium {
                                sx {
                                    marginLeft = 8.px
                                }
                                size = 18
                                text = if (isB2B) {
                                    order.customer?.name.orEmpty()
                                } else {
                                    order.customerName
                                }
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                alignItems = AlignItems.end
                                marginTop = 4.px
                            }
                            AppText {
                                text = "Telefon"
                            }

                            AppTextMedium {
                                sx {
                                    marginLeft = 8.px
                                }
                                size = 18
                                text = formatUzbPhone(order.customerPhone)
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                alignItems = AlignItems.end
                                marginTop = 4.px
                            }
                            AppText {
                                text = "Mijoz kodi"
                            }

                            AppTextMedium {
                                sx {
                                    marginLeft = 8.px
                                }
                                size = 18
                                text = order.customerExternalId
                            }
                        }


                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                alignItems = AlignItems.end
                                marginTop = 4.px
                            }
                            AppText {
                                text = "Balans"
                            }

                            AppTextMedium {
                                sx {
                                    marginLeft = 8.px
                                }
                                size = 18
                                text =
                                    customerInfo?.balanceByUniqueCode?.toString() ?: customerInfo?.balance?.toString()
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                alignItems = AlignItems.end
                                marginTop = 4.px
                            }
                            AppText {
                                text = "Izoh"
                            }

                            AppTextMedium {
                                sx {
                                    marginLeft = 8.px
                                }
                                size = 18
                                text = customerInfo?.payInfo
                            }
                        }

                        Stack {
                            sx {
                                alignItems = AlignItems.center
                                marginTop = 4.px
                            }
                            if (false && order.customer?.role != Role.B2B_USER) {
                                if (order.customerExternalId == null) {
                                    Button {
                                        sx {
                                            marginLeft = 32.px
                                        }
                                        size = Size.small
                                        variant = ButtonVariant.contained
                                        +"Kontaktga qo‘shish"

                                        onClick = {
                                            addCustomer()
                                        }
                                    }
                                } else {
                                    Button {
                                        sx {
                                            marginLeft = 32.px
                                        }
                                        size = Size.small
                                        variant = ButtonVariant.contained
                                        +"O‘zgartirish"

                                        onClick = {
                                            changeCustomer()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                Stack {
                    direction = responsive(StackDirection.column)
                    Box {
                        +"Buyurtma holati"
                    }

                    Paper {
                        sx {
                            marginTop = 8.px
                            padding = 16.px
                            flex = Flex.maxContent
                        }

                        Stack {
                            direction = responsive(StackDirection.column)

                            if (order.externalDocNum.orEmpty().isNotBlank()) {
                                AppText {
                                    text = "Hujjat nomeri"
                                }

                                AppTextMedium {
                                    sx {
                                        marginTop = 4.px
                                    }
                                    size = 18
                                    text = order.externalDocNum
                                }

                                Box {
                                    sx {
                                        height = 16.px
                                    }
                                }
                            }

                            AppTextMedium {
                                size = 18
                                text = order.displayStatus()
                            }

                            Box {
                                sx {
                                    position = Position.relative
                                    marginTop = 16.px
                                }

                                if (order.status == OrderStatus.NEW) {
                                    Button {
                                        size = Size.small
                                        variant = ButtonVariant.contained
                                        +"SAPga jo‘natish"
                                        disabled = isOrderUpdateState

                                        onClick = {
                                            showSendConfirmation = true
                                        }
                                    }
                                } else {
                                    Button {
                                        size = Size.small
                                        variant = ButtonVariant.contained
                                        +"O‘zgartirish"
                                        disabled = isOrderUpdateState

                                        onClick = {
                                            showStatusDialog = true
                                        }
                                    }
                                }


                                if (isOrderUpdateState) {
                                    CircularProgress {
                                        size = 24.px
                                        sx {
                                            position = Position.absolute
                                            top = 50.pct
                                            left = 50.pct
                                            marginTop = (-12).px
                                            marginLeft = (-12).px
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                sx {
                    marginTop = 32.px
                    alignItems = AlignItems.center
                }
                Box {
                    +"Maxsulotlar"
                }

                if (editMode.not() && order.status == OrderStatus.NEW) {
                    Button {
                        sx {
                            marginLeft = 32.px
                        }
                        size = Size.small
                        variant = ButtonVariant.contained
                        +"Tahrirlash"

                        onClick = {
                            editMode = true
                        }
                    }
                }

                if (editMode && order.status == OrderStatus.NEW) {
                    Button {
                        sx {
                            marginLeft = 32.px
                        }
                        size = Size.small
                        variant = ButtonVariant.contained
                        +"Saqlash"
                        onClick = {
                            saveOrder(order = order, reload = true)
                        }
                    }

                    Box {
                        sx {
                            flex = Flex.maxContent
                        }
                    }

                    Button {
                        sx {
                        }
                        size = Size.small
                        variant = ButtonVariant.contained
                        +"Yangi qo'shish"
                        onClick = {
                            openProductPicker()
                        }
                    }
                }
            }

            if (editMode.not()) {
                OrderProductList()
            } else {
                EditableOrderProductList {
                    onDeleteClick = {
                        deleteConfirmationOrderProduct = it
                    }
                }
            }
        }
    }

    if (showAddCustomerDialog) {
        AdminCustomerDetailsDialog {
            filledName = order.customerName
            filledPhone = cleanPhone(order.customerPhone)
            onClose = {
                showAddCustomerDialog = false
            }
            onSaveSuccess = ::setCustomer
        }
    }

    if (showMatchedCustomersDialog) {
        MatchedCustomersDialog {
            phone = order.customerPhone.orEmpty()
            onPicked = ::setCustomer
            onClose = {
                showMatchedCustomersDialog = false
            }
            onAddNewClick = {
                showMatchedCustomersDialog = false
                showAddCustomerDialog = true
            }
        }
    }

    if (showCustomerPicker) {
        CustomersPickerDialog {
            onPicked = ::setCustomer
            onClose = {
                showCustomerPicker = false
            }
            onAddNewClick = {
                showCustomerPicker = false
                showAddCustomerDialog = true
            }
        }
    }

    if (showProductPicker) {
        AdminProductPicker {
            isOpen = showProductPicker
            onClose = {
                showProductPicker = false
            }
            onItemSelected = { product ->
                addProduct(product)
            }
        }
    }

    if (deleteConfirmationOrderProduct != null) {
        fun closeDeleteConfirmationDialog() {
            deleteConfirmationOrderProduct = null
        }
        Dialog {
            open = deleteConfirmationOrderProduct != null
            onClose = { _, _ ->
                closeDeleteConfirmationDialog()
            }

            fullWidth = true

            DialogTitle {
                +"O'chirishni tasdiqlaysizmi?"
            }
            DialogContent {
                Box {
                    sx {
                        marginTop = 8.px
                    }

                    Box {
                        +deleteConfirmationOrderProduct?.product?.name.localized()
                    }
                }

                DialogActions {
                    Button {
                        onClick = {
                            closeDeleteConfirmationDialog()
                        }
                        +"Bekor"
                    }
                    Button {
                        onClick = {
                            val orderProduct = deleteConfirmationOrderProduct
                            val ord = order
                            val list = ord.products.toMutableList()
                            list.remove(orderProduct)
                            order = ord.copy(
                                products = list.toList()
                            )
                            closeDeleteConfirmationDialog()
                        }
                        +"O'chirish"
                    }
                }
            }
        }
    }


    if (showSendConfirmation) {
        fun closeSendConfirmDialog() {
            showSendConfirmation = false
        }
        Dialog {
            open = showSendConfirmation
            onClose = { _, _ ->
                closeSendConfirmDialog()
            }

            fullWidth = true

            DialogTitle {
                +"SAPga jo'natish"
            }
            DialogContent {
                +"Buyurtmani jo'natishni tasdiqlaysizmi?"
            }
            DialogActions {
                Button {
                    onClick = {
                        closeSendConfirmDialog()
                    }
                    +"Bekor"
                }
                Button {
                    onClick = {
                        closeSendConfirmDialog()
                        sendOrder()
                    }
                    +"Jo'natish"
                }
            }
        }
    }

    if (showStatusDialog) {
        fun closeStatusDialog() {
            showStatusDialog = false
        }

        Dialog {
            sx {
                minWidth = 400.px
            }
            open = showStatusDialog
            onClose = { _, _ ->
                closeStatusDialog()
            }

            DialogTitle {
                +"Holatini o‘zgartirish"
            }
            DialogContent {
                Box {
                    InputLabel {
                        +"Holat"
                    }
                    Select {
                        sx {
                            width = 300.px
                        }
                        value = selectedStatus.name
                        onChange = { evt, _ ->
                            val name = evt.target.value
                            if (name.isNotBlank()) {
                                selectedStatus = OrderStatus.entries.first { it.name == name }
                            }
                        }

                        OrderStatus.entries
                            .filter { it != OrderStatus.NEW }
                            .forEach {
                                MenuItem {
                                    value = it.name
                                    +it.displayStatus()
                                }
                            }
                    }
                }

            }
            DialogActions {
                Button {
                    onClick = {
                        closeStatusDialog()
                    }
                    +"Bekor"
                }
                Button {
                    onClick = {
                        closeStatusDialog()
                        updateStatus(selectedStatus)
                    }
                    +"O‘zgartirish"
                }
            }
        }
    }
}