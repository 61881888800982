package uz.ferro.shop.pages.admin.dict

import mui.material.FormControlVariant
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.useEffect
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.model.DictItem
import uz.ferro.shop.ui.AppText
import web.cssom.LineStyle
import web.cssom.px
import web.html.HTMLInputElement

external interface DictItemProps : Props {
    var title: String?
    var item: DictItem?
    var onChange: ((DictItem) -> Unit)?
    var multiline: Boolean?
}

val DictItemEditor = FC<DictItemProps> { props ->
    var item by useState(props.item ?: DictItem())
    useEffect(props.item) {
        if (props.item != null) {
            item = props.item!!
        }
    }
    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(16.px)

        sx {
            borderRadius = 8.px
            padding = 12.px
            borderStyle = LineStyle.solid
            borderWidth = 1.px
            borderColor = Colors.borderPrimary
        }

        AppText {
            text = props.title
        }

        TextField {
            label = ReactNode("Uzb (Lotin)")
            variant = FormControlVariant.outlined
            value = item.textUzLat.orEmpty()
            multiline = props.multiline
            if (props.multiline == true) {
                maxRows = 5
            }
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                val updated = item.copy(textUzLat = event.target.value)
                item = updated
                props.onChange?.invoke(updated)
            }
        }

        TextField {
            label = ReactNode("Uzb (Kirill)")
            variant = FormControlVariant.outlined
            value = item.textUzCyr.orEmpty()
            multiline = props.multiline
            if (props.multiline == true) {
                maxRows = 5
            }
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                val updated = item.copy(textUzCyr = event.target.value)
                item = updated
                props.onChange?.invoke(updated)
            }
        }

        TextField {
            label = ReactNode("Rus")
            variant = FormControlVariant.outlined
            value = item.textRu.orEmpty()
            multiline = props.multiline
            if (props.multiline == true) {
                maxRows = 5
            }
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                val updated = item.copy(textRu = event.target.value)
                item = updated
                props.onChange?.invoke(updated)
            }
        }

        TextField {
            label = ReactNode("Eng")
            variant = FormControlVariant.outlined
            value = item.textEn.orEmpty()
            multiline = props.multiline
            if (props.multiline == true) {
                maxRows = 5
            }
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                val updated = item.copy(textEn = event.target.value)
                item = updated
                props.onChange?.invoke(updated)
            }
        }
    }
}