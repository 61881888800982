package uz.ferro.shop.pages.admin.banner

import uz.ferro.shop.api.suspendDelete
import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.api.suspendPut
import uz.ferro.shop.model.ComponentBanner
import uz.ferro.shop.util.orZero

private const val API_PATH_SECTION = "component-banner"

object ComponentBannerManager {

    suspend fun getComponentBanner(id: Int): ComponentBanner {
        return suspendGet("$API_PATH_SECTION/$id")
    }

    suspend fun saveComponentBanner(componentBanner: ComponentBanner): ComponentBanner {
        return if (componentBanner.id.orZero() > 0) {
            updateComponentCategory(componentBanner)
        } else {
            addComponentCategory(componentBanner)
        }
    }

    suspend fun deleteComponentBanner(id: Int) {
        suspendDelete("$API_PATH_SECTION/$id")
    }

    private suspend fun addComponentCategory(componentBanner: ComponentBanner): ComponentBanner {
        return suspendPost(
            path = API_PATH_SECTION,
            body = componentBanner
        )
    }

    private suspend fun updateComponentCategory(componentBanner: ComponentBanner): ComponentBanner {
        return suspendPut(
            path = API_PATH_SECTION,
            body = componentBanner
        )
    }
}