package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class User(
    val id: Int? = null,
    val externalId: String? = null,
    val phone: String? = null,
    val name: String? = null,
    val username: String? = null,
    val password: String? = null,
    val role: Role? = null,
    val profileImage: DataFile? = null,
    val warehouses: List<String>? = null,
    val disabled: Boolean? = null
)