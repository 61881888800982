package uz.ferro.shop.pages.product

import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableHead
import mui.material.TableRow
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.useContext
import react.useEffect
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.CartIconRed
import uz.ferro.shop.icon.CartIconRed16
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.model.Product
import uz.ferro.shop.pages.MarketContext
import uz.ferro.shop.pages.cart.QuantityItemView
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextBoldLarge
import uz.ferro.shop.ui.AppTextBoldSmall
import uz.ferro.shop.ui.AppTextBoldSmallest
import uz.ferro.shop.util.formatActualPrice
import uz.ferro.shop.util.formatPrice
import uz.ferro.shop.util.getActualPrice
import uz.ferro.shop.util.isDiscounted
import uz.ferro.shop.util.orZero
import uz.ferro.shop.util.styleInMobile
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Length
import web.cssom.LineStyle
import web.cssom.None
import web.cssom.Overflow
import web.cssom.TextAlign
import web.cssom.TextOverflow
import web.cssom.TextTransform
import web.cssom.WhiteSpace
import web.cssom.em
import web.cssom.integer
import web.cssom.pct
import web.cssom.px

external interface ChildProductsViewProps : Props {
    var products: List<Product>
}

val ChildProductsView = FC<ChildProductsViewProps> { props ->
    val locale = useContext(LocaleContext)
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()

    if (isMobile) {
        ChildProductsViewMobile {
            products = props.products
        }
    } else {
        Box {
            sx {
                borderRadius = 5.px
                borderWidth = 1.px
                borderColor = Colors.borderPrimary
                borderStyle = LineStyle.solid
            }

            Table {
                TableHead {
                    TableRow {
                        sx {
                            backgroundColor = Colors.secondaryBackground
                        }

                        TableCell {
                            AppTextBold {
                                text = locale.naming
                            }
                        }

                        TableCell {
                            sx {
                                textAlign = TextAlign.center
                                width = 0.px
                            }
                        }

                        TableCell {
                            sx {
                                textAlign = TextAlign.center
                                width = 0.px
                            }
                            AppTextBold {
                                text = locale.price
                            }
                        }

                        TableCell {
                            sx {
                                textAlign = TextAlign.center
                                width = 0.px
                            }
                            AppTextBold {
                                text = locale.measureUnit
                            }
                        }

                        TableCell {
                            sx {
                                textAlign = TextAlign.center
                                width = 0.px
                            }
                            AppTextBold {
                                text = locale.quantity
                            }
                        }

                        TableCell {
                            sx {
                                width = 0.px
                            }
                            +""
                        }
                    }
                }

                TableBody {
                    props.products.forEach { product ->
                        ChildProductItemView {
                            this.product = product
                        }
                    }
                }
            }
        }
    }
}

external interface ChildProductItemViewProps : Props {
    var product: Product
}

val ChildProductItemView = FC<ChildProductItemViewProps> { props ->
    val product = props.product
    val marketContext = useContext(MarketContext)
    val navigator = useContext(NavigationContext)
    val locale = useContext(LocaleContext)
    var quantity by useState(1)
    var totalPrice by useState(product.localPrice.orZero())
    val language = LanguageManager.currentLanguage

    fun calculatePrice(qty: Int) {
        totalPrice = product.localPrice.orZero() * qty
    }

    fun addToCart() {
        if (quantity == 0) return
        marketContext.addToCart(product, quantity)
    }

    TableRow {
        hover = true
        key = product.id.toString()

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                cursor = Cursor.pointer
            }

            onClick = {
                navigator.openProductDetails(product.id!!)
            }

            Stack {
                direction = responsive(StackDirection.column)
                sx {
                    justifyContent = JustifyContent.center
                }
                AppTextBoldSmall {
                    text = product.getLocalizedName()
                }

                val amountInPack = product.getAmountInPack()?.takeIf { it.isNotBlank() }
                val amountInBox = product.getAmountInBox()?.takeIf { it.isNotBlank() }

                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        marginTop = 2.px
                    }

                    if (amountInPack != null) {
                        AppTextBoldSmallest {
                            color = Colors.textSecondary
                            text = (locale.amountInPack + ": " + amountInPack)
                        }

                        Box {
                            sx {
                                width = 32.px
                            }
                        }
                    }

                    if (amountInBox != null) {
                        AppTextBoldSmallest {
                            color = Colors.textSecondary
                            text = (locale.amountInBox + ": " + amountInBox)
                        }
                    }
                }
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.end
                width = 0.px
            }

            if (product.isDiscounted) {
                Box {
                    sx {
                        height = 20.px
                        borderRadius = 2.px
                        backgroundColor = Colors.discountLabelColor
                        color = Colors.white
                        paddingLeft = 4.px
                        paddingRight = 4.px
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        fontWeight = FontWeight.bold
                        fontSize = 13.px
                    }
                    +"-${product.discountPercent}%"
                }
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = product.formatActualPrice()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = product.unit?.getLocalizedName().orEmpty()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            if (product.externalId.orEmpty().isNotBlank()) {
                Box {
                    sx {
                        display = Display.inlineBlock
                    }

                    if (product.isNotAvailable) {
                        Box {
                            sx {
                                display = Display.flex
                                alignItems = AlignItems.center
                                width = Length.fitContent
                                maxWidth = 100.pct
                                backgroundColor = Colors.amountNotEnoughLabel
                                borderRadius = 2.px
                                color = Colors.white
                                fontSize = styleInMobile(10.px, 12.px)
                                fontWeight = integer(700)
                                paddingLeft = 8.px
                                paddingRight = 8.px
                                height = 2.em
                                textOverflow = TextOverflow.ellipsis
                                whiteSpace = WhiteSpace.nowrap
                                maxLines = integer(1)
                                overflow = Overflow.hidden
                                textTransform = TextTransform.uppercase
                            }
                            +locale.itemNotAvailable
                        }
                    } else {
                        QuantityItemView {
                            size = Size.small
                            initialValue = quantity
                            changeValue = {
                                quantity = it
                                calculatePrice(it)
                            }
                        }
                    }
                }
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                width = 0.px
                textAlign = TextAlign.center
            }

            if (product.isAvailable) {
                Button {
                    sx {
                        textTransform = None.none
                    }
                    variant = ButtonVariant.outlined
                    startIcon = CartIconRed16.create()
                    size = Size.small
                    onClick = { addToCart() }
                    +locale.add
                }
            }
        }
    }
}

val ChildProductsViewMobile = FC<ChildProductsViewProps> { props ->
    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(16.px)

        props.products.forEach { product ->
            ChildProductItemViewMobile {
                this.product = product
            }
        }
    }
}

val ChildProductItemViewMobile = FC<ChildProductItemViewProps> { props ->
    val product = props.product
    val marketContext = useContext(MarketContext)
    val navigator = useContext(NavigationContext)
    val locale = useContext(LocaleContext)
    var quantity by useState(1)
    var totalUsdPrice by useState(product.price)

    fun calculatePrice(qty: Int) {
        totalUsdPrice = product.getActualPrice().times(qty)
    }

    useEffect(props.product) {
        quantity = 1
        calculatePrice(1)
    }

    fun addToCart() {
        if (quantity == 0) return
        marketContext.addToCart(product, quantity)
    }

    fun openDetails() {
        navigator.openProductDetails(product.id!!)
    }

    Stack {
        direction = responsive(StackDirection.column)
        sx {
            width = 100.pct
            borderStyle = LineStyle.solid
            borderColor = Colors.borderPrimary
            borderWidth = 1.px
            borderRadius = 5.px
            padding = 12.px
        }

        AppTextBold {
            text = product.getLocalizedName()
            onClick = ::openDetails
        }

        val amountInPack = product.getAmountInPack()?.takeIf { it.isNotBlank() }
        val amountInBox = product.getAmountInBox()?.takeIf { it.isNotBlank() }

        if (amountInPack != null) {
            AppTextBoldSmallest {
                sx {
                    marginTop = 2.px
                }
                color = Colors.textSecondary
                text = (locale.amountInPack + ": " + amountInPack)
            }
        }

        if (amountInBox != null) {
            AppTextBoldSmallest {
                sx {
                    marginTop = 2.px
                }
                color = Colors.textSecondary
                text = (locale.amountInBox + ": " + amountInBox)
            }
        }

        Box {
            sx {
                display = Display.inlineBlock
                marginTop = 12.px
            }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(16.px)
                sx {
                    alignItems = AlignItems.center
                }

                if (product.isNotAvailable) {
                    Box {
                        sx {
                            display = Display.flex
                            alignItems = AlignItems.center
                            width = Length.fitContent
                            maxWidth = 100.pct
                            backgroundColor = Colors.amountNotEnoughLabel
                            borderRadius = 2.px
                            color = Colors.white
                            fontSize = styleInMobile(10.px, 12.px)
                            fontWeight = integer(700)
                            paddingLeft = 8.px
                            paddingRight = 8.px
                            height = 2.em
                            textOverflow = TextOverflow.ellipsis
                            whiteSpace = WhiteSpace.nowrap
                            maxLines = integer(1)
                            overflow = Overflow.hidden
                            textTransform = TextTransform.uppercase
                        }
                        +locale.itemNotAvailable
                    }
                } else {
                    QuantityItemView {
                        size = Size.medium
                        initialValue = quantity
                        changeValue = {
                            quantity = it
                            calculatePrice(it)
                        }
                    }

                    AppTextBold {
                        text = product.unit?.getLocalizedName().orEmpty()
                    }
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
                marginTop = 12.px
            }

            AppTextBoldLarge {
                text = if (totalUsdPrice != null) {
                    totalUsdPrice.formatPrice()
                } else {
                    ""
                }
            }

            if (product.isDiscounted) {
                Box {
                    sx {
                        height = 20.px
                        borderRadius = 2.px
                        backgroundColor = Colors.discountLabelColor
                        color = Colors.white
                        paddingLeft = 4.px
                        paddingRight = 4.px
                        display = Display.flex
                        flexDirection = FlexDirection.column
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        fontWeight = FontWeight.bold
                        fontSize = 13.px
                    }
                    +"-${product.discountPercent}%"
                }
            }

            if (product.isAvailable) {
                Stack {
                    direction = responsive(StackDirection.row)

                    sx {
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.center
                        width = 40.px
                        height = 40.px
                        borderRadius = 3.px
                        borderWidth = 1.px
                        borderColor = Colors.accentColor
                        borderStyle = LineStyle.solid
                        cursor = Cursor.pointer
                    }

                    Box {
                        sx {
                            width = 24.px
                            height = 24.px
                        }

                        CartIconRed()
                    }

                    onClick = {
                        if (quantity > 0) {
                            addToCart()
                        }
                        it.preventDefault()
                        it.stopPropagation()
                    }
                }
            }
        }
    }
}

