package uz.ferro.shop.pages.cart

import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TableCell
import mui.material.TableRow
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import react.useEffect
import react.useState
import uz.ferro.shop.CartContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.DeleteIcon
import uz.ferro.shop.icon.DeleteIcon20
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.model.CartProduct
import uz.ferro.shop.model.Country
import uz.ferro.shop.model.PHOTO_SIZE_SMALL
import uz.ferro.shop.pages.MarketContext
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextBoldSmall
import uz.ferro.shop.ui.AppTextBoldSmallest
import uz.ferro.shop.ui.BorderType
import uz.ferro.shop.ui.CountryFlagView
import uz.ferro.shop.ui.ImageView
import uz.ferro.shop.util.formatActualPrice
import uz.ferro.shop.util.formatActualPriceWithUnit
import uz.ferro.shop.util.formatPrice
import uz.ferro.shop.util.formatPriceWithQuantity
import uz.ferro.shop.util.getActualPrice
import uz.ferro.shop.util.styleInMobile
import web.cssom.AlignItems
import web.cssom.BackgroundRepeat
import web.cssom.BackgroundSize
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.GeometryPosition
import web.cssom.JustifyContent
import web.cssom.Length
import web.cssom.LineStyle
import web.cssom.None
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.TextAlign
import web.cssom.TextOverflow
import web.cssom.TextTransform
import web.cssom.WhiteSpace
import web.cssom.WordWrap
import web.cssom.em
import web.cssom.integer
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.cssom.url

external interface CartItemViewProps : Props {
    var cartProduct: CartProduct
    var refreshCallback: () -> Unit
    var onTotalChanged: () -> Unit
}

val CartItemView = FC<CartItemViewProps> { props ->
    val cartProduct = props.cartProduct
    val product = cartProduct.product

    val marketContext = useContext(MarketContext)
    val navigator = useContext(NavigationContext)
    val cartManager = useContext(CartContext)
    val locale = useContext(LocaleContext)
    val language = LanguageManager.currentLanguage

    var quantity by useState(cartProduct.quantity)
    var totalPrice by useState("")

    useEffect(quantity) {
        totalPrice = product.formatPriceWithQuantity(quantity)
    }

    fun openDetails() {
        navigator.openProductDetails(product.id!!)
    }

    val photoUrl = product.getPrimaryPhotoUrl(PHOTO_SIZE_SMALL)

    TableRow {
        hover = true
        key = product.id.toString()

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                cursor = Cursor.pointer
            }

            onClick = {
                navigator.openProductDetails(product.id!!)
            }

            ImageView {
                sx {
                    width = 48.px
                    height = 48.px
                }
                borderType = BorderType.Radius3
                url = photoUrl
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                cursor = Cursor.pointer
            }

            onClick = {
                navigator.openProductDetails(product.id!!)
            }

            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(4.px)

                AppTextBoldSmall {
                    text = product.getLocalizedName()
                }

                val amountInPack = product.getAmountInPack()?.takeIf { it.isNotBlank() }
                val amountInBox = product.getAmountInBox()?.takeIf { it.isNotBlank() }

                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        marginTop = 2.px
                    }

                    if (amountInPack != null) {
                        AppTextBoldSmallest {
                            color = Colors.textSecondary
                            text = (locale.amountInPack + ": " + amountInPack)
                        }

                        Box {
                            sx {
                                width = 24.px
                            }
                        }
                    }

                    if (amountInBox != null) {
                        AppTextBoldSmallest {
                            color = Colors.textSecondary
                            text = (locale.amountInBox + ": " + amountInBox)
                        }
                    }
                }
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 1.pct
                whiteSpace = WhiteSpace.nowrap
            }

            if (product.isNotAvailable) {
                Box {
                    sx {
                        display = Display.flex
                        alignItems = AlignItems.center
                        width = Length.fitContent
                        maxWidth = 100.pct
                        backgroundColor = Colors.amountNotEnoughLabel
                        borderRadius = 2.px
                        color = Colors.white
                        fontSize = styleInMobile(10.px, 12.px)
                        fontWeight = integer(700)
                        paddingLeft = 8.px
                        paddingRight = 8.px
                        height = 2.em
                        textOverflow = TextOverflow.ellipsis
                        whiteSpace = WhiteSpace.nowrap
                        maxLines = integer(1)
                        overflow = Overflow.hidden
                        textTransform = TextTransform.uppercase
                    }
                    +locale.itemNotAvailable
                }
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = product.formatActualPrice()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = product.unit?.getLocalizedName().orEmpty()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            Box {
                sx {
                    display = Display.inlineBlock
                }
                QuantityItemView {
                    this.size = Size.small
                    this.initialValue = quantity
                    this.changeValue = { newValue ->
                        quantity = newValue
                        cartProduct.quantity = quantity
                        cartManager.changeQuantity(product.id!!, newValue)
                        props.onTotalChanged()
                    }
                }
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                width = 150.px
                textAlign = TextAlign.center
            }

            AppTextBoldSmall {
                text = totalPrice
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                width = 0.px
                textAlign = TextAlign.center
            }

            Button {
                sx {
                    textTransform = None.none
                    width = 32.px
                    height = 32.px
                    minWidth = 0.px
                }
                variant = ButtonVariant.outlined
                size = Size.small
                onClick = {
                    marketContext.removeProduct(product)
                    props.refreshCallback()
                }

                DeleteIcon20()
            }
        }

    }


    if (false) {
        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(16.px)

            sx {
                width = 100.pct
                borderWidth = 1.px
                borderColor = Colors.borderPrimary
                borderStyle = LineStyle.solid
                borderRadius = 5.px
                overflow = Overflow.hidden
                position = Position.relative
                padding = 16.px
            }

            Box {
                sx {
                    width = 154.px
                    height = 154.px
                    borderWidth = 1.px
                    borderColor = Colors.borderPrimary
                    borderStyle = LineStyle.solid
                    borderRadius = 10.px
                    padding = 16.px

                    if (photoUrl.isNotBlank()) {
                        backgroundImage = url(photoUrl)
                    }
                    backgroundPosition = GeometryPosition.center
                    backgroundSize = BackgroundSize.contain
                    backgroundRepeat = BackgroundRepeat.noRepeat
                    cursor = Cursor.pointer
                }

                onClick = { openDetails() }
            }

            Stack {
                direction = responsive(StackDirection.column)
                sx {
                    flexGrow = number(1.0)
                }

                Box {
                    sx {
                        fontSize = 13.px
                        fontWeight = integer(500)
                        color = Colors.accentColor
                    }
                    +(locale.productCode + ": " + product.externalId.orEmpty())
                }

                Box {
                    sx {
                        fontSize = 16.px
                        fontWeight = integer(700)
                        color = Colors.textPrimary
                        marginTop = 12.px
                        lineHeight = 1.2.em
                        height = 2.4.em
                        cursor = Cursor.pointer
                    }
                    onClick = { openDetails() }
                    +product.getLocalizedName()
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(8.px)
                    sx {
                        marginTop = 8.px
                    }
                    Box {
                        sx {
                            fontSize = 13.px
                            height = 16.px
                            fontWeight = integer(500)
                            color = Colors.textSecondary
                            wordWrap = WordWrap.breakWord
                            overflow = Overflow.hidden
                        }
                        +(locale.vendorCountry + ":")
                    }

                    CountryFlagView {
                        this.country = Country.fromShortName(product.country.orEmpty())
                    }
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        marginTop = 16.px
                        alignItems = AlignItems.center
                    }

                    QuantityItemView {
                        this.size = Size.large
                        this.initialValue = quantity
                        this.changeValue = { newValue ->
                            quantity = newValue
                            cartProduct.quantity = quantity
                            cartManager.changeQuantity(product.id!!, newValue)
                            props.onTotalChanged()
                        }
                    }

                    Box {
                        sx {
                            flexGrow = number(1.0)
                        }
                    }

                    Stack {
                        direction = responsive(StackDirection.column)
                        spacing = responsive(2.px)
                        sx {
                            justifyContent = JustifyContent.spaceBetween
                        }

                        Box {
                            sx {
                                fontSize = 20.px
                                fontWeight = integer(700)
                                color = Colors.accentColor
                            }
                            +totalPrice
                        }

                        Box {
                            sx {
                                fontSize = 13.px
                                fontWeight = integer(600)
                                color = Colors.textSecondary
                            }
                            +product.formatActualPriceWithUnit()
                        }
                    }

                    Box {
                        sx {
                            flexGrow = number(3.0)
                        }
                    }

                    Box {
                        sx {
                            display = Display.flex
                            alignItems = AlignItems.center
                            justifyContent = JustifyContent.center
                            width = 48.px
                            height = 48.px
                            borderRadius = 3.px
                            borderWidth = 1.px
                            borderColor = Colors.accentColor
                            borderStyle = LineStyle.solid
                            cursor = Cursor.pointer
                        }

                        Box {
                            sx {
                                width = 24.px
                                height = 24.px
                            }

                            DeleteIcon()
                        }

                        onClick = {
                            marketContext.removeProduct(product)
                            props.refreshCallback()
                        }
                    }
                }
            }
        }
    }
}

val CartItemViewMobile = FC<CartItemViewProps> { props ->
    val cartProduct = props.cartProduct
    val product = cartProduct.product

    val marketContext = useContext(MarketContext)
    val navigator = useContext(NavigationContext)
    val cartManager = useContext(CartContext)
    val locale = useContext(LocaleContext)

    var quantity by useState(cartProduct.quantity)
    var totalUsdPrice: Double? by useState()

    useEffect(quantity) {
        totalUsdPrice = product.getActualPrice().times(quantity)
    }

    val photoUrl = product.getPrimaryPhotoUrl(PHOTO_SIZE_SMALL)

    Stack {
        direction = responsive(StackDirection.column)
        sx {
            width = 100.pct
            borderStyle = LineStyle.solid
            borderColor = Colors.borderPrimary
            borderWidth = 1.px
            borderRadius = 5.px
            padding = 12.px
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(8.px)

            Box {
                sx {
                    width = 48.px
                    height = 48.px
                    flexShrink = number(0.0)
                    borderStyle = LineStyle.solid
                    borderWidth = 1.px
                    borderRadius = 3.px
                    borderColor = Colors.borderPrimary
                    if (photoUrl.isNotBlank()) {
                        backgroundImage = url(photoUrl)
                    }
                    backgroundPosition = GeometryPosition.center
                    backgroundSize = BackgroundSize.contain
                    backgroundRepeat = BackgroundRepeat.noRepeat
                }
            }

            Stack {
                direction = responsive(StackDirection.column)
                AppTextBoldSmall {
                    text = product.getLocalizedName()
                    onClick = {
                        navigator.openProductDetails(product.id!!)
                    }
                }

                val amountInPack = product.getAmountInPack()?.takeIf { it.isNotBlank() }
                val amountInBox = product.getAmountInBox()?.takeIf { it.isNotBlank() }

                if (amountInPack != null) {
                    AppTextBoldSmallest {
                        sx {
                            marginTop = 2.px
                        }
                        color = Colors.textSecondary
                        text = (locale.amountInPack + ": " + amountInPack)
                    }
                }

                if (amountInBox != null) {
                    AppTextBoldSmallest {
                        sx {
                            marginTop = 2.px
                        }
                        color = Colors.textSecondary
                        text = (locale.amountInBox + ": " + amountInBox)
                    }
                }
            }


        }

        Box {
            sx {
                display = Display.inlineBlock
                marginTop = 12.px
            }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(16.px)
                sx {
                    alignItems = AlignItems.center
                }

                if (product.isAvailable) {
                    QuantityItemView {
                        size = Size.medium
                        initialValue = quantity
                        changeValue = {
                            quantity = it
                            cartProduct.quantity = quantity
                            cartManager.changeQuantity(product.id!!, it)
                            props.onTotalChanged()
                        }
                    }

                    AppTextBold {
                        text = product.unit?.getLocalizedName().orEmpty()
                    }
                } else {
                    Box {
                        sx {
                            display = Display.flex
                            alignItems = AlignItems.center
                            width = Length.fitContent
                            maxWidth = 100.pct
                            backgroundColor = Colors.amountNotEnoughLabel
                            borderRadius = 2.px
                            color = Colors.white
                            fontSize = styleInMobile(10.px, 12.px)
                            fontWeight = integer(700)
                            paddingLeft = 8.px
                            paddingRight = 8.px
                            height = 2.em
                            textOverflow = TextOverflow.ellipsis
                            whiteSpace = WhiteSpace.nowrap
                            maxLines = integer(1)
                            overflow = Overflow.hidden
                            textTransform = TextTransform.uppercase
                        }
                        +locale.itemNotAvailable
                    }
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
                marginTop = 12.px
            }

            AppTextBold {
                text = totalUsdPrice.formatPrice()
            }

            Stack {
                direction = responsive(StackDirection.row)

                sx {
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                    width = 40.px
                    height = 40.px
                    borderRadius = 3.px
                    borderWidth = 1.px
                    borderColor = Colors.accentColor
                    borderStyle = LineStyle.solid
                    cursor = Cursor.pointer
                }

                Box {
                    sx {
                        width = 20.px
                        height = 20.px
                    }

                    DeleteIcon20()
                }

                onClick = {
                    marketContext.removeProduct(product)
                    props.refreshCallback()
                    it.preventDefault()
                    it.stopPropagation()
                }
            }
        }

    }
}


