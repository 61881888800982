package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import uz.ferro.shop.serializer.ComponentTypeSerializer

@Serializable
data class Component(
    val id: Int? = null,

    @Serializable(with = ComponentTypeSerializer::class)
    var type: ComponentType? = null,

    val banners: List<ComponentBanner>? = null,

    var categories: List<ComponentCategory>? = null,

    var filterMenuList: List<ComponentMenuFilter>? = null,

    var filterGroups: List<ComponentFilterGroup>? = null
)