package uz.ferro.shop.pages.admin.productgroup

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Fab
import mui.material.FabColor
import mui.material.InputLabel
import mui.material.Paper
import mui.material.Size
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableHead
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.router.dom.useSearchParams
import react.router.useParams
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.PARAM_HISTORY
import uz.ferro.shop.VALUE_NO_BACK
import uz.ferro.shop.api.saveProductGroup
import uz.ferro.shop.manager.ProductGroupManager
import uz.ferro.shop.model.ProductGroup
import uz.ferro.shop.model.ProductGroupProduct
import uz.ferro.shop.navigator.NavigationPage
import uz.ferro.shop.pages.admin.dict.DictItemEditor
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.pct
import web.cssom.px

val AdminEditProductGroup = FC<Props> {
    val pathParam = useParams()["productGroupId"] ?: return@FC
    val historyParam = useSearchParams().component1()[PARAM_HISTORY]

    val navigator = useContext(NavigationContext)
    var productGroup by useState(ProductGroup())
    val productGroupId = pathParam.toIntOrNull()
    var showGroupProductView by useState(false)
    var selectedGroupProduct by useState<ProductGroupProduct?>(null)

    var groupProducts by useState<List<ProductGroupProduct>>(emptyList())

    fun refreshProducts() {
        productGroupId ?: return
        MainScope().launch {
            groupProducts = ProductGroupManager.getProductGroupProducts((productGroupId))
        }
    }

    fun goBack() {
        if (historyParam == VALUE_NO_BACK) {
            navigator.openPage(NavigationPage.ADMIN_PRODUCT_GROUPS)
        } else {
            navigator.goBack()
        }
    }

    useEffectOnce {
        if (productGroupId != null) {
            MainScope().launch {
                productGroup = ProductGroupManager.getProductGroup(productGroupId)
            }
            refreshProducts()
        }
    }

    AdminEditProductGroupProductView {
        isOpen = showGroupProductView
        onClose = {
            selectedGroupProduct = null
            showGroupProductView = false
        }
        onItemSaved = {
            refreshProducts()
        }
        onItemDeleted = {
            refreshProducts()
        }
        productGroupProduct = selectedGroupProduct
        existingProductsIdList = groupProducts.mapNotNull { it.product?.id }
    }

    Box {
        sx {
            padding = 16.px
        }

        InputLabel {
            +"Maxsulot guruhi"
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
                alignItems = AlignItems.center
            }

            DictItemEditor {
                title = "Nomi"
                item = productGroup.name
                onChange = {
                    productGroup = productGroup.copy(name = it)
                }
            }

            Button {
                sx {
                    marginLeft = 16.px
                }
                variant = ButtonVariant.contained
                size = Size.small
                onClick = {
                    if (productGroup.name?.textUzLat.orEmpty().isNotBlank()) {
                        saveProductGroup(productGroup)
                            .then {
                                goBack()
                            }
                    }
                }

                +"Saqlash"
            }
        }

        if (productGroupId != null) {
            Box {
                sx {
                    marginTop = 32.px
                    display = Display.flex
                    flexDirection = FlexDirection.row
                    alignItems = AlignItems.center
                }

                InputLabel {
                    +"Maxsulotlar"
                }

                Fab {
                    sx {
                        marginLeft = 200.px
                    }
                    size = Size.small
                    color = FabColor.primary
                    Add()
                    onClick = {
                        selectedGroupProduct = ProductGroupProduct(
                            productGroup = productGroup,
                            sort = groupProducts.maxOfOrNull { it.sort.orZero() }.orZero() + 1
                        )
                        showGroupProductView = true
                    }
                }
            }

            Paper {
                sx {
                    width = 100.pct
                    height = 800.px
                    marginTop = 16.px
                    overflow = Overflow.scroll
                    position = Position.relative
                }

                Table {
                    sx {
                        position = Position.absolute
                        top = 0.px
                        right = 0.px
                        bottom = 0.px
                        left = 0.px
                    }

                    stickyHeader = true
                    TableHead {
                        TableRow {
                            TableCell {
                                sx {
                                    width = 120.px
                                }
                                +"Kod"
                            }
                            TableCell {
                                align = TableCellAlign.left
                                +"Nomi"
                            }
                        }
                    }
                    TableBody {
                        groupProducts.forEach { groupProduct ->
                            TableRow {
                                hover = true
                                key = groupProduct.id.toString()

                                onClick = {
                                    selectedGroupProduct = groupProduct.copy(
                                        productGroup = productGroup
                                    )
                                    showGroupProductView = true
                                }

                                TableCell {
                                    +groupProduct.product?.externalId.orEmpty()
                                }

                                TableCell {
                                    +groupProduct.product?.name.localized()
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
