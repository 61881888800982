package uz.ferro.shop.ui.category

import emotion.react.css
import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.base.ClickAwayListener
import mui.material.Accordion
import mui.material.AccordionDetails
import mui.material.AccordionSummary
import mui.material.Box
import mui.material.Grid
import mui.material.Paper
import mui.material.Popper
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import popper.core.Placement
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import react.useContext
import react.useEffect
import react.useEffectOnce
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.CategoryHamburgerIcon
import uz.ferro.shop.icon.ChevronRightIcon
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.manager.PageContentManager
import uz.ferro.shop.model.Category
import uz.ferro.shop.model.ComponentType
import uz.ferro.shop.navigator.NavigationPage.ALL_PRODUCTS
import uz.ferro.shop.pages.category.CategoryManager
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextBoldSmall
import uz.ferro.shop.util.hoverAccentColor
import uz.ferro.shop.util.selectableItemBackground
import uz.ferro.shop.util.sourceUrl
import uz.ferro.shop.util.xs
import web.cssom.AlignItems
import web.cssom.Border
import web.cssom.ClassName
import web.cssom.Cursor
import web.cssom.LineStyle
import web.cssom.TextTransform
import web.cssom.integer
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.dom.Element

data class CategoryMenuItemModel(
    var category: Category,
    var expanded: Boolean = false
)

var popupCloseJob: Job? = null

external interface CategoryMenuProps : Props {
    var stretchHeight: Boolean
    var isToggleMode: Boolean
    var isExpandedByDefault: Boolean
}

val CategoryMenu = FC<CategoryMenuProps> { props ->
    val locale = useContext(LocaleContext)
    val language = LanguageManager.currentLanguage

    var categories by useState(listOf<CategoryMenuItemModel>())
    val stretchHeight = props.stretchHeight

    var anchor by useState<Element>()
    var isPopupOpen by useState(false)
    var subcategories by useState<List<Category>>()
    var isExpanded by useState(props.isToggleMode.not() || props.isExpandedByDefault)

    fun loadCategories() {
        MainScope().launch {
            categories = PageContentManager.getGeneralContent()
                .findComponent(ComponentType.GENERIC)?.categories.orEmpty()
                .mapNotNull { it.category }
                .map { CategoryMenuItemModel(category = it) }
        }
    }

    fun cancelPopupClose() {
        popupCloseJob?.cancel()
    }

    fun openPopup(id: Int) {
        cancelPopupClose()
        MainScope().launch {
            val subResult = CategoryManager.getSubCategories(id)
                .sortedBy { it.getLocalizedName() }
            subcategories = subResult
            isPopupOpen = subResult.isNotEmpty()
        }
    }

    fun closePopup() {
        isPopupOpen = false
    }

    fun closePopupWithDelay() {
        popupCloseJob?.cancel()
        popupCloseJob = MainScope().launch {
            delay(300)
            closePopup()
        }
    }

    useEffectOnce {
        loadCategories()
    }

    val navigator = useContext(NavigationContext)

    fun hoverCategory(id: Int?) {
        id ?: return
        openPopup(id)
    }

    fun unhoverCategory(id: Int?) {
        id ?: return
        closePopupWithDelay()
    }

    Stack {
        direction = responsive(StackDirection.column)
        sx {
            if (stretchHeight) {
                height = 100.pct
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                borderTopLeftRadius = 5.px
                borderTopRightRadius = 5.px
                if (isExpanded.not()) {
                    borderBottomLeftRadius = 5.px
                    borderBottomRightRadius = 5.px
                }

                backgroundColor = Colors.accentColor
                alignItems = AlignItems.center
                padding = 16.px
            }

            Box {
                sx {
                    fontWeight = integer(700)
                    fontSize = 20.px
                    color = Colors.accentContrastColor
                    flexGrow = number(1.0)
                }

                +locale.productsCatalog
            }

            Box {
                sx {
                    width = 24.px
                    height = 24.px
                    cursor = Cursor.pointer
                }

                CategoryHamburgerIcon()

                onClick = {
                    if (props.isToggleMode) {
                        isExpanded = !isExpanded
                    }
                }
            }
        }

        if (isExpanded) {
            Box {
                val borderCss = Border(1.px, LineStyle.solid, Colors.categoryBorder)
                sx {
                    borderBottomLeftRadius = 5.px
                    borderBottomRightRadius = 5.px
                    borderLeft = borderCss
                    borderBottom = borderCss
                    borderRight = borderCss
                    if (stretchHeight) {
                        flexGrow = number(1.0)
                    }
                }

                categories.forEach { mc ->
                    val cat = mc.category
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(12.px)

                        sx {
                            alignItems = AlignItems.center
                            cursor = Cursor.pointer
                            paddingLeft = 12.px
                            paddingRight = 12.px
                            paddingTop = 10.px
                            paddingBottom = 10.px
                        }

                        selectableItemBackground()

                        onClick = {
                            navigator.openCategory(mc.category.id)
                        }

                        if (cat.icon.sourceUrl().isNotBlank()) {
                            img {
                                css {
                                    flexShrink = number(0.0)
                                    width = 36.px
                                    height = 36.px
                                }
                                src = cat.icon.sourceUrl()
                            }
                        } else {
                            Box {
                                sx {
                                    flexShrink = number(0.0)
                                    width = 36.px
                                    height = 36.px
                                }
                            }
                        }

                        Box {
                            sx {
                                flexGrow = number(1.0)
                                fontSize = 16.px
                                fontWeight = integer(700)
                                color = Colors.textPrimary
                            }

                            css(ClassName("line-clamp-2")) {}

                            +cat.getLocalizedName()
                        }

                        ChevronRightIcon()

                        onMouseEnter = {
                            anchor = it.currentTarget
                            hoverCategory(cat.id)
                        }

                        onMouseLeave = {
                            unhoverCategory(cat.id)
                        }
                    }
                }

                if (categories.isNotEmpty()) {
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(12.px)

                        sx {
                            alignItems = AlignItems.center
                            cursor = Cursor.pointer
                            paddingLeft = 12.px
                            paddingRight = 12.px
                            paddingTop = 10.px
                            paddingBottom = 10.px
                        }

                        selectableItemBackground()

                        onClick = {
                            navigator.openPage(ALL_PRODUCTS)
                        }

                        val iconUrl = ""
                        if (iconUrl.isNotBlank()) {
                            img {
                                css {
                                    flexShrink = number(0.0)
                                    width = 36.px
                                    height = 36.px
                                }
                                src = iconUrl
                            }
                        } else {
                            Box {
                                sx {
                                    flexShrink = number(0.0)
                                    width = 36.px
                                    height = 36.px
                                }
                            }
                        }

                        Box {
                            sx {
                                flexGrow = number(1.0)
                                fontSize = 16.px
                                fontWeight = integer(700)
                                color = Colors.textPrimary
                            }

                            css(ClassName("line-clamp-2")) {}

                            +locale.all.uppercase()
                        }

                        ChevronRightIcon()
                    }
                }
            }
        }
    }

    Popper {
        anchorEl = anchor
        open = isPopupOpen
        placement = Placement.rightStart

        Paper {
            sx {
                paddingTop = 20.px
                paddingBottom = 20.px
                paddingLeft = 30.px
                paddingRight = 30.px
            }

            onMouseEnter = {
                cancelPopupClose()
            }

            onMouseLeave = {
                closePopupWithDelay()
            }

            mui.material.List {
                sx {
                    columnCount = integer(2)
                    marginBlockStart = 0.px
                    marginBlockEnd = 0.px
                }

                subcategories?.forEachIndexed { i, cat ->
                    Box {
                        sx {
                            fontSize = 13.px
                            fontWeight = integer(700)
                            color = Colors.textPrimary
                            textTransform = TextTransform.uppercase
                            if (i != 0) {
                                marginTop = 18.px
                            }
                            cursor = Cursor.pointer
                        }

                        hoverAccentColor()

                        onClick = {
                            navigator.openCategory(cat.id)
                            closePopup()
                        }

                        +cat.getLocalizedName()
                    }
                }
            }
        }
    }
}

external interface MobileCategoriesPopupProps : Props {
    var targetElement: Element?
    var isOpen: Boolean
    var close: () -> Unit
}

val HomeCategoryMenuMobile = FC<Props> {
    val locale = useContext(LocaleContext)
    val navigator = useContext(NavigationContext)
    var categories by useState(listOf<CategoryMenuItemModel>())

    fun loadCategories() {
        MainScope().launch {
            categories = PageContentManager.getGeneralContent()
                .findComponent(ComponentType.GENERIC)?.categories.orEmpty()
                .mapNotNull { it.category }
                .map { CategoryMenuItemModel(category = it) }
        }
    }

    useEffectOnce {
        loadCategories()
    }

    Box {
        sx {
            height = 20.px
        }
    }

    Grid {
        container = true
        spacing = responsive(8.px)

        categories.forEach { mc ->
            val cat = mc.category
            Grid {
                item = true
                xs = 6

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(8.px)

                    sx {
                        alignItems = AlignItems.center
                        cursor = Cursor.pointer
                        width = 100.pct
                        height = 44.px
                        borderStyle = LineStyle.solid
                        borderColor = Colors.borderPrimary
                        borderWidth = 1.px
                        borderRadius = 5.px
                        padding = 10.px
                    }

                    if (cat.icon.sourceUrl().isNotBlank()) {
                        img {
                            css {
                                flexShrink = number(0.0)
                                width = 24.px
                                height = 24.px
                            }
                            src = cat.icon.sourceUrl()
                        }
                    } else {
                        Box {
                            sx {
                                flexShrink = number(0.0)
                                width = 24.px
                                height = 24.px
                            }
                        }
                    }

                    Box {
                        sx {
                            fontSize = 13.px
                            fontWeight = integer(700)
                            flexGrow = number(1.0)
                            color = Colors.textPrimary
                            maxLines = integer(2)
                        }

                        css(ClassName("line-clamp-2")) {}

                        +cat.getLocalizedName()
                    }

                    onClick = {
                        navigator.openCategory(cat.id)
                        it.preventDefault()
                        it.stopPropagation()
                    }
                }
            }
        }

        if (categories.isNotEmpty()) {
            Grid {
                item = true
                xs = 6

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(8.px)

                    sx {
                        alignItems = AlignItems.center
                        cursor = Cursor.pointer
                        width = 100.pct
                        height = 44.px
                        borderStyle = LineStyle.solid
                        borderColor = Colors.borderPrimary
                        borderWidth = 1.px
                        borderRadius = 5.px
                        padding = 10.px
                    }

                    val iconUrl = ""
                    if (iconUrl.isNotBlank()) {
                        img {
                            css {
                                flexShrink = number(0.0)
                                width = 24.px
                                height = 24.px
                            }
                            src = iconUrl
                        }
                    } else {
                        Box {
                            sx {
                                flexShrink = number(0.0)
                                width = 24.px
                                height = 24.px
                            }
                        }
                    }

                    Box {
                        sx {
                            fontSize = 13.px
                            fontWeight = integer(700)
                            flexGrow = number(1.0)
                            color = Colors.textPrimary
                            maxLines = integer(2)
                        }

                        css(ClassName("line-clamp-2")) {}

                        +locale.all.uppercase()
                    }

                    onClick = {
                        navigator.openPage(ALL_PRODUCTS)
                        it.preventDefault()
                        it.stopPropagation()
                    }
                }
            }
        }
    }
}

val MobileCategoriesPopup = FC<MobileCategoriesPopupProps> { props ->
    val locale = useContext(LocaleContext)
    val navigator = useContext(NavigationContext)
    val appContext = useContext(AppContext)

    var categories by useState(listOf<CategoryMenuItemModel>())

    useEffect(props.isOpen) {
        if (props.isOpen) {
            MainScope().launch {
                categories = PageContentManager.getGeneralContent()
                    .findComponent(ComponentType.GENERIC)?.categories.orEmpty()
                    .mapNotNull { it.category }
                    .map { CategoryMenuItemModel(category = it) }
            }
        }
    }

    ClickAwayListener {
        onClickAway = {
            if (props.isOpen) {
                props.close()
            }
            it.preventDefault()
            it.stopPropagation()
        }


        Popper {
            sx {
                width = 100.pct
                paddingLeft = 16.px
                paddingRight = 16.px
                minHeight = 100.px
                zIndex = integer(9999)
            }
            anchorEl = props.targetElement
            open = props.isOpen
            placement = Placement.bottom

            Stack {
                direction = responsive(StackDirection.column)
                sx {
                    width = 100.pct
                }

                categories.forEach { mc ->
                    val cat = mc.category
                    Accordion {
                        sx {
                        }
                        disableGutters = true
                        AccordionSummary {
                            sx {
                                borderBottomStyle = LineStyle.hidden
                                minHeight = 56.px
                            }
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(8.px)

                                sx {
                                    alignItems = AlignItems.center
                                    cursor = Cursor.pointer
                                    width = 100.pct
                                }

                                if (cat.icon.sourceUrl().isNotBlank()) {
                                    img {
                                        css {
                                            flexShrink = number(0.0)
                                            width = 24.px
                                            height = 24.px
                                        }
                                        src = cat.icon.sourceUrl()
                                    }
                                } else {
                                    Box {
                                        sx {
                                            flexShrink = number(0.0)
                                            width = 24.px
                                            height = 24.px
                                        }
                                    }
                                }

                                AppTextBold {
                                    sx {
                                        flexGrow = number(1.0)
                                    }
                                    text = cat.getLocalizedName()
                                }

                                ChevronRightIcon()

                                if (cat.subCategories.orEmpty().isEmpty()) {
                                    onClick = {
                                        navigator.openCategory(cat.id)
                                        props.close()
                                        it.preventDefault()
                                        it.stopPropagation()
                                    }
                                }
                            }
                        }

                        AccordionDetails {
                            Stack {
                                direction = responsive(StackDirection.column)
                                cat.subCategories.orEmpty().sortedBy { it.getLocalizedName() }
                                    .forEach { subCat ->
                                        AppTextBoldSmall {
                                            sx {
                                                paddingTop = 10.px
                                                paddingBottom = 10.px
                                            }
                                            text = subCat.getLocalizedName()

                                            onClick = {
                                                navigator.openCategory(subCat.id)
                                                props.close()
                                            }
                                        }
                                    }
                            }
                        }
                    }
                }

                if (categories.isNotEmpty()) {
                    Accordion {
                        sx {
                        }
                        disableGutters = true
                        AccordionSummary {
                            sx {
                                borderBottomStyle = LineStyle.hidden
                                minHeight = 56.px
                            }
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(8.px)

                                sx {
                                    alignItems = AlignItems.center
                                    cursor = Cursor.pointer
                                    width = 100.pct
                                }

                                val iconUrl = ""
                                if (iconUrl.isNotBlank()) {
                                    img {
                                        css {
                                            flexShrink = number(0.0)
                                            width = 24.px
                                            height = 24.px
                                        }
                                        src = iconUrl
                                    }
                                } else {
                                    Box {
                                        sx {
                                            flexShrink = number(0.0)
                                            width = 24.px
                                            height = 24.px
                                        }
                                    }
                                }

                                AppTextBold {
                                    sx {
                                        flexGrow = number(1.0)
                                    }
                                    text = locale.all.uppercase()
                                }

                                ChevronRightIcon()

                                onClick = {
                                    navigator.openPage(ALL_PRODUCTS)
                                    props.close()
                                    it.preventDefault()
                                    it.stopPropagation()
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
