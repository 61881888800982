package uz.ferro.shop.pages.admin.document

import kotlinx.serialization.Serializable
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.model.AccessKey
import uz.ferro.shop.model.AccessKeyGroup
import uz.ferro.shop.model.AccessKeyType
import uz.ferro.shop.util.apiDate
import kotlin.js.Date

object AccessKeyManager {

    suspend fun createGroup(): AccessKeyGroup {
        val expire = Date(Date.now() + 30 * 86_000_000.0)
        val group = AccessKeyGroup(expireDate = expire.apiDate())
        return suspendPost("access-key/group", group)
    }

    suspend fun generate(groupId: Long, idList: List<Long>): List<AccessKey> {
        val body = GenerateBody(
            groupId = groupId,
            type = AccessKeyType.PRODUCT,
            targetIdList = idList
        )
        return suspendPost("access-key/generate", body)
    }
}

@Serializable
data class GenerateBody(
    val groupId: Long,
    val type: AccessKeyType,
    val targetIdList: List<Long>
)