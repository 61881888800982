package uz.ferro.shop.pages.product

import uz.ferro.shop.model.Product

class ProductNameComparator: Comparator<Product> {

    override fun compare(a: Product, b: Product): Int {

        val nameA = a.getLocalizedName()
        val nameB = b.getLocalizedName()

        val matchThreeSizeA = THREE_SIZE_REGEX.find(nameA)
        val matchThreeSizeB = THREE_SIZE_REGEX.find(nameB)

        val matchTwoSizeA = TWO_SIZE_REGEX.find(nameA)
        val matchTwoSizeB = TWO_SIZE_REGEX.find(nameB)

        val matchOneSizeA = ONE_SIZE_REGEX.find(nameA)
        val matchOneSizeB = ONE_SIZE_REGEX.find(nameB)

        val defSize = 0f
        val sizesA = floatArrayOf(defSize, defSize, defSize)
        val sizesB = floatArrayOf(defSize, defSize, defSize)

        fun MatchResult.floatGroup(index: Int): Float = groupValues[index].toFloatOrNull() ?: defSize

        when {
            matchThreeSizeA != null -> {
                sizesA[0] = matchThreeSizeA.floatGroup(1)
                sizesA[1] = matchThreeSizeA.floatGroup(2)
                sizesA[2] = matchThreeSizeA.floatGroup(3)
            }

            matchTwoSizeA != null -> {
                sizesA[0] = matchTwoSizeA.floatGroup(1)
                sizesA[1] = matchTwoSizeA.floatGroup(2)
            }

            matchOneSizeA != null -> {
                sizesA[0] = matchOneSizeA.floatGroup(1)
            }
        }

        when {
            matchThreeSizeB != null -> {
                sizesB[0] = matchThreeSizeB.floatGroup(1)
                sizesB[1] = matchThreeSizeB.floatGroup(2)
                sizesB[2] = matchThreeSizeB.floatGroup(3)
            }

            matchTwoSizeB != null -> {
                sizesB[0] = matchTwoSizeB.floatGroup(1)
                sizesB[1] = matchTwoSizeB.floatGroup(2)
            }

            matchOneSizeB != null -> {
                sizesB[0] = matchOneSizeB.floatGroup(1)
            }
        }

        val compare = compareSizes(sizesA, sizesB)

        return if (compare == 0) {
            nameA.compareTo(nameB)
        } else {
            compare
        }
    }

    private fun compareSizes(sizesA: FloatArray, sizesB: FloatArray): Int {
        val arrSize = minOf(sizesA.size, sizesB.size)
        for (i in 0..<arrSize) {
            val sizeA = sizesA[i]
            val sizeB = sizesB[i]
            if (sizeA != sizeB) {
                return sizeA.compareTo(sizeB)
            }
        }

        return 0
    }

    companion object {
        private val ONE_SIZE_REGEX = Regex("(\\d+(?:\\.\\d+)?)(?:mm|мм)?$")
        private val TWO_SIZE_REGEX = Regex("(\\d+(?:\\.\\d+)?)[xX](\\d+(?:\\.\\d+)?)(?:mm|мм)?$")
        private val THREE_SIZE_REGEX = Regex("(\\d+(?:\\.\\d+)?)[xX](\\d+(?:\\.\\d+)?)[xX](\\d+(?:\\.\\d+)?)(?:mm|мм)?$")
    }
}