package uz.ferro.shop.pages.admin

import emotion.react.css
import react.FC
import react.Props
import react.VFC
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.useContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.navigator.NavigationPage
import uz.ferro.shop.manager.AuthManager.isSalesManager
import uz.ferro.shop.manager.AuthManager.isSuperAdmin
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.LineStyle
import web.cssom.None
import web.cssom.pct
import web.cssom.px

external interface MenuItemProps : Props {
    var item: MenuItem
}

data class MenuItem(val page: NavigationPage, val name: String)

val AdminMenu = VFC {
    val menu: MutableList<MenuItem> = mutableListOf(
        MenuItem(
            page = NavigationPage.ADMIN_HOME,
            name = "Asosiy Sahifa"
        ),
        MenuItem(
            page = NavigationPage.ADMIN_BANNERS,
            name = "Bannerlar"
        ),
        MenuItem(
            page = NavigationPage.ADMIN_CATEGORIES,
            name = "Kategoriya"
        ),
        MenuItem(
            page = NavigationPage.ADMIN_PRODUCTS,
            name = "Maxsulotlar"
        ),
        MenuItem(
            page = NavigationPage.ADMIN_PRODUCT_GROUPS,
            name = "Maxsulotlar Guruhlari"
        ),
        MenuItem(
            page = NavigationPage.ADMIN_DEALERS,
            name = "Dilerlar"
        ),
        MenuItem(
            page = NavigationPage.ADMIN_BRANDS,
            name = "Brend"
        ),
        MenuItem(
            page = NavigationPage.ADMIN_PRICE_GENERATOR,
            name = "Narxlar ro'yxati"
        )
    )

    if (isSuperAdmin() || isSalesManager()) {
        menu.clear()
    }

    menu.add(
        MenuItem(
            page = NavigationPage.ADMIN_USERS,
            name = "Foydalanuvchilar"
        )
    )

    menu.add(
        MenuItem(
            page = NavigationPage.ADMIN_ORDER,
            name = "Buyurtmalar"
        )
    )

    menu.add(
        MenuItem(
            page = NavigationPage.ADMIN_SETTINGS,
            name = "Sozlashlar"
        )
    )

    menu.add(
        MenuItem(
            page = NavigationPage.ADMIN_HELP,
            name = "Yordam"
        )
    )

    ReactHTML.ul {
        css {
            width = 100.pct
            paddingTop = 50.px
            paddingInlineStart = 0.px
            marginBlockStart = 0.px
            marginBlockEnd = 0.px
        }

        menu.forEach { menuItem: MenuItem ->
            AdminMenuItem {
                item = menuItem
            }
        }
    }
}

val AdminMenuItem = FC<MenuItemProps> { props: MenuItemProps ->
    val navigator = useContext(NavigationContext)
    ReactHTML.li {
        css {
            borderBottomStyle = LineStyle.solid
            borderBottomWidth = 1.px
            borderBottomColor = Color("#7e7e7e")
            cursor = Cursor.pointer
            display = Display.flex
            paddingLeft = 20.px
            paddingRight = 20.px
            alignItems = AlignItems.center
            listStyleType = None.none
            paddingTop = 14.px
            paddingBottom = 14.px
            fontSize = 18.px
        }
        onClick = {
            navigator.openPage(props.item.page)
        }
        div {

            css {
                width = 100.pct
            }
            +props.item.name
        }
    }
}