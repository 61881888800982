package uz.ferro.shop.favorite

import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Size
import mui.system.PropsWithSx
import mui.system.sx
import react.FC
import react.create
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.icon.FavoriteRedStrokeIcon
import uz.ferro.shop.icon.FavoriteWhiteFilledIcon
import uz.ferro.shop.util.styleInMobile
import web.cssom.None
import web.cssom.Overflow
import web.cssom.TextOverflow
import web.cssom.WhiteSpace
import web.cssom.px

external interface FavoriteButtonProps : PropsWithSx {
    var productId: Long?
    var isLabeled: Boolean?
}

val FavoriteButton = FC<FavoriteButtonProps> { props ->
    val productId = props.productId ?: return@FC
    val locale = useContext(LocaleContext)
    var isFavorite by useState(false)

    useEffectOnce {
        isFavorite = FavoriteManager.isFavorite(productId)
    }

    fun toggleFavorite() {
        if (isFavorite) {
            FavoriteManager.removeFavorite(productId)
        } else {
            FavoriteManager.setFavorite(productId)
        }
        isFavorite = !isFavorite
    }

    Button {
        variant = if (isFavorite) {
            ButtonVariant.contained
        } else {
            ButtonVariant.outlined
        }
        if (props.isLabeled == true) {
            size = Size.medium
            startIcon = if (isFavorite) {
                FavoriteWhiteFilledIcon.create()
            } else {
                FavoriteRedStrokeIcon.create()
            }
            +locale.toFavorite
        } else {
            if (isFavorite) {
                FavoriteWhiteFilledIcon()
            } else {
                FavoriteRedStrokeIcon()
            }
        }

        sx {
            borderColor = Colors.accentColor
            textTransform = None.none
            if (props.isLabeled != true) {
                minWidth = 0.px
                width = 38.px
            }

            if (props.sx?.width != null) {
                width = props.sx?.width
            }

            if (props.sx?.marginTop != null) {
                marginTop = props.sx?.marginTop
            }

            height = 38.px
            fontSize = styleInMobile(11.px, 16.px)
            whiteSpace = WhiteSpace.nowrap
            textOverflow = TextOverflow.ellipsis
            overflow = Overflow.hidden
        }

        onClick = {
            toggleFavorite()
        }
    }
}