package uz.ferro.shop.manager

import kotlinx.browser.localStorage
import org.w3c.dom.get
import org.w3c.dom.set
import uz.ferro.shop.KEY_AUTH_TOKEN
import uz.ferro.shop.KEY_ROLE
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.error.HttpException
import uz.ferro.shop.model.AuthBody
import uz.ferro.shop.model.AuthResult
import uz.ferro.shop.model.Role
import uz.ferro.shop.model.SmsAuthBody

object AuthManager {

    var role: Role?
        get() = Role.entries.firstOrNull { it.name == localStorage[KEY_ROLE] }
        set(value) {
            localStorage[KEY_ROLE] = value?.name.orEmpty()
        }

    var token: String?
        get() = localStorage[KEY_AUTH_TOKEN]
        set(value) {
            localStorage[KEY_AUTH_TOKEN] = value.orEmpty()
        }

    fun isAuthorized(): Boolean = token.orEmpty().isNotBlank()

    fun isAdmin(): Boolean = isAuthorized() && role == Role.ADMIN

    fun isSuperAdmin(): Boolean = isAuthorized() && role == Role.SUPER_ADMIN

    fun isSalesManager(): Boolean = isAuthorized() && role == Role.SALES_MANAGER

    fun isSalesAgent(): Boolean = isAuthorized() && role == Role.SALES_AGENT

    fun isB2BUser(): Boolean = isAuthorized() && isRoleB2B()

    fun isRoleB2B(): Boolean = role == Role.B2B_USER

    fun canUseAdminDashboard(): Boolean = isAdmin() || isSuperAdmin() || isSalesManager()

    fun logout() {
        role = null
        token = null
    }

    suspend fun checkAuthorization() {
        if (isAuthorized()) {
            try {
                UserManager.getMe()
            } catch (e: Exception) {
                if (e is HttpException) {
                    if (e.code == 401) {
                        logout()
                    }
                }
            }
        }
    }

    suspend fun authenticate(login: String, password: String): AuthResult {
        val result = suspendPost<AuthBody, AuthResult>(
            path = "auth",
            AuthBody(
                username = login,
                password = password
            )
        )

        if (result.token.orEmpty().isNotBlank()) {
            token = result.token
            role = Role.entries.firstOrNull { it.name == result.user?.role?.name }
        }

        return result
    }

    suspend fun smsConfirm(
        login: String,
        password: String,
        code: String
    ): AuthResult {
        val result = suspendPost<SmsAuthBody, AuthResult>(
            path = "auth/sms-confirm",
            SmsAuthBody(
                username = login,
                password = password,
                code = code
            )
        )

        if (result.token.orEmpty().isNotBlank()) {
            token = result.token
            role = Role.entries.firstOrNull { it.name == result.user?.role?.name }
        }

        return result
    }
}