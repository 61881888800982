package uz.ferro.shop.manager

import uz.ferro.shop.api.suspendDelete
import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.api.suspendPut
import uz.ferro.shop.model.ComponentCategory
import uz.ferro.shop.util.orZero

private const val API_PATH_SECTION = "component-category"

object ComponentCategoryManager {

    suspend fun getComponentCategory(id: Int): ComponentCategory {
        return suspendGet("$API_PATH_SECTION/$id")
    }

    suspend fun saveComponentCategory(componentCategory: ComponentCategory): ComponentCategory {
        return if (componentCategory.id.orZero() > 0) {
            updateComponentCategory(componentCategory)
        } else {
            addComponentCategory(componentCategory)
        }
    }

    suspend fun deleteComponentCategory(id: Int) {
        suspendDelete("$API_PATH_SECTION/$id")
    }

    private suspend fun addComponentCategory(componentCategory: ComponentCategory): ComponentCategory {
        return suspendPost(
            path = API_PATH_SECTION,
            body = componentCategory
        )
    }

    private suspend fun updateComponentCategory(componentCategory: ComponentCategory): ComponentCategory {
        return suspendPut(
            path = API_PATH_SECTION,
            body = componentCategory
        )
    }
}