package uz.ferro.shop.ui

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML
import uz.ferro.shop.model.Country
import web.cssom.Length
import web.cssom.px

external interface CountryFlagProps : Props {
    var country: Country?
    var marginLeft: Length?
}

val CountryFlagView = FC<CountryFlagProps> { props ->
    if (props.country != null) {
        ReactHTML.img {
            css {
                width = 16.px
                height = 16.px
                if (props.marginLeft != null) {
                    marginLeft = props.marginLeft
                }
            }
            src = "https://hatscripts.github.io/circle-flags/flags/${props.country!!.name.lowercase()}.svg"
        }
    }
}