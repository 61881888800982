package uz.ferro.shop.pages.admin.customer

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.Divider
import mui.material.ListItem
import mui.material.ListItemText
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.li
import react.useEffect
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.manager.CustomerManager
import uz.ferro.shop.model.Customer

external interface MatchedCustomersDialogProps : Props {
    var phone: String
    var onPicked: (Customer) -> Unit
    var onAddNewClick: () -> Unit
    var onClose: () -> Unit
}

val MatchedCustomersDialog = FC<MatchedCustomersDialogProps> { props ->

    var matchedCustomers by useState(listOf<Customer>())
    val customerManager = CustomerManager
    var selectedCustomer by useState<Customer>()

    useEffect(props.phone) {
        MainScope().launch {
            try {
                matchedCustomers = customerManager.getMatchesByPhone(props.phone)
            } catch (e: Exception) {
                // no-op
            }
        }
    }

    fun closeDialog() {
        props.onClose()
    }

    Dialog {
        fullWidth = true
        open = true
        onClose = { _, _ -> closeDialog() }
        DialogTitle {
            +"Ushbu telefondagi mijozlar"
        }
        DialogContent {
            mui.material.List {
                matchedCustomers.forEach { customer ->
                    ListItem {
                        sx {
                            if (selectedCustomer == customer) {
                                backgroundColor = Colors.defaultSelectedColor
                            }
                        }
                        onClick = {
                            selectedCustomer = customer
                        }

                        ListItemText {
                            primary = ReactNode(customer.id)
                            secondary = ReactNode(customer.name)
                        }
                    }
                    Divider { component = li }
                }
            }
        }

        DialogActions {
//            Button {
//                onClick = {
//                    props.onAddNewClick()
//                }
//                +"Yangi qo'shish"
//            }
            Button {
                onClick = {
                    props.onPicked(selectedCustomer!!)
                }
                disabled = selectedCustomer == null
                +"Tanlash"
            }
        }
    }
}