package uz.ferro.shop.pages.admin.productgroup

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogContentText
import mui.material.DialogTitle
import mui.material.FormControlVariant
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableRow
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.useContext
import react.useEffect
import react.useState
import uz.ferro.shop.manager.ProductGroupManager
import uz.ferro.shop.model.Product
import uz.ferro.shop.model.ProductGroupProduct
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.pages.admin.product.AdminProductPicker
import uz.ferro.shop.ui.AppTextMedium
import uz.ferro.shop.util.localized
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.html.HTMLInputElement

external interface ProductGroupProductProps : Props {
    var isOpen: Boolean
    var existingProductsIdList: List<Long>
    var productGroupProduct: ProductGroupProduct?
    var onClose: () -> Unit
    var onItemSaved: () -> Unit
    var onItemDeleted: () -> Unit
}

val AdminEditProductGroupProductView = FC<ProductGroupProductProps> { props ->
    val groupProduct: ProductGroupProduct = props.productGroupProduct ?: return@FC
    var showProductPicker by useState(false)
    var productGroupProduct: ProductGroupProduct by useState(groupProduct)
    var currentProduct: Product? by useState(groupProduct.product)
    val alertManager = useContext(AlertManagerContext)
    var showDeleteConfirmDialog by useState(false)

    fun onClose() {
        props.onClose()
    }

    fun openProductPicker() {
        showProductPicker = true
    }

    fun save() {
        MainScope().launch {
            try {
                ProductGroupManager.saveProductGroupProduct(productGroupProduct)
                props.onItemSaved()
                onClose()
            } catch (e: Exception) {
                alertManager.handleError(e)
            }
        }
    }

    fun delete() {
        MainScope().launch {
            try {
                ProductGroupManager.deleteProductGroupProduct(productGroupProduct.id!!)
                props.onItemDeleted()
                onClose()
            } catch (e: Exception) {
                alertManager.handleError(e)
            }
        }
    }

    fun openDeleteConfirm() {
        showDeleteConfirmDialog = true
    }

    fun isSaveEnabled(): Boolean {
        return productGroupProduct.product != null && productGroupProduct.sort != null &&
                productGroupProduct != groupProduct
    }

    useEffect(productGroupProduct) {
        if (productGroupProduct.product == null) {
            openProductPicker()
        }
    }

    Dialog {
        sx {
            minWidth = 400.px
        }
        open = showDeleteConfirmDialog
        onClose = { _, _ -> showDeleteConfirmDialog = false }
        DialogTitle {
            +"O'chirish"
        }
        DialogContent {
            DialogContentText {
                +"O'chirishni tasdiqlaysizmi?"
            }
            DialogActions {
                Button {
                    onClick = { showDeleteConfirmDialog = false }
                    +"Bekor"
                }
                Button {
                    onClick = {
                        delete()
                        showDeleteConfirmDialog = false
                    }
                    +"O'chirish"
                }
            }
        }
    }
    AdminProductPicker {
        isOpen = showProductPicker
        onClose = {
            showProductPicker = false
        }
        onItemSelected = { product ->
            if (groupProduct.product?.id == product.id || props.existingProductsIdList.contains(product.id).not()) {
                productGroupProduct = productGroupProduct.copy(
                    product = product
                )
                currentProduct = product
            }
        }
    }

    Dialog {
        open = props.isOpen
        onClose = { _, _ ->
            onClose()
        }

        fullWidth = true

        DialogTitle {
            +"Guruhdagi mahsulot"
        }
        DialogContent {
            Box {
                sx {
                    marginTop = 8.px
                }

                Table {
                    TableBody {
                        TableRow {
                            hover = true
                            TableCell {
                                AppTextMedium {
                                    text =
                                        "${currentProduct?.externalId.orEmpty()} ${currentProduct?.name.localized()}".trim()
                                }
                            }
                            onClick = {
                                openProductPicker()
                            }
                        }
                    }
                }

                TextField {
                    sx {
                        marginTop = 16.px
                    }
                    label = ReactNode("Tartib raqami")
                    variant = FormControlVariant.outlined
                    value = productGroupProduct.sort
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        val sort = event.target.value.toIntOrNull()
                        if (sort != null) {
                            productGroupProduct = productGroupProduct.copy(sort = sort)
                        }
                    }
                }
            }
            DialogActions {
                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        width = 100.pct
                    }

                    if (productGroupProduct.id != null) {
                        Button {
                            onClick = {
                                openDeleteConfirm()
                            }
                            +"O'chirish"
                        }
                    }

                    Box {
                        sx {
                            flexGrow = number(1.0)
                        }
                    }

                    Button {
                        onClick = { onClose() }
                        +"Bekor"
                    }

                    Button {
                        disabled = isSaveEnabled().not()
                        onClick = { save() }
                        +"Saqlash"
                    }
                }
            }
        }
    }
}