package uz.ferro.shop.pages.admin.product

import mui.material.*
import mui.system.sx
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.useState
import uz.ferro.shop.api.useAllProducts
import uz.ferro.shop.model.Product
import uz.ferro.shop.util.localized
import web.cssom.px
import web.html.HTMLInputElement

external interface ProductPickerProps : Props {
    var isOpen: Boolean
    var onClose: () -> Unit
    var onItemSelected: (Product) -> Unit
}

val AdminProductPicker = FC<ProductPickerProps> { props ->

    val products = useAllProducts()
    var searchQuery by useState("")
    val filteredProducts: List<Product> = if (searchQuery.isNotBlank()) {
        products.filter {
            it.externalId.orEmpty().startsWith(searchQuery) ||
                    it.name.localized().lowercase().contains(searchQuery.trim().lowercase())
        }
    } else products.toList()

    fun onClose() {
        searchQuery = ""
        props.onClose()
    }

    Dialog {
        open = props.isOpen
        onClose = { _, _ ->
            onClose()
        }

        fullWidth = true

        DialogTitle {
            +"Maxsulotlar"
        }
        DialogContent {
            Box {
                sx {
                    marginTop = 8.px
                }

                TextField {
                    sx {
                        paddingTop = 4.px
                        paddingBottom = 4.px
                    }
                    placeholder = "Qidiruv"
                    size = Size.small
                    variant = FormControlVariant.outlined
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        searchQuery = event.target.value
                    }
                }

                Box {
                    sx {
                        height = 500.px
                    }

                    Table {
                        stickyHeader = true
                        TableHead {
                            TableRow {
                                TableCell {
                                    sx {
                                        width = 120.px
                                    }
                                    +"Kod"
                                }
                                TableCell {
                                    align = TableCellAlign.left
                                    +"Nomi"
                                }
                            }
                        }
                        TableBody {
                            filteredProducts.forEach { product ->
                                TableRow {
                                    hover = true
                                    key = product.id.toString()

                                    TableCell {
                                        +product.externalId.orEmpty()
                                    }

                                    TableCell {
                                        +product.name.localized()
                                    }

                                    onClick = {
                                        props.onItemSelected(product)
                                        onClose()
                                    }
                                }
                            }
                        }
                    }
                }
            }
            DialogActions {
                Button {
                    onClick = { onClose() }
                    +"Bekor"
                }
            }
        }
    }
}