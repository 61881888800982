package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class PageContentProductGroup(
    val id: Int? = null,
    val pageContent: PageContent? = null,
    val productGroup: ProductGroup? = null,
    val sort: Int?
)