package uz.ferro.shop.ui

import mui.material.Box
import mui.system.PropsWithSx
import mui.system.sx
import react.FC
import uz.ferro.shop.Colors
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FontWeight
import web.cssom.TextAlign
import web.cssom.integer
import web.cssom.px

external interface AppTextProps : PropsWithSx {
    var weight: FontWeight?
    var text: String?
    var color: Color?
    var size: Int?
    var onClick: (() -> Unit)?
    var center: Boolean?
}

val AppText = FC<AppTextProps> { props ->
    Box {
        sx {
            fontWeight = props.weight ?: integer(400)
            fontSize = props.sx?.fontSize ?: (props.size ?: 16).px
            color = props.color ?: Colors.textPrimary
            display = props.sx?.display ?: Display.inlineBlock

            if (props.sx?.fontStyle != null) {
                fontStyle = props.sx?.fontStyle
            }

            if (props.center == true) {
                textAlign = TextAlign.center
            }

            if (props.sx?.textAlign != null) {
                textAlign = props.sx?.textAlign
            }

            if (props.sx?.verticalAlign != null) {
                verticalAlign = props.sx?.verticalAlign
            }

            if (props.sx?.width != null) {
                width = props.sx?.width
            }

            if (props.sx?.height != null) {
                height = props.sx?.height
            }

            if (props.sx?.maxWidth != null) {
                maxWidth = props.sx?.maxWidth
            }

            if (props.onClick != null) {
                cursor = Cursor.pointer
            }
            if (props.sx?.marginLeft != null) {
                marginLeft = props.sx?.marginLeft
            }

            if (props.sx?.marginRight != null) {
                marginRight = props.sx?.marginRight
            }

            if (props.sx?.marginTop != null) {
                marginTop = props.sx?.marginTop
            }

            if (props.sx?.marginBottom != null) {
                marginBottom = props.sx?.marginBottom
            }

            if (props.sx?.flexGrow != null) {
                flexGrow = props.sx?.flexGrow
            }

            if (props.sx?.padding != null) {
                padding = props.sx?.padding
            }

            if (props.sx?.paddingTop != null) {
                paddingTop = props.sx?.paddingTop
            }

            if (props.sx?.paddingRight != null) {
                paddingRight = props.sx?.paddingRight
            }

            if (props.sx?.paddingBottom != null) {
                paddingBottom = props.sx?.paddingBottom
            }

            if (props.sx?.paddingLeft != null) {
                paddingLeft = props.sx?.paddingLeft
            }

            if (props.sx?.textAlign != null) {
                textAlign = props.sx?.textAlign
            }

            if (props.sx?.textJustify != null) {
                textJustify = props.sx?.textJustify
            }

            if (props.sx?.whiteSpace != null) {
                whiteSpace = props.sx?.whiteSpace
            }
        }

        if (props.onClick != null) {
            onClick = {
                it.preventDefault()
                it.stopPropagation()
                props.onClick?.invoke()
            }
        }

        +props.text.orEmpty()
    }
}

val AppTextSmall = FC<AppTextProps> { props ->
    AppText {
        size = props.size ?: 13
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextItalic = FC<AppTextProps> { props ->
    AppText {
        size = props.size ?: 13
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextMedium = FC<AppTextProps> { props ->
    AppText {
        weight = integer(500)
        size = props.size
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextMediumSmall = FC<AppTextProps> { props ->
    AppTextMedium {
        size = props.size ?: 13
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextSemiBold = FC<AppTextProps> { props ->
    AppText {
        weight = integer(600)
        size = props.size
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextSemiBoldSmall = FC<AppTextProps> { props ->
    AppTextSemiBold {
        size = props.size ?: 13
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextSemiBoldLarge = FC<AppTextProps> { props ->
    AppTextSemiBold {
        size = props.size ?: 20
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextBold = FC<AppTextProps> { props ->
    AppText {
        weight = integer(700)
        size = props.size
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextBoldSmall = FC<AppTextProps> { props ->
    AppTextBold {
        size = props.size ?: 13
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextBoldSmallest = FC<AppTextProps> { props ->
    AppTextBold {
        size = props.size ?: 10
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextBoldLarge = FC<AppTextProps> { props ->
    AppTextBold {
        size = props.size ?: 20
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextBoldExtraLarge = FC<AppTextProps> { props ->
    AppTextBold {
        size = props.size ?: 25
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextBoldGiant = FC<AppTextProps> { props ->
    AppTextBold {
        size = props.size ?: 31
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}

val AppTextExtraBold = FC<AppTextProps> { props ->
    AppText {
        weight = integer(800)
        size = props.size
        color = props.color
        onClick = props.onClick
        text = props.text
        center = props.center
        sx = props.sx
    }
}