package uz.ferro.shop.manager

import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.api.suspendPut
import uz.ferro.shop.model.Order
import uz.ferro.shop.model.OrderStatus

object OrderManager {

    suspend fun getOrderList(date: String): List<Order> {
        val params = if (date.isNotBlank()) {
            mapOf("minUpdateDate" to date)
        } else {
            emptyMap()
        }
        return suspendGet<List<Order>>(path = "admin/order/list/latest", queryParams = params)
    }

    suspend fun getUserOrderList(): List<Order> {
        return suspendGet<List<Order>>("order/list")
    }

    suspend fun getOrder(orderId: Long): Order {
        return suspendGet<Order>("order/${orderId}")
    }

    suspend fun getAdminOrder(orderId: Long): Order {
        return suspendGet<Order>("admin/order/${orderId}")
    }

    suspend fun saveOrder(order: Order): Order {
        return suspendPut(path = "admin/order", body = order)
    }

    suspend fun sendOrder(order: Order): Order {
        return suspendPost(path = "admin/order/send-to-sale", body = order)
    }

    suspend fun updateStatus(order: Order, status: OrderStatus): Order {
        return suspendPost(
            path = "admin/order/update-status",
            body = order.copy(
                status = status
            )
        )
    }

    suspend fun createOrder(order: Order): Order {
        val path = if (AuthManager.isRoleB2B()) {
            if (AuthManager.isAuthorized().not()) throw IllegalStateException("Auth required")
            "order/b2b"
        } else {
            "order/guest"
        }

        return suspendPost(path = path, body = order)
    }
}