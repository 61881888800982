package uz.ferro.shop.pages.admin

import uz.ferro.shop.model.Country
import mui.material.FormControl
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.div

external interface CountryPickerProps : Props {
    var selectedCountry: Country?
    var onCountrySelect: (Country?) -> Unit
}

val CountryPicker = FC<CountryPickerProps> { props ->
    val countries: List<Country> = Country.entries
    val selectedItem = props.selectedCountry
    val onItemSelect = props.onCountrySelect

    val labelTitle = "Ishlab chiqaruvchi mamlakat"

    div {
        FormControl {
            fullWidth = true
            InputLabel {
                +labelTitle
            }
            Select {
                value = selectedItem?.name.orEmpty()
                label = ReactNode(labelTitle)
                onChange = { evt, _ ->
                    val name = evt.target.value
                    val selectedCountry = Country.fromShortName(name)
                    onItemSelect(selectedCountry)
                }

                MenuItem {
                    value = ""
                    +"--"
                }
                countries.forEach {
                    MenuItem {
                        value = it.name
                        +it.title
                    }
                }
            }
        }
    }
}