package uz.ferro.shop.model

import uz.ferro.shop.Colors
import uz.ferro.shop.locale.LanguageManager
import web.cssom.Color

enum class OrderStatus {
    NEW,
    ACCEPTED,
    CANCELED,
    IN_PROGRESS,
    IN_SHIPPING,
    SHIPPED,
    COMPLETED;
}

fun Order.displayStatus(): String = status.displayStatus()

fun OrderStatus.displayStatus(): String {
    val dict = LanguageManager.getCurrentDict()
    return when (this) {
        OrderStatus.NEW -> dict.statusNew
        OrderStatus.ACCEPTED -> dict.statusAccepted
        OrderStatus.IN_PROGRESS -> dict.statusInProgress
        OrderStatus.IN_SHIPPING -> dict.statusShipping
        OrderStatus.SHIPPED -> dict.statusShipped
        OrderStatus.COMPLETED -> dict.statusCompleted
        OrderStatus.CANCELED -> dict.statusCanceled
    }
}

fun OrderStatus.statusBackgroundColor(): Color {
    return when (this) {
        OrderStatus.NEW -> Colors.orderStatusNew
        OrderStatus.ACCEPTED -> Colors.orderStatusAccepted
        OrderStatus.IN_PROGRESS -> Colors.orderStatusInProgress
        OrderStatus.IN_SHIPPING -> Colors.orderStatusInShipping
        OrderStatus.SHIPPED -> Colors.orderStatusInShipping
        OrderStatus.COMPLETED -> Colors.orderStatusCompleted
        OrderStatus.CANCELED -> Colors.orderStatusCanceled
    }
}

