package uz.ferro.shop.model

import kotlinx.serialization.Serializable


@Serializable
data class ProductPhoto(
    val id: Int?,
    val photo: DataFile?,
    val sort: Int?
)