package uz.ferro.shop.pages.admin.salepoint

import uz.ferro.shop.api.suspendDelete
import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.api.suspendPut
import uz.ferro.shop.model.SalePoint
import uz.ferro.shop.util.orZero

object SalePointManager {
    private const val API_PATH_SECTION = "sale-point"

    suspend fun getSalePoints(): List<SalePoint> {
        return suspendGet<List<SalePoint>>("${API_PATH_SECTION}/list")
            .sortedBy { it.sort }
    }

    suspend fun getSalePoint(id: Long): SalePoint {
        return suspendGet("${API_PATH_SECTION}/$id")
    }

    suspend fun saveSalePoint(salePoint: SalePoint): SalePoint {
        return if (salePoint.id.orZero() > 0) {
            updateSalePoint(salePoint)
        } else {
            addSalePoint(salePoint)
        }
    }

    private suspend fun addSalePoint(salePoint: SalePoint): SalePoint {
        return suspendPost(
            path = API_PATH_SECTION,
            body = salePoint
        )
    }

    private suspend fun updateSalePoint(salePoint: SalePoint): SalePoint {
        return suspendPut(
            path = API_PATH_SECTION,
            body = salePoint
        )
    }

    suspend fun deleteSalePoint(id: Long) {
        return suspendDelete("${API_PATH_SECTION}/$id")
    }
}