package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.thumbnailSquareUrl

@Serializable
data class Category(
    val id: Int?,
    val name: DictItem?,
    val icon: DataFile? = null,
    val photo: DataFile? = null,
    val parent: Category? = null,
    val isDisabled: Boolean? = null,
    val subCategories: List<Category>? = null,
    val products: List<Product>? = null
) {

    fun getPhotoUrl(size: Int = PHOTO_SIZE_MEDIUM): String {
        return photo.thumbnailSquareUrl(size)
    }

    fun getRootCategory(): Category? {
        return if (parent != null) {
            parent.getRootCategory()
        } else {
            this
        }
    }

    fun getLocalizedName(): String = name.localized().uppercase()
}
