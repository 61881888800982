package uz.ferro.shop.api

import js.core.Void
import js.core.jso
import tanstack.query.core.QueryKey
import tanstack.react.query.useMutation
import tanstack.react.query.useQuery
import tanstack.react.query.useQueryClient
import uz.ferro.shop.BRANDS_ALL_QUERY_KEY
import uz.ferro.shop.model.Brand
import uz.ferro.shop.util.orZero
import kotlin.js.Promise

fun useAllBrands(): List<Brand> {
    val result = useQuery<List<Brand>, Error, List<Brand>, QueryKey>(
        queryKey = BRANDS_ALL_QUERY_KEY,
        queryFn = { getAllBrands() }
    )
    return result.data.orEmpty()
}

private fun addBrand(brand: Brand): Promise<Brand> {
    return post(path = "brand", body = brand)
}

typealias SaveBrand = (Brand) -> Unit

private fun updateBrand(brand: Brand): Promise<Brand> {
    return put(path = "brand", body = brand)
}

fun useSaveBrand(): SaveBrand {
    val client = useQueryClient()
    return useMutation<Brand, Error, Brand, QueryKey>(
        mutationFn = { brand ->
            if (brand.id.orZero() > 0) {
                updateBrand(brand)
            } else {
                addBrand(brand)
            }
        },
        options = jso {
            onSuccess = { _, _, _ -> client.invalidateQueries<Void>(BRANDS_ALL_QUERY_KEY) }
        }
    ).mutate.unsafeCast<SaveBrand>()
}

fun getAllBrands(): Promise<List<Brand>> {
    return get<List<Brand>>(path = "brand/all")
        .then { data ->
            data.sortedBy { it.name }
        }
}
