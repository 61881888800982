package uz.ferro.shop.pages.admin.order

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Paper
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableHead
import mui.material.TableRow
import mui.system.sx
import react.VFC
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.manager.OrderManager
import uz.ferro.shop.model.Order
import uz.ferro.shop.model.displayStatus
import uz.ferro.shop.model.statusBackgroundColor
import uz.ferro.shop.util.formatUzbPhone
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.TextAlign
import web.cssom.WhiteSpace
import web.cssom.integer
import web.cssom.pct
import web.cssom.px

val AdminOrderList = VFC {
    var orders by useState(listOf<Order>())
    val navigator = useContext(NavigationContext)
    val scope = CoroutineScope(Dispatchers.Main)
    val rawOrders = linkedMapOf<Long, Order>()
    var job: Job? = null

    fun syncData() {
        var date = ""
        rawOrders.clear()
        job?.cancel()
        job = scope.launch {
            while (scope.isActive) {
                try {
                    val newOrders = OrderManager.getOrderList(date)
                    newOrders.forEach {
                        rawOrders[it.id!!] = it
                    }

                    date = rawOrders.values.maxOfOrNull { it.updatedDate.orEmpty() }.orEmpty()
                    orders = rawOrders.values.sortedBy { it.createdDate }.reversed()
                } catch (e: Exception) {
                    // no-op
                }
                delay(10_000L)
            }
        }
    }

    useEffectOnce {
        syncData()
        cleanup {
            scope.cancel()
        }
    }

    Box {
        sx {
            width = 100.pct
            height = 100.pct
            padding = 16.px
        }

        Paper {
            sx {
                width = 100.pct
                height = 100.pct
                overflow = Overflow.scroll
                position = Position.relative
            }

            Table {
                sx {
                    position = Position.absolute
                    top = 0.px
                    right = 0.px
                    bottom = 0.px
                    left = 0.px
                }

                stickyHeader = true
                TableHead {
                    TableRow {
                        TableCell {
                            sx {
                                width = 120.px
                            }
                            +"ID"
                        }
                        TableCell {
                            sx {
                                width = 120.px
                            }
                            +"Sana"
                        }
                        TableCell {
                            sx {
                                width = 1.pct
                                whiteSpace = WhiteSpace.nowrap
                            }
                            +"Holati"
                        }
                        TableCell {
                            sx {
                                width = 120.px
                            }
                            +"SAP ID"
                        }

                        TableCell {
                            sx {
                                width = 120.px
                            }
                            +"SAP Hujjat"
                        }

                        TableCell {
                            +"Mijoz"
                        }
                        TableCell {
                            +"Telefon"
                        }
                    }
                }
                TableBody {
                    orders.forEach { order ->
                        TableRow {
                            hover = true
                            key = order.id.toString()

                            onClick = { event ->
                                event.preventDefault()
                                navigator.openAdminOrder(order.id!!)
                            }

                            TableCell {
                                +order.id.toString()
                            }

                            TableCell {
                                +order.displayDate()
                            }

                            TableCell {
                                Box {
                                    sx {
                                        borderRadius = 8.px
                                        backgroundColor = order.status.statusBackgroundColor()
                                        paddingTop = 8.px
                                        paddingBottom = 8.px
                                        paddingLeft = 12.px
                                        paddingRight = 12.px
                                        color = Colors.white
                                        textAlign = TextAlign.center
                                        fontWeight = integer(500)
                                        whiteSpace = WhiteSpace.nowrap
                                    }
                                    +order.displayStatus()
                                }
                            }

                            TableCell {
                                +order.externalId?.toString().orEmpty()
                            }

                            TableCell {
                                +order.externalDocNum.orEmpty()
                            }

                            TableCell {
                                +order.customerName
                            }

                            TableCell {
                                +formatUzbPhone(order.customerPhone)
                            }
                        }
                    }
                }
            }
        }
    }
}
