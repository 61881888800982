package uz.ferro.shop.pages.admin.salepoint

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.ListItemButton
import mui.material.ListItemText
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.model.SalePoint
import uz.ferro.shop.ui.AppTextSemiBoldLarge
import web.cssom.AlignItems
import web.cssom.px

val AdminSalePointList = FC<Props> {
    val language = LanguageManager.currentLanguage
    val navigator = useContext(NavigationContext)

    var salePoints by useState(listOf<SalePoint>())

    fun loadData() {
        MainScope().launch {
            try {
                salePoints = SalePointManager.getSalePoints()
            } catch (e: Exception) {
                // no-op
            }
        }
    }

    useEffectOnce {
        loadData()
    }

    Box {
        sx {
            padding = 16.px
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                alignItems = AlignItems.center
            }

            AppTextSemiBoldLarge {
                text = "Дилерлар"
            }

            Button {
                sx {
                    marginLeft = 200.px
                }
                variant = ButtonVariant.contained
                Add()
                onClick = {
                    navigator.openAdminNewSalePoint()
                }
            }
        }
        mui.material.List {
            sx {
                marginTop = 16.px
            }
            salePoints.forEach { salePoint ->
                ListItemButton {
                    ListItemText {
                        +salePoint.getLocalizedName()
                    }

                    onClick = {
                        navigator.openAdminEditSalePoint(salePoint.id!!)
                    }
                }
            }
        }
    }


}
