package uz.ferro.shop.manager

import uz.ferro.shop.api.post
import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.model.Customer
import uz.ferro.shop.util.toIntOrZero
import kotlin.js.Promise

private val nonDigitsRegex = Regex("\\D")
private const val MAX_RESULT = 250
private const val API_PREFIX = "admin/customer"

object CustomerManager {

    private val lastDigits = Regex("\\d+$")
    private val leadingZeros = Regex("^0+")

    private var cachedList: List<Customer> = listOf()
    private var defaultList: List<Customer> = emptyList()

    suspend fun getCustomers(): List<Customer> {
        return cachedList.ifEmpty {
            val data = getListFromApi()
            data.forEach { c ->
                processCustomer(c)
            }
            if (data.isNotEmpty()) {
                defaultList = data.subList(0, minOf(MAX_RESULT, data.size))
            }
            cachedList = data
            cachedList
        }
    }

    suspend fun getCustomersLimited(): List<Customer> {
        getCustomers()
        return defaultList
    }

    suspend fun searchCustomers(query: String): List<Customer> {
        val q = query.lowercase()
        if (q.isBlank()) {
            return getCustomersLimited()
        }
        val allData = getCustomers()
        val out = mutableListOf<Customer>()
        for (c in allData) {
            if (c.id.orEmpty().lowercase().contains(q) ||
                c.name.orEmpty().lowercase().contains(q) ||
                c.cleanedPhone.contains(q)
            ) {
                out.add(c)
                if (out.size >= MAX_RESULT) {
                    return out
                }
            }
        }
        return out
    }

    suspend fun getCustomerById(id: String): Customer {
        return suspendGet<Customer>("$API_PREFIX?id=$id")
    }

    fun suggestCode(name: String): String {
        val cleanedName = name.trim().uppercase()
        val codePart = if (cleanedName.contains(" ")) {
            cleanedName.substring(0, cleanedName.indexOf(" "))
        } else {
            cleanedName
        }

        val code = "O-$codePart"
        val maxDigit: Int? = cachedList
            .filter { it.codeWithoutDigit == code }
            .maxOfOrNull { it.codeDigit }

        if (maxDigit == null) {
            return code
        } else {
            return code + (maxDigit + 1).toString()
        }
    }

    fun suggestNickName(name: String): String {
        val cleanedName = name.trim().replace(Regex("\\s+"), " ")
        val firstSpaceIndex = cleanedName.indexOf(' ')
        if (firstSpaceIndex != -1) {
            val secondSpaceIndex = cleanedName.indexOf(' ', firstSpaceIndex + 1)
            if (secondSpaceIndex != -1) {
                return cleanedName.substring(0, secondSpaceIndex)
            }
        }

        return cleanedName
    }

    fun addNewCustomer(customer: Customer): Promise<Customer> {
        return apiAddNewCustomer(customer)
    }

    suspend fun getMatchesByPhone(phone: String): List<Customer> {
        val cleanedPhone = cleanPhone(phone)

        return getCustomers().let { result ->
            if (cleanedPhone.isBlank()) {
                emptyList()
            } else {
                result.filter {
                    cleanedPhone == it.cleanedPhone
                }
            }
        }
    }

    private suspend fun getListFromApi(): List<Customer> {
        return suspendGet("$API_PREFIX/list")
    }

    private fun apiAddNewCustomer(customer: Customer): Promise<Customer> {
        return post<Customer, Customer>(
            path = API_PREFIX,
            body = customer
        )
    }

    private fun processCustomer(customer: Customer) {
        customer.cleanedPhone = cleanPhone(customer.phone1)

        val code = customer.id.orEmpty()
        customer.codeWithoutDigit = code
        if (code.isNotBlank()) {
            val digitString = lastDigits.find(code)?.groupValues?.getOrNull(0).orEmpty()
            if (digitString.isNotEmpty()) {
                customer.codeWithoutDigit = code.removeSuffix(digitString)
                customer.codeDigit = digitString.replace(leadingZeros, "").toIntOrZero()
            }
        }
    }
}

fun cleanPhone(phone: String?): String {
    val phoneDigits = phone.orEmpty().trim().replace(nonDigitsRegex, "")
    if (phoneDigits.length == 9) {
        return "998$phoneDigits"
    }

    if (phoneDigits.length == 12) {
        return phoneDigits
    }

    return ""
}