package uz.ferro.shop.pages.order

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Breadcrumbs
import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableHead
import mui.material.TableRow
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.router.useParams
import react.useContext
import react.useEffect
import react.useEffectOnce
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.ChevronRightIcon16
import uz.ferro.shop.manager.OrderManager
import uz.ferro.shop.model.Order
import uz.ferro.shop.model.displayStatus
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextMediumSmall
import uz.ferro.shop.ui.AppTextSemiBoldSmall
import uz.ferro.shop.util.formatPrice
import uz.ferro.shop.util.styleInMobile
import web.cssom.JustifyContent
import web.cssom.LineStyle
import web.cssom.TextAlign
import web.cssom.integer
import web.cssom.pct
import web.cssom.px

val OrderDetailsPage = FC<Props> {
    val pathParam = useParams()["orderId"] ?: return@FC
    val orderId = pathParam.toLongOrNull()
    val navigator = useContext(NavigationContext)
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()
    val locale = useContext(LocaleContext)

    MarketPage(
        hideCategory = true,
        centerBlock = {
            Breadcrumbs {
                sx {
                    fontSize = 13.px
                }

                separator = ChevronRightIcon16.create()
                Link {
                    color = Colors.textSecondary
                    underline = LinkUnderline.hover
                    key = "1"
                    href = "/"
                    onClick = {

                    }
                    +locale.main
                }

                Box {
                    sx {
                        color = Colors.textSecondary
                    }
                    +locale.profile
                }

                Link {
                    color = Colors.textSecondary
                    underline = LinkUnderline.hover
                    key = "1"
                    onClick = {
                        navigator.openOrders()
                    }
                    +locale.orders
                }
            }

            Box {
                sx {
                    marginTop = 10.px
                    color = Colors.textPrimary
                    fontSize = styleInMobile(25.px, 31.px)
                    fontWeight = integer(700)
                }
                +"${locale.order}${Typography.nbsp}#$orderId"
            }
        },
        fullBlock = {
            var order by useState<Order>()
            var isLoading by useState(false)

            fun loadData() {
                orderId ?: return
                MainScope().launch {
                    isLoading = true
                    val result = OrderManager.getOrder(orderId)
                    order = result
                    isLoading = false
                }
            }

            useEffectOnce {
                loadData()
            }

            useEffect(orderId) {
                if (order != null && orderId != null && order?.id == orderId) {
                    return@useEffect
                }
                loadData()
            }

            if (order != null) {
                if (isMobile) {
                    Stack {
                        direction = responsive(StackDirection.column)
                        spacing = responsive(12.px)
                        sx {
                            width = 100.pct
                            borderStyle = LineStyle.solid
                            borderColor = Colors.borderPrimary
                            borderWidth = 1.px
                            borderRadius = 5.px
                            padding = 12.px
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.spaceBetween
                            }

                            AppTextMediumSmall {
                                text = locale.name
                            }

                            AppTextSemiBoldSmall {
                                text = order?.customer?.name
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.spaceBetween
                            }

                            AppTextMediumSmall {
                                text = locale.date
                            }

                            AppTextSemiBoldSmall {
                                text = order?.displayDate()
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.spaceBetween
                            }

                            AppTextMediumSmall {
                                text = locale.productsCount
                            }

                            AppTextSemiBoldSmall {
                                text = order?.productsCount?.toString()
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.spaceBetween
                            }

                            AppTextMediumSmall {
                                text = locale.inTotal
                            }

                            AppTextSemiBoldSmall {
                                text = order?.total.formatPrice()
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.spaceBetween
                            }

                            AppTextMediumSmall {
                                text = locale.shippingAddress
                            }

                            AppTextSemiBoldSmall {
                                text = order?.externalCustomer?.shippingAddressLine()
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.spaceBetween
                            }

                            AppTextMediumSmall {
                                text = locale.status
                            }

                            AppTextSemiBoldSmall {
                                text = order?.displayStatus()
                            }
                        }
                    }

                    Stack {
                        direction = responsive(StackDirection.column)
                        spacing = responsive(16.px)
                        sx {
                            marginTop = 20.px
                        }
                        order?.products.orEmpty().forEach { orderProduct ->
                            OrderProductItemViewMobile {
                                this.orderProduct = orderProduct
                            }
                        }
                    }
                } else {

                    Box {
                        sx {
                            borderRadius = 5.px
                            borderWidth = 1.px
                            borderColor = Colors.borderPrimary
                            borderStyle = LineStyle.solid
                            marginTop = 36.px
                        }

                        Table {
                            TableHead {
                                TableRow {
                                    sx {
                                        backgroundColor = Colors.secondaryBackground
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = locale.name
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = locale.date
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = locale.productsCount
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = locale.inTotal
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = locale.shippingAddress
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = locale.status
                                        }
                                    }
                                }
                            }

                            TableBody {
                                TableRow {
                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = order?.customer?.name
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = order?.displayDate()
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = order?.productsCount?.toString()
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = order?.total.formatPrice()
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = order?.externalCustomer?.shippingAddressLine()
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = order?.displayStatus()
                                        }
                                    }
                                }
                            }
                        }
                    }


                    Box {
                        sx {
                            borderRadius = 5.px
                            borderWidth = 1.px
                            borderColor = Colors.borderPrimary
                            borderStyle = LineStyle.solid
                            marginTop = 36.px
                        }

                        Table {
                            TableHead {
                                TableRow {
                                    sx {
                                        backgroundColor = Colors.secondaryBackground
                                    }

                                    TableCell {
                                        sx {
                                            width = 0.px
                                        }
                                    }

                                    TableCell {
                                        AppTextBold {
                                            text = locale.naming
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = locale.price
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = locale.measureUnit
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = locale.quantity
                                        }
                                    }

                                    TableCell {
                                        sx {
                                            textAlign = TextAlign.center
                                            width = 0.px
                                        }
                                        AppTextBold {
                                            text = locale.inTotal
                                        }
                                    }
                                }
                            }

                            TableBody {
                                order?.products?.forEach { orderProduct ->
                                    OrderProductItemView {
                                        this.orderProduct = orderProduct
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    ).invoke()
}