package uz.ferro.shop.pages.admin.product

import mui.material.Box
import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.FormControlVariant
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.useState
import uz.ferro.shop.api.useSaveProperty
import uz.ferro.shop.model.Property
import uz.ferro.shop.pages.admin.brand.MeasureUnitPicker
import uz.ferro.shop.pages.admin.dict.DictItemEditor
import uz.ferro.shop.util.isNotFull
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.number
import web.cssom.px
import web.html.HTMLInputElement

external interface AddPropertyProps : Props {
    var isOpen: Boolean
    var onClose: () -> Unit
    var onReady: (Property) -> Unit
}

val AddPropertyDialog = FC<AddPropertyProps> { props ->

    var newProperty by useState(Property())
    val saveProperty = useSaveProperty()

    fun onClose() {
        props.onClose()
    }

    Dialog {
        fullWidth = true
        open = props.isOpen
        onClose = { _, _ ->
            onClose()
        }
        DialogTitle {
            +"Hususiyat"
        }
        DialogContent {
            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.stretch
                }

                DictItemEditor {
                    title = "Hususiyat nomi"
                    item = newProperty.name
                    onChange = {
                        newProperty = newProperty.copy(name = it)
                    }
                }

                TextField {
                    sx {
                        marginTop = 12.px
                        flexGrow = number(1.0)
                    }
                    label = ReactNode("Kalit")
                    variant = FormControlVariant.outlined
                    value = newProperty.key.orEmpty()
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        newProperty = newProperty.copy(key = event.target.value)
                    }
                }

                MeasureUnitPicker() {
                    selectedMeasureUnit = newProperty.unit
                    onMeasureUnitSelect = { unit ->
                        newProperty = newProperty.copy(unit = unit)
                    }
                }
            }
        }

        DialogActions {
            Button {
                onClick = { onClose() }
                +"Bekor"
            }
            Button {
                onClick = {
                    var isValid = true
                    if (newProperty.key.orEmpty().isBlank()) {
                        isValid = false
                    }

                    if (newProperty.name.isNotFull()) {
                        isValid = false
                    }

                    if (isValid) {
                        saveProperty(newProperty)
                            .then { result ->
                                props.onReady(result)
                                onClose()
                            }
                    }
                }
                +"Saqlash"
            }
        }
    }
}