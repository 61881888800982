package uz.ferro.shop.ui.banner

import mui.material.Box
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.model.ComponentBanner
import uz.ferro.shop.ui.ImageView
import web.cssom.Cursor
import web.cssom.number
import web.cssom.pct
import web.cssom.px

external interface BannerItemProps : Props {
    var componentBanner: ComponentBanner
    var isEditMode: Boolean
}

val BannerItem = FC<BannerItemProps> { props ->
    val banner = props.componentBanner.banner
    val navigator = useContext(NavigationContext)

    Box {
        sx {
            flexGrow = number(1.0)
            flexShrink = number(1.0)
            cursor = Cursor.pointer
        }

        ImageView {
            sx {
                width = 100.pct
                height = 100.pct
                borderRadius = 5.px
            }

            url = banner.getPhotoUrl()
            isCovered = true
        }

        onClick = {
            if (props.isEditMode) {
                navigator.openAdminEditComponentBanner(
                    componentId = props.componentBanner.component.id!!,
                    componentBannerId = props.componentBanner.id!!
                )
            } else {
                navigator.openUrl(banner.url)
            }
        }
    }
}
