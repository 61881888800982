package uz.ferro.shop.util

import uz.ferro.shop.BASE_FILE_URL
import uz.ferro.shop.model.DataFile
import uz.ferro.shop.model.OrderProduct
import uz.ferro.shop.model.Product
import uz.ferro.shop.model.PropertyValue
import kotlin.js.Date

fun Int?.orZero(): Int = this ?: 0

fun Long?.orZero(): Long = this ?: 0L

fun Float?.orZero(): Float = this ?: 0f

fun String?.toIntOrZero(): Int = this?.toIntOrNull().orZero()

fun Double?.orZero(): Double = this ?: 0.0

fun String?.tryToDouble(): Double? {
    return this.orEmpty().removePrefix(".")
        .removeSuffix(".")
        .toDoubleOrNull()
}

val Product.isDiscounted
    get() = discountPercent.orZero() > 0

private fun getPriceWithDiscount(price: Double?, discountPercent: Int?): Double {
    return if (discountPercent.orZero() > 0) {
        price.orZero() * (100.0 - discountPercent.orZero()) / 100.0
    } else {
        price.orZero()
    }
}

fun Product.getActualPrice(): Double = getPriceWithDiscount(price, discountPercent)

fun OrderProduct.getActualPrice(): Double = getPriceWithDiscount(productPrice, discountPercent)

fun String.sourceUrl(): String {
    return "$BASE_FILE_URL/api/file/download/$this"
}

fun DataFile?.sourceUrl(): String {
    if (this?.url.orEmpty().isBlank()) {
        return ""
    }
    return "$BASE_FILE_URL/api/file/download/${this!!.url}"
}

fun DataFile?.thumbnailUrl(size: Int): String {
    if (this?.url.orEmpty().isBlank()) {
        return ""
    }
    return this!!.url.thumbnailUrl(size)
}

fun String.thumbnailUrl(size: Int): String {
    return "$BASE_FILE_URL/api/file/thumbnail/$size/$this"
}

fun DataFile?.thumbnailSquareUrl(size: Int): String {
    if (this?.url.orEmpty().isBlank()) {
        return ""
    }
    return this?.url.thumbnailSquareUrl(size)
}

fun String?.thumbnailSquareUrl(size: Int): String {
    if (this.isNullOrBlank()) {
        return ""
    }
    return "$BASE_FILE_URL/api/file/thumbnail/square/$size/$this"
}

fun PropertyValue?.valueWithUnit(): String {
    this ?: return ""
    if (this.value.localizedOrDefault().isBlank()) return ""
    return (this.value.localizedOrDefault().trim() + " " + this.property?.unit?.getLocalizedName()).trim()
}

fun Date?.formatDMY(): String {
    this ?: return ""
    val day = getDate().toString().let { if (it.length == 1) "0$it" else it }
    val year = getFullYear().toString()
    val month = (getMonth() + 1).toString().let { if (it.length == 1) "0$it" else it }
    return "$day.$month.$year"
}

fun Date?.formatDMYHm(): String {
    this ?: return ""
    val hour = getHours().toString().let { if (it.length == 1) "0$it" else it }
    val minutes = getMinutes().toString().let { if (it.length == 1) "0$it" else it }
    return "${formatDMY()} $hour:$minutes"
}

fun Date?.apiDate(): String {
    this ?: return ""
    val day = getDate().toString().let { if (it.length == 1) "0$it" else it }
    val year = getFullYear().toString()
    val month = (getMonth() + 1).toString().let { if (it.length == 1) "0$it" else it }

    val hour = getHours().toString().let { if (it.length == 1) "0$it" else it }
    val minutes = getMinutes().toString().let { if (it.length == 1) "0$it" else it }
    val seconds = getSeconds().toString().let { if (it.length == 1) "0$it" else it }
    return "$year-$month-${day}T$hour:$minutes:$seconds.000"
}

fun String?.onBlank(value: String?): String = if (this.isNullOrBlank()) value.orEmpty() else this

fun String?.onBlank(first: String?, second: String?): String {
    return this.onBlank(first.onBlank(second))
}