package uz.ferro.shop.pages.admin.product

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.material.Box
import mui.material.Fab
import mui.material.FabColor
import mui.material.FormControlVariant
import mui.material.Paper
import mui.material.Size
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableHead
import mui.material.TableRow
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.manager.ProductManager
import uz.ferro.shop.model.Product
import uz.ferro.shop.navigateToLink
import uz.ferro.shop.util.localized
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.number
import web.cssom.pct
import web.cssom.pt
import web.cssom.px
import web.html.HTMLInputElement

val AdminProductList = FC<Props> {

    var filteredProducts by useState<List<Product>>(emptyList())
    val navigator = useContext(NavigationContext)

    fun onSearchQueryChanged(query: String) {
        MainScope().launch {
            if (query.isNotBlank()) {
                delay(500)
            }
            filteredProducts = ProductManager.getAllProductsLimited(query.lowercase())
        }
    }

    useEffectOnce {
        onSearchQueryChanged("")
    }

    Box {
        sx {
            padding = 16.px
            width = 100.pct
            height = 100.pct
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
            }
            Box {
                sx {
                    color = Colors.title
                    fontSize = 16.pt
                }
                +"Maxsulotlar"
            }

            TextField {
                sx {
                    paddingTop = 4.px
                    paddingBottom = 4.px
                    marginLeft = 100.px
                    minWidth = 400.px
                }
                placeholder = "Qidiruv"
                size = Size.small
                variant = FormControlVariant.outlined
                onChange = { event ->
                    event as ChangeEvent<HTMLInputElement>
                    onSearchQueryChanged(event.target.value)
                }
            }

            Box {
                sx {
                    flexGrow = number(1.0)
                }
            }

            Fab {
                color = FabColor.primary
                Add()
                onClick = {
                    navigator.openAdminNewProduct()
                }
            }
        }

        Paper {
            sx {
                width = 100.pct
                height = 100.pct
                marginTop = 16.px
                overflow = Overflow.scroll
                position = Position.relative
            }

            Table {
                sx {
                    position = Position.absolute
                    top = 0.px
                    right = 0.px
                    bottom = 0.px
                    left = 0.px
                }

                stickyHeader = true
                TableHead {
                    TableRow {
                        TableCell {
                            sx {
                                width = 120.px
                            }
                            +"Kod"
                        }
                        TableCell {
                            align = TableCellAlign.left
                            +"Nomi"
                        }
                        TableCell {
                            align = TableCellAlign.right
                            +"Qoldiq"
                        }
                        TableCell {
                            align = TableCellAlign.right

                            +"Narxi"
                        }
                    }
                }
                TableBody {
                    filteredProducts.forEach { product ->
                        TableRow {
                            sx {
                                cursor = Cursor.pointer
                            }
                            hover = true
                            key = product.id.toString()

                            TableCell {
                                +product.externalId.orEmpty()
                            }

                            TableCell {
                                +product.name.localized()
                            }

                            TableCell {
                                align = TableCellAlign.right
                                +product.available.orEmpty()
                            }

                            TableCell {
                                +product.unit?.name.localized()
                            }

                            onClick = {
                                navigator.openAdminEditProduct(product.id)
                            }
                        }
                    }
                }
            }
        }
    }
}
