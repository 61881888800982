package uz.ferro.shop.pages.about

import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import uz.ferro.shop.Colors
import uz.ferro.shop.icon.CallIcon2
import uz.ferro.shop.icon.LocationIcon2
import uz.ferro.shop.icon.MarketIconRed
import uz.ferro.shop.model.SalePoint
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextSemiBoldSmall
import uz.ferro.shop.util.styleInMobile
import web.cssom.AlignItems
import web.cssom.VerticalAlign
import web.cssom.pct
import web.cssom.px

external interface SalePointProps : Props {
    var salePoint: SalePoint
}

val SalePointView = FC<SalePointProps> { props ->
    val salePoint = props.salePoint
    Stack {
        direction = responsive(StackDirection.column)
        spacing = styleInMobile(8.px, 12.px)
        Stack {
            direction = responsive(StackDirection.row)
            spacing = styleInMobile(6.px, 8.px)
            sx {
                alignItems = AlignItems.center
            }
            MarketIconRed {
                size = styleInMobile(16.px, 24.px)
            }

            AppTextBold {
                sx {
                    fontSize = styleInMobile(13.px, 16.px)
                    height = 100.pct
                    verticalAlign = VerticalAlign.middle
                }
                color = Colors.accentColor
                text = salePoint.getLocalizedName()
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = styleInMobile(6.px, 12.px)
            sx {
                alignItems = AlignItems.start
            }
            LocationIcon2 {
                size = styleInMobile(16.px, 20.px)
            }
            AppTextSemiBoldSmall {
                text = salePoint.getLocalizedAddress()
                sx {
                    fontSize = styleInMobile(11.px, 13.px)
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = styleInMobile(6.px, 12.px)
            sx {
                alignItems = AlignItems.start
            }
            CallIcon2 {
                size = styleInMobile(16.px, 20.px)
            }
            Stack {
                direction = responsive(StackDirection.column)
                AppTextSemiBoldSmall {
                    text = salePoint.phone
                    sx {
                        fontSize = styleInMobile(11.px, 13.px)
                    }
                }
                if (salePoint.phone2.isNotBlank()) {
                    AppTextSemiBoldSmall {
                        text = salePoint.phone2
                        sx {
                            fontSize = styleInMobile(11.px, 13.px)
                        }
                    }
                }
            }
        }
    }
}