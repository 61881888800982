package uz.ferro.shop.icon

import react.FC
import web.cssom.px

val SearchIcon = SvgUrlIcon("./icons/search.svg")
val CallIcon = SvgUrlIcon("./icons/call.svg", 24)
val CallIcon16 = SvgUrlIcon("./icons/call_16.svg", 16)
val ChevronBottomIcon = SvgUrlIcon("./icons/chevron_bottom.svg")
val LanguageIcon = SvgUrlIcon("./icons/language.svg")
val FavoriteIcon = SvgUrlIcon("./icons/favorite.svg")
val CartIcon = SvgUrlIcon("./icons/cart.svg")
val CartIconWhite = SvgUrlIcon("./icons/cart_white.svg", 24)
val MarketIcon20 = SvgUrlIcon("./icons/market_20.svg")
val MarketIcon = SvgUrlIcon("./icons/market.svg", 24)
val FavoriteRedStrokeIcon = SvgUrlIcon("./icons/favorite_red_stroke.svg", 24)
val FavoriteWhiteFilledIcon = SvgUrlIcon("./icons/favorite_white_fill.svg", 24)
val CategoryHamburgerIcon = SvgUrlIcon("./icons/category_hamburger.svg", 24)
val DeleteIcon = SvgUrlIcon("./icons/cart_item_delete.svg", 24)
val LocationIcon = SvgUrlIcon("./icons/location.svg", 24)
val RouteIcon = SvgUrlIcon("./icons/route.svg", 20)
val HamburgerIcon20 = SvgUrlIcon("./icons/hamburger_20.svg", 20)

val DeleteIcon20 = SvgUrlIcon("./icons/cart_item_delete.svg", 20)

val ChevronRightIcon = SvgUrlIcon("./icons/chevron_right.svg", 24)
val ChevronRightIcon16 = SvgUrlIcon("./icons/chevron_right.svg", 16)

val HeaderMailIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/mail.svg"
        size = props.size ?: 42
    }
}
val HeaderCallIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/call_red_42.svg"
        size = props.size ?: 42
    }
}
val HeaderClockIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/clock_red_42.svg"
        size = props.size ?: 42
    }
}

val HeaderTelegramIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/telegram_red_32.svg"
        size = props.size ?: 42
        clickAction = props.clickAction
    }
}

val TelegramIcon32 = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/telegram_red_32.svg"
        size = props.size ?: 32
        clickAction = props.clickAction
    }
}

val InstagramIcon32 = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/instagram_red_32.svg"
        size = props.size ?: 32
        clickAction = props.clickAction
    }
}

val FacebookIcon32 = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/facebook_red_32.svg"
        size = props.size ?: 32
        clickAction = props.clickAction
    }
}

val CatalogIcon32 = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/catalog_red_32.svg"
        size = 32
        clickAction = props.clickAction
    }
}

val MarketIcon32 = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/market_red_32.svg"
        size = 32
        clickAction = props.clickAction
    }
}

val MarketIconRed = FC<SvgIconViewProps2> { props ->
    SvgIconView2 {
        url = "./icons/market_red_32.svg"
        size = props.size ?: 24.px
    }
}

val LocationIcon2 = FC<SvgIconViewProps2> { props ->
    SvgIconView2 {
        url = "./icons/location.svg"
        size = props.size ?: 24.px
    }
}

val CallIcon2 = FC<SvgIconViewProps2> { props ->
    SvgIconView2 {
        url = "./icons/call.svg"
        size = props.size ?: 24.px
    }
}

val CartIconRed16 = SvgUrlIcon("./icons/cart_red.svg", 16)

val CartIconRed = SvgUrlIcon("./icons/cart_red.svg", 24)

val FooterTelegramIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/telegram_footer.svg"
        size = props.size ?: 32
        clickAction = props.clickAction
    }
}

val FooterInstagramIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/instagram_footer.svg"
        size = props.size ?: 32
        clickAction = props.clickAction
    }
}

val FooterFacebookIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/facebook_footer.svg"
        size = props.size ?: 32
        clickAction = props.clickAction
    }
}

val FooterEmailIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/email_footer.svg"
        size = props.size ?: 32
        clickAction = props.clickAction
    }
}

val FooterCallIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/call_footer.svg"
        size = props.size ?: 32
        clickAction = props.clickAction
    }
}

val FooterLocationIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/location_footer.svg"
        size = props.size ?: 32
        clickAction = props.clickAction
    }
}


val ProfileIcon = FC<SvgIconViewProps> { props ->
    SvgIconView {
        url = "./icons/profile.svg"
        size = props.size ?: 20
        clickAction = props.clickAction
    }
}

fun QuantityMinusIcon(size: Int, clickAction: () -> Unit) =
    SvgUrlIcon("./icons/minus.svg", size, clickAction = clickAction)

fun QuantityPlusIcon(size: Int, clickAction: () -> Unit) =
    SvgUrlIcon("./icons/plus.svg", size, clickAction = clickAction)