package uz.ferro.shop.model

enum class ComponentType {
    MENU,
    HEADER,
    FOOTER,
    PRIMARY_BANNERS,
    SECONDARY_BANNERS,
    PRIMARY_BANNERS_MOBILE,
    SECONDARY_BANNERS_MOBILE,
    GENERIC,
    UNKNOWN;

    companion object {
        fun fromString(name: String): ComponentType {
            return entries.firstOrNull { it.name == name } ?: UNKNOWN
        }
    }
}