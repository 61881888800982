package uz.ferro.shop.serializer

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import uz.ferro.shop.model.ComponentType

@OptIn(ExperimentalSerializationApi::class)
@Suppress("EXTERNAL_SERIALIZER_USELESS")
@Serializer(forClass = ComponentType::class)
object ComponentTypeSerializer : KSerializer<ComponentType> {
    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("ComponentType", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: ComponentType) {
        encoder.encodeString(value.name)
    }

    override fun deserialize(decoder: Decoder): ComponentType {
        return try {
            ComponentType.fromString(decoder.decodeString())
        } catch (e: IllegalArgumentException) {
            ComponentType.UNKNOWN
        }
    }
}