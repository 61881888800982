package uz.ferro.shop.search

import uz.ferro.shop.model.Product

class SearchMultiWordMatcher(ferroInTop: Boolean = false) : SearchMatcher(ferroInTop) {

    override fun search(query: String, list: List<Product>): List<Product> {
        val splitQuery = query.splitWords()

        if (splitQuery.size <= 1) return emptyList()

        return filter(list).filter { p ->
            p.namesLowerCase().any { it.multiWordsMatching(splitQuery) }
        }
    }
}