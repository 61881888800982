package uz.ferro.shop.model

import kotlin.text.Typography.nbsp

interface LocaleDict {
    val aboutCompany: String
    val added: String
    val addedToCart: String
    val admin: String
    val all: String

    val currentLanguageShort: String
    val contacts: String
    val uzSum: String
    val toCart: String
    val toFavorite: String
    val enterToSystem: String
    val signIn: String
    val smsConfirm: String
    val smsCode: String
    val smsCodeSentToPhoneTemplate: String
    val login: String
    val profile: String
    val signOut: String
    val favorites: String
    val feedback: String
    val fullName: String
    val password: String
    val passwordConfirmation: String
    val role: String
    val id: String
    val itemNotAvailable: String
    val itemAvailable: String
    val name: String
    val productCode: String
    val sizes: String
    val catalog: String
    val exit: String
    val exitConfirm: String
    val main: String
    val mainPage: String
    val photo: String
    val photos: String
    val products: String
    val productGroup: String
    val productsGroups: String
    val buttonDetails: String
    val property: String
    val properties: String
    val privacyPolicy: String
    val publicOffer: String
    val code: String
    val remainingCount: String
    val externalIdSap: String
    val price: String
    val inDiscount: String
    val discountPrice: String
    val mainProductOfGroup: String
    val mainProduct: String
    val addNew: String
    val value: String
    val done: String
    val brand: String
    val users: String
    val naming: String
    val cancel: String
    val save: String
    val add: String
    val searchBrands: String
    val measureUnit: String
    val category: String
    val subcategory: String
    val categories: String
    val no: String
    val icon: String
    val deletion: String
    val delete: String
    val confirmDeleteMessage: String
    val edit: String
    val gallery: String
    val group: String
    val sortOrder: String
    val continueShopping: String
    val goToCart: String
    val cart: String
    val search: String
    val inTotal: String
    val status: String
    val statusNew: String
    val statusAccepted: String
    val statusInProgress: String
    val statusShipping: String
    val statusShipped: String
    val statusCompleted: String
    val statusCanceled: String
    val shippingAddress: String
    val totalAmount: String
    val totalBalance: String
    val cartIsEmpty: String
    val order: String
    val orders: String
    val date: String
    val sendOrder: String
    val phoneNumber: String
    val close: String
    val orderSuccessMessage: String
    val orderSent: String
    val languageUzbCyr: String
    val languageUzbLat: String
    val languageRus: String
    val languageEng: String
    val allRightsReserved: String
    val sizesCount: String
    val vendorCountry: String
    val makeOrder: String
    val deliveryByTashkent: String
    val dealers: String
    val inSocial: String
    val productsCount: String
    val checkout: String
    val termsOfPaymentAndDelivery: String
    val headerFasteners: String
    val headerFastenersShort: String
    val headerConsumables: String
    val headerConsumablesShort: String
    val headerInstruments: String
    val headerInstrumentsShort: String
    val workingDays: String
    val productNotFound: String
    val quantity: String
    val amountInPack: String
    val amountInBox: String
    val paymentAndDelivery: String
    val checkoutAndOrderConfirmation: String
    val payment: String
    val delivery: String
    val cashPayment: String
    val paymentServicePayment: String
    val nonCashPayment: String
    val deliveryInCity: String
    val selfPickupFromDealers: String
    val marketCheckoutDescription: String
    val callCenterDescription: String
    val emailAndPhoneOrderingDescription: String
    val cashPaymentDescription: String
    val paymentServiceDescription: String
    val nonCashPaymentDescription: String
    val noResult: String
    val deliveryInCityDescription: String
    val selfPickupDescription: String
    val mapRoute: String
    val menu: String
    val productsCatalog: String
    val dealersMarkets: String
    val getMore: String
    val resetFilter: String
    val footerPriceDescription: String
    val confirmCheckout: String
    val unavailableProducts: String
    val unavailableProductsDescription: String
    val newTagTitle: String
}

abstract class AbsLocale : LocaleDict {
    override val languageUzbCyr: String = "Ўзб"
    override val languageUzbLat: String = "O‘zb"
    override val languageRus: String = "Рус"
    override val languageEng: String = "Eng"
}

object LocaleUz : AbsLocale() {
    override val all: String = "Barchasi"

    override val currentLanguageShort: String = "O‘zb"
    override val aboutCompany: String = "Kompaniya Haqida"
    override val contacts: String = "Kontaktlar"
    override val uzSum: String = "so‘m"
    override val toCart: String = "Savatga"
    override val toFavorite: String = "Tanlanganlarga"
    override val added: String = "Qo‘shilgan"
    override val addedToCart: String = "Savatga qo‘shildi"
    override val continueShopping: String = "Xaridni davom ettirish"
    override val goToCart: String = "Savatga o‘tish"
    override val cart: String = "Savat"

    override val admin: String = ""
    override val enterToSystem: String = "Tizimga kirish"
    override val signIn: String = "Kirish"
    override val smsConfirm: String = "SMS tasdiqlash"
    override val smsCode: String = "SMS kod"
    override val smsCodeSentToPhoneTemplate: String = "SMS kod {{phone}} raqamiga jo‘natildi"
    override val signOut: String = "Chiqish"
    override val login: String = "Login"
    override val profile: String = "Profil"

    override val buttonDetails: String = "Batafsil"

    override val favorites: String = "Tanlanganlar"
    override val fullName: String = ""
    override val feedback: String = "Aloqa uchun"

    override val confirmCheckout: String = "Buyurtmani jo‘natishni tasdiqlaysizmi?"

    override val password: String = "Parol"
    override val passwordConfirmation: String = ""
    override val role: String = ""
    override val id: String = ""
    override val name: String = "Ismingiz"
    override val productCode: String = "Tovar kodi"
    override val sizes: String = "O‘lchamlar"
    override val catalog: String = "Katalog"
    override val exit: String = ""
    override val exitConfirm: String = "Chiqishni tasdiqlaysizmi?"
    override val main: String = "Asosiy"
    override val mainPage: String = "Asosiy sahifa"
    override val photo: String = ""
    override val photos: String = ""
    override val products: String = ""
    override val productGroup: String = ""
    override val productsGroups: String = ""
    override val property: String = ""
    override val properties: String = ""
    override val privacyPolicy: String = "Mahfiylik siyosati"
    override val publicOffer: String = "Ommaviy oferta"
    override val code: String = ""
    override val remainingCount: String = ""
    override val externalIdSap: String = ""
    override val price: String = "Narx"
    override val inDiscount: String = ""
    override val discountPrice: String = ""
    override val mainProductOfGroup: String = ""
    override val mainProduct: String = ""
    override val itemNotAvailable: String = "Mavjud emas"
    override val itemAvailable: String = "Mavjud"
    override val addNew: String = ""
    override val value: String = ""
    override val done: String = ""
    override val brand: String = ""
    override val users: String = ""
    override val naming: String = "Nomlanishi"
    override val cancel: String = "Bekor"
    override val save: String = ""
    override val add: String = "Qo‘shish"
    override val searchBrands: String = ""
    override val measureUnit: String = "Birlik"
    override val category: String = "Kategoriya"
    override val subcategory: String = "Bo‘lim"
    override val categories: String = "Kategoriyalar"
    override val no: String = ""
    override val icon: String = ""
    override val deletion: String = ""
    override val delete: String = "O‘chirish"
    override val confirmDeleteMessage: String = ""
    override val edit: String = ""
    override val gallery: String = ""
    override val group: String = ""
    override val sortOrder: String = ""
    override val search: String = "Qidiruv"
    override val inTotal: String = "Jami"
    override val status: String = "Holati"
    override val statusNew: String = "Yangi"
    override val statusAccepted: String = "Qabul qilindi"
    override val statusInProgress: String = "Jarayonda"
    override val statusShipping: String = "Yetkazilmoqda"
    override val statusShipped: String = "Yetkazildi"
    override val statusCompleted: String = "Tugallangan"
    override val statusCanceled: String = "Bekor qilingan"
    override val shippingAddress: String = "Yetkazish manzili"
    override val totalAmount: String = "Umumiy narx"
    override val totalBalance: String = "Umumiy qoldiq"
    override val cartIsEmpty: String = "Savatingiz hozircha bo‘sh"
    override val order: String = "Buyurtma"
    override val orders: String = "Buyurtmalar"
    override val date: String = "Sana"
    override val sendOrder: String = "Buyurtmani jo‘natish"
    override val phoneNumber: String = "Telefon raqamlari"
    override val close: String = "Yopish"
    override val orderSuccessMessage: String =
        "Sizning buyurtmangiz jo‘natildi. Yaqin orada bizning operatorimiz siz bilan bog‘lanadi."
    override val orderSent: String = "Buyurtma jo‘natildi"
    override val allRightsReserved = "Barcha huquqlar himoyalangan"
    override val sizesCount: String = "O‘lchamlar soni"
    override val vendorCountry: String = "Ishlab chiqaruvchi"
    override val makeOrder: String = "Buyurtma qilish"
    override val checkout: String = "Buyurtmani rasmiylashtirish"
    override val deliveryByTashkent: String = "Toshkent bo‘yicha yetkazish"
    override val dealers: String = "Diler do‘konlar"
    override val inSocial: String = "Ijtimoiy tarmoqlarda"
    override val productsCount: String = "Tovarlar soni"
    override val termsOfPaymentAndDelivery: String = "To‘lov va yetkazib berish shartlari"
    override val headerFasteners: String = "MAHKAMLOVCHILAR"
    override val headerFastenersShort: String = "MAHKAMLOVCHI"
    override val headerConsumables: String = "ELEKTR AKSESSUARLAR"
    override val headerConsumablesShort: String = "ELEKTR"
    override val headerInstruments: String = "INSTRUMENTLAR"
    override val headerInstrumentsShort: String = "INSTRUMENTLAR"
    override val workingDays: String = "Du-Sh"
    override val productNotFound: String = "Mahsulot topilmadi :("
    override val quantity: String = "Miqdori"
    override val amountInPack: String = "Qadoqdagi miqdori"
    override val amountInBox: String = "Qutidagi miqdori"
    override val paymentAndDelivery: String = "To‘lov va yetkazib berish"
    override val noResult: String = "Natija topilmadi"

    override val checkoutAndOrderConfirmation: String = "Buyurtmani rasmiylashtirish va qabul qilish"
    override val payment: String = "To‘lov"
    override val delivery: String = "Yetkazib berish"
    override val cashPayment: String = "Naqd to‘lov"
    override val paymentServicePayment: String = "Click, Payme orqali to‘lov"
    override val nonCashPayment: String = "Naqdsiz to‘lov"
    override val deliveryInCity: String = "Toshkent bo‘yicha yetkazib berish"
    override val selfPickupFromDealers: String = "Diler do‘konlardan olib ketish"
    override val marketCheckoutDescription: String =
        "Bizning do‘kondan haridni amalga oshirish uchun o‘zizni qiziqtirgan mahsulotlarni savatingizga qo‘shing va buyurtmani rasmiylashtirish shaklini to‘ldiring."
    override val callCenterDescription: String =
        "Bizning menejerlarimiz siz bilan tez orada buyurtmani tasdiqlash va qulay usuldagi to‘lov va yetkazib berish usullarini tanlash uchun bog‘lanishadi."
    override val emailAndPhoneOrderingDescription: String =
        "Shuningdek biz {{phone}} telefon raqami yoki {{email}} e-mail orqali buyurtmalarni qabul qilamiz"
    override val cashPaymentDescription: String =
        "To‘lov naqd ko‘rinishda yetkazib beruvchi kuryerga yoki o‘ziz olib ketgan do‘konga amalga oshiriladi. Mahsulot bilan birgalikda sizga mahsulot va kassa cheki va kafolat taloni beriladi."
    override val paymentServiceDescription: String =
        "Siz buyurtmani Click, Payme ilovalari orqali yoki boshqa to‘lov tizimlari orqali kompaniya hisob raqamiga to‘lov qilishingiz mumkin."
    override val nonCashPaymentDescription: String = "QQS orqali ishlaymiz, schot faktura beramiz"
    override val deliveryInCityDescription: String =
        "Yetkazib berish narxi — 100${nbsp}000${nbsp}so‘m. 1${nbsp}000${nbsp}000${nbsp}so‘m va undan yuqori buyurtmalarga yetkazib berish bepul. Batafsil ma‘lumotlarni bizning menejerimiz bilan bog‘lanib aniqlashtirib olishingiz mumkin."
    override val selfPickupDescription: String =
        "Mahsulotlarimizni siz dilerlik do‘konlarimizdan keng tanlovda harid qilishingiz mumkin."
    override val mapRoute: String = "Xaritada"
    override val menu: String = "Menyu"
    override val productsCatalog: String = "Mahsulotlar katalogi"
    override val dealersMarkets: String = "Dilerlar do‘konlari"
    override val getMore: String = "Yana yuklash"
    override val resetFilter: String = "Tozalash"
    override val footerPriceDescription: String =
        "Texnik sabablarga ko‘ra, berilgan tovarlarning narxlari va xususiyatlari faqat ma’lumot uchun mo‘ljallangan va ommaviy oferta hisoblanmaydi. " +
                "{{phone}} raqamiga qo‘ng‘iroq qilib buyurtma berishdan oldin o‘zingizni qiziqtirgan mahsulot narxini oldindan bilib olishingiz mumkin. " +
                "Agar siz veb-saytda buyurtma bergan bo‘lsangiz, menejer siz bilan bog‘lanib, mavjudligi va narxi haqida ma’lumot beradi. " +
                "Keltirilgan noqulayliklar uchun uzr so‘raymiz."
    override val unavailableProducts: String = "Mavjud bo‘lmagan mahsulotlar"
    override val unavailableProductsDescription: String = "Mavjud bo‘lmagan mahsulotlarni o‘chirib buyurtmani davom ettirishingiz mumkin"

    override val newTagTitle: String = "Yangi"
}

object LocaleUzCyr : AbsLocale() {
    override val all: String = "Барчаси"

    override val currentLanguageShort: String = "Ўзб"
    override val aboutCompany: String = "Компания Ҳақида"
    override val contacts: String = "Контактлар"
    override val uzSum: String = "сўм"
    override val toCart: String = "Саватга"
    override val toFavorite: String = "Танланганларга"
    override val added: String = "Қўшилган"
    override val addedToCart: String = "Саватга қўшилди"
    override val continueShopping: String = "Харидни давом эттириш"
    override val goToCart: String = "Саватга ўтиш"
    override val cart: String = "Сават"
    override val noResult: String = "Натижа топилмади"

    override val confirmCheckout: String = "Буюртмани жўнатишни тасдиқлайсизми?"

    override val admin: String = ""
    override val enterToSystem: String = "Тизимга кириш"
    override val signIn: String = "Кириш"
    override val smsConfirm: String = "SMS тасдиқлаш"
    override val smsCode: String = "SMS код"
    override val smsCodeSentToPhoneTemplate: String = "SMS код {{phone}} рақамига жўнатилди"
    override val signOut: String = "Чиқиш"
    override val login: String = "Логин"
    override val profile: String = "Профил"

    override val buttonDetails: String = "Батафсил"

    override val favorites: String = "Танланганлар"
    override val fullName: String = ""
    override val feedback: String = "Алоқа учун"

    override val password: String = "Парол"
    override val passwordConfirmation: String = ""
    override val role: String = ""
    override val id: String = ""
    override val itemNotAvailable: String = "Мавжуд эмас"
    override val itemAvailable: String = "Мавжуд"
    override val name: String = "Исмингиз"
    override val productCode: String = "Товар коди"
    override val sizes: String = "Ўлчамлар"
    override val catalog: String = "Каталог"
    override val exit: String = ""
    override val exitConfirm: String = "Чиқишни тасдиқлайсизми?"
    override val main: String = "Асосий"
    override val mainPage: String = "Асосий саҳифа"
    override val photo: String = ""
    override val photos: String = ""
    override val products: String = ""
    override val productGroup: String = ""
    override val productsGroups: String = ""
    override val property: String = ""
    override val properties: String = ""
    override val privacyPolicy: String = "Маҳфийлик сиёсати"
    override val publicOffer: String = "Оммавий оферта"
    override val code: String = ""
    override val remainingCount: String = ""
    override val externalIdSap: String = ""
    override val price: String = "Нарх"
    override val inDiscount: String = ""
    override val discountPrice: String = ""
    override val mainProductOfGroup: String = ""
    override val mainProduct: String = ""
    override val addNew: String = ""
    override val value: String = ""
    override val done: String = ""
    override val brand: String = ""
    override val users: String = ""
    override val naming: String = "Номланиши"
    override val cancel: String = "Бекор"
    override val save: String = ""
    override val add: String = "Қўшиш"
    override val searchBrands: String = ""
    override val measureUnit: String = "Бирлик"
    override val category: String = "Категория"
    override val subcategory: String = "Бўлим"
    override val categories: String = "Категориялар"
    override val no: String = ""
    override val icon: String = ""
    override val deletion: String = ""
    override val delete: String = "Ўчириш"
    override val confirmDeleteMessage: String = ""
    override val edit: String = ""
    override val gallery: String = ""
    override val group: String = ""
    override val sortOrder: String = ""
    override val search: String = "Қидирув"
    override val inTotal: String = "Жами"
    override val status: String = "Статус"
    override val statusNew: String = "Янги"
    override val statusAccepted: String = "Қабул қилинди"
    override val statusInProgress: String = "Жараёнда"
    override val statusShipping: String = "Етказилмоқда"
    override val statusShipped: String = "Етказилди"
    override val statusCompleted: String = "Тугалланган"
    override val statusCanceled: String = "Бекор қилинган"
    override val shippingAddress: String = "Етказиш манзили"
    override val totalAmount: String = "Умумий нарх"
    override val totalBalance: String = "Умумий қолдиқ"
    override val cartIsEmpty: String = "Саватингиз ҳозирча бўш"
    override val order: String = "Буюртма"
    override val orders: String = "Буюртмалар"
    override val date: String = "Сана"
    override val sendOrder: String = "Буюртмани жўнатиш"
    override val phoneNumber: String = "Телефон рақамлари"
    override val close: String = "Ёпиш"
    override val orderSuccessMessage: String =
        "Сизнинг буюртмангиз жўнатилди. Яқин орада бизнинг операторимиз сиз билан боғланади."
    override val orderSent: String = "Буюртма жўнатилди"
    override val allRightsReserved = "Барча ҳуқуқлар ҳимояланган"
    override val sizesCount: String = "Ўлчамлар сони"
    override val vendorCountry: String = "Ишлаб чиқарувчи"
    override val makeOrder: String = "Буюртма қилиш"
    override val checkout: String = "Буюртмани расмийлаштириш"
    override val deliveryByTashkent: String = "Тошкент бўйича етказиш"
    override val dealers: String = "Дилер дўконлар"
    override val inSocial: String = "Ижтимоий тармоқларда"
    override val productsCount: String = "Товарлар сони"
    override val termsOfPaymentAndDelivery: String = "Тўлов ва етказиб бериш шартлари"
    override val headerFasteners: String = "МАҲКАМЛОВЧИЛАР"
    override val headerFastenersShort: String = "МАҲКАМЛОВЧИ"
    override val headerConsumables: String = "ЭЛЕКТР АКСЕССУАРЛАР"
    override val headerConsumablesShort: String = "ЭЛЕКТР"
    override val headerInstruments: String = "ИНСТРУМЕНТЛАР"
    override val headerInstrumentsShort: String = "ИНСТРУМЕНТЛАР"
    override val workingDays: String = "Ду-Шн"
    override val productNotFound: String = "Маҳсулот топилмади :("
    override val quantity: String = "Миқдори"
    override val amountInPack: String = "Қадоқдаги миқдори"
    override val amountInBox: String = "Қутидаги миқдори"
    override val paymentAndDelivery: String = "Тўлов ва етказиб бериш"

    override val checkoutAndOrderConfirmation: String = "Буюртмани расмийлаштириш ва қабул қилиш"
    override val payment: String = "Тўлов"
    override val delivery: String = "Етказиб бериш"
    override val cashPayment: String = "Нақд тўлов"
    override val paymentServicePayment: String = "Click, Payme орқали тўлов"
    override val nonCashPayment: String = "Нақдсиз тўлов"
    override val deliveryInCity: String = "Тошкент бўйича етказиб бериш"
    override val selfPickupFromDealers: String = "Дилер дўконлардан олиб кетиш"
    override val marketCheckoutDescription: String =
        "Бизнинг дўкондан ҳаридни амалга ошириш учун ўзизни қизиқтирган маҳсулотларни саватингизга қўшинг ва буюртмани расмийлаштириш шаклини тўлдиринг."
    override val callCenterDescription: String =
        "Бизнинг менежерларимиз сиз билан тез орада буюртмани тасдиқлаш ва қулай усулдаги тўлов ва етказиб бериш усулларини танлаш учун боғланишади."
    override val emailAndPhoneOrderingDescription: String =
        "Шунингдек биз {{phone}} телефон рақами ёки {{email}} e-mail орқали буюртмаларни қабул қиламиз"
    override val cashPaymentDescription: String =
        "Тўлов нақд кўринишда етказиб берувчи курерга ёки ўзиз олиб кетган дўконга амалга оширилади. Маҳсулот билан биргаликда сизга маҳсулот ва касса чеки ва кафолат талони берилади."
    override val paymentServiceDescription: String =
        "Сиз буюртмани Click, Payme иловалари орқали ёки бошқа тўлов тизимлари орқали компания ҳисоб рақамига тўлов қилишингиз мумкин."
    override val nonCashPaymentDescription: String = "ҚҚС орқали ишлаймиз, счет-фактура берамиз"
    override val deliveryInCityDescription: String =
        "Етказиб бериш нархи — 100${nbsp}000${nbsp}сўм. 1${nbsp}000${nbsp}000${nbsp}сўм ва ундан юқори буюртмаларга етказиб бериш бепул. Батафсил маълумотларни бизнинг менежеримиз билан боғланиб аниқлаштириб олишингиз мумкин."
    override val selfPickupDescription: String =
        "Маҳсулотларимизни сиз дилерлик дўконларимиздан кенг танловда ҳарид қилишингиз мумкин."
    override val mapRoute: String = "Харитада"
    override val menu: String = "Меню"
    override val productsCatalog: String = "Маҳсулотлар каталоги"
    override val dealersMarkets: String = "Дилерлар дўконлари"
    override val getMore: String = "Яна юклаш"
    override val resetFilter: String = "Тозалаш"
    override val footerPriceDescription: String =
        "Техник сабабларга кўра, берилган товарларнинг нархлари ва хусусиятлари фақат маълумот учун мўлжалланган ва оммавий оферта ҳисобланмайди. " +
                "{{phone}} рақамига қўнғироқ қилиб буюртма беришдан олдин ўзингизни қизиқтирган маҳсулот нархини олдиндан билиб олишингиз мумкин. " +
                "Агар сиз веб-сайтда буюртма берган бўлсангиз, менежер сиз билан боғланиб, мавжудлиги ва нархи ҳақида маълумот беради. " +
                "Келтирилган ноқулайликлар учун узр сўраймиз."
    override val unavailableProducts: String = "Мавжуд бўлмаган маҳсулотлар"
    override val unavailableProductsDescription: String = "Мавжуд бўлмаган маҳсулотларни ўчириб буюртмани давом эттиришингиз мумкин"
    override val newTagTitle: String = "Янги"
}

object LocaleRu : AbsLocale() {
    override val all: String = "Все"

    override val currentLanguageShort: String = "Рус"
    override val aboutCompany: String = "О Компании"
    override val contacts: String = "Контакты"
    override val uzSum: String = "сум"
    override val toCart: String = "В корзину"
    override val toFavorite: String = "В избранное"
    override val added: String = "Добавлено"
    override val addedToCart: String = "Добавлено в корзину"
    override val continueShopping: String = "Продолжить покупку"
    override val goToCart: String = "Перейти в корзину"
    override val cart: String = "Корзина"

    override val admin: String = ""
    override val enterToSystem: String = "Вход в систему"
    override val signIn: String = "Вход"
    override val smsConfirm: String = "SMS подтверждение"
    override val smsCode: String = "SMS код"
    override val smsCodeSentToPhoneTemplate: String = "SMS код отправлен на номер {{phone}}"
    override val login: String = "Логин"
    override val profile: String = "Профиль"
    override val signOut: String = "Выход"

    override val buttonDetails: String = "Подробнее"

    override val favorites: String = "Избранные"
    override val fullName: String = ""
    override val feedback: String = "Обратная связь"

    override val password: String = "Пароль"
    override val passwordConfirmation: String = ""
    override val role: String = ""
    override val id: String = ""
    override val itemNotAvailable: String = "В наличии: нет"
    override val itemAvailable: String = "В наличии: есть"
    override val name: String = "Имя"
    override val productCode: String = "Код товара"
    override val sizes: String = "Размеры"
    override val catalog: String = "Каталог"
    override val exit: String = ""
    override val exitConfirm: String = "Вы действительно хотите выйти?"
    override val main: String = "Главная"
    override val mainPage: String = "Asosiy sahifa"
    override val photo: String = ""
    override val photos: String = ""
    override val products: String = ""
    override val productGroup: String = ""
    override val productsGroups: String = ""
    override val property: String = ""
    override val properties: String = ""
    override val privacyPolicy: String = "Политика конфединциальности"
    override val publicOffer: String = "Публичная оферта"
    override val code: String = ""
    override val remainingCount: String = ""
    override val externalIdSap: String = ""
    override val price: String = "Цена"
    override val inDiscount: String = ""
    override val discountPrice: String = ""
    override val mainProductOfGroup: String = ""
    override val mainProduct: String = ""
    override val addNew: String = ""
    override val value: String = ""
    override val done: String = ""
    override val brand: String = ""
    override val users: String = ""
    override val naming: String = "Наименование"
    override val cancel: String = "Отмена"
    override val save: String = ""
    override val add: String = "Добавить"
    override val searchBrands: String = ""
    override val measureUnit: String = "Единица"
    override val category: String = "Категория"
    override val subcategory: String = "Подкатегория"
    override val categories: String = "Категории"
    override val no: String = ""
    override val icon: String = ""
    override val deletion: String = ""
    override val delete: String = "Удалить"
    override val confirmDeleteMessage: String = ""
    override val edit: String = ""
    override val gallery: String = ""
    override val group: String = ""
    override val sortOrder: String = ""
    override val search: String = "Поиск"
    override val inTotal: String = "Сумма"
    override val status: String = "Статус"
    override val statusNew: String = "Новый"
    override val statusAccepted: String = "Принят"
    override val statusInProgress: String = "В процессе"
    override val statusShipping: String = "Доставляется"
    override val statusShipped: String = "Доставлено"
    override val statusCompleted: String = "Завершен"
    override val statusCanceled: String = "Отменен"
    override val shippingAddress: String = "Адрес доставки"
    override val totalAmount: String = "Итоговая сумма"
    override val totalBalance: String = "Общий остаток"
    override val cartIsEmpty: String = "Корзина пока пуста"
    override val order: String = "Заказ"
    override val orders: String = "Заказы"
    override val date: String = "Дата"
    override val sendOrder: String = "Отправить заказ"
    override val phoneNumber: String = "Номер телефона"
    override val close: String = "Закрыть"
    override val orderSuccessMessage: String =
        "Ваш заказ успешно отправлен. B ближайщее время наш оператор будет связаться с вами."
    override val orderSent: String = "Заказ отправлен"
    override val allRightsReserved = "Все права защищены"
    override val sizesCount: String = "Кол-во размеров"
    override val vendorCountry: String = "Производство"
    override val makeOrder: String = "Сделать заказ"
    override val checkout: String = "Оформить заказ"
    override val deliveryByTashkent: String = "Поставки по Ташкенту"
    override val dealers: String = "Магазины дилеров"
    override val inSocial: String = "Следите за нами"
    override val productsCount: String = "Количество товаров"
    override val termsOfPaymentAndDelivery: String = "Условия оплаты и доставки"
    override val headerFasteners: String = "КРЕПЕЖНЫЕ ИЗДЕЛИЯ"
    override val headerFastenersShort: String = "КРЕПЕЖИ"
    override val headerConsumables: String = "РАСХОДНЫЕ МАТЕРИАЛЫ ДЛЯ ЭЛЕКТРОИНСТРУМЕНТА"
    override val headerConsumablesShort: String = "ОСНАСТКА"
    override val headerInstruments: String = "ИНСТРУМЕНТЫ"
    override val headerInstrumentsShort: String = "ИНСТРУМЕНТЫ"
    override val workingDays: String = "Пн-Сб"
    override val productNotFound: String = "Продукт не найден :("
    override val quantity: String = "Количество"
    override val noResult: String = "Нет результатов"
    override val amountInPack: String = "Кол-во в упаковке"
    override val amountInBox: String = "Кол-во в коробке"
    override val paymentAndDelivery: String = "Оплата и доставка"
    override val checkoutAndOrderConfirmation: String = "Оформление и подтверждение заказа"
    override val confirmCheckout: String = "Подтверждаете отправление заказа"
    override val payment: String = "Оплата"
    override val delivery: String = "Доставка"
    override val cashPayment: String = "Наличный расчет"
    override val paymentServicePayment: String = "Оплата через Click, Payme"
    override val nonCashPayment: String = "Безналичный расчет"
    override val deliveryInCity: String = "Курьерская доставка по Ташкенту"
    override val selfPickupFromDealers: String = "Самовывоз из дилерских магазинов"
    override val marketCheckoutDescription: String =
        "Для совершения покупки в нашем магазине, добавьте интересующие вас позиции в корзину и заполните форму оформления заказа."
    override val callCenterDescription: String =
        "Наши менеджеры оперативно свяжутся с вами для подтверждения заказа и выбора оптимального способа оплаты и доставки товаров."
    override val emailAndPhoneOrderingDescription: String =
        "Также мы принимаем заказы по телефону: {{phone}} или на e-mail: {{email}}"
    override val cashPaymentDescription: String =
        "Оплата производится наличными курьеру или в магазине при самовывозе. Вместе с товаром передается товарный и кассовый чеки, а также гарантийный талон."
    override val paymentServiceDescription: String =
        "Вы можете оплатить заказ через приложения Click, Payme или через любые другие платежные системы путем перевода на лицевой счет компании."
    override val nonCashPaymentDescription: String = "Работаем с НДС, выписываем счет-фактуру"
    override val deliveryInCityDescription: String =
        "Стоимость — 100${nbsp}000 сум. При заказе от 1${nbsp}000${nbsp}000 сум, доставка бесплатная. Подробную информацию вы можете получить у наших менеджеров."
    override val selfPickupDescription: String =
        "Вы можете приобрести товары через наши магазины в широком ассортименте."
    override val mapRoute: String = "Схема проезда"
    override val menu: String = "Меню"
    override val productsCatalog: String = "Каталог товаров"
    override val dealersMarkets: String = "Магазины дилеров"
    override val getMore: String = "Загрузить еще"
    override val resetFilter: String = "Сбросить"
    override val footerPriceDescription: String =
        "По техническим причинам, приведённые цены и характеристики товаров носят исключительно ознакомительный характер и не являются публичной офертой. " +
                "Вы можете уточнить стоимость на интересующий вас товар, предварительно, перед заказом по телефону {{phone}}." +
                "Если вы сделали заказ на сайте менеджер свяжется с вами и сообщит информацию о наличии и цене. " +
                "Приносим извинения за доставленные неудобства."
    override val unavailableProducts: String = "Нет в наличии"
    override val unavailableProductsDescription: String = "Можете удалить недоступные товары и продолжить заказ"
    override val newTagTitle: String = "Новинка"
}