package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class ProductGroupProduct(
    val id: Int? = null,
    val productGroup: ProductGroup? = null,
    val product: Product? = null,
    var sort: Int? = null
)