package uz.ferro.shop.ui.search

import js.core.jso
import mui.material.IconButton
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.List
import mui.material.ListItemButton
import mui.material.Paper
import mui.material.Popper
import mui.material.Size
import mui.material.TextField
import mui.system.sx
import popper.core.Placement
import react.FC
import react.Props
import react.create
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.router.dom.useSearchParams
import react.useContext
import react.useRef
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.SearchIcon
import uz.ferro.shop.model.SearchResult
import uz.ferro.shop.ui.AppTextMedium
import uz.ferro.shop.util.InputProps
import uz.ferro.shop.util.orZero
import web.cssom.integer
import web.cssom.pct
import web.cssom.px
import web.html.HTMLDivElement
import web.html.HTMLInputElement

external interface SearchViewProps : Props

val SearchView = FC<SearchViewProps> { props ->

    val results by useState<List<SearchResult>>(listOf())
    val navigator = useContext(NavigationContext)
    val locale = useContext(LocaleContext)
    val appContext = useContext(AppContext)
    val queryParams = useSearchParams()
    val query: String = queryParams.component1()["q"].orEmpty()
    var inputValue by useState(query)

    val inputRef = useRef<HTMLDivElement>(null)

    TextField {
        this.ref = inputRef
        InputProps = jso {
            endAdornment = InputAdornment.create {
                position = InputAdornmentPosition.end
                IconButton {
                    onClick = { evt ->
                        val currentInputValue = inputValue
                        if (currentInputValue.isNotBlank()) {
                            navigator.openSearch(currentInputValue)
                        }
                        evt.preventDefault()
                    }
                    +SearchIcon.create()
                }
            }
        }

        sx {
            width = 100.pct
        }
        size = Size.small
        placeholder = locale.search
        value = inputValue
        onChange = { event ->
            val evt = event as ChangeEvent<HTMLInputElement>
            val searchQuery = evt.target.value
            inputValue = searchQuery
        }

        onKeyDown = { evt ->
            if (evt.key === "Enter") {
                val searchQuery = (evt.target as HTMLInputElement).value
                if (searchQuery.isNotBlank()) {
                    navigator.openSearch(searchQuery)
                }
                evt.preventDefault()
            }
        }
    }

    val popperWidth = if (appContext.isMd) 600 else 0
    val mainPadding = if (appContext.isMd) 12.px else 8.px

    Popper {
        sx {
            width = inputRef.current?.clientWidth.orZero().coerceAtLeast(popperWidth).px
            minHeight = 100.px
            zIndex = integer(9999)
        }
        anchorEl = inputRef.current
        open = results.isNotEmpty()
        placement = Placement.bottom
        Paper {
            sx {
                padding = mainPadding
            }

            List {
                sx {
                    marginBlockStart = 0.px
                    marginBlockEnd = 0.px
                }

                results.forEach { resultItem ->
                    ListItemButton {
                        sx {
                            paddingTop = 8.px
                            paddingBottom = 8.px
                            minHeight = 48.px
                        }
                        AppTextMedium {
                            text = resultItem.getLocalizedName()
                        }
                        onClick = {
                            navigator.openProductDetails(resultItem.referenceId)
                        }
                    }
                }
            }
        }
    }
}