package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import uz.ferro.shop.locale.Language
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.util.sourceUrl

@Serializable
data class Banner(
    val id: Int? = null,
    val url: String = "",
    val photo: DataFile? = null,
    val photoCyrillic: DataFile? = null,
    val photoTranslation: DataFile? = null,
    val photoEnglish: DataFile? = null
) {

    fun getPhotoUrl(): String {

        val language = LanguageManager.currentLanguage
        val localizedPhoto = when (language) {
            Language.RU -> photoTranslation ?: photoCyrillic ?: photo
            Language.UZ_CYR -> photoCyrillic ?: photo ?: photoTranslation
            Language.EN -> photoEnglish ?: photo ?: photoCyrillic
            else -> photo ?: photoCyrillic ?: photoTranslation
        }

        return localizedPhoto.sourceUrl()
    }
}
