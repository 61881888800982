package uz.ferro.shop.model

import kotlinx.serialization.Serializable


@Serializable
data class WarehouseAmount(
    val warehouseId: String,
    val amount: Double
)
