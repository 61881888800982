package uz.ferro.shop.pages.admin.user

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.icons.material.Edit
import mui.material.Box
import mui.material.Fab
import mui.material.FabColor
import mui.material.FormControlVariant
import mui.material.IconButton
import mui.material.Paper
import mui.material.Size
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableHead
import mui.material.TableRow
import mui.material.TextField
import mui.system.sx
import react.VFC
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.useEffectOnce
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.manager.AuthManager
import uz.ferro.shop.manager.UserManager
import uz.ferro.shop.model.Role
import uz.ferro.shop.model.User
import uz.ferro.shop.navigateToLink
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.Visibility
import web.cssom.number
import web.cssom.pct
import web.cssom.pt
import web.cssom.px
import web.html.HTMLInputElement

val AdminUserList = VFC {
    var users by useState(listOf<User>())
    var searchQuery by useState("")

    var hoveredUserId: Int by useState(-1)

    val filteredUsers: List<User> = if (searchQuery.isNotBlank()) {
        users.filter {
            it.name.orEmpty().lowercase().contains(searchQuery.trim().lowercase()) ||
                    it.username.orEmpty().lowercase().contains(searchQuery.trim().lowercase())
        }
    } else users

    fun loadData() {
        MainScope().launch {
            try {
                users = UserManager.getAllUsers()
            } catch (e: Exception) {
                // no-op
            }
        }
    }

    useEffectOnce {
        loadData()
    }

    fun isEditAllowed(user: User): Boolean {
        return when (user.role) {
            Role.ADMIN -> AuthManager.isSuperAdmin()
            Role.B2B_USER -> AuthManager.isAdmin() || AuthManager.isSalesManager()
            else -> AuthManager.isAdmin()
        }
    }

    Box {
        sx {
            padding = 16.px
            width = 100.pct
            height = 100.pct
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
            }
            Box {
                sx {
                    color = Colors.title
                    fontSize = 16.pt
                }
                +"Foydalanuvchilar"
            }

            TextField {
                sx {
                    paddingTop = 4.px
                    paddingBottom = 4.px
                    marginLeft = 100.px
                }
                placeholder = "Qidiruv"
                size = Size.small
                variant = FormControlVariant.outlined
                onChange = { event ->
                    event as ChangeEvent<HTMLInputElement>
                    searchQuery = event.target.value
                }
            }

            Box {
                sx {
                    flexGrow = number(1.0)
                }
            }

            Fab {
                color = FabColor.primary
                Add()
                onClick = {
                    navigateToLink("/admin/user/new")
                }
            }
        }


        Paper {
            sx {
                width = 100.pct
                height = 100.pct
                marginTop = 16.px
                overflow = Overflow.scroll
                position = Position.relative
            }

            Table {
                sx {
                    position = Position.absolute
                    top = 0.px
                    right = 0.px
                    bottom = 0.px
                    left = 0.px
                }

                stickyHeader = true
                TableHead {
                    TableRow {
                        TableCell {
                            sx {
                                width = 120.px
                            }
                            +"ID"
                        }
                        TableCell {
                            align = TableCellAlign.left
                            +"Ism"
                        }
                        TableCell {
                            +"login"
                        }
                        TableCell {
                            +"Rol"
                        }

                        TableCell {
                            +""
                        }
                    }
                }
                TableBody {
                    filteredUsers.forEach { user ->
                        TableRow {
                            hover = true
                            key = user.id.toString()

                            onMouseOver = {
                                hoveredUserId = user.id!!
                            }

                            onMouseOut = {
                                if (hoveredUserId == user.id) {
                                    hoveredUserId = -1
                                }
                            }

                            TableCell {
                                +user.id.toString()
                            }

                            TableCell {
                                +user.name.orEmpty()
                            }

                            TableCell {
                                +user.username.orEmpty()
                            }

                            TableCell {
                                +user.role?.name.orEmpty()
                            }

                            TableCell {
                                Box {
                                    IconButton {
                                        sx {
                                            padding = 0.px
                                            visibility = if (hoveredUserId == user.id && isEditAllowed(user)) {
                                                Visibility.visible
                                            } else {
                                                Visibility.hidden
                                            }
                                        }
                                        Edit {
                                            htmlColor = Colors.iconSecondary
                                        }
                                        onClick = {
                                            navigateToLink("/admin/user/${user.id!!}")
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}