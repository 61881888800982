package uz.ferro.shop.pages.admin.salepoint

import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.model.SalesPerson

object SalesPersonManager {
    private const val API_PATH_SECTION = "admin/sales-person"

    suspend fun getSalesPersons(): List<SalesPerson> {
        return suspendGet<List<SalesPerson>>("$API_PATH_SECTION/list")
            .filter { it.isActive }
            .sortedBy { it.name }
    }

    suspend fun getDefaultSalesPerson(): SalesPerson {
        return suspendGet<SalesPerson>("$API_PATH_SECTION/default")
    }

    suspend fun saveDefaultSalesPerson(salesPerson: SalesPerson): SalesPerson {
        return suspendPost("$API_PATH_SECTION/default", salesPerson)
    }
}