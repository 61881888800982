package uz.ferro.shop.icon

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML
import web.cssom.Cursor
import web.cssom.Height
import web.cssom.LengthProperty
import web.cssom.Width
import web.cssom.px

fun SvgUrlIcon(
    url: String,
    size: Int = 20,
    width: Int = size,
    height: Int = size,
    clickAction: (() -> Unit)? = null
): FC<Props> = FC {
    ReactHTML.img {
        src = url
        css {
            this.width = width.px
            this.height = height.px
            if (clickAction != null) {
                cursor = Cursor.pointer
            }
        }

        if (clickAction != null) {
            onClick = {
                clickAction()
                it.preventDefault()
                it.stopPropagation()
            }
        }
    }
}

external interface SvgIconViewProps : Props {
    var url: String
    var size: Int?
    var width: Int?
    var height: Int?
    var clickAction: (() -> Unit)?
}

val SvgIconView = FC<SvgIconViewProps> { props ->
    ReactHTML.img {
        src = props.url
        css {
            this.width = (props.width ?: props.size ?: 20).px
            this.height = (props.height ?: props.size ?: 20).px
            if (props.clickAction != null) {
                cursor = Cursor.pointer
            }
        }

        if (props.clickAction != null) {
            onClick = {
                props.clickAction?.invoke()
            }
        }
    }
}

external interface SvgIconViewProps2 : Props {
    var url: String
    var size: LengthProperty?
    var width: Width?
    var height: Height?
    var clickAction: (() -> Unit)?
}

val SvgIconView2 = FC<SvgIconViewProps2> { props ->
    ReactHTML.img {
        src = props.url
        css {
            width = props.width ?: props.size ?: 20.px
            this.height = props.height ?: props.size ?: 20.px
            if (props.clickAction != null) {
                cursor = Cursor.pointer
            }
        }

        if (props.clickAction != null) {
            onClick = {
                props.clickAction?.invoke()
            }
        }
    }
}