package uz.ferro.shop.manager

import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.api.suspendPut
import uz.ferro.shop.model.Document

object DocumentManager {

    private const val API_PATH = "document"

    suspend fun saveDocument(document: Document): Document {
        return if (document.id == null) {
            suspendPost(API_PATH, document)
        } else {
            suspendPut(API_PATH, document)
        }
    }

    suspend fun getDocument(id: Long): Document {
        return suspendGet("$API_PATH/$id")
    }
}