package uz.ferro.shop.pages.admin.home

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.manager.PageContentManager
import uz.ferro.shop.model.ComponentType
import uz.ferro.shop.model.PageContent
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.pages.admin.banner.ui.AdminBannersComponent
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.px

val AdminBannersPage = FC<Props> {
    var homePageContent by useState(PageContent())
    val alertManager = useContext(AlertManagerContext)


    fun refreshData() {
        MainScope().launch {
            try {
                homePageContent = PageContentManager.getHomePageContent()
            } catch (e: Exception) {
                alertManager.handleError(e)
            }
        }
    }

    useEffectOnce {
        refreshData()
    }

    Box {
        sx {
            padding = 16.px
            position = Position.absolute
            top = 0.px
            right = 0.px
            bottom = 0.px
            left = 0.px
            overflow = Overflow.scroll
        }

        Box {
            sx {

            }

            AdminBannersComponent {
                title = "Asosiy Bannerlar (Desktop)"
                component = homePageContent.findComponent(ComponentType.PRIMARY_BANNERS)
                fitFirst = false
            }

            AdminBannersComponent {
                title = "Asosiy Bannerlar (Mobil)"
                component = homePageContent.findComponent(ComponentType.PRIMARY_BANNERS_MOBILE)
                fitFirst = false
            }

            AdminBannersComponent {
                title = "Ikkilamchi Bannerlar (Desktop)"
                component = homePageContent.findComponent(ComponentType.SECONDARY_BANNERS)
                fitFirst = true
            }

            AdminBannersComponent {
                title = "Ikkilamchi Bannerlar (Mobil)"
                component = homePageContent.findComponent(ComponentType.SECONDARY_BANNERS_MOBILE)
                fitFirst = true
            }
        }
    }
}