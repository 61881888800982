package uz.ferro.shop.pages.delivery

import mui.material.Box
import mui.material.Breadcrumbs
import mui.material.Grid
import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.useContext
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import uz.ferro.shop.Const
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.ChevronRightIcon16
import uz.ferro.shop.icon.SvgUrlIcon
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextSemiBold
import uz.ferro.shop.util.md
import uz.ferro.shop.util.sm
import uz.ferro.shop.util.styleInMobile
import uz.ferro.shop.util.xs
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.JustifyContent
import web.cssom.px

val PaymentAndDeliveryPage = FC<Props> {
    val locale = useContext(LocaleContext)
    val navigator = useContext(NavigationContext)

    MarketPage(
        collapseMainCatalogByDefault = true,
        centerBlock = {
            Box {
                sx {
                    marginLeft = styleInMobile(0.px, 30.px)
                }

                Breadcrumbs {
                    sx {
                        fontSize = 13.px
                    }

                    separator = ChevronRightIcon16.create()
                    Link {
                        color = Colors.textSecondary
                        underline = LinkUnderline.hover
                        key = "1"
                        href = "/"
                        onClick = {

                        }
                        +locale.main
                    }

                    Link {
                        color = Colors.textSecondary
                        underline = LinkUnderline.hover
                        key = "2"
                        sx {
                            cursor = Cursor.pointer
                        }
                        onClick = {
                            navigator.openCart()
                        }
                        +locale.cart
                    }

                    Box {
                        sx {
                            color = Colors.textSecondary
                        }
                        +locale.paymentAndDelivery
                    }
                }

                AppTextBold {
                    sx {
                        marginTop = 8.px
                        fontSize = styleInMobile(25.px, 31.px)
                    }
                    text = locale.paymentAndDelivery
                }
            }
        },
        fullBlock = {
            Box {
                sx {
                    marginTop = 12.px
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        justifyContent = JustifyContent.center
                    }
                    AppTextBold {
                        sx {
                            fontSize = styleInMobile(16.px, 20.px)
                        }
                        text = locale.checkoutAndOrderConfirmation
                    }
                }

                val items1 = listOf(
                    UiInfo(
                        imageUrl = "./icons/payment-and-delivery/01_market.svg",
                        description = locale.marketCheckoutDescription
                    ),
                    UiInfo(
                        imageUrl = "./icons/payment-and-delivery/02_call.svg",
                        description = locale.callCenterDescription
                    ),
                    UiInfo(
                        imageUrl = "./icons/payment-and-delivery/03_email_phone.svg",
                        description = locale.emailAndPhoneOrderingDescription
                            //.replace("{{phone}}", Const.ORDER_PHONE)
                            .replace("{{phone}}", "")
                            .replace("{{email}}", Const.INFO_EMAIL)
                    )
                )

                Grid {
                    container = true
                    sx {
                        marginTop = 12.px
                    }

                    items1.forEach { info ->
                        Grid {
                            item = true
                            xs = 12
                            sm = 12
                            md = 4

                            InfoItem {
                                this.info = info
                            }
                        }
                    }
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        justifyContent = JustifyContent.center
                    }

                    AppTextBold {
                        sx {
                            fontSize = styleInMobile(16.px, 20.px)
                            marginTop = styleInMobile(16.px, 36.px)
                        }
                        text = locale.payment
                    }
                }

                val items2 = listOf(
                    UiInfo(
                        imageUrl = "./icons/payment-and-delivery/04_payment.svg",
                        title = locale.cashPayment,
                        description = locale.cashPaymentDescription
                    ),
                    UiInfo(
                        imageUrl = "./icons/payment-and-delivery/05_payment_service.svg",
                        title = locale.paymentServicePayment,
                        description = locale.paymentServiceDescription
                    ),
                    UiInfo(
                        imageUrl = "./icons/payment-and-delivery/06_bank_payment.svg",
                        title = locale.nonCashPayment,
                        description = locale.nonCashPaymentDescription
                    )
                )

                Grid {
                    container = true
                    sx {
                        marginTop = 12.px
                    }

                    items2.forEach { info ->
                        Grid {
                            item = true
                            xs = 12
                            sm = 12
                            md = 4

                            InfoItem {
                                this.info = info
                            }
                        }
                    }
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        justifyContent = JustifyContent.center
                    }

                    AppTextBold {
                        sx {
                            fontSize = styleInMobile(16.px, 20.px)
                            marginTop = styleInMobile(16.px, 36.px)
                        }
                        text = locale.delivery
                    }
                }

                val items3 = listOf(
                    UiInfo(
                        imageUrl = "./icons/payment-and-delivery/07_delivery.svg",
                        title = locale.deliveryInCity,
                        description = locale.deliveryInCityDescription,
                        useCustomImageSize = true
                    ),
                    UiInfo(
                        imageUrl = "./icons/payment-and-delivery/08_self_pickup.svg",
                        title = locale.selfPickupFromDealers,
                        description = locale.selfPickupDescription,
                        useCustomImageSize = true
                    )
                )

                Grid {
                    container = true
                    sx {
                        marginTop = 12.px
                    }

                    items3.forEach { info ->
                        Grid {
                            item = true
                            xs = 12
                            sm = 12
                            md = 6

                            InfoItem {
                                this.forceRow = true
                                this.info = info
                            }
                        }
                    }
                }
            }
        }
    ).invoke()
}

private external interface InfoItemProps : Props {
    var forceRow: Boolean?
    var info: UiInfo

}

private val InfoItem = FC<InfoItemProps> { props ->
    val info = props.info
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()

    val imageWidth = if (isMobile) 90 else 180
    val customSize = if (isMobile) 75 else 150

    Stack {
        direction = if (props.forceRow == true) {
            responsive(StackDirection.row)
        } else {
            styleInMobile(StackDirection.row, StackDirection.column)
        }

        spacing = styleInMobile(12.px, 24.px)
        sx {
            alignItems = AlignItems.center
            padding = styleInMobile(8.px, 16.px)
            if (isMobile) {
                marginTop = 12.px
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                width = imageWidth.px
                height = (if (info.useCustomImageSize == true) customSize else imageWidth).px
            }
            SvgUrlIcon(
                url = info.imageUrl,
                width = imageWidth,
                height = if (info.useCustomImageSize == true) customSize else imageWidth
            ).invoke()
        }

        Stack {
            direction = responsive(StackDirection.column)
            spacing = styleInMobile(8.px, 12.px)
            if (info.title.orEmpty().isNotBlank()) {
                AppTextBold {
                    sx {
                        fontSize = styleInMobile(11.px, 20.px)
                    }
                    color = Colors.accentColor
                    text = info.title
                }
            }
            AppTextSemiBold {
                sx {
                    fontSize = styleInMobile(11.px, 16.px)
                }
                text = info.description
            }
        }
    }
}

private data class UiInfo(
    val imageUrl: String,
    val title: String? = null,
    val description: String,
    val useCustomImageSize: Boolean? = null
)