package uz.ferro.shop.pages.admin.product

import emotion.react.css
import mui.material.FormControl
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.div
import uz.ferro.shop.api.useAllParentProducts
import uz.ferro.shop.model.Product
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import web.cssom.px

external interface ParentProductPickerProps : Props {
    var selectedProduct: Product?
    var onProductSelect: (Product?) -> Unit
}

val ParentProductPicker = FC<ParentProductPickerProps> { props ->
    val allItems = useAllParentProducts()
    val selectedItem = props.selectedProduct
    val onItemSelect = props.onProductSelect

    div {
        css { marginTop = 20.px }
        val title = "Bosh tovar"
        if (allItems.isNotEmpty()) {
            FormControl {
                fullWidth = true
                InputLabel {
                    +title
                }
                Select {
                    value = selectedItem?.id.orZero()
                    label = ReactNode(title)
                    onChange = { evt, _ ->
                        val id = evt.target.value
                        val item = allItems.firstOrNull { it.id.toString() == id }
                        onItemSelect(item)
                    }

                    MenuItem {
                        value = 0
                        +"--"
                    }
                    allItems.forEach {
                        MenuItem {
                            value = it.id.toString()
                            +it.name.localized()
                        }
                    }
                }
            }
        }
    }
}