package uz.ferro.shop.model


import kotlinx.serialization.Serializable

@Serializable
data class ProductProperty(
    val id: Int?,
    val propertyValue: PropertyValue?,
    val sort: Int?
)