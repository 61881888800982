package uz.ferro.shop.pages.admin.order

import emotion.react.css
import mui.icons.material.Delete
import mui.material.ListItemIcon
import mui.material.ListItemText
import mui.material.Menu
import mui.material.MenuItem
import mui.material.PopoverPosition
import mui.material.PopoverReference
import mui.material.TableCell
import mui.material.TableRow
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useContext
import react.useState
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.model.OrderProduct
import uz.ferro.shop.model.PHOTO_SIZE_SMALL
import uz.ferro.shop.pages.cart.QuantityItemView
import uz.ferro.shop.util.formatPrice
import uz.ferro.shop.util.formatPriceWithQuantity
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import web.cssom.ObjectFit
import web.cssom.px

data class ContextMenu(
    val mouseX: Int,
    val mouseY: Int
) : PopoverPosition {
    override var left: Number
        get() = mouseX
        set(value) {}
    override var top: Number
        get() = mouseY
        set(value) {}
}

external interface EditableOrderProductItemProps : Props {
    var orderProduct: OrderProduct
    var onDeleteClick: (OrderProduct) -> Unit
}

val EditableOrderProductItem = FC<EditableOrderProductItemProps> { props ->
    val orderProduct = props.orderProduct
    val product = orderProduct.product
    var quantityState by useState(orderProduct.quantity)
    val locale = useContext(LocaleContext)
    var contextMenu by useState<ContextMenu>()

    fun closeContextMenu() {
        contextMenu = null
    }

    TableRow {
        hover = true
        key = orderProduct.id.toString()

        onContextMenu = { event ->
            event.preventDefault()
            if (contextMenu == null) {
                contextMenu = ContextMenu(
                    mouseX = event.clientX.toInt(),
                    mouseY = event.clientY.toInt()

                )
            }
        }

        TableCell {
            ReactHTML.img {
                css {
                    width = 128.px
                    height = 128.px
                    objectFit = ObjectFit.contain
                }

                val photoUrl = product.getPrimaryPhotoUrl(PHOTO_SIZE_SMALL)

                if (photoUrl.isNotBlank()) {
                    src = photoUrl
                }
            }
        }

        TableCell {
            +product.externalId
        }

        TableCell {
            +product.name.localized()
        }

        TableCell {
            if (orderProduct.discountPercent.orZero() > 0) {
                +"-${orderProduct.discountPercent}%"
            }
        }

        TableCell {
            +orderProduct.formatPrice()
        }

        TableCell {
            QuantityItemView {
                this.initialValue = orderProduct.quantity
                this.changeValue = { newValue ->
                    orderProduct.quantity = newValue
                    quantityState = newValue
                }
            }
        }

        TableCell {
            +product.unit?.name.localized()
        }

        TableCell {
            +orderProduct.formatPriceWithQuantity(quantityState)
        }

        Menu {
            open = contextMenu != null
            onClose = {
                closeContextMenu()
            }
            anchorReference = PopoverReference.anchorPosition
            anchorPosition = contextMenu

            MenuItem {
                onClick = {
                    closeContextMenu()
                    props.onDeleteClick(orderProduct)
                }

                ListItemIcon {
                    Delete {}
                }

                ListItemText {
                    +"O'chirish"
                }
            }
        }
    }
}