package uz.ferro.shop

import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.material.useMediaQuery
import mui.system.Breakpoint
import react.Context
import react.VFC
import react.create
import react.createContext
import react.dom.client.createRoot
import react.router.Outlet
import react.router.RouterProvider
import react.useState
import remix.run.router.Router
import tanstack.query.core.QueryClient
import tanstack.react.query.QueryClientProvider
import uz.ferro.shop.api.BaseUrl
import uz.ferro.shop.locale.Language
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.model.CartManager
import uz.ferro.shop.model.LocaleDict
import uz.ferro.shop.model.LocaleUz
import uz.ferro.shop.navigator.AppNavigator
import uz.ferro.shop.navigator.Navigator
import web.cssom.Color
import web.dom.document

const val BASE_FILE_URL = BaseUrl.HOST

fun main() {
    val root = document.createElement(web.html.HTML.div)
        .also { document.body.appendChild(it) }

    createRoot(root)
        .render(ReactRouterDomApp.create())
}


val appQueryClient = QueryClient()

abstract class ApplicationContext {

    // <576px
    abstract val isXs: Boolean

    // 	≥576px
    abstract val isSm: Boolean

    //  ≥768px
    abstract val isMd: Boolean

    //  ≥992px
    abstract val isLg: Boolean

    //  ≥1200px
    abstract val isXl: Boolean

    abstract fun setLanguage(language: Language)
}

val AppContext: Context<ApplicationContext> = createContext(object : ApplicationContext() {
    override val isXs: Boolean = false
    override val isSm: Boolean = true
    override val isMd: Boolean = true
    override val isLg: Boolean = true
    override val isXl: Boolean = true

    override fun setLanguage(language: Language) {}
})

val LocaleContext: Context<LocaleDict> = createContext(LocaleUz)
val NavigationContext: Context<Navigator> = createContext(AppNavigator(getRouterProvider()))
val CartContext: Context<CartManager> = createContext(CartManager)

val Root = VFC {
    QueryClientProvider {
        client = appQueryClient
        Outlet()
    }
}

fun navigateToLink(link: String, options: Any? = null) {
    appRouter.navigate(link, options ?: "")
}

fun navigateToBack() {
    appRouter.navigate(-1.0)
}

class RouterProvider {
    fun getRouter(): Router = appRouter
}

private fun getRouterProvider() = RouterProvider()

val ReactRouterDomApp = VFC {
    val theme: Theme = useTheme()

    theme.palette.primary.apply {
        main = Color(Colors.primaryColorHex)
        dark = Color(Colors.primaryDarkColorHex)
        light = Color(Colors.primaryLightColorHex)
    }

    val family = "Montserrat, Arial, sans-serif"
    val typography = theme.typography
    typography.fontFamily = family
    typography.body1.fontFamily = family
    typography.body2.fontFamily = family
    typography.button.fontFamily = family
    typography.caption.fontFamily = family
    typography.h1.fontFamily = family
    typography.h2.fontFamily = family
    typography.h3.fontFamily = family
    typography.h4.fontFamily = family
    typography.h5.fontFamily = family
    typography.h6.fontFamily = family
    typography.h5.fontFamily = family
    typography.h5.fontFamily = family
    typography.overline.fontFamily = family
    typography.subtitle1.fontFamily = family
    typography.subtitle2.fontFamily = family

    val currentDict = LanguageManager.getCurrentDict()
    var localeCtx: LocaleDict by useState(currentDict)

    val appContext = object : ApplicationContext() {
        override val isXs: Boolean = useMediaQuery(theme.breakpoints.up(Breakpoint.xs))
        override val isSm: Boolean = useMediaQuery(theme.breakpoints.up(Breakpoint.sm))
        override val isMd: Boolean = useMediaQuery(theme.breakpoints.up(Breakpoint.md))
        override val isLg: Boolean = useMediaQuery(theme.breakpoints.up(Breakpoint.lg))
        override val isXl: Boolean = useMediaQuery(theme.breakpoints.up(Breakpoint.xl))

        override fun setLanguage(language: Language) {
            LanguageManager.currentLanguage = language
            localeCtx = LanguageManager.getCurrentDict()
        }
    }

    AppContext(appContext) {
        CartContext(CartManager) {
            LocaleContext(localeCtx) {
                NavigationContext(AppNavigator(getRouterProvider())) {
                    RouterProvider {
                        router = appRouter
                    }
                }
            }
        }
    }
}
