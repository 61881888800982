@file:Suppress("UNCHECKED_CAST", "UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")

package uz.ferro.shop.pages.admin.customer

import kotlinx.coroutines.Job
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.CircularProgress
import mui.material.FormControlVariant
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableHead
import mui.material.TableRow
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.useContext
import react.useEffect
import react.useEffectOnce
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.manager.CustomerManager
import uz.ferro.shop.model.Customer
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.util.formatUzbPhone
import web.cssom.AlignContent
import web.cssom.AlignItems
import web.cssom.Flex
import web.cssom.JustifyContent
import web.cssom.Overflow
import web.cssom.pct
import web.cssom.px
import web.html.HTMLInputElement

external interface AdminCustomerListProps : Props {
    var onCustomerSelected: (Customer) -> Unit
    var onCustomerDeselected: () -> Unit
}

val AdminCustomerList = FC<AdminCustomerListProps> { props ->

    val customerManager = CustomerManager
    var customers by useState(listOf<Customer>())
    var searchQuery by useState("")
    var selectedCustomer by useState<Customer>()
    var isLoading by useState(false)
    var noResult by useState(false)
    val alertManager = useContext(AlertManagerContext)
    val locale = useContext(LocaleContext)
    var searchJob: Job? = null

    useEffectOnce {
        isLoading = true
        MainScope().launch {
            try {
                customers = customerManager.getCustomersLimited()
            } catch (e: Exception) {
                alertManager.handleError(e)
            }
            isLoading = false
            noResult = false
        }
    }

    useEffect(searchQuery) {
        selectedCustomer = null
        props.onCustomerDeselected()
        searchJob?.cancel()
        searchJob = MainScope().launch {
            val searchResult = customerManager.searchCustomers(searchQuery)
            noResult = searchResult.isEmpty()
            customers = searchResult
        }
    }

    Stack {
        direction = responsive(StackDirection.column)

        sx {
            width = 100.pct
            height = 100.pct
        }

        Box {
            TextField {
                sx {
                    paddingTop = 4.px
                    paddingBottom = 4.px
                }
                placeholder = "Qidiruv"
                size = Size.small
                variant = FormControlVariant.outlined
                onChange = { event ->
                    event as ChangeEvent<HTMLInputElement>
                    searchQuery = event.target.value
                }
            }
        }

        Box {
            sx {
                flex = Flex.maxContent
                overflow = Overflow.scroll
                width = 100.pct
            }

            if (customers.isEmpty()) {
                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        width = 100.pct
                        height = 100.pct
                        color = Colors.label
                        alignContent = AlignContent.center
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                    }

                    if (isLoading) {
                        CircularProgress()
                    }

                    if (noResult) {
                        +locale.noResult
                    }
                }
            } else {
                Table {
                    sx {
                        width = 100.pct
                    }

                    stickyHeader = true
                    TableHead {
                        TableRow {
                            TableCell {
                                sx {
                                    minWidth = 120.px
                                }
                                +"Kod"
                            }
                            TableCell {
                                align = TableCellAlign.left
                                +"Ism"
                            }
                            TableCell {
                                align = TableCellAlign.right
                                +"Telefon"
                            }
                        }
                    }
                    TableBody {
                        customers.forEach { customer ->
                            TableRow {
                                sx {
                                    if (selectedCustomer == customer) {
                                        backgroundColor = Colors.defaultSelectedColor
                                    }
                                }

                                hover = true
                                key = customer.id.toString()

                                onClick = {
                                    selectedCustomer = customer
                                    props.onCustomerSelected(customer)
                                    it.preventDefault()
                                }

                                TableCell {
                                    +customer.id.orEmpty()
                                }

                                TableCell {
                                    +customer.name.orEmpty()
                                }

                                TableCell {
                                    +formatUzbPhone(customer.phone1)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
