package uz.ferro.shop.api

import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import uz.ferro.shop.error.HttpException
import uz.ferro.shop.manager.AuthManager
import uz.ferro.shop.util.encodeUri
import kotlin.js.Promise
import kotlin.js.json

inline fun <reified T> get(path: String): Promise<T> {
    return call(
        method = "GET",
        path = path,
        body = null as Any?
    )
}

inline fun <reified B, reified T> post(path: String, body: B): Promise<T> {
    return call(
        method = "POST",
        path = path,
        body = body
    )
}

inline fun <reified B, reified T> put(path: String, body: B): Promise<T> {
    return call(
        method = "PUT",
        path = path,
        body = body
    )
}

fun delete(path: String): Promise<Unit> {
    return window.fetch(
        input = BaseUrl.get(path),
        init = RequestInit(
            method = "DELETE",
            headers = json(
                "Accept" to "application/json",
                "Content-Type" to "application/json"
            )
        )
    ).then {}
}

suspend inline fun <reified T> suspendGet(path: String, queryParams: Map<String, Any> = emptyMap()): T {
    return suspendCall(
        method = "GET",
        path = path,
        queryParams = queryParams,
        body = null as Any?
    )
}

suspend inline fun <reified B, reified T> suspendPost(path: String, body: B): T {
    return suspendCall(
        method = "POST",
        path = path,
        body = body
    )
}

suspend inline fun <reified B, reified T> suspendPut(path: String, body: B): T {
    return suspendCall(
        method = "PUT",
        path = path,
        body = body
    )
}

suspend inline fun suspendDelete(path: String) {
    suspendCall<String, String>(
        method = "DELETE",
        path = path
    )
}

suspend inline fun <reified B, reified T> suspendCall(
    method: String,
    path: String,
    body: B? = null,
    queryParams: Map<String, Any> = emptyMap()
): T {
    val headers = mutableListOf(
        "Accept" to "application/json"
    )

    var requestBody: Any? = body
    if (body != null && body !is web.http.FormData) {
        headers.add(
            "Content-Type" to "application/json"
        )
        val preJsonBody: B = body
        requestBody = jsonSerializer.encodeToString(preJsonBody)
    }

    AuthManager.token?.also {
        headers.add(
            "Authorization" to "Bearer $it"
        )
    }

    var url = BaseUrl.get(path)
    if (queryParams.isNotEmpty()) {
        url += "?" + queryParams.map { (name, value) ->
            name.encodeUri() + "=" + value.toString().encodeUri()
        }.joinToString(separator = "&")
    }

    val promise: Promise<Response> = window.fetch(
        input = url,
        init = RequestInit(
            method = method,
            headers = json(*headers.toTypedArray()),
            body = requestBody,
        )
    ).catch {
        Response.error()
    }

    val response: Response = promise.await()
    val text = response.text().catch { "" }.await()

    return if (response.ok.not()) {
        val code = response.status.toInt()
        if (code == 401) {
            AuthManager.logout()
        }
        throw HttpException(code, text)
    } else {
        when {
            method == "DELETE" -> {
                "" as T
            }

            "" is T -> text as T
            else -> jsonSerializer.decodeFromString(text)
        }
    }
}

inline fun <reified B, reified T> call(method: String, path: String, body: B? = null): Promise<T> {

    val headers = mutableListOf(
        "Accept" to "application/json"
    )

    var requestBody: Any? = body
    if (body != null && body !is web.http.FormData) {
        headers.add(
            "Content-Type" to "application/json"
        )
        val preJsonBody: B = body
        requestBody = jsonSerializer.encodeToString(preJsonBody)
    }

    if (path.contains("/auth").not() && path.contains("/auth/sms-confirm").not()) {
        AuthManager.token?.also {
            headers.add(
                "Authorization" to "Bearer $it"
            )
        }
    }

    return window.fetch(
        input = BaseUrl.get(path),
        init = RequestInit(
            method = method,
            headers = json(*headers.toTypedArray()),
            body = if (method != "DELETE") requestBody else null
        )
    )
        .then {
            if (it.ok.not()) {
                it.text()
                    .then { body ->
                        console.log(body)
                        //throw HttpException(errorResponse = body)
                        body
                    }
            } else {
                it.text()
            }
        }
        .then {
            jsonSerializer.decodeFromString(it)
        }

}
