package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class FilterValue(
    val id: Long?,
    val filter: Filter?,
    val value: String?,
    val displayValue: String?,
    var referencedPropertyValue: PropertyValue?
)