package uz.ferro.shop.pages.admin

import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.material.Alert
import mui.material.AlertColor
import mui.material.AlertTitle
import mui.material.Box
import mui.material.Snackbar
import mui.material.SnackbarCloseReason
import mui.material.SnackbarOrigin
import mui.material.SnackbarOriginHorizontal
import mui.material.SnackbarOriginVertical
import mui.system.sx
import react.VFC
import react.createContext
import react.dom.html.ReactHTML.div
import react.router.Outlet
import react.useContext
import react.useEffectOnce
import react.useState
import tanstack.react.query.QueryClientProvider
import uz.ferro.shop.Colors
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.appQueryClient
import uz.ferro.shop.error.HttpException
import uz.ferro.shop.manager.AuthManager
import uz.ferro.shop.manager.AuthManager.canUseAdminDashboard
import web.cssom.AlignContent
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.Flex
import web.cssom.FlexDirection
import web.cssom.LineStyle
import web.cssom.None
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.pct
import web.cssom.px

interface AlertManagerHost {
    fun onSuccessMessage(message: String)
    fun onErrorMessage(message: String)
}

class AlertManager(val host: AlertManagerHost? = null) {

    fun handleError(e: Exception) {
        if (e is HttpException && e.errorResponse.isNotBlank()) {
            showError(e.errorResponse)
        } else {
            e.printStackTrace()
            showError("Noma'lum xatolik")
        }
    }

    fun showSuccess(message: String) {
        host?.onSuccessMessage(message)
    }

    fun showError(message: String) {
        host?.onErrorMessage(message)
    }
}

val AlertManagerContext = createContext(AlertManager())

private enum class AlertType {
    ERROR,
    SUCCESS
}

private data class AlertMessage(
    val type: AlertType,
    val message: String
)

val AdminDashboard = VFC {
    val navigator = useContext(NavigationContext)
    if (canUseAdminDashboard()) {
        var alertMessage by useState<AlertMessage>()

        val alertManagerHost = object : AlertManagerHost {
            override fun onSuccessMessage(message: String) {
                alertMessage = AlertMessage(
                    type = AlertType.SUCCESS,
                    message = message
                )
            }

            override fun onErrorMessage(message: String) {
                alertMessage = AlertMessage(
                    type = AlertType.ERROR,
                    message = message
                )
            }
        }
        val alertManager = AlertManager(alertManagerHost)

        AlertManagerContext(alertManager) {
            AdminDashboardContent()

            Snackbar {
                open = alertMessage != null
                autoHideDuration = 5000
                anchorOrigin = object : SnackbarOrigin {
                    override var vertical: SnackbarOriginVertical = SnackbarOriginVertical.top
                    override var horizontal: SnackbarOriginHorizontal = SnackbarOriginHorizontal.right
                }
                onClose = { event, reason ->
                    if (reason != SnackbarCloseReason.clickaway) {
                        alertMessage = null
                    }
                }

                Alert {
                    severity = when (alertMessage?.type) {
                        AlertType.SUCCESS -> AlertColor.success
                        AlertType.ERROR -> AlertColor.error
                        else -> AlertColor.error
                    }

                    sx {
                        width = 100.pct
                    }
                    AlertTitle {
                        when (alertMessage?.type) {
                            AlertType.ERROR -> +"Xatolik"
                            else -> +""
                        }
                    }

                    onClose = { _ ->
                        alertMessage = null
                    }
                    +alertMessage?.message.orEmpty()
                }
            }
        }
    } else {
        MainScope().launch {
            delay(100) // Workaround for avoid blank page
            navigator.openLogin()
        }
    }
}

val AdminDashboardContent = VFC {
    val navigator = useContext(NavigationContext)
    QueryClientProvider {
        client = appQueryClient

        useEffectOnce {
            MainScope().launch {
                try {
                    AuthManager.checkAuthorization()
                } catch (e: Exception) {
                    // no-op
                }
            }
        }

        div {
            css {
                position = Position.absolute
                width = 100.pct
                minHeight = 100.pct
                maxHeight = 100.pct
                backgroundColor = Colors.adminBgColor
                display = Display.flex
                flexDirection = FlexDirection.row
                alignContent = AlignContent.stretch
            }

            div {
                css {
                    width = 240.px
                    backgroundColor = Colors.adminMenuBgColor
                    color = Colors.adminMenuTextColor
                    position = Position.relative
                }

                AdminMenu()

                Box {
                    sx {
                        position = Position.absolute
                        bottom = 0.px
                        left = 0.px
                        right = 0.px
                        borderTopStyle = LineStyle.solid
                        borderTopWidth = 1.px
                        borderTopColor = Color("#7e7e7e")
                        cursor = Cursor.pointer
                        display = Display.flex
                        paddingLeft = 20.px
                        paddingRight = 20.px
                        alignItems = AlignItems.center
                        listStyleType = None.none
                        paddingTop = 14.px
                        paddingBottom = 14.px
                        fontSize = 18.px
                    }

                    +"Chiqish"

                    onClick = {
                        AuthManager.logout()
                        navigator.openLogin()
                    }
                }
            }

            div {
                css {
                    position = Position.relative
                    flex = Flex.maxContent
                    overflow = Overflow.scroll
                }
                Outlet()
            }
        }
    }
}