package uz.ferro.shop.pages.admin.brand

import emotion.react.css
import mui.material.FormControl
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.div
import uz.ferro.shop.api.useAllBrands
import uz.ferro.shop.model.Brand
import uz.ferro.shop.util.orZero
import web.cssom.px

external interface BrandPickerProps : Props {
    var selectedBrand: Brand?
    var onBrandSelect: (Brand?) -> Unit
}

val BrandPicker = FC<BrandPickerProps> { props ->
    val allItems = useAllBrands()
    val selectedItem = props.selectedBrand
    val onItemSelect = props.onBrandSelect

    div {
        css { marginTop = 20.px }
        if (allItems.isNotEmpty()) {
            FormControl {
                fullWidth = true
                InputLabel {
                    +"Brend"
                }
                Select {
                    value = selectedItem?.id.orZero()
                    label = ReactNode("Brend")
                    onChange = { evt, _ ->
                        val id = evt.target.value
                        val cat = allItems.firstOrNull { it.id.toString() == id }
                        onItemSelect(cat)
                    }

                    MenuItem {
                        value = 0
                        +"--"
                    }
                    allItems.forEach {
                        MenuItem {
                            value = it.id.toString()
                            +it.name
                        }
                    }
                }
            }
        }
    }
}