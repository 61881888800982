package uz.ferro.shop.pages.admin.document

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.CircularProgress
import mui.material.Link
import mui.material.Size
import mui.system.sx
import react.FC
import react.Props
import react.useState
import web.cssom.px

val AdminPriceGenerator = FC<Props> {
    var link by useState("")
    var isProgress by useState(false)

    fun generatePrices() {
        MainScope().launch {
            isProgress = true
            try {
                link = PriceListGenerator.generate()
            } catch (e: Exception) {
                // no-op
                e.printStackTrace()
            }
            isProgress = false
        }
    }

    Box {
        sx {
            padding = 20.px
        }
        if (isProgress.not() && link.isBlank()) {
            Button {
                sx {
                    marginLeft = 32.px
                }
                size = Size.small
                variant = ButtonVariant.contained
                +"Нархлар руйхати"

                onClick = {
                    generatePrices()
                }
            }
        }

        if (isProgress) {
            CircularProgress()
        }

        if (link.isNotBlank()) {
            Link {
                href = link
                +link
            }
        }
    }

}