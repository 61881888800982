package uz.ferro.shop.navigator

import kotlinx.browser.window
import uz.ferro.shop.PARAM_HISTORY
import uz.ferro.shop.RouterProvider
import uz.ferro.shop.VALUE_NO_BACK
import uz.ferro.shop.navigator.NavigationPage.*
import uz.ferro.shop.util.encodeUri
import web.url.URL
import web.url.URLSearchParams

interface Navigator {

    fun openLogin()

    fun openMain()

    fun openSearch(query: String)

    fun goBack()

    fun openProductDetails(productId: Long?)

    fun openPhotoPreview(
        photos: List<String>,
        selected: String?
    )

    fun openCategory(categoryId: Int?)

    fun openCart()

    fun openOrders()

    fun openOrderDetails(orderId: Long?)

    fun openFavorites()

    fun openPaymentAndDelivery()

    fun openAdminOrder(orderId: Long)

    fun openAdminNewComponentBanner(componentId: Int)

    fun openAdminEditComponentBanner(componentId: Int, componentBannerId: Int)

    fun openAdminNewComponentCategory(componentId: Int)

    fun openAdminEditComponentCategory(componentId: Int, componentCategoryId: Int)

    fun openAdminNewSalePoint()

    fun openAdminEditSalePoint(salePointId: Long)

    fun openAdminEditProduct(productId: Long?)

    fun openAdminNewProduct()

    fun openPage(page: NavigationPage, vararg pathArgs: Any)

    fun openUrl(url: String)
}

class AppNavigator(private val routerProvider: RouterProvider) : Navigator {

    override fun openLogin() {
        val currentUrl = URL(window.location.href)
        val redirect = (currentUrl.pathname + currentUrl.hash)
            .removePrefix("/#") + "?" + URLSearchParams("").apply {
            append(PARAM_HISTORY, VALUE_NO_BACK)
        }
        val path = "/login?" + URLSearchParams("").apply {
            append("redirect", redirect)
        }

        toLink(path)
    }

    override fun openMain() {
        toLink("/")
    }

    override fun openSearch(query: String) {
        toLink("/search?q=${query.encodeUri()}")
    }

    override fun goBack() {
        routerProvider.getRouter().navigate(-1.0)
    }

    override fun openProductDetails(productId: Long?) {
        productId ?: return
        toLink("/product/${productId}")
    }

    override fun openPhotoPreview(
        photos: List<String>,
        selected: String?
    ) {
        toLink("/photo-viewer?selectedUrl=${selected.orEmpty()}&urls=${photos.joinToString(",")}")
    }

    override fun openCategory(categoryId: Int?) {
        categoryId ?: return
        toLink("/category/${categoryId}")
    }

    override fun openCart() {
        toLink("/cart")
    }

    override fun openOrders() {
        toLink("/order")
    }

    override fun openOrderDetails(orderId: Long?) {
        orderId ?: return
        toLink("/order/${orderId}")
    }

    override fun openFavorites() {
        toLink("/favorites")
    }

    override fun openPaymentAndDelivery() {
        toLink("/payment-and-delivery")
    }

    override fun openAdminOrder(orderId: Long) {
        openPage(NavigationPage.ADMIN_ORDER, orderId)
    }

    override fun openAdminNewComponentBanner(componentId: Int) {
        toLink("/admin/component/$componentId/component-banner/new")
    }

    override fun openAdminEditComponentBanner(componentId: Int, componentBannerId: Int) {
        toLink("/admin/component/$componentId/component-banner/$componentBannerId")
    }

    override fun openAdminNewComponentCategory(componentId: Int) {
        toLink("/admin/component/$componentId/component-category/new")
    }

    override fun openAdminEditComponentCategory(componentId: Int, componentCategoryId: Int) {
        toLink("/admin/component/$componentId/component-category/$componentCategoryId")
    }

    override fun openAdminNewSalePoint() {
        toLink("/admin/sale-point/new")
    }

    override fun openAdminEditSalePoint(salePointId: Long) {
        toLink("/admin/sale-point/$salePointId")
    }

    override fun openAdminEditProduct(productId: Long?) {
        openExternal("/#/admin/product/$productId")
    }

    override fun openAdminNewProduct() {
        openExternal("/#/admin/product/new")
    }

    override fun openPage(page: NavigationPage, vararg pathArgs: Any) {
        val path = when (page) {
            LOGIN -> "/login"
            STORES -> "/stores"
            ALL_PRODUCTS -> "/all-products"
            ABOUT_COMPANY -> "/about"
            ADMIN_HOME -> "/admin/home"
            ADMIN_BANNERS -> "/admin/banner"
            ADMIN_CATEGORIES -> "/admin/category"
            ADMIN_PRODUCTS -> "/admin/product"
            ADMIN_PRODUCT_GROUPS -> "/admin/product-group"
            ADMIN_DEALERS -> "/admin/sale-point"
            ADMIN_BRANDS -> "/admin/brand"
            ADMIN_PRICE_GENERATOR -> "/admin/price-generator"
            ADMIN_USERS -> "/admin/user"
            ADMIN_ORDER -> "/admin/order"
            ADMIN_SETTINGS -> "/admin/settings"
            ADMIN_SALE_PERSON -> "/admin/settings/sale-person"
            ADMIN_HELP -> "/admin/help"
        }

        val suffix = pathArgs.joinToString(separator = "/").let {
            if (it.isNotEmpty()) {
                "/$it"
            } else {
                it
            }
        }

        toLink(path + suffix)
    }

    override fun openUrl(url: String) {
        if (isInternalUrl(url)) {
            toLink(url)
        } else {
            openExternal(url)
        }
    }

    private fun toLink(link: String) {
        routerProvider.getRouter().navigate(link)
    }

    private fun openExternal(url: String) {
        if (url.isNotBlank()) {
            window.open(url, "_blank", "noreferrer")
        }
    }

    private fun isInternalUrl(url: String): Boolean {
        if (url.startsWith("/category/")) return true

        if (url.startsWith("/product/")) return true

        if (url.startsWith("/admin/help/")) return true

        return false
    }
}