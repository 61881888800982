package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class DictItem(
    val id: Long? = null,
    val textUzLat: String? = null,
    val textUzCyr: String? = null,
    val textRu: String? = null,
    val textEn: String? = null
)
