package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class Document(
    val id: Long? = null,
    val title: String,
    val type: DocumentType,
    val content: String?
)