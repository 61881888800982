package uz.ferro.shop.model

enum class Role {
    SUPER_ADMIN,
    ADMIN,
    SALES_MANAGER,
    SALES_AGENT,
    B2B_USER,
    USER,
    ANONYMOUS_USER
}