package uz.ferro.shop.pages.cart

import emotion.react.css
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML
import react.useEffect
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.icon.QuantityMinusIcon
import uz.ferro.shop.icon.QuantityPlusIcon
import uz.ferro.shop.util.orZero
import web.cssom.AlignItems
import web.cssom.Length
import web.cssom.LineStyle
import web.cssom.NamedColor
import web.cssom.TextAlign
import web.cssom.integer
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType

external interface QuantityViewProps : Props {
    var initialValue: Int
    var changeValue: (Int) -> Unit
    var size: Size?
}

private val numberInputInvalidChars = arrayOf("-", "+", "e")

val QuantityItemView = FC<QuantityViewProps> { props ->

    var quantity by useState(props.initialValue)

    useEffect(props.initialValue) {
        quantity = props.initialValue
    }

    val heightProp = when (props.size) {
        Size.large -> 48
        Size.small -> 32
        else -> 40
    }

    val radiusProp = when (props.size) {
        Size.large -> 3
        else -> 2
    }

    val iconSize = when (props.size) {
        Size.large -> 24
        Size.small -> 20
        else -> 22
    }

    Stack {
        direction = responsive(StackDirection.row)
        sx {
            height = heightProp.px
            borderWidth = 1.px
            borderColor = Colors.accentColor
            borderStyle = LineStyle.solid
            borderRadius = radiusProp.px
            alignItems = AlignItems.center
            paddingLeft = 12.px
            paddingRight = 12.px
            width = Length.minContent
        }

        QuantityMinusIcon(iconSize) {
            var qty = quantity
            if (qty == 0) {
                qty = 1
                quantity = qty
                props.changeValue(qty)
            } else if (qty > 1) {
                qty--
                quantity = qty
                props.changeValue(qty)
            }
        }.invoke()

        ReactHTML.input {
            key = "QuantityInput"
            css {
                minWidth = 50.px
                height = heightProp.px
                fontWeight = integer(700)
                color = Colors.textPrimary
                fontSize = 16.px
                borderStyle = LineStyle.hidden
                textAlign = TextAlign.center
                backgroundColor = NamedColor.transparent
                outline = null
            }
            type = InputType.number
            min = "0"
            max = "99999"
            value = if (quantity > 0) quantity.toString() else ""

            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                val intVal = event.target.value.trim().toIntOrNull().orZero()
                if (intVal in 0..99_999) {
                    quantity = intVal
                    props.changeValue(intVal)
                }
            }

            onKeyDown = { event ->
                if (numberInputInvalidChars.contains(event.key)) {
                    event.preventDefault()
                }
            }
        }

        QuantityPlusIcon(iconSize) {
            var qty = quantity
            if (qty < 99_999) {
                qty++
                quantity = qty
                props.changeValue(qty)
            }
        }.invoke()
    }
}