package uz.ferro.shop.api

import js.core.Void
import js.core.jso
import tanstack.query.core.QueryKey
import tanstack.react.query.useMutation
import tanstack.react.query.useQuery
import tanstack.react.query.useQueryClient
import uz.ferro.shop.MEASURE_UNITS_ALL_QUERY_KEY
import uz.ferro.shop.model.MeasureUnit
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import kotlin.js.Promise

fun useAllMeasureUnits(): List<MeasureUnit> {
    val result = useQuery<List<MeasureUnit>, Error, List<MeasureUnit>, QueryKey>(
        queryKey = MEASURE_UNITS_ALL_QUERY_KEY,
        queryFn = { getAllMeasureUnits() }
    )
    return result.data.orEmpty()
}

private fun addMeasureUnit(unit: MeasureUnit): Promise<MeasureUnit> {
    return post(path = "measure-unit", body = unit)
}

typealias SaveMeasureUnit = (MeasureUnit) -> Unit

private fun updateMeasureUnit(unit: MeasureUnit): Promise<MeasureUnit> {
    return put(path = "measure-unit", body = unit)
}

fun useSaveMeasureUnit(): SaveMeasureUnit {
    val client = useQueryClient()
    return useMutation<MeasureUnit, Error, MeasureUnit, QueryKey>(
        mutationFn = { unit ->
            if (unit.id.orZero() > 0) {
                updateMeasureUnit(unit)
            } else {
                addMeasureUnit(unit)
            }
        },
        options = jso {
            onSuccess = { _, _, _ -> client.invalidateQueries<Void>(MEASURE_UNITS_ALL_QUERY_KEY) }
        }
    ).mutate.unsafeCast<SaveMeasureUnit>()
}

fun getAllMeasureUnits(): Promise<List<MeasureUnit>> {
    return get<List<MeasureUnit>>("measure-unit/list")
        .then { data ->
            data.sortedBy { it.name.localized() }
        }
}
