package uz.ferro.shop.api

import uz.ferro.shop.model.PageContentProductGroup
import uz.ferro.shop.util.orZero
import kotlin.js.Promise

fun getPageContentProductGroup(id: Int): Promise<PageContentProductGroup> {
    return get("page-content-product-group/$id")
}

fun savePageContentProductGroup(cc: PageContentProductGroup): Promise<PageContentProductGroup> {
    return if (cc.id.orZero() > 0) {
        updatePageContentProductGroup(cc)
    } else {
        addPageContentProductGroup(cc)
    }
}

fun deletePageContentProductGroup(id: Int): Promise<Unit> {
    return delete("page-content-product-group/$id")
}

private fun addPageContentProductGroup(cc: PageContentProductGroup): Promise<PageContentProductGroup> {
    return post(path = "page-content-product-group", body = cc)
}

private fun updatePageContentProductGroup(cc: PageContentProductGroup): Promise<PageContentProductGroup> {
    return put(path = "page-content-product-group", body = cc)
}
