package uz.ferro.shop.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class OrderProduct(
    @SerialName("id")
    val id: Long? = null,

    @SerialName("orderId")
    val orderId: Long? = null,

    @SerialName("product")
    val product: Product,

    @SerialName("quantity")
    var quantity: Int,

    @SerialName("productPrice")
    var productPrice: Double?,

    @SerialName("discountPercent")
    var discountPercent: Int?
)
