package uz.ferro.shop.util

import com.benasher44.uuid.uuid4

fun generateUUID(): String = uuid4().toString()


private val UZ_PHONE_REGEX = Regex("\\+?998\\s?(\\d{2})[\\s-]?(\\d{3})[\\s-]?(\\d{2})[\\s-]?(\\d{2})")

fun formatUzbPhone(phone: String?): String {
    val result = UZ_PHONE_REGEX.matchEntire(phone.orEmpty())
    if (result != null) {
        val parts = result.groupValues
        if (parts.size == 5) {
            return "+998 ${parts[1]} ${parts[2]}-${parts[3]}-${parts[4]}"
        }
    }

    if (phone != null) return phone
    return ""
}