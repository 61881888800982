package uz.ferro.shop.locale

enum class Language {
    UZ,
    UZ_CYR,
    RU,
    EN;

    companion object {
        fun getByString(stringVal: String?): Language {
            return entries.firstOrNull { it.name.lowercase() == stringVal.orEmpty().lowercase() }
                ?: UZ
        }
    }
}