package uz.ferro.shop.pages.order

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Breadcrumbs
import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableHead
import mui.material.TableRow
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.ChevronRightIcon16
import uz.ferro.shop.manager.OrderManager
import uz.ferro.shop.model.Order
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.util.styleInMobile
import web.cssom.LineStyle
import web.cssom.TextAlign
import web.cssom.integer
import web.cssom.pct
import web.cssom.px

val OrderListPage = FC<Props> {
    val navigator = useContext(NavigationContext)
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()
    val locale = useContext(LocaleContext)

    MarketPage(
        hideCategory = true,
        centerBlock = {
            Breadcrumbs {
                sx {
                    fontSize = 13.px
                }

                separator = ChevronRightIcon16.create()
                Link {
                    color = Colors.textSecondary
                    underline = LinkUnderline.hover
                    key = "1"
                    href = "/"
                    onClick = {

                    }
                    +locale.main
                }

                Box {
                    sx {
                        color = Colors.textSecondary
                    }
                    +locale.profile
                }

                Box {
                    sx {
                        color = Colors.textSecondary
                    }
                    +locale.orders
                }
            }

            Box {
                sx {
                    marginTop = 10.px
                    color = Colors.textPrimary
                    fontSize = styleInMobile(25.px, 31.px)
                    fontWeight = integer(700)
                }
                +locale.orders
            }
        },
        fullBlock = {
            var allOrders by useState(listOf<Order>())
            var orders by useState(listOf<Order>())
            var isLoading by useState(false)
            var showEmptyView by useState(false)
            var status by useState("")

            fun filterData(all: List<Order>) {
                orders = all
            }

            fun loadData() {
                MainScope().launch {
                    isLoading = true
                    val result = OrderManager.getUserOrderList()
                    allOrders = result
                    showEmptyView = allOrders.isEmpty()
                    isLoading = false
                    filterData(result)
                }
            }

            useEffectOnce {
                loadData()
            }

            if (isMobile) {
                Stack {
                    direction = responsive(StackDirection.column)
                    spacing = responsive(16.px)
                    sx {
                        width = 100.pct
                    }

                    orders.forEach { order ->
                        OrderItemViewMobile {
                            this.order = order
                        }
                    }
                }
            } else {
                Box {
                    sx {
                        borderRadius = 5.px
                        borderWidth = 1.px
                        borderColor = Colors.borderPrimary
                        borderStyle = LineStyle.solid
                    }

                    Table {
                        TableHead {
                            TableRow {
                                sx {
                                    backgroundColor = Colors.secondaryBackground
                                }

                                TableCell {
                                    sx {
                                        width = 0.px
                                        textAlign = TextAlign.center
                                    }

                                    AppTextBold {
                                        text = "ID"
                                    }
                                }

                                TableCell {
                                    sx {
                                        textAlign = TextAlign.center
                                        width = 0.px
                                    }
                                    AppTextBold {
                                        text = locale.date
                                    }
                                }

                                TableCell {
                                    sx {
                                        textAlign = TextAlign.center
                                        width = 0.px
                                    }
                                    AppTextBold {
                                        text = locale.productsCount
                                    }
                                }

                                TableCell {
                                    sx {
                                        textAlign = TextAlign.center
                                        width = 0.px
                                    }
                                    AppTextBold {
                                        text = locale.inTotal
                                    }
                                }

                                TableCell {
                                    sx {
                                        textAlign = TextAlign.center
                                        width = 0.px
                                    }
                                    AppTextBold {
                                        text = locale.status
                                    }
                                }
                            }
                        }

                        TableBody {
                            orders.forEach { order ->
                                OrderItemView {
                                    this.order = order
                                }
                            }
                        }
                    }
                }
            }
        }
    ).invoke()
}