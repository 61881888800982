package uz.ferro.shop.pages.admin.settings

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControl
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.router.dom.useSearchParams
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.PARAM_HISTORY
import uz.ferro.shop.VALUE_NO_BACK
import uz.ferro.shop.model.SalesPerson
import uz.ferro.shop.navigator.NavigationPage
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.pages.admin.salepoint.SalesPersonManager
import web.cssom.JustifyContent
import web.cssom.px

val AdminSalePersonDetails = FC<Props> {
    val historyParam = useSearchParams().component1()[PARAM_HISTORY]

    val navigator = useContext(NavigationContext)
    val alertManager = useContext(AlertManagerContext)
    var salesPersons by useState<List<SalesPerson>>(emptyList())
    var currentSalesPerson by useState<SalesPerson>()
    var selectedPerson by useState<SalesPerson>()

    useEffectOnce {
        MainScope().launch {
            try {
                val allPersons = SalesPersonManager.getSalesPersons()
                val default = SalesPersonManager.getDefaultSalesPerson()

                val defaultInList = allPersons.firstOrNull {
                    it.id == default.id
                }
                currentSalesPerson = defaultInList
                selectedPerson = defaultInList
                salesPersons = allPersons
            } catch (e: Exception) {
                alertManager.handleError(e)
            }
        }
    }

    fun goBack() {
        if (historyParam == VALUE_NO_BACK) {
            navigator.openPage(NavigationPage.ADMIN_SETTINGS)
        } else {
            navigator.goBack()
        }
    }

    val title = "Sotuv menegeri"



    Stack {
        direction = responsive(StackDirection.column)

        sx {
            padding = 16.px
            width = 400.px
        }

        FormControl {
            fullWidth = true
            InputLabel {
                +title
            }

            Select {
                value = (selectedPerson?.id ?: 0L).toString()
                label = ReactNode(title)
                onChange = { evt, _ ->
                    val pickedId = evt.target.value
                    val pickedPerson = salesPersons.firstOrNull { it.id.toString() == pickedId }
                    selectedPerson = pickedPerson
                }

                salesPersons.forEach {
                    MenuItem {
                        value = it.id.toString()
                        +it.name
                    }
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                marginTop = 32.px
                justifyContent = JustifyContent.end
            }

            Button {
                variant = ButtonVariant.contained
                onClick = {
                    goBack()
                }
                +"Bekor"
            }

            Button {
                sx {
                    marginLeft = 16.px
                }
                disabled = selectedPerson == null || selectedPerson?.id == currentSalesPerson?.id
                variant = ButtonVariant.contained
                onClick = {
                    MainScope().launch {
                        try {
                            SalesPersonManager.saveDefaultSalesPerson(selectedPerson!!)
                            goBack()
                        } catch (e: Exception) {
                            alertManager.handleError(e)
                        }
                    }
                }
                +"Saqlash"
            }
        }
    }
}
