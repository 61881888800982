package uz.ferro.shop.pages.admin.component

import uz.ferro.shop.api.suspendDelete
import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.api.suspendPut
import uz.ferro.shop.model.Component
import uz.ferro.shop.model.ComponentType
import uz.ferro.shop.util.orZero

private const val API_PATH_SECTION = "component"

object ComponentManager {

    suspend fun getComponent(id: Int): Component {
        return suspendGet("$API_PATH_SECTION/$id")
    }

    suspend fun createNewComponent(type: ComponentType): Component {
        val newItem = Component(
            type = type
        )
        return addComponent(newItem)
    }

    suspend fun saveComponent(component: Component): Component {
        return if (component.id.orZero() > 0) {
            updateComponent(component)
        } else {
            addComponent(component)
        }
    }

    suspend fun deleteComponent(id: Int) {
        suspendDelete("$API_PATH_SECTION/$id")
    }

    private suspend fun addComponent(component: Component): Component {
        return suspendPost(
            path = API_PATH_SECTION,
            body = component
        )
    }

    private suspend fun updateComponent(component: Component): Component {
        return suspendPut(
            path = API_PATH_SECTION,
            body = component
        )
    }
}