package uz.ferro.shop.pages.admin.help

import mui.material.Box
import mui.material.Link
import mui.material.LinkUnderline
import mui.system.sx
import react.FC
import react.Props
import react.router.Outlet
import react.useContext
import uz.ferro.shop.Colors
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.ui.AppTextSemiBold
import web.cssom.Cursor
import web.cssom.integer
import web.cssom.px

val AdminHelp = FC<Props> {
    Outlet()
}

val AdminHelpTopics = FC<Props> {

    val navigator = useContext(NavigationContext)

    Box {
        sx {
            padding = 16.px
        }

        topics.forEach { topic ->
            Link {
                sx {
                    color = Colors.textPrimary
                    fontWeight = integer(500)
                    fontSize = 20.px
                    cursor = Cursor.pointer
                }
                underline = LinkUnderline.hover
                onClick = {
                    navigator.openUrl(topic.url)
                }
                +topic.title
            }
        }
    }
}

private val topics = listOf(
    HelpItem(title = "Maxsulot qo‘shish", url = "/admin/help/add-product")
)

private class HelpItem(
    val title: String,
    val url: String
)

