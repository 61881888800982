package uz.ferro.shop.pages.admin.salepoint

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.router.dom.useSearchParams
import react.router.useParams
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.PARAM_HISTORY
import uz.ferro.shop.VALUE_NO_BACK
import uz.ferro.shop.model.SalePoint
import uz.ferro.shop.navigator.NavigationPage
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.pages.admin.dict.DictItemEditor
import uz.ferro.shop.ui.AppTextSemiBoldLarge
import uz.ferro.shop.util.isNotFull
import uz.ferro.shop.util.orZero
import uz.ferro.shop.util.toIntOrZero
import web.cssom.JustifyContent
import web.cssom.pct
import web.cssom.px
import web.html.HTMLInputElement

val AdminSalePointDetails = FC<Props> {
    val pathParam = useParams()["salePointId"] ?: return@FC
    val historyParam = useSearchParams().component1()[PARAM_HISTORY]

    val salePointId = pathParam.toLongOrNull()

    val navigator = useContext(NavigationContext)
    val alertManager = useContext(AlertManagerContext)

    var salePoint by useState(
        SalePoint()
    )

    fun goBack() {
        if (historyParam == VALUE_NO_BACK) {
            navigator.openPage(NavigationPage.ADMIN_DEALERS)
        } else {
            navigator.goBack()
        }
    }

    useEffectOnce {
        MainScope().launch {
            try {
                if (salePointId == null) {
                    val newSort = SalePointManager.getSalePoints().maxOfOrNull { it.sort }.orZero() + 1
                    salePoint = SalePoint(sort = newSort)
                } else {
                    salePoint = SalePointManager.getSalePoint(salePointId)
                }
            } catch (e: Exception) {
                alertManager.handleError(e)
            }
        }
    }

    Box {
        sx {
            padding = 16.px
            width = 400.px
        }

        if (salePointId == null) {
            AppTextSemiBoldLarge {
                text = "Yangi Do‘kon"
            }
        } else {
            AppTextSemiBoldLarge {
                text = "Do‘kon: $salePointId"
            }
        }

        DictItemEditor {
            title = "Nomi"
            item = salePoint.name
            onChange = {
                salePoint = salePoint.copy(name = it)
            }
        }

        DictItemEditor {
            title = "Manzil"
            item = salePoint.address
            onChange = {
                salePoint = salePoint.copy(address = it)
            }
        }

        TextField {
            sx {
                marginTop = 32.px
                width = 100.pct
            }
            label = ReactNode("Telefon")
            variant = FormControlVariant.outlined
            value = salePoint.phone
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                salePoint = salePoint.copy(
                    phone = event.target.value
                )
            }
        }

        TextField {
            sx {
                marginTop = 16.px
                width = 100.pct
            }
            label = ReactNode("Telefon 2")
            variant = FormControlVariant.outlined
            value = salePoint.phone2
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                salePoint = salePoint.copy(
                    phone2 = event.target.value
                )
            }
        }

        TextField {
            sx {
                marginTop = 16.px
                width = 100.pct
            }
            label = ReactNode("Haritaga havola")
            variant = FormControlVariant.outlined
            value = salePoint.mapLink.orEmpty()
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                salePoint = salePoint.copy(
                    mapLink = event.target.value
                )
            }
        }

        TextField {
            sx {
                marginTop = 16.px
                width = 100.pct
            }
            label = ReactNode("Tartib raqami")
            variant = FormControlVariant.outlined
            value = salePoint.sort
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                salePoint = salePoint.copy(sort = event.target.value.toIntOrZero())
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                marginTop = 32.px
                justifyContent = JustifyContent.end
            }

            if (salePoint.id != null) {
                Button {
                    sx {
                        marginRight = 16.px
                    }
                    variant = ButtonVariant.contained
                    onClick = {
                        MainScope().launch {
                            try {
                                SalePointManager.deleteSalePoint(salePoint.id!!)
                                goBack()
                            } catch (e: Exception) {
                                alertManager.handleError(e)
                            }
                        }
                    }
                    +"O'chirish"
                }
            }


            Button {
                variant = ButtonVariant.contained
                onClick = {
                    goBack()
                }
                +"Bekor"
            }

            Button {
                sx {
                    marginLeft = 16.px
                }
                variant = ButtonVariant.contained
                onClick = {
                    if (isValidSalePoint(salePoint)) {
                        MainScope().launch {
                            try {
                                SalePointManager.saveSalePoint(salePoint)
                                goBack()
                            } catch (e: Exception) {
                                alertManager.handleError(e)
                            }
                        }
                    } else {
                        alertManager.showError("Iltimos ma’lumotlarni to‘ldiring")
                    }
                }
                +"Saqlash"
            }
        }
    }
}

private fun isValidSalePoint(salePoint: SalePoint): Boolean {
    if (salePoint.name.isNotFull()) return false

    if (salePoint.address.isNotFull()) return false

    if (salePoint.phone.isBlank()) return false

    if (salePoint.mapLink.orEmpty().isBlank()) return false

    return true
}