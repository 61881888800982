package uz.ferro.shop.pages.admin.product

import mui.material.Paper
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableCellAlign
import mui.material.TableContainer
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML
import uz.ferro.shop.model.ProductProperty
import uz.ferro.shop.util.localized
import web.cssom.pct
import web.cssom.px

external interface ProductPropertiesListProps : Props {
    var items: List<ProductProperty>

}

val ProductProperties = FC<ProductPropertiesListProps> { prop ->

    TableContainer {
        component = Paper.create().type

        Table {
            sx {
                width = 100.pct
                marginTop = 12.px
            }
            ariaLabel = "Hususiyatlar"

            TableBody {
                prop.items.forEach { item ->
                    val property = item.propertyValue?.property
                    TableRow {
                        hover = true
                        key = item.id.toString()

                        TableCell {
                            component = ReactHTML.th
                            scope = "row"

                            +property?.name.localized()
                        }

                        var value = item.propertyValue?.value.localized()
                        if (value.isNotBlank() && property?.unit != null) {
                            value += " " + property.unit.getLocalizedName()
                        }
                        TableCell {
                            align = TableCellAlign.right

                            +value
                        }
                    }
                }
            }
        }
    }
}