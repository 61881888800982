package uz.ferro.shop.pages.documentpage

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.serialization.decodeFromString
import mui.material.Box
import mui.material.Link
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.h4
import react.router.useParams
import react.useEffect
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.api.jsonSerializer
import uz.ferro.shop.manager.DocumentManager
import uz.ferro.shop.pages.MAX_PAGE_AREA
import uz.ferro.shop.pages.admin.document.Item
import uz.ferro.shop.pages.admin.document.Page
import web.cssom.Auto
import web.cssom.pct
import web.cssom.px
import web.window.WindowTarget

val DocumentPage = FC<Props> {
    val pathParam = useParams()["documentId"]
    val documentId = pathParam?.toLongOrNull()
    var page by useState(Page())

    useEffect(documentId) {
        MainScope().launch {
            documentId ?: return@launch
            val document = DocumentManager.getDocument(documentId)
            val p: Page = jsonSerializer.decodeFromString(document.content.orEmpty())
            page = p
        }
    }

    Box {
        sx {
            width = 100.pct
            maxWidth = MAX_PAGE_AREA
            marginLeft = Auto.auto
            marginRight = Auto.auto
            marginTop = 16.px
            paddingLeft = 16.px
            paddingRight = 16.px
        }

        h4 {
            +page.title
        }

        page.items.forEach { item ->
            ItemView {
                this.item = item
            }
        }
    }
}

external interface ItemProps : Props {
    var item: Item
}

val ItemView = FC<ItemProps> { props ->
    fun build(item: Item) {
        when (item.type) {
            "H4" -> {
                h4 {
                    +item.text.orEmpty()
                }
            }

            "P" -> {
                ReactHTML.p {
                    if (item.text.orEmpty().isNotBlank()) {
                        +item.text.orEmpty()
                    } else {
                        item.children.orEmpty().forEach {
                            build(it)
                        }
                    }
                }
            }

            "A" -> {
                Link {
                    sx {
                        color = Colors.textPrimary
                        textDecorationColor = Colors.textPrimary
                    }
                    if (item.text.orEmpty().isNotBlank()) {
                        +item.text.orEmpty()
                    }
                    href = item.href
                    target = WindowTarget._blank
                }
            }
        }
    }

    build(props.item)
}