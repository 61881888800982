package uz.ferro.shop.pages.category

import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.api.suspendPut
import uz.ferro.shop.model.Category
import uz.ferro.shop.model.MarketCategory
import uz.ferro.shop.util.orZero

object CategoryManager {
    private var cachedList: List<Category> = emptyList()
    private var cachedHomeCategories: List<Category> = emptyList()

    private suspend fun getAllCategories(): List<Category> {
        if (cachedList.isEmpty()) {
            cachedList = suspendGet("category/list")
        }

        return cachedList
    }

    suspend fun getHomeCategories(): List<Category> {
        if (cachedHomeCategories.isEmpty()) {
            cachedHomeCategories = suspendGet<List<MarketCategory>>(path = "market-category/list")
                .sortedBy { it.sort.orZero() }
                .mapNotNull {
                    it.category
                }
        }

        return cachedHomeCategories
    }

    suspend fun getRootCategories(): List<Category> {
        return suspendGet("category/root")
    }

    suspend fun getSubCategories(parentId: Int): List<Category> {
        return getAllCategories().filter {
            it.parent?.id == parentId
        }
    }

    suspend fun getParentCategory(categoryId: Int): Category? {
        return getAllCategories().firstOrNull { it.id == categoryId }?.parent
    }

    suspend fun saveCategory(category: Category): Category {
        return if (category.id.orZero() > 0) {
            updateCategory(category)
        } else {
            addCategory(category)
        }
    }

    suspend fun getCategoryById(id: Int): Category {
        return getAllCategories().first {it.id == id}
    }

    private suspend fun addCategory(category: Category): Category {
        return suspendPost(path = "category", body = category)
    }

    private suspend fun updateCategory(category: Category): Category {
        return suspendPut(path = "category", body = category)
    }
}