package uz.ferro.shop.pages.product

import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.favorite.FavoriteButton
import uz.ferro.shop.model.PHOTO_SIZE_MEDIUM
import uz.ferro.shop.model.Product
import uz.ferro.shop.pages.MarketContext
import uz.ferro.shop.ui.AppTextBoldSmallest
import uz.ferro.shop.ui.CountryFlagView
import uz.ferro.shop.ui.ImageView
import uz.ferro.shop.util.formatActualPriceWithUnit
import uz.ferro.shop.util.formatDMY
import uz.ferro.shop.util.formatOriginPrice
import uz.ferro.shop.util.isDiscounted
import uz.ferro.shop.util.orZero
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Length
import web.cssom.LineStyle
import web.cssom.None
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.TextDecoration
import web.cssom.TextOverflow
import web.cssom.WhiteSpace
import web.cssom.WordWrap
import web.cssom.em
import web.cssom.integer
import web.cssom.number
import web.cssom.pct
import web.cssom.pt
import web.cssom.px
import kotlin.js.Date

external interface ProductItemViewProps : Props {
    var isFavorite: Boolean?
    var date: Date?
    var product: Product
}

val ProductItemView = FC<ProductItemViewProps> { props ->
    val product = props.product

    val marketContext = useContext(MarketContext)
    val navigator = useContext(NavigationContext)
    val locale = useContext(LocaleContext)
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()

    fun openDetails() {
        navigator.openProductDetails(product.id!!)
    }

    fun addToCart(product: Product) {
        marketContext.addToCart(product)
    }

    Box {
        sx {
            width = 100.pct
            borderWidth = 1.px
            borderColor = Colors.borderPrimary
            borderStyle = LineStyle.solid
            borderRadius = 5.px
            overflow = Overflow.hidden
            position = Position.relative
        }

        Box {
            sx {
                width = 100.pct
                aspectRatio = number(1.0)
                cursor = Cursor.pointer
            }

            onClick = { openDetails() }

            ImageView {
                sx {
                    width = 100.pct
                    height = 100.pct
                }
                url = product.getPrimaryPhotoUrl(size = PHOTO_SIZE_MEDIUM)
            }
        }

        Box {
            sx {
                width = 100.pct
                height = responsive(
                    Breakpoint.xs to 216.px,
                    Breakpoint.md to 200.px
                )
                backgroundColor = Colors.secondaryBackground
                padding = 12.px
            }

            Box {
                sx {
                    display = Display.flex
                    alignItems = AlignItems.center
                    width = Length.fitContent
                    maxWidth = 100.pct
                    backgroundColor = Colors.gray05
                    borderRadius = 2.px
                    color = Colors.accentContrastColor
                    fontSize = responsive(
                        Breakpoint.xs to 8.px,
                        Breakpoint.md to 10.px
                    )
                    fontWeight = integer(700)
                    paddingLeft = 4.px
                    paddingRight = 4.px
                    height = 1.6.em
                    textOverflow = TextOverflow.ellipsis
                    whiteSpace = WhiteSpace.nowrap
                    maxLines = integer(1)
                    overflow = Overflow.hidden
                }

                +product.getRootCategory()?.getLocalizedName()
            }

            Box {
                sx {
                    fontSize = responsive(
                        Breakpoint.xs to 12.px,
                        Breakpoint.md to 16.px
                    )
                    fontWeight = integer(600)
                    color = Colors.textPrimary
                    textOverflow = TextOverflow.ellipsis
                    wordWrap = WordWrap.breakWord
                    overflow = Overflow.hidden
                    lineHeight = 1.2.em
                    height = 2.4.em
                    cursor = Cursor.pointer
                    marginTop = responsive(
                        Breakpoint.xs to 8.px,
                        Breakpoint.md to 12.px
                    )
                }
                onClick = { openDetails() }
                +product.getLocalizedName()
            }

            if (product.externalId.orEmpty().isNotBlank()) {
                PriceInfo(product).invoke()

                if (isMobile) {
                    ProductSubInfo(product).invoke()
                    FavoriteButton {
                        sx {
                            width = 100.pct
                            marginTop = 8.px
                        }
                        productId = product.id
                        isLabeled = true
                    }

                    if (props.isFavorite == true && product.isAvailable) {
                        Button {
                            sx {
                                marginTop = 8.px
                                width = 100.pct
                                height = 38.px
                                fontSize = 16.px
                                fontWeight = integer(700)
                                flexGrow = number(1.0)
                                textTransform = None.none
                            }
                            variant = ButtonVariant.outlined
                            onClick = {
                                addToCart(product)
                            }
                            +locale.toCart
                        }
                    }
                } else {
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(16.px)
                        sx {
                            marginTop = 8.px
                        }

                        Box {
                            sx {
                                flexGrow = number(1.0)
                                flexShrink = number(1.0)
                            }

                            if (props.isFavorite == true) {
                                if (product.isAvailable) {
                                    Button {
                                        sx {
                                            width = 100.pct
                                            height = 38.px
                                            fontSize = 16.px
                                            fontWeight = integer(700)
                                            flexGrow = number(1.0)
                                            textTransform = None.none
                                        }
                                        variant = ButtonVariant.outlined
                                        onClick = {
                                            addToCart(product)
                                        }
                                        +locale.toCart
                                    }
                                }
                            } else {
                                ProductSubInfo(product).invoke()
                            }
                        }

                        FavoriteButton {
                            productId = product.id
                        }
                    }
                }
            } else {
                Box {
                    sx {
                        marginTop = 8.px
                    }
                    ProductSubInfo(product).invoke()
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(16.px)
                    sx {
                        marginTop = 20.px
                    }

                    if (isMobile) {
                        FavoriteButton {
                            sx {
                                width = 100.pct
                            }
                            productId = product.id
                            isLabeled = true
                        }
                    } else {
                        Button {
                            sx {
                                height = 38.px
                                fontSize = 16.px
                                fontWeight = integer(700)
                                flexGrow = number(1.0)
                                textTransform = None.none
                            }
                            variant = ButtonVariant.outlined
                            onClick = {
                                openDetails()
                            }
                            +locale.buttonDetails
                        }

                        FavoriteButton {
                            productId = product.id
                        }
                    }
                }
            }
        }

        if (props.date != null) {
            val margin = responsive(
                Breakpoint.xs to 8.px,
                Breakpoint.md to 16.px,
            )
            Stack {
                direction = responsive(StackDirection.column)
                sx {
                    position = Position.absolute
                    top = margin
                    left = margin
                }
                AppTextBoldSmallest {
                    text = (locale.added + ":")
                    color = Colors.textSecondary
                }
                AppTextBoldSmallest {
                    text = props.date.formatDMY()
                    color = Colors.textSecondary
                }
            }
        }

        if (product.isDiscounted) {
            Box {
                sx {
                    position = Position.absolute
                    top = 16.px
                    left = 16.px
                    width = Length.fitContent
                    height = 20.px
                    backgroundColor = Colors.discountLabelColor
                    color = Colors.white
                    padding = 4.px
                    borderRadius = 2.px
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    justifyContent = JustifyContent.center
                    alignItems = AlignItems.center
                    fontWeight = FontWeight.bold
                    fontSize = 13.px
                }
                +"-${product.discountPercent}%"
            }
        }
    }
}

private fun ProductSubInfo(product: Product): FC<Props> = FC<Props> {
    val locale = useContext(LocaleContext)

    Box {
        Box {
            sx {
                fontSize = 13.px
                fontWeight = integer(500)
                color = Colors.textSecondary
                wordWrap = WordWrap.breakWord
                overflow = Overflow.hidden
            }
            val childCounts = product.variantsCount.orZero()
            if (childCounts > 0) {
                +(locale.sizesCount + ": ($childCounts)")
            } else {
                +" "
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(8.px)
            sx {
                marginTop = 8.px
            }

            val country = product.getCountry()
            Box {
                sx {
                    fontSize = 13.px
                    height = 16.px
                    fontWeight = integer(500)
                    color = Colors.textSecondary
                    wordWrap = WordWrap.breakWord
                    overflow = Overflow.hidden
                }

                if (country != null) {
                    +(locale.vendorCountry + ":")
                } else {
                    +" "
                }
            }

            if (country != null) {
                CountryFlagView {
                    this.country = country
                }
            }
        }
    }
}

private fun PriceInfo(product: Product) = FC<Props> {
    val locale = useContext(LocaleContext)

    Box {
        Box {
            sx {
                fontSize = responsive(
                    Breakpoint.xs to 13.px,
                    Breakpoint.md to 16.px
                )
                color = Colors.textSecondary
                marginTop = responsive(
                    Breakpoint.xs to 6.px,
                    Breakpoint.md to 8.px
                )
                height = 1.2.em
                fontWeight = integer(500)
                textDecoration = TextDecoration.lineThrough
            }

            if (product.isDiscounted) {
                +product.formatOriginPrice()
            }
        }

        Box {
            sx {
                fontSize = responsive(
                    Breakpoint.xs to 16.px,
                    Breakpoint.md to 25.px
                )
                marginTop = responsive(
                    Breakpoint.xs to 4.px,
                    Breakpoint.md to 2.px
                )
                color = Colors.textPrimary
                fontWeight = integer(700)
            }

            +product.formatActualPriceWithUnit()
        }
    }
}