package uz.ferro.shop.api

import js.core.Void
import js.core.jso
import tanstack.query.core.QueryKey
import tanstack.react.query.useMutation
import tanstack.react.query.useQuery
import tanstack.react.query.useQueryClient
import uz.ferro.shop.PRODUCT_GROUPS_ALL_QUERY_KEY
import uz.ferro.shop.model.ProductGroup
import uz.ferro.shop.util.orZero
import kotlin.js.Promise

fun useAllProductGroups(): List<ProductGroup> {
    val result = useQuery<List<ProductGroup>, Error, List<ProductGroup>, QueryKey>(
        queryKey = PRODUCT_GROUPS_ALL_QUERY_KEY,
        queryFn = { getProductGroupList() }
    )
    return result.data.orEmpty()
}

fun addProductGroup(productGroup: ProductGroup): Promise<ProductGroup> {
    return post(
        path = "product-group",
        body = productGroup
    )
}

typealias SaveProductGroup = (ProductGroup) -> Unit

private fun updateProductGroup(productGroup: ProductGroup): Promise<ProductGroup> {
    return put(path = "product-group", body = productGroup)
}

fun useSaveProductGroup(): SaveProductGroup {
    val client = useQueryClient()
    return useMutation<ProductGroup, Error, ProductGroup, QueryKey>(
        mutationFn = ::saveProductGroup,
        options = jso {
            onSuccess = { _, _, _ -> client.invalidateQueries<Void>(PRODUCT_GROUPS_ALL_QUERY_KEY) }
        }
    ).mutate.unsafeCast<SaveProductGroup>()
}

fun saveProductGroup(productGroup: ProductGroup): Promise<ProductGroup> {
    return if (productGroup.id.orZero() > 0) {
        updateProductGroup(productGroup)
    } else {
        addProductGroup(productGroup)
    }
}

fun getProductGroupList(): Promise<List<ProductGroup>> {
    return get("product-group/list")
}

fun getProductGroup(id: Int): Promise<ProductGroup> {
    return get("product-group/${id}")
}
