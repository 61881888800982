package uz.ferro.shop.manager

import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.api.suspendPut
import uz.ferro.shop.model.User
import uz.ferro.shop.util.orZero

object UserManager {
    private const val API_PATH = "user"

    suspend fun getMe(): User {
        return suspendGet<User>("$API_PATH/me")
    }

    suspend fun getAllUsers(): List<User> {
        return suspendGet<List<User>>("$API_PATH/list")
            .let { data ->
                data.sortedBy { it.name }
            }
    }

    suspend fun getUser(id: Int): User {
        return suspendGet<User>("$API_PATH/$id")
    }

    private suspend fun addUser(user: User): User {
        return suspendPost(path = API_PATH, body = user)
    }

    private suspend fun updateUser(user: User): User {
        return suspendPut(path = API_PATH, body = user)
    }

    suspend fun saveUser(user: User): User {
        return if (user.id.orZero() > 0) {
            updateUser(user)
        } else {
            addUser(user)
        }
    }
}