package uz.ferro.shop.pages.order

import mui.material.TableCell
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.useContext
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.model.PHOTO_SIZE_SMALL
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextBoldSmall
import uz.ferro.shop.ui.BorderType
import uz.ferro.shop.ui.ImageView
import uz.ferro.shop.util.formatActualPrice
import uz.ferro.shop.util.formatPrice
import web.cssom.Cursor
import web.cssom.LineStyle
import web.cssom.TextAlign
import web.cssom.px

val OrderProductItemView = FC<OrderProductItemProps> { props ->
    val orderProduct = props.orderProduct
    val product = orderProduct.product

    val navigator = useContext(NavigationContext)
    val locale = useContext(LocaleContext)
    val photoUrl = product.getPrimaryPhotoUrl(PHOTO_SIZE_SMALL)

    TableRow {
        hover = true
        key = product.id.toString()

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                cursor = Cursor.pointer
            }

            onClick = {
                navigator.openProductDetails(product.id!!)
            }

            ImageView {
                sx {
                    width = 48.px
                    height = 48.px
                }
                borderType = BorderType.Radius3
                url = photoUrl
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                cursor = Cursor.pointer
            }

            onClick = {
                navigator.openProductDetails(product.id!!)
            }

            AppTextBoldSmall {
                text = product.getLocalizedName()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = product.formatActualPrice()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBoldSmall {
                text = product.unit?.getLocalizedName().orEmpty()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                textAlign = TextAlign.center
                width = 0.px
            }

            AppTextBold {
                text = orderProduct.quantity.toString()
            }
        }

        TableCell {
            sx {
                borderBottomStyle = LineStyle.hidden
                width = 150.px
                textAlign = TextAlign.center
            }

            val totalPrice = orderProduct.productPrice?.times(orderProduct.quantity)
            AppTextBoldSmall {
                text = totalPrice.formatPrice()
            }
        }
    }
}