package uz.ferro.shop.util

import uz.ferro.shop.model.Product
import uz.ferro.shop.model.Property

fun Product.findPropValue(property: Property): String? {
    return properties.orEmpty()
        .firstOrNull {
            it.propertyValue?.property?.key == property.key
        }?.propertyValue?.value?.localizedOrDefault()
}