package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import uz.ferro.shop.util.getLocalized
import uz.ferro.shop.util.localized


@Serializable
data class Property(
    val id: Int? = null,
    val key: String? = null,
    val name: DictItem? = null,
    val type: String? = null,
    val subType: String? = null,
    val unit: MeasureUnit? = null
) {

    fun getLocalizedName(): String = name.localized()
}