package uz.ferro.shop.pages.product

import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Breadcrumbs
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.VFC
import react.create
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.router.dom.useSearchParams
import react.router.useParams
import react.useContext
import react.useEffect
import react.useEffectOnce
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.favorite.FavoriteButton
import uz.ferro.shop.icon.CartIconWhite
import uz.ferro.shop.icon.ChevronRightIcon16
import uz.ferro.shop.manager.ProductManager
import uz.ferro.shop.model.FilterComboData
import uz.ferro.shop.model.PHOTO_SIZE_LARGE
import uz.ferro.shop.model.PHOTO_SIZE_SMALL
import uz.ferro.shop.model.Product
import uz.ferro.shop.model.ProductPhoto
import uz.ferro.shop.pages.MarketCallback
import uz.ferro.shop.pages.MarketContext
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.pages.cart.QuantityItemView
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextBoldExtraLarge
import uz.ferro.shop.ui.AppTextBoldLarge
import uz.ferro.shop.ui.AppTextBoldSmall
import uz.ferro.shop.ui.AppTextMedium
import uz.ferro.shop.ui.AppTextSemiBold
import uz.ferro.shop.ui.AppTextSemiBoldSmall
import uz.ferro.shop.ui.BorderType
import uz.ferro.shop.ui.CountryFlagView
import uz.ferro.shop.ui.ImageView
import uz.ferro.shop.util.FilterUtils
import uz.ferro.shop.util.columnInMobile
import uz.ferro.shop.util.findPropValue
import uz.ferro.shop.util.formatActualPriceWithUnit
import uz.ferro.shop.util.isDiscounted
import uz.ferro.shop.util.noScrollBars
import uz.ferro.shop.util.orZero
import uz.ferro.shop.util.styleInMobile
import uz.ferro.shop.util.thumbnailSquareUrl
import uz.ferro.shop.util.valueWithUnit
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.JustifyContent
import web.cssom.Length
import web.cssom.LineStyle
import web.cssom.None
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.TextOverflow
import web.cssom.TextTransform
import web.cssom.WhiteSpace
import web.cssom.em
import web.cssom.fr
import web.cssom.integer
import web.cssom.number
import web.cssom.pct
import web.cssom.px

val ProductDetailsPage = VFC {
    val pathParam = useParams()["productId"] ?: return@VFC
    val productId = pathParam.toLongOrNull()
    val queryParams = useSearchParams()
    val accessKey: String? = queryParams.component1()["key"]
    val navigator = useContext(NavigationContext)
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()
    val locale = useContext(LocaleContext)

    MarketPage(
        collapseMainCatalogByDefault = true,
        centerBlock = {
            var breadCrumbProduct by useState<Product>()

            fun loadProduct() {
                productId ?: return
                MainScope().launch {
                    try {
                        val result = ProductManager.getProduct(productId, accessKey)
                        breadCrumbProduct = result
                    } catch (e: Exception) {
                        // no-op
                    }
                }
            }

            useEffectOnce {
                loadProduct()
            }

            val mTop = responsive(
                Breakpoint.xs to 0.px,
                Breakpoint.md to 20.px
            )

            val mLeft = responsive(
                Breakpoint.xs to 0.px,
                Breakpoint.md to 30.px
            )

            Box {
                sx {
                    marginLeft = mLeft
                    marginTop = mTop
                }

                Breadcrumbs {
                    sx {
                        fontSize = 13.px
                    }

                    separator = ChevronRightIcon16.create()
                    Link {
                        color = Colors.textSecondary
                        underline = LinkUnderline.hover
                        key = "1"
                        href = "/"
                        onClick = {

                        }
                        +locale.main
                    }

                    Link {
                        color = Colors.textSecondary
                        underline = LinkUnderline.hover
                        key = "2"
                        href = "/"
                        onClick = {

                        }
                        +locale.catalog
                    }

                    if (breadCrumbProduct != null) {
                        val parentCategory = breadCrumbProduct!!.let {
                            it.category ?: it.parentProduct?.category
                        }

                        if (parentCategory != null) {
                            if (parentCategory.parent != null) {
                                Link {
                                    sx {
                                        cursor = Cursor.pointer
                                    }
                                    color = Colors.textSecondary
                                    underline = LinkUnderline.hover
                                    key = "4"
                                    onClick = {
                                        navigator.openCategory(parentCategory.parent.id)
                                    }
                                    +parentCategory.parent.getLocalizedName()
                                }
                            }

                            Link {
                                sx {
                                    cursor = Cursor.pointer
                                }
                                color = Colors.textSecondary
                                underline = LinkUnderline.hover
                                key = "3"
                                onClick = {
                                    navigator.openCategory(parentCategory.id)
                                }
                                +parentCategory.getLocalizedName()
                            }
                        }

//                        Box {
//                            sx {
//                                color = Colors.textSecondary
//                            }
//                            +product?.getLocalizedName(language).orEmpty()
//                        }
                    }

                    if (isMobile) {
                        AppTextBoldLarge {
                            sx {
                                marginTop = 10.px
                            }
                            text = breadCrumbProduct?.getLocalizedName()
                        }
                    }
                }
            }
        },
        fullBlock = {
            var productInfo by useState<Product>()

            var children by useState<List<Product>>(emptyList())
            var filteredChildren by useState<List<Product>>(emptyList())

            var childrenFilters by useState<List<FilterComboData>>(emptyList())

            fun runChildrenFilter() {
                filteredChildren = children.filter { p ->
                    childrenFilters.isEmpty() || childrenFilters.all { filterVal ->
                        filterVal.selectedValue == null || p.findPropValue(filterVal.property) == filterVal.selectedValue
                    }
                }
            }

            fun clearFilters() {
                childrenFilters = childrenFilters.map {
                    it.copy(selectedValue = null)
                }
            }

            useEffect(childrenFilters) {
                runChildrenFilter()
            }

            fun renderFilters(data: List<Product>) {
                if (data.size < 5) {
                    childrenFilters = emptyList()
                    return
                }

                val filters = FilterUtils.getFilters(data)

                if (filters.isNotEmpty()) {
                    childrenFilters = filters
                }
            }

            fun getChildren(id: Long) {
                children = emptyList()
                MainScope().launch {
                    val result = ProductManager.getProductChildren(id, accessKey)
                    children = result
                    filteredChildren = result
                    renderFilters(result)
                }
            }

            fun loadProduct() {
                productId ?: return
                children = emptyList()
                MainScope().launch {
                    try {
                        val result = ProductManager.getProduct(productId, accessKey)
                        productInfo = result
                        if (result.isParent == true) {
                            getChildren(result.id!!)
                        }
                    } catch (e: Exception) {
                        // no-op
                    }
                }
            }

            useEffect(productId) {
                if (productInfo != null && productId != null && productInfo?.id == productId) {
                    return@useEffect
                }
                loadProduct()
            }

            var selectedPhoto by useState<ProductPhoto>()
            val product = productInfo

            if (selectedPhoto == null && product?.getFullPhotoList().orEmpty().isNotEmpty()) {
                selectedPhoto = product?.getFullPhotoList().orEmpty().firstOrNull()
            }

            var addToCartQuantity by useState(1)
            val marketCallback: MarketCallback = useContext(MarketContext)
            var totalPrice by useState(0)

            fun calculatePrice(qty: Int) {
                totalPrice = product?.localPrice.orZero() * qty
            }

            fun addToCart() {
                val qty: Int = addToCartQuantity
                if (qty == 0) {
                    return
                }
                marketCallback.addToCart(product ?: return, qty)
                addToCartQuantity = 1
                calculatePrice(1)
            }

            if (productId == null || product?.isDisabled == true) {
                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        justifyContent = JustifyContent.center
                        color = Colors.textPrimary
                        fontSize = 24.px
                    }

                    +locale.productNotFound

                    Box {
                        sx {
                            minHeight = 400.px
                        }
                    }
                }
            } else if (product?.id != null) {
                Stack {
                    direction = responsive(
                        Breakpoint.xs to StackDirection.column,
                        Breakpoint.md to StackDirection.row
                    )
                    spacing = responsive(24.px)
                    sx {
                        borderWidth = 1.px
                        borderColor = Colors.borderPrimary
                        borderStyle = LineStyle.solid
                        borderRadius = 5.px
                        padding = responsive(
                            Breakpoint.xs to 12.px,
                            Breakpoint.md to 24.px
                        )
                    }

                    Stack {
                        direction = responsive(StackDirection.column)
                        spacing = responsive(12.px)

                        Box {
                            sx {
                                position = Position.relative
                            }
                            ImageView {
                                sx {
                                    width = styleInMobile(100.pct, 450.px)
                                    aspectRatio = number(1.0)
                                }
                                borderType = BorderType.Radius5
                                url = selectedPhoto?.photo?.thumbnailSquareUrl(PHOTO_SIZE_LARGE)
                            }

                            Stack {
                                direction = responsive(StackDirection.column)
                                spacing = responsive(8.px)
                                sx {
                                    position = Position.absolute
                                    alignItems = AlignItems.end
                                    top = 12.px
                                    right = 12.px
                                    width = Length.fitContent
                                    maxWidth = 100.pct
                                }

                                if (product.externalId.orEmpty().isNotBlank() && product.amountNotEnough != null) {
                                    Box {
                                        sx {
                                            display = Display.flex
                                            alignItems = AlignItems.center
                                            width = Length.fitContent
                                            backgroundColor = if (product.isNotAvailable) {
                                                Colors.amountNotEnoughLabel
                                            } else {
                                                Colors.amountEnoughLabel
                                            }
                                            borderRadius = 2.px
                                            color = Colors.white
                                            fontSize = responsive(
                                                Breakpoint.xs to 8.px,
                                                Breakpoint.md to 10.px
                                            )
                                            fontWeight = integer(700)
                                            paddingLeft = 4.px
                                            paddingRight = 4.px
                                            height = 1.6.em
                                            textOverflow = TextOverflow.ellipsis
                                            whiteSpace = WhiteSpace.nowrap
                                            maxLines = integer(1)
                                            overflow = Overflow.hidden
                                            textTransform = TextTransform.uppercase
                                        }

                                        if (product.isNotAvailable) {
                                            +locale.itemNotAvailable
                                        } else {
                                            +locale.itemAvailable
                                        }
                                    }
                                }

                                if (product.isDiscounted) {
                                    Box {
                                        sx {
                                            height = 28.px
                                            borderRadius = 2.px
                                            backgroundColor = Colors.discountLabelColor
                                            color = Colors.white
                                            paddingLeft = 4.px
                                            paddingRight = 4.px
                                            display = Display.flex
                                            flexDirection = FlexDirection.column
                                            justifyContent = JustifyContent.center
                                            alignItems = AlignItems.center
                                            fontWeight = FontWeight.bold
                                            fontSize = 20.px
                                        }
                                        +"-${product.discountPercent}%"
                                    }
                                }
                            }
                        }


                        if (product.getFullPhotoList().size > 1) {
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(8.px)

                                sx {
                                    width = 100.pct
                                    maxWidth = 450.px
                                    height = 100.px
                                    marginTop = 10.px
                                    overflowX = Overflow.scroll
                                    overflowY = Overflow.hidden
                                }

                                noScrollBars()

                                product.getFullPhotoList().forEach { productPhoto ->
                                    val isSelected = productPhoto.id == selectedPhoto?.id
                                    ImageView {
                                        sx {
                                            height = 100.pct
                                            aspectRatio = number(1.0)
                                            borderRadius = 5.px
                                        }
                                        borderType = if (isSelected) {
                                            BorderType.AccentRadius5
                                        } else null
                                        url = productPhoto.photo?.thumbnailSquareUrl(PHOTO_SIZE_SMALL)

                                        onMouseEnter = {
                                            if (isSelected.not()) {
                                                selectedPhoto = productPhoto
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    Stack {
                        direction = responsive(StackDirection.column)

                        sx {
                            width = 100.pct
                        }

                        if (product.externalId.orEmpty().isNotBlank()) {
                            AppTextMedium {
                                color = Colors.accentColor
                                text = (locale.productCode + ": " + product.externalId.orEmpty())
                            }

                            Box {
                                sx {
                                    height = responsive(
                                        Breakpoint.xs to 8.px,
                                        Breakpoint.md to 20.px
                                    )
                                }
                            }
                        }

                        if (isMobile.not()) {
                            AppTextBoldExtraLarge {
                                text = product.getLocalizedName()
                            }
                        }

                        val country = product.getCountry()

                        if (isMobile) {
                            if (country != null) {
                                Stack {
                                    direction = responsive(StackDirection.row)
                                    spacing = responsive(8.px)
                                    sx {
                                        alignItems = AlignItems.center
                                    }
                                    AppTextMedium {
                                        text = (locale.vendorCountry + ":")
                                    }

                                    CountryFlagView {
                                        this.country = country
                                    }
                                }
                            }
                        } else {
                            Stack {
                                direction = responsive(StackDirection.row)
                                sx {
                                    marginTop = 12.px
                                    alignItems = AlignItems.center
                                }

                                AppTextMedium {
                                    text = (locale.category + ":")
                                }

                                val rootCategory = product.getRootCategory()
                                AppTextMedium {
                                    color = Colors.accentColor
                                    sx {
                                        marginLeft = 8.px
                                    }
                                    onClick = {
                                        navigator.openCategory(rootCategory?.id)
                                    }
                                    text = rootCategory?.getLocalizedName()
                                }

                                AppTextMedium {
                                    sx {
                                        marginLeft = 8.px
                                        marginRight = 8.px
                                    }
                                    text = "|"
                                }

                                AppTextMedium {
                                    text = (locale.subcategory + ":")
                                }

                                val category = product.getCategory()
                                AppTextMedium {
                                    color = Colors.accentColor
                                    sx {
                                        marginLeft = 8.px
                                    }
                                    onClick = {
                                        navigator.openCategory(category?.id)
                                    }
                                    text = category?.getLocalizedName()
                                }

                                if (country != null) {
                                    AppTextMedium {
                                        sx {
                                            marginLeft = 8.px
                                            marginRight = 8.px
                                        }
                                        text = "|"
                                    }

                                    AppTextMedium {
                                        text = (locale.vendorCountry + ":")
                                    }

                                    CountryFlagView {
                                        this.country = country
                                        this.marginLeft = 8.px
                                    }
                                }
                            }
                        }

                        if (product.totalAmount != null) {
                            val amountsColumns = if (isMobile) 1 else 3
                            Box {
                                sx {
                                    display = Display.grid
                                    marginTop = 16.px
                                    width = 100.pct
                                    gridTemplateColumns = web.cssom.repeat(amountsColumns, 1.fr)
                                    gap = 16.px
                                }

                                Stack {
                                    direction = responsive(StackDirection.row)
                                    sx {
                                        justifyContent = JustifyContent.spaceBetween
                                        alignItems = AlignItems.center
                                        paddingLeft = 16.px
                                        paddingRight = 16.px
                                        height = 48.px
                                        background = Colors.secondaryBackground
                                        flex = 50.pct
                                    }

                                    AppTextBoldSmall {
                                        text = locale.totalBalance + " :"
                                    }

                                    AppTextSemiBoldSmall {
                                        text = product.totalAmount.toString()
                                    }
                                }

                                product.amounts.orEmpty().forEach { whsAmount ->
                                    Stack {
                                        direction = responsive(StackDirection.row)
                                        sx {
                                            justifyContent = JustifyContent.spaceBetween
                                            alignItems = AlignItems.center
                                            paddingLeft = 16.px
                                            paddingRight = 16.px
                                            height = 48.px
                                            background = Colors.secondaryBackground
                                            flex = 50.pct
                                        }

                                        AppTextBoldSmall {
                                            text = whsAmount.warehouseId + " :"
                                        }

                                        AppTextSemiBoldSmall {
                                            text = whsAmount.amount.toString()
                                        }
                                    }
                                }
                            }
                        }

                        Stack {
                            direction = columnInMobile()
                            spacing = styleInMobile(16.px, 12.px)

                            sx {
                                alignItems = styleInMobile(AlignItems.start, AlignItems.center)
                                marginTop = styleInMobile(16.px, 24.px)
                            }

                            if (product.externalId.orEmpty().isNotBlank()) {
                                AppTextBold {
                                    sx {
                                        fontSize = styleInMobile(20.px, 25.px)
                                    }
                                    color = Colors.accentColor
                                    text = product.formatActualPriceWithUnit()
                                }

                                if (product.isAvailable) {
                                    QuantityItemView {
                                        initialValue = addToCartQuantity
                                        changeValue = {
                                            addToCartQuantity = it
                                            calculatePrice(it)
                                        }
                                    }

                                    Button {
                                        sx {
                                            height = 38.px
                                            textTransform = None.none
                                        }
                                        size = Size.medium
                                        variant = ButtonVariant.contained
                                        startIcon = CartIconWhite.create()
                                        +locale.toCart

                                        onClick = {
                                            addToCart()
                                        }
                                    }
                                }
                            }

                            if (isMobile) {
                                Box {
                                    sx {
                                        height = 8.px
                                    }
                                }
                            }

                            FavoriteButton {
                                this.isLabeled = true
                                this.productId = product.id
                            }
                        }

                        if (product.properties.orEmpty().isNotEmpty()) {
                            val propertyColumns = if (isMobile) 1 else 2
                            Box {
                                sx {
                                    display = Display.grid
                                    marginTop = 24.px
                                    width = 100.pct
                                    gridTemplateColumns = web.cssom.repeat(propertyColumns, 1.fr)
                                    gap = 16.px
                                }

                                product.properties.orEmpty().forEachIndexed { i, p ->
                                    Stack {
                                        direction = responsive(StackDirection.row)
                                        sx {
                                            justifyContent = JustifyContent.spaceBetween
                                            alignItems = AlignItems.center
                                            paddingLeft = 16.px
                                            paddingRight = 16.px
                                            height = 48.px
                                            background = Colors.secondaryBackground
                                            flex = 50.pct
                                        }

                                        AppTextBoldSmall {
                                            text = p.propertyValue?.getLocalizedName()
                                        }

                                        AppTextSemiBoldSmall {
                                            text = p.propertyValue?.valueWithUnit()
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                Box {
                    sx {
                        height = styleInMobile(12.px, 24.px)
                    }
                }

                if (childrenFilters.isNotEmpty()) {
                    Stack {
                        direction = columnInMobile()
                        spacing = styleInMobile(16.px, 24.px)

                        sx {
                            if (isMobile.not()) {
                                alignItems = AlignItems.end
                            }
                        }

                        childrenFilters.forEach { data ->
                            FilterComboView {
                                this.comboData = data
                                onValueChanged = { newValue ->
                                    childrenFilters = childrenFilters.map {
                                        if (it.property.key == data.property.key) {
                                            it.copy(selectedValue = newValue)
                                        } else {
                                            it
                                        }
                                    }
                                }
                            }
                        }

                        if (childrenFilters.any { it.selectedValue != null }) {
                            Button {
                                sx {
                                    height = 38.px
                                    fontSize = styleInMobile(13.px, 16.px)
                                    fontWeight = integer(700)
                                    textTransform = None.none
                                }
                                variant = ButtonVariant.outlined
                                onClick = {
                                    clearFilters()
                                }
                                +locale.resetFilter
                            }
                        }
                    }

                    Box {
                        sx {
                            height = 16.px
                        }
                    }
                }

                if (filteredChildren.isNotEmpty()) {
                    ChildProductsView {
                        products = filteredChildren
                    }
                }
            }
        }
    ).invoke()
}

private external interface FilterComboViewProps : Props {
    var comboData: FilterComboData
    var onValueChanged: (String?) -> Unit
}

private val FilterComboView = FC<FilterComboViewProps> { props ->
    val locale = useContext(LocaleContext)
    val filter = props.comboData
    val property = filter.property

    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(8.px)

        val unit = property.unit?.getLocalizedName().let {
            if (it.orEmpty().isNotBlank()) {
                " ($it)"
            } else {
                ""
            }
        }

        AppTextSemiBold {
            sx {
                fontSize = styleInMobile(13.px, 13.px)
            }
            text = property.getLocalizedName() + unit
        }

        select {
            css {
                minWidth = 200.px
                height = 38.px
                paddingLeft = 8.px
                paddingRight = 8.px
                borderRadius = 5.px
                borderWidth = 1.px
                borderStyle = LineStyle.solid
                borderColor = Colors.borderPrimary
            }

            option {
                value = "--"
                +locale.all
            }

            filter.values.forEach {
                option {
                    value = it
                    +it
                }
            }

            value = if (filter.selectedValue == null) "--" else filter.selectedValue

            onChange = {
                var selectedValue: String? = it.target.value
                if (selectedValue == "--") {
                    selectedValue = null
                }

                props.onValueChanged(selectedValue)
            }
        }
    }
}