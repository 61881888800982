package uz.ferro.shop.pages.admin.user

import js.core.jso
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.icons.material.AccountCircle
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControl
import mui.material.FormControlVariant
import mui.material.InputAdornment
import mui.material.InputAdornmentPosition
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.ReactNode
import react.VFC
import react.create
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.router.dom.useSearchParams
import react.router.useParams
import react.useContext
import react.useEffect
import react.useEffectOnce
import react.useState
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.PARAM_HISTORY
import uz.ferro.shop.VALUE_NO_BACK
import uz.ferro.shop.manager.AuthManager.isAdmin
import uz.ferro.shop.manager.AuthManager.isSalesManager
import uz.ferro.shop.manager.AuthManager.isSuperAdmin
import uz.ferro.shop.manager.CustomerManager
import uz.ferro.shop.manager.UserManager
import uz.ferro.shop.manager.cleanPhone
import uz.ferro.shop.model.Customer
import uz.ferro.shop.model.Role
import uz.ferro.shop.model.User
import uz.ferro.shop.navigator.NavigationPage
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.pages.admin.customer.AdminCustomerDetailsDialog
import uz.ferro.shop.pages.admin.customer.CustomersPickerDialog
import uz.ferro.shop.util.InputProps
import uz.ferro.shop.util.formatUzbPhone
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType

val AdminEditUser = VFC {
    val pathParam = useParams()["userId"] ?: return@VFC
    val historyParam = useSearchParams().component1()[PARAM_HISTORY]

    val userId = pathParam.toIntOrNull()
    var user by useState(
        User(
            role = Role.B2B_USER
        )
    )
    var passwordInput by useState("")
    var passwordConfirmInput by useState("")
    var showCustomerPicker by useState(false)
    var showAddCustomerDialog by useState(false)
    var phones by useState(listOf<String>())

    val navigator = useContext(NavigationContext)
    val alertManager = useContext(AlertManagerContext)
    var isSaveEnabled by useState(false)

    fun Customer.phonesFormatted(): List<String> {
        return listOf(phone1, phone2)
            .map { cleanPhone(it) }
            .filter { it.isNotBlank() }
    }

    fun goBack() {
        if (historyParam == VALUE_NO_BACK) {
            navigator.openPage(NavigationPage.ADMIN_USERS)
        } else {
            navigator.goBack()
        }
    }

    fun setCustomer(customer: Customer) {
        showAddCustomerDialog = false
        showCustomerPicker = false
        val previousExtId = user.externalId


        if (previousExtId != customer.id) {
            val customerPhones = customer.phonesFormatted()
            var phone: String? = null
            if (customerPhones.size == 1) {
                phone = customerPhones.firstOrNull()
            }

            user = user.copy(
                externalId = customer.id,
                phone = phone
            )
            phones = customerPhones
        }
    }

    fun isValidData(): Boolean {
        val usr: User = user
        val password: String = passwordInput
        val passwordConfirm: String = passwordConfirmInput

        return (usr.externalId.isNullOrBlank().not() || usr.role != Role.B2B_USER) &&
                (usr.phone.isNullOrBlank().not() || usr.role != Role.B2B_USER) &&
                usr.name.isNullOrBlank().not() &&
                usr.username.isNullOrBlank().not() &&
                (password.isNotBlank() || usr.id != null) &&
                (password == passwordConfirm)
    }

    useEffectOnce {
        MainScope().launch {
            if (userId != null) {
                val userData = UserManager.getUser(userId)
                if (userData.role == Role.B2B_USER && userData.externalId.isNullOrBlank().not()) {
                    try {
                        val customer = CustomerManager.getCustomerById(userData.externalId.orEmpty())
                        phones = customer.phonesFormatted()
                    } catch (e: Exception) {
                        // no-op
                    }
                }
                user = userData
            }
        }
    }

    useEffect(user, passwordInput, passwordConfirmInput) {
        isSaveEnabled = isValidData()
    }

    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(16.px)
        sx {
            padding = 16.px
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(32.px)

            Stack {
                direction = responsive(StackDirection.column)

                sx {
                    alignItems = AlignItems.stretch
                    width = 500.px
                }

                val roles = when {
                    isSuperAdmin() -> listOf(Role.ADMIN)
                    isAdmin() -> listOf(
                        Role.SALES_AGENT,
                        Role.B2B_USER,
                        Role.SALES_MANAGER,
                    )
                    isSalesManager() -> listOf(Role.B2B_USER)
                    else -> emptyList()
                }

                FormControl {
                    sx {
                        marginTop = 16.px
                    }
                    fullWidth = true
                    val title = "Rol"
                    InputLabel {
                        +title
                    }
                    Select {
                        value = (user.role ?: Role.B2B_USER).name
                        label = ReactNode(title)
                        onChange = { evt, _ ->
                            val id = evt.target.value
                            val pickedRole = roles.firstOrNull { it.name == id }
                            if (pickedRole != null) {
                                user = user.copy(role = pickedRole)
                            }
                        }

                        roles.forEach {
                            MenuItem {
                                value = it.name
                                +it.name
                            }
                        }
                    }
                }

                if (user.role == Role.B2B_USER) {
                    TextField {
                        sx {
                            marginTop = 16.px
                        }
                        label = ReactNode("SAP mijoz")
                        variant = FormControlVariant.outlined

                        value = if (user.externalId.isNullOrBlank()) {
                            "Tanglang"
                        } else {
                            user.externalId
                        }

                        InputProps = jso {
                            endAdornment = InputAdornment.create {
                                position = InputAdornmentPosition.end
                                sx {
                                    cursor = Cursor.pointer
                                }

                                onClick = {
                                    showCustomerPicker = true
                                }

                                onMouseDown = {
                                    it.preventDefault()
                                }
                                +AccountCircle.create()
                            }
                        }
                    }

                    FormControl {
                        sx {
                            marginTop = 16.px
                        }
                        fullWidth = true
                        val title = "Telefon"
                        InputLabel {
                            +title
                        }
                        Select {
                            value = user.phone ?: "Tanlang"
                            label = ReactNode(title)
                            onChange = { evt, _ ->
                                val id = evt.target.value
                                val pickedPhone = phones.firstOrNull { it == id }
                                if (pickedPhone != null) {
                                    user = user.copy(phone = pickedPhone)
                                }
                            }

                            if (phones.size > 1) {
                                MenuItem {
                                    value = "Tanlang"
                                    +"Tanlang"
                                }
                            }

                            phones.forEach {
                                MenuItem {
                                    value = it
                                    +formatUzbPhone(it)
                                }
                            }
                        }
                    }
                }

                TextField {
                    sx {
                        marginTop = 16.px
                    }
                    label = ReactNode("Ism/Sharifi")
                    variant = FormControlVariant.outlined
                    value = user.name.orEmpty()
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        user = user.copy(name = event.target.value)
                    }
                }

                TextField {
                    sx {
                        marginTop = 16.px
                    }
                    label = ReactNode("login")
                    variant = FormControlVariant.outlined
                    value = user.username.orEmpty()
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        user = user.copy(username = event.target.value.trim())
                    }
                }

                TextField {
                    sx {
                        marginTop = 16.px
                    }
                    label = ReactNode("Parol")
                    variant = FormControlVariant.outlined
                    type = InputType.password
                    value = passwordInput
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        passwordInput = event.target.value.trim()
                    }
                }

                TextField {
                    sx {
                        marginTop = 16.px
                    }
                    label = ReactNode("Parol (tasdiqlash)")
                    type = InputType.password
                    variant = FormControlVariant.outlined
                    value = passwordConfirmInput
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        passwordConfirmInput = event.target.value.trim()
                    }
                }


                val statusLabel = "Holati"
                val currentStatus = if (user.disabled == true) {
                    "disabled"
                } else {
                    "enabled"
                }

                FormControl {
                    sx {
                        marginTop = 16.px
                    }
                    fullWidth = true
                    InputLabel {
                        +statusLabel
                    }
                    Select {
                        value = currentStatus
                        label = ReactNode(statusLabel)
                        onChange = { evt, _ ->
                            val status = evt.target.value
                            user = user.copy(disabled = status == "disabled")
                        }

                        MenuItem {
                            value = "enabled"
                            +"Aktiv"
                        }

                        MenuItem {
                            value = "disabled"
                            +"Noaktiv"
                        }
                    }
                }

            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.end
            }

            Button {
                variant = ButtonVariant.contained
                onClick = {
                    goBack()
                }
                +"Bekor"
            }

            Button {
                sx {
                    marginLeft = 16.px
                }
                disabled = isSaveEnabled.not()
                variant = ButtonVariant.contained
                onClick = {
                    val isValidPassword = if (user.id == null) {
                        passwordInput.isNotBlank() && passwordInput == passwordConfirmInput
                    } else {
                        if (passwordInput.isNotBlank()) {
                            passwordInput == passwordConfirmInput
                        } else {
                            true
                        }
                    }

                    val usr = if (passwordInput.isNotBlank()) {
                        user.copy(password = passwordInput)
                    } else {
                        user
                    }

                    if (isValidPassword && isValidData()) {
                        MainScope().launch {
                            try {
                                UserManager.saveUser(usr)
                                goBack()
                            } catch (e: Exception) {
                                alertManager.handleError(e)
                            }
                        }
                    }
                }
                +"Saqlash"
            }
        }
    }

    if (showAddCustomerDialog) {
        AdminCustomerDetailsDialog {
            filledName = null
            filledPhone = null
            onClose = {
                showAddCustomerDialog = false
            }
            onSaveSuccess = ::setCustomer
        }
    }

    if (showCustomerPicker) {
        CustomersPickerDialog {
            onPicked = ::setCustomer
            onClose = {
                showCustomerPicker = false
            }
            onAddNewClick = {
                showCustomerPicker = false
                showAddCustomerDialog = true
            }
        }
    }
}
