package uz.ferro.shop.search

import uz.ferro.shop.FERRO_BRAND_ID
import uz.ferro.shop.model.Product

abstract class SearchMatcher(private val ferroInTop: Boolean) {

    fun filter(list: List<Product>): List<Product> {
        return if (ferroInTop) {
            list.filter {
                it.brand?.id == FERRO_BRAND_ID ||
                        it.namesLowerCase().any { name ->
                            name.contains("ferro")
                        }
            }
        } else {
            list
        }
    }

    abstract fun search(query: String, list: List<Product>): List<Product>
}