package uz.ferro.shop.search

import uz.ferro.shop.model.Product

class SearchStartWordMatcher(ferroInTop: Boolean = false) : SearchMatcher(ferroInTop) {

    override fun search(query: String, list: List<Product>): List<Product> {
        return filter(list).filter { p ->
            p.namesLowerCase().any { it.startWordsMatching(query) }
        }
    }
}