package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import uz.ferro.shop.util.localizedOrDefault

@Serializable
data class SalePoint(
    val id: Long? = null,
    val name: DictItem? = null,
    val address: DictItem? = null,
    val phone: String = "",
    val phone2: String = "",
    val mapLink: String? = null,
    val latitude: Double? = null,
    val longitude: Double? = null,
    val sort: Int = 0,
    val isDisabled: Boolean = false
) {

    fun getLocalizedName(): String = name.localizedOrDefault()

    fun getLocalizedAddress(): String = address.localizedOrDefault()
}
