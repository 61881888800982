package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class CustomerAddress(
    val addressName: String,
    val buildingFloorRoom: String?,
    val addressType: String?,
    val county: String?,

    val type: String?,
    val address2: String?,
    val address3: String?,
    val street: String?,
    val streetNo: String?,
    val block: String?,
    val city: String?,
    val latitude: Double?,
    val longitude: Double?
)