package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class AuthResult(
    val token: String? = null,
    val user: User? = null,
    val smsConfirmRequired: Boolean = false,
    val phone: String = ""
)
