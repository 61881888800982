package uz.ferro.shop.pages.category

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Breadcrumbs
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Grid
import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.router.useParams
import react.useContext
import react.useEffect
import react.useEffectOnce
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.ChevronRightIcon16
import uz.ferro.shop.manager.PageContentManager
import uz.ferro.shop.manager.ProductManager
import uz.ferro.shop.model.Category
import uz.ferro.shop.model.PageContent
import uz.ferro.shop.model.Product
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.pages.product.ProductItemView
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.util.md
import uz.ferro.shop.util.sm
import uz.ferro.shop.util.styleInMobile
import uz.ferro.shop.util.xs
import web.cssom.Cursor
import web.cssom.JustifyContent
import web.cssom.None
import web.cssom.integer
import web.cssom.px

data class SubcategoryData(
    val index: Int,
    val category: Category,
    val products: List<Product>
)

external interface SubcategoryProductsProps : Props {
    var category: Category
}

val SubcategoryProductsView = FC<SubcategoryProductsProps> { props ->
    val category = props.category
    var products by useState(listOf<Product>())
    val navigator = useContext(NavigationContext)

    fun loadData() {
        MainScope().launch {
            try {
                products = ProductManager.getProductsByCategory(category.id!!)
            } catch (e: Exception) {
                // no-op
            }
        }
    }

    useEffectOnce {
        loadData()
    }

    Box {
        sx {
            marginBottom = 48.px
        }

        AppTextBold {
            sx {
                fontSize = styleInMobile(16.px, 20.px)
            }
            onClick = {
                navigator.openCategory(category.id)
            }
            text = category.getLocalizedName()
        }

        Box {
            sx {
                marginTop = 12.px
            }
        }

        Grid {
            container = true
            spacing = responsive(12.px)

            products.forEach { product ->
                Grid {
                    item = true
                    xs = 6
                    sm = 6
                    md = 4

                    sx {

                    }
                    ProductItemView {
                        this.product = product
                    }
                }
            }
        }
    }
}

val CategoryContentPage = FC<Props> {

    MarketPage(
        collapseMainCatalogByDefault = false,
        centerBlock = {
            val pathParam = useParams()["categoryId"] ?: return@MarketPage

            var pageContent by useState<PageContent>()
            var products by useState(listOf<Product>())
            var subCategories by useState(listOf<Category>())
            val categoryId = pathParam.toIntOrNull() ?: return@MarketPage
            val locale = useContext(LocaleContext)
            val navigator = useContext(NavigationContext)
            var parentCategory: Category? by useState()
            var shownSubcategoryIndex by useState(0)

            fun loadSubcategories() {
                categoryId
                MainScope().launch {
                    val result = CategoryManager.getSubCategories(categoryId)
                    subCategories = result
                    shownSubcategoryIndex = 1.coerceAtMost(result.lastIndex)
                }
            }

            fun loadProducts() {
                MainScope().launch {
                    try {
                        products = ProductManager.getProductsByCategory(categoryId)
                    } catch (e: Exception) {
                        // no-op
                    }
                }
            }

            useEffect(categoryId) {
                subCategories = emptyList()
                products = emptyList()

                MainScope().launch {
                    val pg = PageContentManager.getCategoryPageContent(categoryId)
                    parentCategory = CategoryManager.getParentCategory(categoryId)
                    pageContent = pg
                    if (pg.showSubcategories == true) {
                        loadSubcategories()
                    }

                    if (pg.showProducts == true) {
                        loadProducts()
                    }
                }
            }

            Box {
                Breadcrumbs {
                    sx {
                        fontSize = 13.px
                    }

                    separator = ChevronRightIcon16.create()
                    Link {
                        color = Colors.textSecondary
                        underline = LinkUnderline.hover
                        key = "1"
                        href = "/"
                        onClick = {

                        }
                        +locale.main
                    }

                    Link {
                        color = Colors.textSecondary
                        underline = LinkUnderline.hover
                        key = "2"
                        href = "/"
                        onClick = {

                        }
                        +locale.catalog
                    }

                    if (parentCategory != null) {
                        Link {
                            sx {
                                cursor = Cursor.pointer
                            }
                            color = Colors.textSecondary
                            underline = LinkUnderline.hover
                            key = "3"
                            onClick = {
                                navigator.openCategory(parentCategory?.id)
                            }
                            +parentCategory?.getLocalizedName()
                        }
                    }

                    Box {
                        sx {
                            color = Colors.textSecondary
                        }
                        +pageContent?.category?.getLocalizedName()
                    }
                }

                Box {
                    sx {
                        marginTop = 10.px
                        color = Colors.textPrimary
                        fontSize = styleInMobile(25.px, 31.px)
                        fontWeight = integer(700)
                    }
                    +pageContent?.category?.getLocalizedName()
                }

                Box {
                    sx {
                        height = 16.px
                    }
                }

                if (subCategories.isNotEmpty()) {
                    (0..shownSubcategoryIndex).forEach { index ->
                        val subCat = subCategories.getOrNull(index)
                        if (subCat != null) {
                            SubcategoryProductsView {
                                category = subCat
                            }
                        }
                    }

                    if (shownSubcategoryIndex < subCategories.lastIndex) {
                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.center
                            }
                            Button {
                                variant = ButtonVariant.outlined
                                sx {
                                    textTransform = None.none
                                }

                                onClick = {
                                    shownSubcategoryIndex += 3
                                }
                                +locale.getMore
                            }
                        }
                    }
                }

                if (products.isNotEmpty()) {
                    Grid {
                        sx {
                        }
                        container = true
                        spacing = responsive(12.px)

                        products.forEach { product ->
                            Grid {
                                item = true
                                xs = 6
                                sm = 6
                                md = 4

                                sx {

                                }
                                ProductItemView {
                                    this.product = product
                                }
                            }
                        }
                    }
                }
            }
        }
    ).invoke()
}