package uz.ferro.shop.ui.banner

import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import uz.ferro.shop.model.ComponentBanner
import web.cssom.AlignItems
import web.cssom.Overflow
import web.cssom.number
import web.cssom.pct
import web.cssom.px

external interface BannerGroupProps : Props {
    var banners: List<ComponentBanner>
    var fitFirst: Boolean
    var isEditMode: Boolean
}

val BannerGroup = FC<BannerGroupProps> { props ->
    val banners = props.banners
    var firstRow: List<ComponentBanner> = emptyList()
    var secondRow: List<ComponentBanner> = emptyList()

    when {
        banners.size == 1 -> firstRow = banners.subList(0, 1)

        banners.size == 2 -> {
            firstRow = banners.subList(0, 1)
            secondRow = banners.subList(1, 2)
        }

        banners.size == 3 -> {
            if (props.fitFirst) {
                firstRow = banners.subList(0, 1)
                secondRow = banners.subList(1, banners.size)
            } else {
                firstRow = banners.subList(0, 2)
                secondRow = banners.subList(2, banners.size)
            }
        }

        banners.size > 3 -> {
            firstRow = banners.subList(0, 2)
            secondRow = banners.subList(2, banners.size.coerceAtMost(4))
        }
    }

    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(
            Breakpoint.xs to 8.px,
            Breakpoint.sm to 8.px,
            Breakpoint.md to 12.px,
            Breakpoint.lg to 16.px
        )

        sx {
            overflow = Overflow.hidden
            width = 100.pct
            height = 100.pct
            alignItems = AlignItems.stretch
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(
                Breakpoint.xs to 8.px,
                Breakpoint.sm to 8.px,
                Breakpoint.md to 12.px,
                Breakpoint.lg to 16.px
            )

            sx {
                flexGrow = number(1.0)
                alignItems = AlignItems.stretch
                flexShrink = number(1.0)
                minHeight = 0.px
            }

            firstRow.forEach { bannerItem ->
                BannerItem {
                    this.componentBanner = bannerItem
                    this.isEditMode = props.isEditMode
                }
            }
        }

        if (secondRow.isNotEmpty()) {
            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(
                    Breakpoint.xs to 8.px,
                    Breakpoint.sm to 8.px,
                    Breakpoint.md to 12.px,
                    Breakpoint.lg to 16.px
                )

                sx {
                    flexGrow = number(1.0)
                    flexShrink = number(1.0)
                    alignItems = AlignItems.stretch
                    minHeight = 0.px
                }

                secondRow.forEach { bannerItem ->
                    BannerItem {
                        this.componentBanner = bannerItem
                        this.isEditMode = props.isEditMode
                    }
                }
            }
        }
    }
}
