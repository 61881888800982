package uz.ferro.shop.pages.admin.productgroup

import mui.icons.material.Add
import mui.material.*
import mui.system.sx
import react.FC
import react.Props
import uz.ferro.shop.Colors
import uz.ferro.shop.api.useAllProductGroups
import uz.ferro.shop.navigateToLink
import uz.ferro.shop.util.localized
import web.cssom.*

val AdminProductGroupList = FC<Props> {

    val productGroups = useAllProductGroups()

    Box {
        sx {
            padding = 16.px
            width = 100.pct
            height = 100.pct
        }

        Box {
            sx {
                display = Display.flex
                flexDirection = FlexDirection.row
            }
            Box {
                sx {
                    color = Colors.title
                    fontSize = 16.pt
                }
                +"Maxsulotlar Guruhlari"
            }

            Box {
                sx {
                    flexGrow = number(1.0)
                }
            }

            Fab {
                color = FabColor.primary
                Add()
                onClick = {
                    navigateToLink("/admin/product-group/new")
                }
            }
        }

        List {
            sx {
                marginTop = 16.px
            }
            productGroups.forEach { pg ->
                ListItemButton {
                    ListItemText {
                        +pg.name.localized()
                    }

                    onClick = {
                        navigateToLink("/admin/product-group/${pg.id}")
                    }
                }
            }
        }
    }
}
