package uz.ferro.shop.util

import uz.ferro.shop.model.OrderProduct
import uz.ferro.shop.model.Product
import kotlin.text.Typography.nbsp

fun Int?.formatGrouped(): String {
    this ?: return ""
    val inp = this.toString()
    if (this < 10_000) return inp

    var i = inp.length
    var out = ""
    while (i > 0) {
        if (out.isNotBlank()) {
            out = nbsp + out
        }
        out = inp.substring((i - 3).coerceAtLeast(0), i) + out
        i -= 3
    }
    return out
}

fun Double?.formatPrice(): String {
    this ?: return ""
    val out = this.toString()
    val decimalPointIndex = out.indexOfLast { it == '.' || it == ',' }
    var decimalPart = ""
    var intPart = out

    // TODO use more right solution for fix precision issues
    if (decimalPointIndex != -1) {
        decimalPart = out.substring(decimalPointIndex + 1)
        intPart = out.substring(0, decimalPointIndex)
    }

    if (decimalPart.isNotBlank()) {
        val seqZeroIndex = decimalPart.indexOf("00000")
        val seqNineIndex = decimalPart.indexOf("99999")
        if (seqZeroIndex != -1) {
            decimalPart = decimalPart.substring(0, seqZeroIndex)
        } else if (seqNineIndex != -1) {
            decimalPart = decimalPart.substring(0, seqNineIndex)
            val decimalPartInt = (decimalPart.toIntOrZero() + 1).toString()
            if (decimalPartInt.length > decimalPart.length) {
                decimalPart = ""
                intPart = (intPart.toIntOrZero() + 1).toString()
            } else {
                decimalPart = decimalPartInt
            }
        }
    }

    val formatted = if (decimalPart.isNotBlank()) {
        "$$intPart.$decimalPart"
    } else {
        "\$$intPart"
    }

    return if (formatted == "$0" || formatted == "$0.0" && formatted != "$0,0") {
        ""
    } else {
        formatted
    }
}

fun Product.formatOriginPrice(): String = price.formatPrice()

fun Product.formatActualPrice(): String = getActualPrice().formatPrice()

fun Product.formatActualPriceWithUnit(): String {
    val unitStr = unit?.getLocalizedName().orEmpty()
    val priceFormatted = formatActualPrice()
    if (priceFormatted.isNotBlank()) {
        return if (unitStr.isNotBlank()) {
            "$priceFormatted/$unitStr"
        } else {
            priceFormatted
        }
    }

    return ""
}

fun Product.formatPriceWithQuantity(quantity: Int): String {
    val priceAmount = getActualPrice() * quantity
    return priceAmount.formatPrice()
}

fun OrderProduct.formatPrice(): String = getActualPrice().formatPrice()

fun OrderProduct.formatPriceWithQuantity(quantity: Int): String {
    val priceAmount = getActualPrice() * quantity
    return priceAmount.formatPrice()
}

fun Any?.withSuffix(suffix: String?): String {
    this ?: return ""
    if (this.toString().isBlank()) return ""
    if (suffix.orEmpty().isBlank()) return this.toString()
    return "$this$nbsp$suffix"
}