package uz.ferro.shop.pages

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.Paper
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.router.dom.useSearchParams
import react.useContext
import react.useState
import tanstack.react.query.QueryClientProvider
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.api.jsonSerializer
import uz.ferro.shop.appQueryClient
import uz.ferro.shop.error.HttpException
import uz.ferro.shop.manager.AuthManager
import uz.ferro.shop.model.Role
import uz.ferro.shop.navigateToLink
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.ui.AppText
import uz.ferro.shop.util.styleInMobile
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontStyle
import web.cssom.JustifyContent
import web.cssom.Position
import web.cssom.integer
import web.cssom.pct
import web.cssom.pt
import web.cssom.px
import web.html.HTMLInputElement
import web.html.InputType

val LoginPage = FC<Props> {

    var loginInput by useState("")
    var passwordInput by useState("")
    var smsCodeInput by useState("")
    var smsConfirmMode by useState(false)
    var smsPhone by useState("")
    val alertManager = useContext(AlertManagerContext)
    val locale = useContext(LocaleContext)
    val queryParams = useSearchParams()
    val redirect = queryParams.component1()["redirect"]
    var errorMessage by useState("")

    fun handleError(e: Exception) {
        if (e is HttpException) {
            try {
                val el = jsonSerializer.parseToJsonElement(e.errorResponse)
                val message = el.jsonObject["errorMessage"]!!.jsonPrimitive.content
                if (message.isNotBlank()) {
                    errorMessage = message
                }
            } catch (e: Exception) {
                // no-op
            }
        }
    }

    fun login() {
        MainScope().launch {
            try {
                val result = AuthManager.authenticate(
                    login = loginInput,
                    password = passwordInput
                )

                if (result.smsConfirmRequired) {
                    smsConfirmMode = true
                    smsPhone = result.phone
                    errorMessage = ""
                    return@launch
                }

                if (result.user != null) {
                    when (result.user.role) {
                        Role.ADMIN,
                        Role.SALES_MANAGER -> {
                            if (redirect != null && redirect.contains("login").not()) {
                                navigateToLink(redirect)
                            } else {
                                navigateToLink("/")
                            }
                        }

                        Role.SUPER_ADMIN -> navigateToLink("/admin")
                        else -> navigateToLink("/")
                    }
                }

                errorMessage = ""
            } catch (e: Exception) {
                handleError(e)
            }
        }
    }

    fun smsConfirm() {
        MainScope().launch {
            try {
                val result = AuthManager.smsConfirm(
                    login = loginInput,
                    password = passwordInput,
                    code = smsCodeInput
                )

                if (result.user != null) {
                    when (result.user.role) {
                        Role.ADMIN,
                        Role.SALES_MANAGER -> {
                            if (redirect != null && redirect.contains("login").not()) {
                                navigateToLink(redirect)
                            } else {
                                navigateToLink("/")
                            }
                        }

                        Role.SUPER_ADMIN -> navigateToLink("/admin")
                        else -> navigateToLink("/")
                    }
                }
                errorMessage = ""
            } catch (e: Exception) {
                handleError(e)
            }
        }
    }

    QueryClientProvider {
        client = appQueryClient

        Box {
            sx {
                position = Position.absolute
                top = 0.px
                right = 0.px
                bottom = 0.px
                left = 0.px
                backgroundColor = Colors.loginBgColor
                display = Display.flex
                flexDirection = FlexDirection.column
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                padding = 16.px
            }

            Paper {
                sx {
                    width = styleInMobile(100.pct, 500.px)
                    display = Display.flex
                    flexDirection = FlexDirection.column
                    alignItems = AlignItems.stretch
                    padding = styleInMobile(16.px, 24.px)
                }

                Box {
                    sx {
                        fontSize = 16.pt
                        color = Colors.title
                        fontWeight = integer(500)
                    }
                    +locale.enterToSystem
                }

                TextField {
                    sx {
                        marginTop = 24.px
                    }

                    label = ReactNode(locale.login)
                    autoComplete = "off"
                    variant = FormControlVariant.outlined
                    value = loginInput
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        loginInput = event.target.value.trim()
                    }
                    disabled = smsConfirmMode
                }

                TextField {
                    sx {
                        marginTop = 16.px
                    }
                    label = ReactNode(locale.password)
                    variant = FormControlVariant.outlined
                    value = passwordInput
                    type = InputType.password
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        passwordInput = event.target.value.trim()
                    }
                    disabled = smsConfirmMode
                    onKeyDown = { event ->
                        if (event.key == "Enter") {
                            if (loginInput.isNotBlank() && passwordInput.isNotBlank()) {
                                login()
                            }
                            event.preventDefault();
                        }
                    }
                }

                if (smsConfirmMode) {
                    AppText {
                        sx {
                            fontStyle = FontStyle.italic
                            marginTop = 16.px
                            color
                        }
                        color = Colors.textSecondary

                        text = locale.smsCodeSentToPhoneTemplate.replace("{{phone}}", smsPhone)
                    }
                    TextField {
                        sx {
                            marginTop = 8.px
                        }
                        label = ReactNode(locale.smsCode)
                        variant = FormControlVariant.outlined
                        value = smsCodeInput
                        onChange = { event ->
                            event as ChangeEvent<HTMLInputElement>
                            smsCodeInput = event.target.value.trim()
                        }
                        onKeyDown = { event ->
                            if (event.key == "Enter") {
                                if (smsCodeInput.isNotBlank()) {
                                    smsConfirm()
                                }
                                event.preventDefault();
                            }
                        }
                    }

                }

                Box {
                    sx {
                        marginTop = 16.px
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        justifyContent = JustifyContent.right
                    }

                    if (smsConfirmMode.not()) {
                        Button {
                            sx {

                            }
                            variant = ButtonVariant.contained
                            disabled = loginInput.isBlank() || passwordInput.isBlank()
                            onClick = {
                                login()
                            }

                            +locale.signIn
                        }
                    } else {
                        Button {
                            sx {

                            }
                            variant = ButtonVariant.contained
                            disabled = smsCodeInput.isBlank()
                            onClick = {
                                smsConfirm()
                            }
                            +locale.smsConfirm
                        }
                    }
                }

                if (errorMessage.isNotBlank()) {
                    AppText {
                        sx {
                            marginTop = 16.px
                        }
                        color = Colors.errorText
                        text = errorMessage
                    }
                }
            }
        }
    }
}