package uz.ferro.shop.manager

import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.model.PageContent

object PageContentManager {
    private const val API_PATH = "page-content"

    private var generalContent: PageContent? = null

    suspend fun getHomePageContent(): PageContent {
        return suspendGet("$API_PATH/home")
    }

    suspend fun getCategoryPageContent(categoryId: Int): PageContent {
        return suspendGet("$API_PATH/category/$categoryId")
    }

    suspend fun getGeneralContent(): PageContent {
        return generalContent ?: suspendGet<PageContent>("$API_PATH/general").also {
            generalContent = it
        }
    }

    fun clearCache() {
        generalContent = null
    }
}