package uz.ferro.shop.pages.admin.page

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.router.dom.useSearchParams
import react.router.useParams
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.PARAM_HISTORY
import uz.ferro.shop.VALUE_NO_BACK
import uz.ferro.shop.model.Banner
import uz.ferro.shop.model.Component
import uz.ferro.shop.model.ComponentBanner
import uz.ferro.shop.navigator.NavigationPage
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.pages.admin.banner.ComponentBannerManager
import uz.ferro.shop.pages.admin.file.PhotoAttacher
import uz.ferro.shop.ui.AppTextMedium
import uz.ferro.shop.util.orZero
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLInputElement

val AdminComponentBanner = FC<Props> {
    val componentPathParam = useParams()["componentId"] ?: return@FC
    val pathParam = useParams()["componentBannerId"] ?: return@FC
    val historyParam = useSearchParams().component1()[PARAM_HISTORY]

    val componentId = componentPathParam.toIntOrNull() ?: return@FC
    val componentBannerId = pathParam.toIntOrNull()

    val navigator = useContext(NavigationContext)
    val alertManager = useContext(AlertManagerContext)

    var componentBanner by useState(
        ComponentBanner(
            component = Component(id = componentId),
            banner = Banner()
        )
    )

    useEffectOnce {
        if (componentBannerId != null) {
            MainScope().launch {
                try {
                    componentBanner = ComponentBannerManager.getComponentBanner(componentBannerId)
                } catch (e: Exception) {
                    alertManager.handleError(e)
                }
            }
        }
    }

    fun goBack() {
        if (historyParam == VALUE_NO_BACK) {
            navigator.openPage(NavigationPage.ADMIN_BANNERS)
        } else {
            navigator.goBack()
        }
    }

    Box {
        sx {
            padding = 16.px
            width = 400.px
        }

        Box {
            sx {
                paddingTop = 16.px
            }
            AppTextMedium {
                text = "Rasm (uzb)"
            }
            Box {
                sx {
                    marginTop = 8.px
                }
                PhotoAttacher {
                    dataFile = componentBanner.banner.photo
                    onFileUpload = { dFile ->
                        componentBanner = componentBanner.copy(
                            banner = componentBanner.banner.copy(
                                photo = dFile
                            )
                        )
                    }
                    onDelete = {
                        componentBanner = componentBanner.copy(
                            banner = componentBanner.banner.copy(
                                photo = null
                            )
                        )
                    }
                }
            }

            AppTextMedium {
                sx {
                    marginTop = 16.px
                }
                text = "Rasm (uzb kirill)"
            }
            Box {
                sx {
                    marginTop = 8.px
                }
                PhotoAttacher {
                    dataFile = componentBanner.banner.photoCyrillic
                    onFileUpload = { dFile ->
                        componentBanner = componentBanner.copy(
                            banner = componentBanner.banner.copy(
                                photoCyrillic = dFile
                            )
                        )
                    }
                    onDelete = {
                        componentBanner = componentBanner.copy(
                            banner = componentBanner.banner.copy(
                                photoCyrillic = null
                            )
                        )
                    }
                }
            }

            AppTextMedium {
                sx {
                    marginTop = 16.px
                }
                text = "Rasm (rus)"
            }
            Box {
                sx {
                    marginTop = 8.px
                }
                PhotoAttacher {
                    dataFile = componentBanner.banner.photoTranslation
                    onFileUpload = { dFile ->
                        componentBanner = componentBanner.copy(
                            banner = componentBanner.banner.copy(
                                photoTranslation = dFile
                            )
                        )
                    }
                    onDelete = {
                        componentBanner = componentBanner.copy(
                            banner = componentBanner.banner.copy(
                                photoTranslation = null
                            )
                        )
                    }
                }
            }

            AppTextMedium {
                sx {
                    marginTop = 16.px
                }
                text = "Rasm (english)"
            }
            Box {
                sx {
                    marginTop = 8.px
                }
                PhotoAttacher {
                    dataFile = componentBanner.banner.photoEnglish
                    onFileUpload = { dFile ->
                        componentBanner = componentBanner.copy(
                            banner = componentBanner.banner.copy(
                                photoEnglish = dFile
                            )
                        )
                    }
                    onDelete = {
                        componentBanner = componentBanner.copy(
                            banner = componentBanner.banner.copy(
                                photoEnglish = null
                            )
                        )
                    }
                }
            }
        }

        TextField {
            sx {
                marginTop = 16.px
            }
            label = ReactNode("Havola")
            variant = FormControlVariant.outlined
            value = componentBanner.banner.url
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                val link = event.target.value
                val cp = componentBanner
                componentBanner = cp.copy(
                    banner = cp.banner.copy(
                        url = link
                    )
                )
            }
        }

        TextField {
            sx {
                marginTop = 16.px
            }
            label = ReactNode("Tartib raqami")
            variant = FormControlVariant.outlined
            value = componentBanner.sort.orZero()
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                componentBanner = componentBanner.copy(sort = event.target.value.toIntOrNull())
            }
        }

        Box {
            sx {
                marginTop = 16.px
                display = Display.flex
                flexDirection = FlexDirection.row
                justifyContent = JustifyContent.end
            }

            if (componentBanner.id != null) {
                Button {
                    sx {
                        marginRight = 16.px
                    }
                    variant = ButtonVariant.contained
                    onClick = {
                        MainScope().launch {
                            try {
                                ComponentBannerManager.deleteComponentBanner(
                                    componentBanner.id!!
                                )
                                goBack()
                            } catch (e: Exception) {
                                alertManager.handleError(e)
                            }
                        }
                    }
                    +"O'chirish"
                }
            }


            Button {
                variant = ButtonVariant.contained
                onClick = {
                    goBack()
                }
                +"Bekor"
            }

            Button {
                sx {
                    marginLeft = 16.px
                }
                variant = ButtonVariant.contained
                onClick = {
                    if (isValidComponentBanner(componentBanner)) {
                        MainScope().launch {
                            try {
                                ComponentBannerManager.saveComponentBanner(componentBanner)
                                goBack()
                            } catch (e: Exception) {
                                alertManager.handleError(e)
                            }
                        }
                    }
                }
                +"Saqlash"
            }
        }
    }
}

private fun isValidComponentBanner(cb: ComponentBanner): Boolean {
    if (cb.component.id == null) {
        return false
    }

    return true
}