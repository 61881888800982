package uz.ferro.shop.pages.cart

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.CircularProgress
import mui.material.FormControlVariant
import mui.material.Size
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.manager.AuthManager
import uz.ferro.shop.manager.CustomerManager
import uz.ferro.shop.manager.UserManager
import uz.ferro.shop.model.Order
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.ui.AppText
import uz.ferro.shop.util.styleInMobile
import web.cssom.AlignItems
import web.cssom.JustifyContent
import web.cssom.None
import web.cssom.pct
import web.cssom.px
import web.html.HTMLInputElement

private val UZ_PHONE_REGEX = Regex("\\+?998\\s?\\d{2}[\\s-]?\\d{3}[\\s-]?\\d{2}[\\s-]?\\d{2}")

external interface CreateOrderViewProps : Props {
    var onSendClick: (Order, (Boolean) -> Unit) -> Unit
}

val CreateOrderView = FC<CreateOrderViewProps> { props ->

    var nameInput by useState("")
    var phoneInput by useState("+998 ")
    val isB2B = AuthManager.isB2BUser()
    var orderButtonEnabled by useState(isB2B)
    var orderSending by useState(false)
    val locale = useContext(LocaleContext)

    fun isValidPhone(phone: String): Boolean {
        return UZ_PHONE_REGEX.matches(phone.trim())
    }

    fun checkForm(name: String = nameInput, phone: String = phoneInput): Boolean {
        val isValid = isB2B || (orderSending.not() && name.length >= 3 && isValidPhone(phone))
        orderButtonEnabled = isValid
        return isValid
    }

    useEffectOnce {
        checkForm()
    }

    fun sendOrder() {
        if (checkForm()) {
            orderSending = true
            MainScope().launch {
                try {
                    if (isB2B) {
                        props.onSendClick(Order()) {
                            orderSending = false
                        }
                    } else {
                        props.onSendClick(
                            Order(
                                customerName = nameInput.trim(),
                                customerPhone = phoneInput.trim()
                            )
                        ) {
                            orderSending = false
                        }
                    }
                } catch (e: Exception) {
                    //alertManager.handleError(e)
                }
            }
        }
    }

    Box {
        sx {
            marginTop = 8.px
            padding = styleInMobile(0.px, 16.px)
        }

        Stack {
            direction = responsive(StackDirection.column)

            sx {
                width = styleInMobile(100.pct, 400.px)
            }

            if (isB2B) {
                AppText {
                    sx {
                        marginBottom = 16.px
                        fontSize = 20.px
                    }
                    text = locale.confirmCheckout
                }
            } else {
                TextField {
                    value = nameInput
                    variant = FormControlVariant.outlined
                    label = ReactNode(locale.name)
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        val inputValue = event.target.value
                        nameInput = inputValue
                        checkForm(name = inputValue)
                    }
                }

                TextField {
                    sx {
                        marginTop = 16.px
                    }
                    value = phoneInput
                    variant = FormControlVariant.outlined
                    label = ReactNode(locale.phoneNumber)
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        val inputValue = event.target.value
                        phoneInput = inputValue
                        checkForm(phone = inputValue)
                    }
                }
            }


            if (orderSending) {
                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        marginTop = 16.px
                        alignItems = AlignItems.center
                        justifyContent = JustifyContent.center
                    }

                    CircularProgress()
                }
            } else {
                Button {
                    size = Size.large
                    sx {
                        marginTop = 16.px
                        textTransform = None.none
                    }
                    variant = ButtonVariant.contained
                    +locale.sendOrder

                    disabled = orderButtonEnabled.not()
                    onClick = {
                        sendOrder()
                    }
                }
            }
        }
    }
}