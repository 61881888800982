package uz.ferro.shop.search

import uz.ferro.shop.model.Product

class SearchCurrentLocaleMatcher(ferroInTop: Boolean = false) : SearchMatcher(ferroInTop) {

    override fun search(query: String, list: List<Product>): List<Product> {
        return filter(list).filter { p ->
            p.getLocalizedName().startWordsMatching(query)
        }
    }
}