package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class ComponentBanner(
    val id: Int? = null,
    val component: Component,
    val banner: Banner,
    val sort: Int? = null
)