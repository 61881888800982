package uz.ferro.shop

import tanstack.query.core.QueryKey

val CATEGORIES_QUERY_KEY = QueryKey<QueryKey>("categories")
val CATEGORIES_ALL_QUERY_KEY = QueryKey<QueryKey>("categories_all")
val FILE_UPLOAD = QueryKey<QueryKey>("file_upload")

val PRODUCTS_ALL_QUERY_KEY = QueryKey<QueryKey>("products_all")
val PRODUCTS_ALL_PARENT_QUERY_KEY = QueryKey<QueryKey>("products_all_parent")
val BRANDS_ALL_QUERY_KEY = QueryKey<QueryKey>("brands_all")
val MEASURE_UNITS_ALL_QUERY_KEY = QueryKey<QueryKey>("measure_units_all")
val PROPERTIES_ALL_QUERY_KEY = QueryKey<QueryKey>("properties_all")
val PROPERTY_VALUES_ALL_QUERY_KEY = QueryKey<QueryKey>("property_Values_all")
val MARKET_CATEGORIES_ALL_QUERY_KEY = QueryKey<QueryKey>("market_categories_all")
val PRODUCT_GROUPS_ALL_QUERY_KEY = QueryKey<QueryKey>("product_groups_all")
val USERS_ALL_QUERY_KEY = QueryKey<QueryKey>("users_all")

