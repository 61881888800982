package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class Brand(
    val id: Int? = null,
    val name: String? = null,
    val icon: DataFile? = null,
    val photo: DataFile? = null
)