package uz.ferro.shop.api

import kotlinx.serialization.json.Json


val jsonSerializer = Json {
    explicitNulls = false
    ignoreUnknownKeys = true
}

object BaseUrl {
//    const val HOST = "http://localhost:8888"
//    const val HOST = "http://94.156.35.35:8888"
    const val HOST = "https://ferro.uz"

    private const val BASE_API = "$HOST/api/"

    fun get(path: String): String {
        return BASE_API + path.removePrefix("/")
    }
}