package uz.ferro.shop.pages.admin.product

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Checkbox
import mui.material.Fab
import mui.material.FabColor
import mui.material.FormControl
import mui.material.FormControlLabel
import mui.material.FormControlVariant
import mui.material.Grid
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.ReactNode
import react.VFC
import react.create
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.router.useParams
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.manager.ProductManager
import uz.ferro.shop.model.DictItem
import uz.ferro.shop.model.Product
import uz.ferro.shop.model.ProductPhoto
import uz.ferro.shop.model.ProductProperty
import uz.ferro.shop.navigateToBack
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.pages.admin.CountryPicker
import uz.ferro.shop.pages.admin.brand.BrandPicker
import uz.ferro.shop.pages.admin.brand.MeasureUnitPicker
import uz.ferro.shop.pages.admin.category.CategoryPicker
import uz.ferro.shop.pages.admin.dict.DictItemEditor
import uz.ferro.shop.pages.admin.file.PhotoAttacher
import uz.ferro.shop.util.orZero
import web.cssom.AlignItems
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.number
import web.cssom.px
import web.html.HTMLInputElement

val EditProductDetails = VFC {
    val pathParam = useParams()["productId"] ?: return@VFC

    var product by useState(Product())
    var isPropertyEditorOpened by useState(false)

    val productId = pathParam.toLongOrNull()

    var inputPrice: String by useState("")
    val alertManager = useContext(AlertManagerContext)

    useEffectOnce {
        if (productId != null) {
            MainScope().launch {
                val p = ProductManager.getProduct(productId)
                    .let {
                        it.copy(
                            name = it.name ?: DictItem(),
                        )
                    }
                inputPrice = p.price?.toString().orEmpty()
                product = p
            }
        }
    }

    Box {
        sx {
            padding = 16.px
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(32.px)

            Stack {
                direction = responsive(StackDirection.column)

                sx {
                    alignItems = AlignItems.stretch
                    width = 500.px
                }

                DictItemEditor {
                    title = "Nomi"
                    item = product.name
                    onChange = {
                        product = product.copy(name = it)
                    }
                }

                TextField {
                    sx {
                        marginTop = 16.px
                    }
                    label = ReactNode("Tashqi ID (SAP)")
                    variant = FormControlVariant.outlined
                    value = product.externalId.orEmpty()
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        product = product.copy(externalId = event.target.value)
                    }
                }

                CategoryPicker {
                    selectedCategory = product.category
                    onCategorySelect = { cat ->
                        product = product.copy(category = cat)
                    }
                }

                TextField {
                    sx {
                        marginTop = 16.px
                    }
                    label = ReactNode("Narxi")
                    variant = FormControlVariant.outlined
                    value = inputPrice
                    onChange = { event ->
                        event as ChangeEvent<HTMLInputElement>
                        inputPrice = event.target.value
                    }
                }

                BrandPicker {
                    selectedBrand = product.brand
                    onBrandSelect = { brand ->
                        product = product.copy(brand = brand)
                    }
                }

                MeasureUnitPicker {
                    selectedMeasureUnit = product?.unit
                    onMeasureUnitSelect = { unit ->
                        product = product.copy(unit = unit)
                    }
                }
            }

            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(24.px)

                FormControlLabel {
                    sx {
                        marginTop = 12.px
                    }
                    label = ReactNode("Guruhning bosh tovari")
                    control = Checkbox.create {
                        checked = product.isParent == true
                        onChange = { _, isChecked ->
                            product = product.copy(isParent = isChecked)
                        }
                    }
                }

                if (product.isParent != true) {
                    ParentProductPicker {
                        selectedProduct = product.parentProduct
                        onProductSelect = { parentProduct ->
                            product = product.copy(parentProduct = parentProduct)
                        }
                    }
                }

                sx {
                    justifyContent = JustifyContent.stretch
                    width = 400.px
                }

                CountryPicker {
                    selectedCountry = product.getCountry()
                    onCountrySelect = {
                        product = product.copy(country = it?.name)
                    }
                }

                val statusLabel = "Holati"
                val currentStatus = if (product.isDisabled == true) {
                    "disabled"
                } else {
                    "enabled"
                }

                FormControl {
                    fullWidth = true
                    InputLabel {
                        +statusLabel
                    }
                    Select {
                        value = currentStatus
                        label = ReactNode(statusLabel)
                        onChange = { evt, _ ->
                            val status = evt.target.value
                            product = product.copy(isDisabled = status == "disabled")
                        }

                        MenuItem {
                            value = "enabled"
                            +"Aktiv"
                        }

                        MenuItem {
                            value = "disabled"
                            +"Noaktiv"
                        }
                    }
                }

                Box {
                    InputLabel {
                        +"Hususiyatlar"
                    }

                    if (product.properties.orEmpty().isNotEmpty()) {
                        ProductProperties {
                            items = product.properties.orEmpty()
                        }
                    }

                    if (isPropertyEditorOpened.not()) {
                        Box {
                            sx {
                                flexGrow = number(1.0)
                                display = Display.flex
                                flexDirection = FlexDirection.row
                                justifyContent = JustifyContent.end
                                marginTop = 12.px
                            }
                            Fab {
                                color = FabColor.primary
                                Add()
                                onClick = {
                                    isPropertyEditorOpened = true
                                }
                            }
                        }
                    } else {
                        PropertyValueEditor {
                            onClose = {
                                isPropertyEditorOpened = false
                            }
                            onReady = { propertyValue ->
                                if (propertyValue != null) {
                                    val arr = product.properties.orEmpty().toMutableList()
                                    val sort = arr.maxOfOrNull { it.sort.orZero() }.orZero() + 1

                                    val productProp = ProductProperty(
                                        id = null,
                                        propertyValue = propertyValue,
                                        sort = sort
                                    )

                                    arr.add(productProp)

                                    product = product.copy(
                                        properties = arr
                                    )
                                }

                                isPropertyEditorOpened = false
                            }
                        }
                    }
                }
            }
        }

        Box {
            sx {
                marginTop = 24.px
            }

            InputLabel {
                +"Rasmlar"
            }

            Grid {
                container = true
                columns = responsive(6)
                spacing = responsive(10.px)

                val photos = product.photos.orEmpty().toMutableList()

                fun onMoveToPrev(index: Int) {
                    if (index <= 0) return
                    val arr = photos.toTypedArray()
                    val current = arr[index]
                    val prev = arr[index - 1]
                    arr[index - 1] = current
                    arr[index] = prev
                    val list = arr.mapIndexed { i, pp->
                        pp.copy(sort = i + 1)
                    }
                    product = product.copy(photos = list)
                }

                fun onMoveToNext(index: Int) {
                    if (index >= photos.lastIndex) return
                    val arr = photos.toTypedArray()
                    val current = arr[index]
                    val next = arr[index + 1]
                    arr[index] = next
                    arr[index + 1] = current
                    val list = arr.mapIndexed { i, pp->
                        pp.copy(sort = i + 1)
                    }
                    product = product.copy(photos = list)
                }

                photos.forEachIndexed { index, productPhoto ->
                    Grid {
                        item = true
                        PhotoAttacher {
                            dataFile = productPhoto.photo
                            onFileUpload = { dFile ->
                                photos.remove(productPhoto)
                                photos.add(
                                    ProductPhoto(
                                        id = null,
                                        photo = dFile,
                                        sort = productPhoto.sort
                                    )
                                )
                                photos.sortBy { it.sort.orZero() }
                                product = product.copy(photos = photos)
                            }
                            onDelete = {
                                photos.remove(productPhoto)
                                product = product.copy(photos = photos)
                            }

                            if (index > 0) {
                                moveToPrev = { onMoveToPrev(index) }
                            }

                            if (index < photos.lastIndex) {
                                moveToNext = { onMoveToNext(index) }
                            }
                        }
                    }
                }

                Grid {
                    item = true
                    PhotoAttacher {
                        onFileUpload = { dFile ->
                            val sort: Int = photos.maxOfOrNull { it.sort.orZero() }.orZero() + 1
                            photos.add(
                                ProductPhoto(
                                    id = null,
                                    photo = dFile,
                                    sort = sort
                                )
                            )
                            photos.sortBy { it.sort.orZero() }
                            product = product.copy(photos = photos)
                        }
                    }
                }
            }
        }

        Box {
            sx {
                marginTop = 16.px
                display = Display.flex
                flexDirection = FlexDirection.row
                justifyContent = JustifyContent.end
            }

            Button {
                variant = ButtonVariant.contained
                onClick = {
                    navigateToBack()
                }
                +"Bekor"
            }

            Button {
                sx {
                    marginLeft = 16.px
                }
                variant = ButtonVariant.contained
                onClick = {
                    val prd: Product = product.copy(
//                        price = inputPrice.toDoubleOrNull() ?: product.price,
//                        discountPrice = inputDiscountPrice.toDoubleOrNull() ?: product.price
                    )
                    if (isValidProduct(prd)) {
                        MainScope().launch {
                            try {
                                ProductManager.saveProduct(prd)
                                alertManager.showSuccess("Saqlandi")
                            } catch (e: Exception) {
                                alertManager.handleError(e)
                            }
                        }
                    }
                }
                +"Saqlash"
            }

        }
    }
}

private fun isValidProduct(product: Product?): Boolean {
    product ?: return false
    if (product.externalId.orEmpty().isBlank()) {
        if (product.name?.textUzLat.orEmpty().isBlank()) return false
        if (product.name?.textUzCyr.orEmpty().isBlank()) return false
        if (product.name?.textRu.orEmpty().isBlank()) return false
    }
    return true
}

