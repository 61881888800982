package uz.ferro.shop.pages.order

import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.useContext
import uz.ferro.shop.CartContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.model.PHOTO_SIZE_SMALL
import uz.ferro.shop.pages.MarketContext
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextBoldSmall
import uz.ferro.shop.util.formatPrice
import web.cssom.AlignItems
import web.cssom.BackgroundRepeat
import web.cssom.BackgroundSize
import web.cssom.GeometryPosition
import web.cssom.JustifyContent
import web.cssom.LineStyle
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.cssom.url

val OrderProductItemViewMobile = FC<OrderProductItemProps> { props ->
    val orderProduct = props.orderProduct
    val product = orderProduct.product

    val marketContext = useContext(MarketContext)
    val navigator = useContext(NavigationContext)
    val cartManager = useContext(CartContext)
    val locale = useContext(LocaleContext)

    val photoUrl = product.getPrimaryPhotoUrl(PHOTO_SIZE_SMALL)

    Stack {
        direction = responsive(StackDirection.column)
        sx {
            width = 100.pct
            borderStyle = LineStyle.solid
            borderColor = Colors.borderPrimary
            borderWidth = 1.px
            borderRadius = 5.px
            padding = 12.px
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(8.px)

            Box {
                sx {
                    width = 48.px
                    height = 48.px
                    flexShrink = number(0.0)
                    borderStyle = LineStyle.solid
                    borderWidth = 1.px
                    borderRadius = 3.px
                    borderColor = Colors.borderPrimary
                    if (photoUrl.isNotBlank()) {
                        backgroundImage = url(photoUrl)
                    }
                    backgroundPosition = GeometryPosition.center
                    backgroundSize = BackgroundSize.contain
                    backgroundRepeat = BackgroundRepeat.noRepeat
                }
            }

            AppTextBoldSmall {
                text = product.getLocalizedName()
                onClick = {
                    navigator.openProductDetails(product.id!!)
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
                marginTop = 12.px
            }

            AppTextBold {
                text = orderProduct.productPrice.formatPrice()
            }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(8.px)
                sx {
                    alignItems = AlignItems.center
                }

                AppTextBold {
                    text = orderProduct.quantity.toString()
                }

                AppTextBold {
                    text = product.unit?.getLocalizedName().orEmpty()
                }
            }

            val totalPrice = orderProduct.productPrice?.times(orderProduct.quantity)
            AppTextBold {
                text = totalPrice.formatPrice()
            }
        }

    }
}