package uz.ferro.shop.pages.admin.settings

import mui.material.Box
import mui.material.ListItemButton
import mui.material.ListItemText
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.navigator.NavigationPage
import uz.ferro.shop.ui.AppTextSemiBoldLarge
import web.cssom.AlignItems
import web.cssom.px

val AdminSettingsList = FC<Props> {
    val navigator = useContext(NavigationContext)

    Box {
        sx {
            padding = 16.px
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                alignItems = AlignItems.center
            }

            AppTextSemiBoldLarge {
                text = "Sozlashlar"
            }
        }

        mui.material.List {
            sx {
                marginTop = 16.px
            }
            ListItemButton {
                ListItemText {
                    +"Sotuv Menejeri"
                }

                onClick = {
                    navigator.openPage(NavigationPage.ADMIN_SALE_PERSON)
                }
            }
        }
    }


}
