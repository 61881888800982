package uz.ferro.shop.product

import uz.ferro.shop.FERRO_BRAND_ID
import uz.ferro.shop.model.Product

class ProductComparator : Comparator<Product> {

    override fun compare(a: Product, b: Product): Int {
        val brandASort = if (a.brand?.id == FERRO_BRAND_ID) 0 else 1
        val brandBSort = if (b.brand?.id == FERRO_BRAND_ID) 0 else 1

        if (brandASort != brandBSort) {
            return brandASort.compareTo(brandBSort)
        }

        return a.getLocalizedName().compareTo(b.getLocalizedName())
    }
}