package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class PageContentComponent(
    val id: Int? = null,
    val pageContent: PageContent? = null,
    val component: Component? = null,
    val sort: Int?
)