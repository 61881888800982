package uz.ferro.shop.pages.admin.customer

import mui.material.Box
import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useState
import uz.ferro.shop.model.Customer
import web.cssom.media.height
import web.cssom.media.maxHeight
import web.cssom.px
import web.cssom.vh

external interface CustomersPickerDialogProps : Props {
    var onPicked: (Customer) -> Unit
    var onAddNewClick: () -> Unit
    var onClose: () -> Unit
}

val CustomersPickerDialog = FC<CustomersPickerDialogProps> { props ->
    var selectedCustomer by useState<Customer>()

    fun closeDialog() {
        props.onClose()
    }

    Dialog {
        fullScreen = true
        open = true
        onClose = { _, _ -> closeDialog() }
        DialogTitle {
            +"Mijozlar"
        }

        DialogContent {
            AdminCustomerList {
                onCustomerSelected = {
                    selectedCustomer = it
                }

                onCustomerDeselected = {
                    selectedCustomer = null
                }
            }
        }

        DialogActions {
//            Button {
//                onClick = {
//                    props.onAddNewClick()
//                }
//                +"Yangi qo'shish"
//            }
            Button {
                onClick = {
                    props.onPicked(selectedCustomer!!)
                }
                disabled = selectedCustomer == null
                +"Tanlash"
            }
        }
    }
}