package uz.ferro.shop.util

import uz.ferro.shop.model.FilterComboData
import uz.ferro.shop.model.Product
import uz.ferro.shop.model.Property

object FilterUtils {
    private const val PROP_DIAMETER = "diameterD"
    private const val PROP_INNER_DIAMETER = "innerDiameter"
    private const val PROP_INSTALL_DIAMETER = "installDiameter"
    private const val PROP_LENGTH = "lengthL"
    private const val PROP_GRAIN = "grain"
    private const val PROP_NUM_OF_TEETH = "numberOfTeeth"

    fun getFilters(products: List<Product>): List<FilterComboData> {
        val filters = listOf(
            PROP_DIAMETER,
            PROP_INNER_DIAMETER,
            PROP_INSTALL_DIAMETER,
            PROP_LENGTH,
            PROP_GRAIN,
            PROP_NUM_OF_TEETH
        ).mapNotNull { key ->
            getFilterData(products, key)
        }
        return filters
    }


    private fun getFilterData(data: List<Product>, key: String): FilterComboData? {
        val filterValues = linkedSetOf<String>()

        var filterProp: Property? = null

        data.forEach { p ->
            val productPropVal = p.properties.orEmpty().firstOrNull { ppv ->
                ppv.propertyValue?.property?.key == key
            }?.propertyValue

            val extractedValue = productPropVal?.value?.localizedOrDefault()

            if (filterProp == null && productPropVal?.property != null) {
                filterProp = productPropVal.property
            }

            if (extractedValue != null) {
                filterValues.add(extractedValue)
            }
        }

        if (filterValues.size > 1 && filterProp != null) {
            return FilterComboData(
                property = filterProp!!,
                values = filterValues.sortedWith { a, b ->
                    val floatA = a.toFloatOrNull()
                    val floatB = b.toFloatOrNull()

                    if (floatA != null && floatB != null) {
                        floatA.compareTo(floatB)
                    } else {
                        a.compareTo(b)
                    }
                }
            )
        }

        return null
    }

}