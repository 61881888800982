package uz.ferro.shop.manager

import uz.ferro.shop.api.suspendDelete
import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.api.suspendPut
import uz.ferro.shop.model.ProductGroup
import uz.ferro.shop.model.ProductGroupProduct
import uz.ferro.shop.util.orZero

object ProductGroupManager {

    private const val PRODUCT_GROUP_PATH = "product-group"
    private const val PRODUCT_GROUP_PRODUCT_PATH = "product-group-product"

    suspend fun getProductGroup(id: Int): ProductGroup {
        return suspendGet("$PRODUCT_GROUP_PATH/$id")
    }

    suspend fun deleteProductGroupProduct(id: Int) {
        return suspendDelete("$PRODUCT_GROUP_PRODUCT_PATH/$id")
    }

    suspend fun saveProductGroupProduct(pgp: ProductGroupProduct): ProductGroupProduct {
        return if (pgp.id == null) {
            addProductGroupProduct(pgp)
        } else {
            updateProductGroupProduct(pgp)
        }
    }

    private suspend fun addProductGroupProduct(productGroupProduct: ProductGroupProduct): ProductGroupProduct {
        return suspendPost(path = PRODUCT_GROUP_PRODUCT_PATH, body = productGroupProduct)
    }

    private suspend fun updateProductGroupProduct(productGroupProduct: ProductGroupProduct): ProductGroupProduct {
        return suspendPut(path = PRODUCT_GROUP_PRODUCT_PATH, body = productGroupProduct)
    }

    suspend fun getProductGroupProducts(productGroupId: Int): List<ProductGroupProduct> {
        return suspendGet<List<ProductGroupProduct>>("$PRODUCT_GROUP_PRODUCT_PATH/group/${productGroupId}/list")
            .sortedBy { it.sort.orZero() }
    }
}