package uz.ferro.shop.util

import uz.ferro.shop.locale.Language
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.model.DictItem

fun getLocalized(uzLat: String?, uzCyr: String?, ru: String?): String {
    val uzLatText = uzLat.onBlank(uzCyr, ru)
    val uzCyrText = uzCyr.onBlank(uzLat, ru)
    val ruText = ru.onBlank(uzCyr, uzLat)

    return when (LanguageManager.currentLanguage) {
        Language.RU -> ruText
        Language.UZ_CYR -> uzCyrText
        else -> uzLatText
    }
}

fun DictItem?.localized(): String {
    this ?: return ""

    return when (LanguageManager.currentLanguage) {
        Language.UZ -> textUzLat.orEmpty()
        Language.UZ_CYR -> textUzCyr.orEmpty()
        Language.RU -> textRu.orEmpty()
        Language.EN -> textEn.onBlank(textUzLat)
    }
}

fun DictItem?.localizedOrDefault(): String {
    this ?: return ""
    val defaultValue = textUzLat.onBlank(textUzCyr).onBlank(textRu).onBlank(textEn)

    return when (LanguageManager.currentLanguage) {
        Language.UZ -> textUzLat.onBlank(defaultValue)
        Language.UZ_CYR -> textUzCyr.onBlank(defaultValue)
        Language.RU -> textRu.onBlank(defaultValue)
        Language.EN -> textEn.onBlank(defaultValue)
    }
}

fun DictItem?.isDefaultBlank(): Boolean {
    return this?.textUzLat.orEmpty().isBlank()
}

fun DictItem?.isNotFull(): Boolean {
    return this?.textUzLat.orEmpty().isBlank() ||
            this?.textUzCyr.orEmpty().isBlank() ||
            this?.textRu.orEmpty().isBlank()
}