package uz.ferro.shop

import js.core.jso
import react.create
import react.router.dom.createHashRouter
import uz.ferro.shop.pages.LoginPage
import uz.ferro.shop.pages.about.AboutPage
import uz.ferro.shop.pages.admin.AdminDashboard
import uz.ferro.shop.pages.admin.brand.AdminBrands
import uz.ferro.shop.pages.admin.category.AdminCategory
import uz.ferro.shop.pages.admin.document.AdminPriceGenerator
import uz.ferro.shop.pages.admin.help.AdminHelp
import uz.ferro.shop.pages.admin.help.AdminHelpAddProduct
import uz.ferro.shop.pages.admin.help.AdminHelpTopics
import uz.ferro.shop.pages.admin.home.AdminBannersPage
import uz.ferro.shop.pages.admin.home.AdminHomePage
import uz.ferro.shop.pages.admin.marketcategory.AdminMarketCategory
import uz.ferro.shop.pages.admin.order.AdminOrder
import uz.ferro.shop.pages.admin.order.AdminOrderDetails
import uz.ferro.shop.pages.admin.order.AdminOrderList
import uz.ferro.shop.pages.admin.page.AdminComponent
import uz.ferro.shop.pages.admin.page.AdminComponentBanner
import uz.ferro.shop.pages.admin.page.AdminComponentCategory
import uz.ferro.shop.pages.admin.product.AdminProduct
import uz.ferro.shop.pages.admin.product.AdminProductList
import uz.ferro.shop.pages.admin.product.EditProductDetails
import uz.ferro.shop.pages.admin.productgroup.AdminEditProductGroup
import uz.ferro.shop.pages.admin.productgroup.AdminProductGroup
import uz.ferro.shop.pages.admin.productgroup.AdminProductGroupList
import uz.ferro.shop.pages.admin.salepoint.AdminSalePoint
import uz.ferro.shop.pages.admin.salepoint.AdminSalePointDetails
import uz.ferro.shop.pages.admin.salepoint.AdminSalePointList
import uz.ferro.shop.pages.admin.settings.AdminSalePersonDetails
import uz.ferro.shop.pages.admin.settings.AdminSettings
import uz.ferro.shop.pages.admin.settings.AdminSettingsList
import uz.ferro.shop.pages.admin.user.AdminEditUser
import uz.ferro.shop.pages.admin.user.AdminUser
import uz.ferro.shop.pages.admin.user.AdminUserList
import uz.ferro.shop.pages.all.AllProductsPage
import uz.ferro.shop.pages.cart.CartPage
import uz.ferro.shop.pages.category.CategoryContentPage
import uz.ferro.shop.pages.delivery.PaymentAndDeliveryPage
import uz.ferro.shop.pages.documentpage.DocumentPage
import uz.ferro.shop.pages.favorite.FavoritesPage
import uz.ferro.shop.pages.home.Home
import uz.ferro.shop.pages.order.OrderDetailsPage
import uz.ferro.shop.pages.order.OrderListPage
import uz.ferro.shop.pages.order.OrderPage
import uz.ferro.shop.pages.product.ProductDetailsPage
import uz.ferro.shop.pages.search.SearchPage

val appRouter = createHashRouter(
    arrayOf(
        jso {
            path = "/"
            element = Root.create()
            children = arrayOf(
                jso {
                    index = true
                    element = Home.create()
                },
                jso {
                    path = "search"
                    element = SearchPage.create()
                },
                jso {
                    path = "all-products"
                    element = AllProductsPage.create()
                },
                jso {
                    path = "category/:categoryId"
                    element = CategoryContentPage.create()
                },
                jso {
                    path = "product/:productId"
                    element = ProductDetailsPage.create()
                },
                jso {
                    path = "favorites"
                    element = FavoritesPage.create()
                },
                jso {
                    path = "cart"
                    element = CartPage.create()
                },
                jso {
                    path = "order"
                    element = OrderPage.create()
                    children = arrayOf(
                        jso {
                            index = true
                            element = OrderListPage.create()
                        },
                        jso {
                            path = ":orderId"
                            element = OrderDetailsPage.create()
                        }
                    )
                },
                jso {
                    path = "payment-and-delivery"
                    element = PaymentAndDeliveryPage.create()
                },
                jso {
                    path = "document-page/:documentId"
                    element = DocumentPage.create()
                },
                jso {
                    path = "about"
                    element = AboutPage.create()
                }
            )
        },
        jso {
            path = "login"
            element = LoginPage.create()
        },
        jso {
            path = "admin"
            element = AdminDashboard.create()
            children = arrayOf(
                jso {
                    path = "category"
                    element = AdminCategory.create()
                },
                jso {
                    path = "product"
                    element = AdminProduct.create()
                    children = arrayOf(
                        jso {
                            index = true
                            element = AdminProductList.create()
                        },
                        jso {
                            path = ":productId"
                            element = EditProductDetails.create()
                        }
                    )
                },
                jso {
                    path = "product-group"
                    element = AdminProductGroup.create()
                    children = arrayOf(
                        jso {
                            index = true
                            element = AdminProductGroupList.create()
                        },
                        jso {
                            path = ":productGroupId"
                            element = AdminEditProductGroup.create()
                        }
                    )
                },
                jso {
                    path = "sale-point"
                    element = AdminSalePoint.create()
                    children = arrayOf(
                        jso {
                            index = true
                            element = AdminSalePointList.create()
                        },
                        jso {
                            path = ":salePointId"
                            element = AdminSalePointDetails.create()
                        }
                    )
                },
                jso {
                    path = "brand"
                    element = AdminBrands.create()
                },
                jso {
                    path = "price-generator"
                    element = AdminPriceGenerator.create()
                },
                jso {
                    path = "home"
                    element = AdminHomePage.create()
                },
                jso {
                    path = "banner"
                    element = AdminBannersPage.create()
                },
                jso {
                    path = "market-category/:marketCategoryId"
                    element = AdminMarketCategory.create()
                },
                jso {
                    path = "component/:componentId"
                    element = AdminComponent.create()
                    children = arrayOf(
                        jso {
                            path = "component-banner/:componentBannerId"
                            element = AdminComponentBanner.create()
                        },
                        jso {
                            path = "component-category/:componentCategoryId"
                            element = AdminComponentCategory.create()
                        }
                    )
                },
                jso {
                    path = "user"
                    element = AdminUser.create()
                    children = arrayOf(
                        jso {
                            index = true
                            element = AdminUserList.create()
                        },
                        jso {
                            path = ":userId"
                            element = AdminEditUser.create()
                        }
                    )
                },
                jso {
                    path = "order"
                    element = AdminOrder.create()
                    children = arrayOf(
                        jso {
                            index = true
                            element = AdminOrderList.create()
                        },
                        jso {
                            path = ":orderId"
                            element = AdminOrderDetails.create()
                        }
                    )
                },
                jso {
                    path = "settings"
                    element = AdminSettings.create()
                    children = arrayOf(
                        jso {
                            index = true
                            element = AdminSettingsList.create()
                        },
                        jso {
                            path = "sale-person"
                            element = AdminSalePersonDetails.create()
                        }
                    )
                },
                jso {
                    path = "help"
                    element = AdminHelp.create()
                    children = arrayOf(
                        jso {
                            index = true
                            element = AdminHelpTopics.create()
                        },
                        jso {
                            path = "add-product"
                            element = AdminHelpAddProduct.create()
                        }
                    )
                }
            )
        }
    )
)
