package uz.ferro.shop

import web.cssom.Color

object Colors {
    val primaryColorHex = "#e30611"
    val primaryDarkColorHex = "#c7141d"
    val primaryLightColorHex = "#f01620"
    val accentColor = Color("#e30611")
    val headerBgColor = Color("#e30611")

    val white = Color("#ffffff")
    val gray01 = Color("#2A2824")
    val gray02 = Color("#F6F6F6")
    val gray03 = Color("#ededed")
    val gray05 = Color("#828282")
    val green = Color("#219653")
    val orange = Color("#FF5F15")

    val accentContrastColor = white
    val footerContrastColor = white

    val backgroundColor = white
    val headerDivider = gray03
    val textPrimary = gray01
    val textSecondary = gray05
    val hoverBackground = gray02
    val footerBackground = gray01
    val secondaryBackground = gray02

    val borderPrimary = gray03
    val categoryBorder = gray03

    val title = Color("#3d3d3d")

    val label = Color("#777777")

    val divider = Color("#7e7e7e")
    val defaultBorder = Color("#999999")
    val lightBorder = Color("#bbbbbb")
    val errorText = Color("#e30611")

    val iconSecondary = "#bbbbbb"
    val iconPrimary = "#555555"

    val loginBgColor = Color("#f2f3f4")
    val adminBgColor = Color("#f5f5f5")
    val adminMenuBgColor = Color("#3d3d3d")
    val adminMenuTextColor = Color("#ffffff")

    val orderStatusNew = Color("#2ECC71")
    val orderStatusAccepted = Color("#F5B041")
    val orderStatusInProgress = Color("#5DADE2")
    val orderStatusInShipping = Color("#5DADE2")
    val orderStatusCompleted = Color("#34495E")
    val orderStatusCanceled = Color("#EC7063")


    val adminItemHover = Color("#f0f0f0")
    val adminItemPressed = Color("#cccccc")
    val selectBorder = Color("#7e7e7e")
    val discountLabelColor = Color("#e30611")
    val productChildrenItemsBg = Color("#fafafa")
    val defaultSelectedColor = Color("#cccccc")

    val amountEnoughLabel = green
    val amountNotEnoughLabel = orange

}