package uz.ferro.shop.ui

import emotion.react.css
import mui.system.Box
import mui.system.PropsWithSx
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML
import uz.ferro.shop.Colors
import web.cssom.BackgroundRepeat
import web.cssom.BackgroundSize
import web.cssom.BorderColor
import web.cssom.BorderRadius
import web.cssom.BorderWidth
import web.cssom.GeometryPosition
import web.cssom.LineStyle
import web.cssom.None
import web.cssom.ObjectFit
import web.cssom.pct
import web.cssom.px
import web.cssom.url

sealed class BorderType(
    val width: BorderWidth,
    val radius: BorderRadius,
    val color: BorderColor = Colors.borderPrimary
) {

    data object Radius5 : BorderType(width = 1.px, radius = 5.px)
    data object AccentRadius5 : BorderType(width = 1.px, radius = 5.px, color = Colors.accentColor)
    data object Radius3 : BorderType(width = 1.px, radius = 3.px)
}

external interface ImageProps : react.dom.html.HTMLAttributes<web.html.HTMLDivElement>, PropsWithSx {
    var url: String?
    var borderType: BorderType?
    var isCovered: Boolean?
    var noBackgroundMode: Boolean?
    var onPhotoClick: (() -> Unit)?
}

val ImageView = FC<ImageProps> { props ->
    val psx = props.sx

    Box {
        sx {
            if (psx?.width != null) {
                width = psx.width
            }
            if (psx?.height != null) {
                height = psx.height
            }

            if (psx?.maxWidth != null) {
                maxWidth = psx.maxWidth
            }

            if (psx?.aspectRatio != null) {
                aspectRatio = psx.aspectRatio
            }

            if (psx?.cursor != null) {
                cursor = psx.cursor
            }

            if (psx?.marginTop != null) {
                marginTop = psx.marginTop
            }

            props.borderType?.let { b ->
                borderStyle = LineStyle.solid
                borderWidth = b.width
                borderRadius = b.radius
                borderColor = b.color
            }
            if (psx?.borderRadius != null) {
                borderRadius = psx.borderRadius
            }

            if (props.noBackgroundMode != true && props.url.orEmpty().isNotBlank()) {
                backgroundImage = url(props.url.orEmpty())
            }
            backgroundPosition = GeometryPosition.center
            backgroundSize = if (props.isCovered == true) {
                BackgroundSize.cover
            } else {
                BackgroundSize.contain
            }
            backgroundRepeat = BackgroundRepeat.noRepeat
            userSelect = None.none
        }

        if (props.noBackgroundMode == true) {
            ReactHTML.img {
                css {
                    width = 100.pct
                    objectFit = if (props.isCovered == true) {
                        ObjectFit.cover
                    } else {
                        ObjectFit.contain
                    }
                }

                if (props.url.orEmpty().isNotBlank()) {
                    src = props.url
                }
            }
        }

        onMouseEnter = props.onMouseEnter

        onContextMenu = {
            it.preventDefault()
            it.stopPropagation()
        }

        onClick = {
            if (props.onPhotoClick != null) {
                it.preventDefault()
                it.stopPropagation()
            }
            props.onPhotoClick?.invoke()
        }
    }
}