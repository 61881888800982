package uz.ferro.shop.api

import js.core.Void
import js.core.jso
import tanstack.query.core.QueryKey
import tanstack.react.query.useMutation
import tanstack.react.query.useQuery
import tanstack.react.query.useQueryClient
import uz.ferro.shop.PRODUCTS_ALL_PARENT_QUERY_KEY
import uz.ferro.shop.PRODUCTS_ALL_QUERY_KEY
import uz.ferro.shop.manager.ProductManager
import uz.ferro.shop.model.Product
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import kotlin.js.Promise

fun useAllProducts(): List<Product> {
    val result = useQuery<List<Product>, Error, List<Product>, QueryKey>(
        queryKey = PRODUCTS_ALL_QUERY_KEY,
        queryFn = { getAllProducts() }
    )
    return result.data.orEmpty()
}

fun useAllParentProducts(): List<Product> {
    val result = useQuery<List<Product>, Error, List<Product>, QueryKey>(
        queryKey = PRODUCTS_ALL_PARENT_QUERY_KEY,
        queryFn = { getAllParentProducts() }
    )
    return result.data.orEmpty()
}

private fun addProduct(product: Product): Promise<Product> {
    return post(path = "product", body = product)
}

typealias SaveProduct = (Product) -> Unit

private fun updateProduct(product: Product): Promise<Product> {
    return put(path = "product", body = product)
}

fun useSaveProduct(): SaveProduct {
    val client = useQueryClient()
    return useMutation<Product, Error, Product, QueryKey>(
        mutationFn = { product ->
            ProductManager.resetCache()
            if (product.id.orZero() > 0) {
                updateProduct(product)
            } else {
                addProduct(product)
            }
        },
        options = jso {
            onSuccess = { _, _, _ -> client.invalidateQueries<Void>(PRODUCTS_ALL_QUERY_KEY) }
        }
    ).mutate.unsafeCast<SaveProduct>()
}

fun getProduct(id: Int): Promise<Product> {
    return get("product/$id")
}

fun getAllProducts(): Promise<List<Product>> {
    return get<List<Product>>("product/all")
        .then { data ->
            data.sortedBy { it.name.localized() }
        }
}

fun getAllParentProducts(): Promise<List<Product>> {
    return get<List<Product>>("product/parent/list")
        .then { data ->
            data.sortedBy { it.name.localized() }
        }
}
