package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import uz.ferro.shop.util.localized

@Serializable
data class ProductGroup(
    val id: Int? = null,
    val name: DictItem? = null,
    val products: List<ProductGroupProduct>? = null
) {
    fun getLocalizedName(): String = name.localized()
}