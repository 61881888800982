package uz.ferro.shop.manager

import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.api.suspendPut
import uz.ferro.shop.model.Product
import uz.ferro.shop.pages.product.ProductNameComparator
import uz.ferro.shop.product.ProductComparator
import uz.ferro.shop.util.orZero
import web.url.URLSearchParams
import kotlin.collections.List
import kotlin.collections.forEach
import kotlin.collections.joinToString
import kotlin.collections.linkedMapOf
import kotlin.collections.mutableListOf
import kotlin.collections.set
import kotlin.collections.sortedBy
import kotlin.collections.sortedWith
import kotlin.collections.toList
import kotlin.js.Date

private const val LIMIT = 100
private const val CACHE_LIMIT = 60_000

object ProductManager {
    private const val API_PATH = "product"
    private const val API_PATH_CATEGORY = "product/list/category"
    private const val QUERY_PARAM_KEY = "key"
    private var allProductsCacheTime = 0.0
    private val productsMap = linkedMapOf<Long, Product>()
    private val allProductsCache: List<Product>
        get() = productsMap.values.toList()

    fun resetCache() {
        productsMap.clear()
    }

    suspend fun getProduct(id: Long, key: String? = null): Product {
        return suspendGet("$API_PATH/$id", queryParams(QUERY_PARAM_KEY to key))
    }

    private suspend fun getAllProducts(): List<Product> {
        val now = Date.now()
        if (now - allProductsCacheTime < CACHE_LIMIT) {
            resetCache()
        }

        if (allProductsCache.isEmpty()) {
            val products = suspendGet<List<Product>>("$API_PATH/all")
                .sortedBy { it.name?.textUzLat.orEmpty() }
            productsMap.clear()
            products.forEach { productsMap[it.id!!] = it }
        }

        return allProductsCache
    }

    suspend fun getAllProductsLimited(search: String): List<Product> {
        return if (search.isBlank()) {
            getAllProducts().subList(0, LIMIT)
        } else {
            val out = mutableListOf<Product>()
            for (product in getAllProducts()) {
                if (product.isApplied(search)) {
                    out.add(product)
                    if (out.size >= LIMIT) break
                }
            }
            out.toList()
        }
    }

    suspend fun getProductChildren(productId: Long, key: String?): List<Product> {
        return suspendGet<List<Product>>(
            "$API_PATH/$productId/children",
            queryParams(QUERY_PARAM_KEY to key)
        ).sortedWith(ProductNameComparator())
    }

    suspend fun getProductsByIdList(productIdList: List<Long>): List<Product> {
        val path = "$API_PATH/listById?" + URLSearchParams("").apply {
            append("products", productIdList.joinToString(separator = ","))
        }
        return suspendGet<List<Product>>(path)
    }

    suspend fun saveProduct(product: Product): Product {
        return if (product.id.orZero() > 0) {
            updateProduct(product)
        } else {
            addProduct(product)
        }.also {
            productsMap[it.id!!] = it
        }
    }

    suspend fun getProductsByCategory(categoryId: Int): List<Product> {
        return suspendGet<List<Product>>("$API_PATH_CATEGORY/$categoryId")
            .sortedWith(ProductComparator())
    }

    private suspend fun addProduct(product: Product): Product {
        return suspendPost(path = API_PATH, body = product)
    }

    private suspend fun updateProduct(product: Product): Product {
        return suspendPut(path = API_PATH, body = product)
    }

    private fun Product.isApplied(search: String): Boolean {
        val pName = name?.textUzLat.orEmpty().lowercase()
        val pNameTranslation = name?.textRu.orEmpty().lowercase()
        val pCode = externalId.orEmpty()
        return pName.contains(search) ||
                pNameTranslation.contains(search) ||
                pCode.startsWith(search)
    }

    private fun queryParams(vararg pair: Pair<String, Any?>): Map<String, Any> {
        val map = mutableMapOf<String, Any>()
        pair.forEach {
            if (it.second != null) {
                map[it.first] = it.second!!
            }
        }
        return map
    }
}