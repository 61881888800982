package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.localizedOrDefault
import uz.ferro.shop.util.thumbnailSquareUrl

const val PHOTO_SIZE_SMALL = 320
const val PHOTO_SIZE_MEDIUM = 512
const val PHOTO_SIZE_LARGE = 1280

@Serializable
data class Product(
    val id: Long? = null,
    val name: DictItem? = null,
    val searchableName: String? = null,
    val description: String? = null,
    val brand: Brand? = null,
    val category: Category? = null,
    val parentProduct: Product? = null,
    val photos: List<ProductPhoto>? = null,
    val isParent: Boolean? = null,
    val externalId: String? = null,
    val barcode: String? = null,
    val price: Double? = null,
    val localPrice: Int? = null,
    val available: String? = null,
    val unit: MeasureUnit? = null,
    val isDisabled: Boolean? = null,
    val country: String? = null,
    val variantsCount: Int? = null,
    val amountNotEnough: Boolean? = null,
    val properties: List<ProductProperty>? = null,
    val totalAmount: Double? = null,
    val amounts: List<WarehouseAmount>? = null,
    val discountPercent: Int? = null
) {

    fun getPrimaryPhotoUrl(size: Int = PHOTO_SIZE_MEDIUM): String {
        var primaryPhoto = photos?.firstOrNull()?.photo
        if (primaryPhoto?.url.orEmpty().isBlank()) {
            primaryPhoto = parentProduct?.photos?.firstOrNull()?.photo
        }

        return primaryPhoto.thumbnailSquareUrl(size)
    }

    fun getFullPhotoList(): List<ProductPhoto> {
        val productPhotos = photos.orEmpty().sortedBy { it.sort }
        if (productPhotos.isEmpty()) {
            val parentPhotos = parentProduct?.photos.orEmpty().sortedBy { it.sort }
            return parentPhotos.sortedBy { it.sort }
        }

        return productPhotos
    }

    fun getCategory(): Category? {
        return parentProduct?.category ?: category
    }

    fun getRootCategory(): Category? {
        return if (parentProduct != null) {
            parentProduct.category?.getRootCategory()
        } else {
            category?.getRootCategory()
        }
    }

    fun getLocalizedName(): String = name.localized().uppercase()

    fun getCountry(): Country? {
        return Country.fromShortName(country ?: return null)
    }

    fun getAmountInPack(): String? {
        val amountInPackagePcs = properties?.map { it.propertyValue }
            ?.firstOrNull { it?.property?.key == "amountInPackagePcs" }?.value

        val amountInPackageKg = properties?.map { it.propertyValue }
            ?.firstOrNull { it?.property?.key == "amountInPackageKg" }?.value

        return (amountInPackagePcs ?: amountInPackageKg)?.localizedOrDefault()
    }

    fun getAmountInBox(): String? {
        val amountInBoxPcs = properties?.map { it.propertyValue }
            ?.firstOrNull { it?.property?.key == "amountInBoxPcs" }?.value

        val amountInBoxKg = properties?.map { it.propertyValue }
            ?.firstOrNull { it?.property?.key == "amountInBoxKg" }?.value

        return (amountInBoxPcs ?: amountInBoxKg)?.localizedOrDefault()
    }

    @Transient
    val isAvailable: Boolean
        get() = amountNotEnough != true

    @Transient
    val isNotAvailable: Boolean
        get() = amountNotEnough == true
}