package uz.ferro.shop.pages.admin.marketcategory

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML
import react.dom.onChange
import react.router.useParams
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.manager.ComponentCategoryManager
import uz.ferro.shop.manager.PageContentManager
import uz.ferro.shop.model.Component
import uz.ferro.shop.model.ComponentCategory
import uz.ferro.shop.model.ComponentType
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.pages.admin.category.CategoryPicker
import uz.ferro.shop.util.autoComplete
import uz.ferro.shop.util.noValidate
import uz.ferro.shop.util.orZero
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLInputElement

val AdminMarketCategory = FC<Props> {
    val pathParam = useParams()["componentCategoryId"] ?: return@FC
    val marketCategoryId = pathParam.toIntOrNull()

    val navigator = useContext(NavigationContext)
    var componentCategory by useState(ComponentCategory())
    val alertManager = useContext(AlertManagerContext)

    useEffectOnce {
        MainScope().launch {
            val page = PageContentManager.getGeneralContent()
            val genericComponent = page.findComponent(ComponentType.GENERIC)
            val allCategories = genericComponent?.categories.orEmpty()

            if (marketCategoryId != null) {
                val mc = allCategories.firstOrNull { it.id == marketCategoryId }
                if (mc != null) {
                    componentCategory = mc
                }
            } else {
                val sort = allCategories.maxOfOrNull { it.sort.orZero() }.orZero() + 1
                componentCategory = componentCategory.copy(
                    component = Component(
                        id = genericComponent?.id
                    ),
                    sort = sort)
            }
        }
    }

    Box {
        sx {
            display = Display.grid
            justifyContent = JustifyContent.stretch
            padding = 16.px
            width = 400.px
        }
        component = ReactHTML.form
        noValidate = true
        autoComplete = "off"

        CategoryPicker {
            selectedCategory = componentCategory.category
            onCategorySelect = { cat ->
                componentCategory = componentCategory.copy(category = cat)
            }
        }

        TextField {
            sx {
                marginTop = 16.px
            }
            label = ReactNode("Tartib raqami")
            variant = FormControlVariant.outlined
            value = componentCategory.sort.orZero()
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                componentCategory = componentCategory.copy(sort = event.target.value.toIntOrNull())
            }
        }

        Box {
            sx {
                marginTop = 16.px
                display = Display.flex
                flexDirection = FlexDirection.row
                justifyContent = JustifyContent.end
            }

            if (componentCategory.id != null) {
                Button {
                    sx {
                        marginRight = 16.px
                    }
                    variant = ButtonVariant.contained
                    onClick = {
                        MainScope().launch {
                            ComponentCategoryManager.deleteComponentCategory(componentCategory.id!!)
                            PageContentManager.clearCache()
                            navigator.goBack()
                        }
                    }
                    +"O'chirish"
                }
            }


            Button {
                variant = ButtonVariant.contained
                onClick = {
                    navigator.goBack()
                }
                +"Bekor"
            }

            Button {
                sx {
                    marginLeft = 16.px
                }
                variant = ButtonVariant.contained
                onClick = {
                    if (isValidMarketCategory(componentCategory)) {
                        MainScope().launch {
                            try {
                                ComponentCategoryManager.saveComponentCategory(componentCategory)
                                PageContentManager.clearCache()
                                navigator.goBack()
                            } catch (e: Exception) {
                                alertManager.handleError(e)
                            }
                        }
                    }
                }
                +"Saqlash"
            }
        }
    }
}

private fun isValidMarketCategory(mc: ComponentCategory): Boolean {
    if (mc.category == null) {
        return false
    }

    if (mc.sort == null) {
        return false
    }

    return true
}