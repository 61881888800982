package uz.ferro.shop.manager

import kotlinx.serialization.decodeFromString
import uz.ferro.shop.api.jsonSerializer
import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.model.Category
import web.storage.sessionStorage

object AllDataManager {

    private const val ALL_DATA_ENDPOINT = "data/category-products"
    private const val CACHE_KEY = "all-category-products"

    suspend fun getMainCategories(): List<Category> {
        val data:String = sessionStorage.getItem(CACHE_KEY).orEmpty()
        return if (data.isNotBlank()) {
            jsonSerializer.decodeFromString(data)
        } else {
            val json: String = suspendGet(ALL_DATA_ENDPOINT)
            sessionStorage.setItem(CACHE_KEY, json)
            return jsonSerializer.decodeFromString(json)
        }
    }
}