package uz.ferro.shop.pages.all

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.CircularProgress
import mui.material.Grid
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.manager.AllDataManager
import uz.ferro.shop.model.Category
import uz.ferro.shop.model.Product
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.pages.product.ProductItemView
import uz.ferro.shop.product.ProductComparator
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.util.md
import uz.ferro.shop.util.sm
import uz.ferro.shop.util.styleInMobile
import uz.ferro.shop.util.xs
import web.cssom.AlignContent
import web.cssom.AlignItems
import web.cssom.JustifyContent
import web.cssom.integer
import web.cssom.px

data class SubcategoryData(
    val index: Int,
    val category: Category,
    val products: List<Product>
)

external interface AllSubcategoryProductsProps : Props {
    var category: Category
}

val AllSubcategoryProductsView = FC<AllSubcategoryProductsProps> { props ->
    val category = props.category
    val navigator = useContext(NavigationContext)

    Box {
        sx {
            marginBottom = 48.px
        }

        AppTextBold {
            sx {
                fontSize = styleInMobile(16.px, 20.px)
            }
            onClick = {
                navigator.openCategory(category.id)
            }
            text = category.getLocalizedName()
        }

        Box {
            sx {
                marginTop = 12.px
            }
        }

        Grid {
            container = true
            spacing = responsive(12.px)

            category.products.orEmpty()
                .sortedWith(ProductComparator())
                .forEach { product ->
                    Grid {
                        item = true
                        xs = 6
                        sm = 6
                        md = 3

                        sx {

                        }
                        ProductItemView {
                            this.product = product
                        }
                    }
                }
        }
    }
}

val AllProductsPage = FC<Props> {

    MarketPage(
        collapseMainCatalogByDefault = true,
        fullBlock = {
            var mainCategories by useState<List<Category>>(emptyList())
            var showProgress by useState(false)

            fun loadData() {
                MainScope().launch {
                    showProgress = true
                    try {
                        mainCategories = AllDataManager.getMainCategories()
                    } catch (e: Exception) {
                        // no-op
                    }
                    showProgress = false
                }
            }

            useEffectOnce {
                loadData()
            }

            if (mainCategories.isNotEmpty()) {
                mainCategories.forEach { mainCategory ->
                    Box {
                        sx {
                            marginTop = 10.px
                            color = Colors.textPrimary
                            fontSize = styleInMobile(25.px, 31.px)
                            fontWeight = integer(700)
                        }
                        +mainCategory.getLocalizedName()
                    }

                    Box {
                        sx {
                            height = 16.px
                        }
                    }

                    val subCategories = mainCategory.subCategories.orEmpty().sortedBy {
                        it.getLocalizedName()
                    }.filter { it.products.orEmpty().isNotEmpty() }

                    subCategories.forEach { subCategory ->
                        AllSubcategoryProductsView {
                            category = subCategory
                        }
                    }
                }
            } else {
                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        minHeight = 200.px
                        color = Colors.label
                        alignContent = AlignContent.center
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                    }

                    if (showProgress) {
                        CircularProgress()
                    }
                }
            }
        }
    ).invoke()
}