package uz.ferro.shop.pages.admin.category

import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.Stack
import mui.material.StackDirection
import mui.material.TextField
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.PropsWithChildren
import react.ReactNode
import react.StateSetter
import react.createContext
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.option
import react.dom.html.ReactHTML.select
import react.dom.onChange
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.api.useAllCategories
import uz.ferro.shop.model.Category
import uz.ferro.shop.model.DictItem
import uz.ferro.shop.pages.admin.dict.DictItemEditor
import uz.ferro.shop.pages.admin.file.PhotoAttacher
import uz.ferro.shop.pages.category.CategoryManager
import uz.ferro.shop.util.localized
import web.cssom.AlignItems
import web.cssom.BackgroundColor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.LineStyle
import web.cssom.None
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.px
import web.html.HTMLInputElement

private val SelectedCategoryContext = createContext<Category>()
private val SetSelectedCategoryContext = createContext<StateSetter<Category?>>()

val SelectedCategoryModule = FC<PropsWithChildren> { props ->
    val (currentCategory, setCurrentCategory) = useState<Category>()
    SelectedCategoryContext(currentCategory) {
        SetSelectedCategoryContext(setCurrentCategory) {
            +props.children
        }
    }
}

external interface CategoryItemProps : Props {
    var category: Category
    var onCategorySelect: (Category) -> Unit
}

external interface CategoryEditProps : Props {
    var onDataUpdated: () -> Unit
    var parentCategories: List<Category>
}

val AdminCategory = FC<Props> {
    var rootCategories by useState<List<Category>>(emptyList())

    fun updateData() {
        MainScope().launch {
            rootCategories = CategoryManager.getRootCategories()
        }
    }

    useEffectOnce {
        updateData()
    }

    SelectedCategoryModule {
        FC<Props> {
            val currentCategory = useContext(SelectedCategoryContext)

            div {
                css {
                    padding = 10.px
                    position = Position.absolute
                    top = 0.px
                    bottom = 0.px
                    left = 0.px
                    right = 0.px
                    display = Display.flex
                    flexDirection = FlexDirection.row
                }

                CategoriesBlock()

                div {
                    css {
                        marginLeft = 10.px
                        width = 400.px
                        borderLeftWidth = 1.px
                        borderLeftStyle = LineStyle.solid
                        borderLeftColor = Colors.divider
                    }

                    if (currentCategory != null) {
                        CategoryEditor {
                            parentCategories = rootCategories
                            onDataUpdated = {
                                updateData()
                            }
                        }
                    }
                }
            }
        }()
    }
}

private val CategoriesBlock = FC<Props> {
    val setSelectedCategory = useContext(SetSelectedCategoryContext)
    div {
        css {
            width = 400.px
            padding = 10.px
            display = Display.flex
            flexDirection = FlexDirection.column
        }

        div {
            css {
                borderBottomColor = Colors.divider
                borderBottomStyle = LineStyle.solid
                borderBlockWidth = 1.px
                paddingBottom = 10.px
            }
            button {
                onClick = {
                    setSelectedCategory!!.invoke(
                        Category(
                            id = null,
                            name = null
                        )
                    )
                }
                +"Qo'shish"
            }
        }

        div {
            css {
                overflowY = Overflow.scroll
                marginTop = 10.px
            }

            CategoryMenu()
        }
    }
}

private val CategoryEditor = FC<CategoryEditProps> { props ->
    val category = useContext(SelectedCategoryContext)
    val setCategory = useContext(SetSelectedCategoryContext)

    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(16.px)
        sx {
            padding = 20.px
        }

        if (category?.id != null) {
            Box {
                +"ID: ${category.id}"
            }
        }

        DictItemEditor {
            title = "Nomi"
            item = category?.name
            onChange = {
                setCategory?.invoke(category?.copy(name = it))
            }
        }

        div {
            css {
                marginTop = 20.px
            }
            +"Bo‘lim"
        }

        div {
            select {
                css {
                    width = 400.px
                }
                val optionCategories = listOf(
                    Category(
                        id = null,
                        name = DictItem(
                            textUzLat = "Yo‘q",
                            textUzCyr = "Йўқ",
                            textRu = "Нет"
                        )
                    )
                ) + props.parentCategories.toList()

                value = category?.parent?.id ?: 0

                onChange = { event ->
                    val parent = props.parentCategories.firstOrNull { it.id == event.target.value.toIntOrNull() }
                    setCategory?.invoke(category?.copy(parent = parent))
                }

                optionCategories.forEach { parent ->
                    option {
                        value = parent.id
                        +parent.name.localized()
                    }
                }
            }
        }

        Box {
            Box {
                +"Ikonka"
            }

            PhotoAttacher {
                dataFile = category?.icon
                onFileUpload = { dFile ->
                    setCategory?.invoke(category?.copy(icon = dFile))
                }
                onDelete = {
                    setCategory?.invoke(category?.copy(icon = null))
                }
            }
        }


        Box {
            Box {
                +"Rasm"
            }

            PhotoAttacher {
                dataFile = category?.photo
                onFileUpload = { dFile ->
                    setCategory?.invoke(category?.copy(photo = dFile))
                }
                onDelete = {
                    setCategory?.invoke(category?.copy(photo = null))
                }
            }
        }

        Button {
            variant = ButtonVariant.outlined
            sx {
                textTransform = None.none
            }
            onClick = {
                val cat: Category? = category
                if (cat != null) {
                    MainScope().launch {
                        val updatedCat = CategoryManager.saveCategory(cat)
                        setCategory?.invoke(updatedCat)
                        props.onDataUpdated()
                    }
                }
            }

            +"Saqlash"
        }
    }
}

private val CategoryMenu = FC<Props> {
    val categories = useAllCategories()
    val setSelectedCategory = useContext(SetSelectedCategoryContext)
    div {
        css {
            display = Display.flex
            flexDirection = FlexDirection.column
        }
        categories.forEach { cat ->
            CategoryItem {
                category = cat
                onCategorySelect = {
                    setSelectedCategory!!.invoke(it.copy())
                }
            }
        }
    }
}

private val CategoryItem = FC<CategoryItemProps> { props ->
    div {
        var isHover: Boolean by useState(false)
        var isDown: Boolean by useState(false)

        val bg: BackgroundColor? = when {
            isDown -> Colors.adminItemPressed
            isHover -> Colors.adminItemHover
            else -> null
        }

        css {
            paddingLeft = 20.px
            paddingRight = 20.px
            alignItems = AlignItems.center
            listStyleType = None.none
            paddingTop = 14.px
            paddingBottom = 14.px
            fontSize = 18.px
            backgroundColor = bg
        }
        onMouseOver = {
            isHover = true
        }

        onMouseOut = {
            isHover = false
            isDown = false
        }

        onClick = {
            props.onCategorySelect.invoke(props.category)
        }

        onMouseDown = {
            isDown = true
        }

        onMouseUp = {
            isDown = false
        }

        div {
            css {

            }
            +props.category.name.localized()
        }
    }
}

