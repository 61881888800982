package uz.ferro.shop.pages.search

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Breadcrumbs
import mui.material.Grid
import mui.material.Link
import mui.material.LinkUnderline
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.router.dom.useSearchParams
import react.useContext
import react.useEffect
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.icon.ChevronRightIcon16
import uz.ferro.shop.model.Product
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.pages.product.ProductItemView
import uz.ferro.shop.search.SearchManager
import uz.ferro.shop.ui.AppTextBoldLarge
import uz.ferro.shop.util.md
import uz.ferro.shop.util.sm
import uz.ferro.shop.util.styleInMobile
import uz.ferro.shop.util.xs
import web.cssom.px

val SearchPage = FC<Props> {
    val locale = useContext(LocaleContext)
    val queryParams = useSearchParams()
    val query: String = queryParams.component1()["q"].orEmpty()

    MarketPage(
        collapseMainCatalogByDefault = true,
        centerBlock = {
            Box {
                sx {
                    marginLeft = styleInMobile(10.px, 10.px)
                }

                Breadcrumbs {
                    sx {
                        fontSize = 13.px
                    }

                    separator = ChevronRightIcon16.create()
                    Link {
                        color = Colors.textSecondary
                        underline = LinkUnderline.hover
                        key = "1"
                        href = "/"
                        onClick = {
                        }
                        +locale.main
                    }

                    Box {
                        sx {
                            color = Colors.textSecondary
                        }
                        +locale.search
                    }
                }

                AppTextBoldLarge {
                    sx {
                        marginTop = 10.px
                    }
                    text = locale.search
                }

            }
        },
        fullBlock = {
            var searchResult by useState(listOf<Product>())

            useEffect(query) {
                MainScope().launch {
                    searchResult = SearchManager.productSearch(query)
                }
            }

            if (searchResult.isNotEmpty()) {
                Grid {
                    sx {
                    }
                    container = true
                    spacing = responsive(12.px)

                    searchResult.forEach { product ->
                        Grid {
                            item = true
                            xs = 6
                            sm = 6
                            md = 3

                            sx {

                            }
                            ProductItemView {
                                this.product = product
                            }
                        }
                    }
                }
            }
        }
    ).invoke()
}