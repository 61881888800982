package uz.ferro.shop.model

import kotlinx.browser.localStorage
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import org.w3c.dom.get
import org.w3c.dom.set
import uz.ferro.shop.KEY_CART
import uz.ferro.shop.api.jsonSerializer
import uz.ferro.shop.manager.ProductManager
import uz.ferro.shop.util.generateUUID

object CartManager {

    var cartIdSeq: Long = 100000L
        private set

    var cart: Cart
        private set

    init {
        val json = localStorage[KEY_CART].orEmpty()
        cart = try {
            jsonSerializer.decodeFromString(json)
        } catch (e: Exception) {
            Cart()
        }

        if (cart.uuid.orEmpty().isBlank()) {
            cart.uuid = generateUUID()
        }
    }

    suspend fun getCartWithSync(): Cart {
        val productIdList = cart.products.mapNotNull { it.product.id }
        if (productIdList.isEmpty()) {
            return cart
        }

        val productsMap = ProductManager.getProductsByIdList(productIdList)
            .associateBy { it.id!! }

        val cartProducts = mutableListOf<CartProduct>()

        cart.products.forEach { cp ->
            val product = productsMap[cp.product.id]
            if (product != null) {
                cartProducts.add(
                    cp.copy(
                        product = product
                    )
                )
            }
        }

        cart = cart.copy(products = cartProducts)
        return cart
    }

    private fun saveCart() {
        localStorage[KEY_CART] = jsonSerializer.encodeToString(cart)
    }

    fun addProduct(product: Product, quantity: Int = 1): CartProduct {
        var cartProduct = getProduct(product.id!!)

        if (cartProduct == null) {
            cartProduct = CartProduct(
                id = ++cartIdSeq,
                product = product,
                quantity = quantity
            )
            cart.products.add(cartProduct)
        } else {
            cartProduct.quantity += quantity
        }

        saveCart()
        return cartProduct
    }

    fun getProduct(productId: Long): CartProduct? {
        return cart.products.firstOrNull { it.product.id == productId }
    }

    fun removeProduct(productId: Long) {
        val cartProduct = getProduct(productId)
        if (cartProduct != null) {
            cart.products.remove(cartProduct)
        }
        saveCart()
    }

    fun removeProducts(idList: List<Long>) {
        cart.products.removeAll {
            it.product.id in idList
        }
        saveCart()
    }

    fun changeQuantity(productId: Long, quantity: Int) {
        if (quantity <= 0) return

        val cartProduct = getProduct(productId)
        if (cartProduct != null) {
            cartProduct.quantity = quantity
            saveCart()
        }
    }

    fun clear() {
        cart = Cart(uuid = generateUUID())
        saveCart()
    }
}