package uz.ferro.shop.ui.footer

import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import uz.ferro.shop.Const
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.FooterCallIcon
import uz.ferro.shop.icon.FooterEmailIcon
import uz.ferro.shop.icon.FooterFacebookIcon
import uz.ferro.shop.icon.FooterInstagramIcon
import uz.ferro.shop.icon.FooterLocationIcon
import uz.ferro.shop.icon.FooterTelegramIcon
import uz.ferro.shop.locale.Language
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.manager.PageContentManager
import uz.ferro.shop.model.Category
import uz.ferro.shop.model.ComponentType
import uz.ferro.shop.navigator.NavigationPage
import uz.ferro.shop.pages.MAX_PAGE_AREA
import uz.ferro.shop.ui.AppTextMediumSmall
import uz.ferro.shop.util.styleInMobile
import web.cssom.AlignItems
import web.cssom.Auto
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.Globals
import web.cssom.JustifyContent
import web.cssom.integer
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.window.WindowTarget
import kotlin.js.Date

val Footer = FC<Props> {
    val locale = useContext(LocaleContext)
    val language = LanguageManager.currentLanguage
    val navigator = useContext(NavigationContext)
    var categories by useState(listOf<Category>())
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()

    fun loadCategories() {
        MainScope().launch {
            categories = PageContentManager.getGeneralContent()
                .findComponent(ComponentType.FOOTER)?.categories.orEmpty()
                .mapNotNull { it.category }
        }
    }

    useEffectOnce {
        loadCategories()
    }

    Box {
        sx {
            display = Display.block
            width = 100.pct
            backgroundColor = Colors.footerBackground
            color = Colors.footerContrastColor
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(16.px)

            sx {
                maxWidth = MAX_PAGE_AREA
                paddingTop = 24.px
                paddingBottom = 24.px
                marginLeft = Auto.auto
                marginRight = Auto.auto
                justifyContent = JustifyContent.spaceBetween
                paddingLeft = 16.px
                paddingRight = 16.px
            }

            Stack {
                direction = responsive(StackDirection.column)
                sx {
                    flexGrow = number(1.0)
                    width = 0.px
                }

                img {
                    src = "./logo_ferro_white.svg"
                    css {
                        height = 37.px
                        width = 131.px
                        cursor = Cursor.pointer
                    }
                    onClick = {
                        navigator.openMain()
                    }
                }

                Box {
                    sx {
                        fontSize = responsive(
                            Breakpoint.xs to 11.px,
                            Breakpoint.md to 16.px
                        )
                        fontWeight = integer(600)
                        color = Colors.footerContrastColor
                        marginTop = 16.px
                    }
                    +locale.inSocial
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(24.px)
                    sx {
                        marginTop = 12.px
                    }

                    FooterTelegramIcon {

                        clickAction = {
                            navigator.openUrl(Const.TELEGRAM)
                        }
                    }

                    FooterInstagramIcon {
                        clickAction = {
                            navigator.openUrl(Const.INSTAGRAM)
                        }
                    }

                    FooterFacebookIcon {
                        clickAction = {
                            navigator.openUrl(Const.FACEBOOK)
                        }
                    }
                }

                if (isMobile) {
                    Box {
                        sx {
                            fontSize = 11.px
                            fontWeight = integer(600)
                            cursor = Cursor.pointer
                            marginTop = 20.px
                        }
                        onClick = {
                            navigator.openPage(NavigationPage.ABOUT_COMPANY)
                            window.scroll(0.0, 0.0)
                        }
                        +locale.aboutCompany
                    }
                }
            }

            if (isMobile.not()) {
                Stack {
                    direction = responsive(StackDirection.column)
                    sx {
                        flexGrow = number(1.0)
                        width = 0.px
                    }
                    Box {
                        sx {
                            fontSize = 16.px
                            fontWeight = integer(600)
                        }
                        +locale.categories
                    }

                    Box {
                        sx {
                            height = 4.px
                        }
                    }

                    categories.forEach { category ->
                        Box {
                            sx {
                                fontSize = 13.px
                                fontWeight = integer(500)
                                marginTop = 12.px
                                cursor = Cursor.pointer
                            }
                            onClick = {
                                navigator.openCategory(category.id!!)
                                window.scroll(0.0, 0.0)
                            }
                            +category.getLocalizedName()
                        }
                    }
                }
            }

            if (isMobile.not()) {
                Stack {
                    direction = responsive(StackDirection.column)
                    sx {
                        flexGrow = number(1.0)
                        width = 0.px
                    }
                    Box {
                        sx {
                            fontSize = 16.px
                            fontWeight = integer(600)
                            cursor = Cursor.pointer
                        }
                        onClick = {
                            navigator.openPage(NavigationPage.ABOUT_COMPANY)
                            window.scroll(0.0, 0.0)
                        }
                        +locale.aboutCompany
                    }

//                    Box {
//                        sx {
//                            fontSize = 13.px
//                            fontWeight = integer(500)
//                            marginTop = 16.px
//                            cursor = Cursor.pointer
//                        }
//                        +locale.privacyPolicy
//                    }
//
//                    Box {
//                        sx {
//                            fontSize = 13.px
//                            fontWeight = integer(500)
//                            marginTop = 12.px
//                            cursor = Cursor.pointer
//                        }
//                        +locale.publicOffer
//                    }
                }
            }

            val contactIconSize = if (isMobile) 24 else 32
            val contactTextSize = if (isMobile) 11 else 13

            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(12.px)
                sx {
                    flexGrow = number(1.0)
                    width = 0.px
                }
                Box {
                    sx {
                        fontSize = 16.px
                        fontWeight = integer(600)
                    }
                    +locale.contacts
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(8.px)
                    sx {
                        alignItems = AlignItems.center
                    }
                    FooterEmailIcon {
                        size = contactIconSize
                    }
                    Link {
                        sx {
                            fontSize = contactTextSize.px
                            fontWeight = integer(700)
                            color = Colors.footerContrastColor
                        }
                        underline = LinkUnderline.hover
                        href = "mailto: ${Const.INFO_EMAIL}"
                        +Const.INFO_EMAIL
                    }
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(8.px)
                    sx {
                        alignItems = AlignItems.center
                    }
                    FooterCallIcon {
                        size = contactIconSize
                    }
                    Stack {
                        direction = responsive(
                            Breakpoint.xs to StackDirection.column,
                            Breakpoint.md to StackDirection.row
                        )
                        spacing = responsive(
                            Breakpoint.xs to 0.px,
                            Breakpoint.md to 8.px
                        )

                        /*
                        Link {
                            sx {
                                fontSize = contactTextSize.px
                                fontWeight = integer(700)
                                color = Colors.footerContrastColor
                            }
                            underline = LinkUnderline.hover
                            href = "tel:${Const.ORDER_PHONE}"
                            +Const.ORDER_PHONE
                        }
                        */

                        Link {
                            sx {
                                fontSize = contactTextSize.px
                                fontWeight = integer(700)
                                color = Colors.footerContrastColor
                            }
                            underline = LinkUnderline.hover
                            href = "tel: ${Const.TASHKENT_DELIVERY_PHONE}"
                            +Const.TASHKENT_DELIVERY_PHONE
                        }
                    }
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(8.px)
                    sx {
                        alignItems = AlignItems.center
                    }
                    FooterLocationIcon {
                        size = contactIconSize
                    }
                    Box {
                        sx {
                            fontSize = contactTextSize.px
                            fontWeight = integer(700)
                        }
                        Link {
                            sx {
                                color = Globals.inherit
                            }
                            underline = LinkUnderline.hover
                            href = Const.STROY_GOROD_GEO
                            target = WindowTarget._blank
                            rel = "noreferrer"
                            +(when (language) {
                                Language.RU -> Const.LOCATION_TASHKENT_RU
                                Language.UZ_CYR -> Const.LOCATION_TASHKENT_UZ_CYR
                                else -> Const.LOCATION_TASHKENT_UZ
                            })
                        }
                    }
                }
            }
        }

        AppTextMediumSmall {
            sx {
                display = Display.block
                maxWidth = MAX_PAGE_AREA
                marginLeft = Auto.auto
                marginRight = Auto.auto
                paddingLeft = 16.px
                paddingRight = 16.px
                paddingBottom = 24.px
            }
            color = Colors.footerContrastColor
            text = locale.footerPriceDescription.replace("{{phone}}", Const.TASHKENT_DELIVERY_PHONE)
        }
    }

    Box {
        sx {
            display = Display.block
            width = 100.pct
            backgroundColor = Colors.accentColor
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                maxWidth = MAX_PAGE_AREA
                height = 40.px
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
                marginLeft = Auto.auto
                marginRight = Auto.auto
                paddingLeft = 16.px
                paddingRight = 16.px
            }

            Box {
                sx {
                    fontSize = styleInMobile(10.px, 13.px)
                    fontWeight = integer(500)
                    color = Colors.accentContrastColor
                }

                val year = Date().getFullYear()

                +"© 2002-$year FERRO GROUP"
            }

            Box {
                sx {
                    fontSize = styleInMobile(10.px, 13.px)
                    fontWeight = integer(500)
                    color = Colors.accentContrastColor
                }

                +locale.allRightsReserved
            }
        }
    }
}