package uz.ferro.shop.ui.productgroup

import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.material.useMediaQuery
import mui.system.Breakpoint
import mui.system.Theme
import mui.system.responsive
import mui.system.sx
import mui.system.useTheme
import react.FC
import react.Props
import react.useEffectOnce
import react.useRef
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.model.ProductGroup
import uz.ferro.shop.pages.product.ProductItemView
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.util.CSS_CLASS_NO_SCROLLBAR
import uz.ferro.shop.util.CSS_CLASS_SNAP_X
import uz.ferro.shop.util.styleInMobile
import web.cssom.AlignItems
import web.cssom.Auto
import web.cssom.ClassName
import web.cssom.JustifyContent
import web.cssom.ScrollBehavior
import web.cssom.ScrollSnapAlign
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.html.HTMLDivElement

external interface ProductGroupViewProps : Props {
    var productGroup: ProductGroup
}

val ProductGroupView = FC<ProductGroupViewProps> { props ->
    val products = props.productGroup.products.orEmpty().mapNotNull { it.product }
    val language = LanguageManager.currentLanguage
    val theme: Theme = useTheme()
    val isLg = useMediaQuery(theme.breakpoints.up(Breakpoint.lg))
    val isMd = useMediaQuery(theme.breakpoints.up(Breakpoint.md))
    var currentChunkIndex by useState(0)

    var tempIndex = 0

    val columns = when {
        isLg -> 4
        isMd -> 3
        else -> 2
    }

    val groupedByColumn = products.chunked(columns)

    val containerRef = useRef<HTMLDivElement>(null)

    useEffectOnce {
        MainScope().launch {
            val size = groupedByColumn.size
            if (size > 0) {
                while (true) {
                    delay(3000)
                    val parent = containerRef.current ?: continue
                    tempIndex = (tempIndex + 1) % size
                    currentChunkIndex = tempIndex
                    val child = containerRef.current?.children
                    if (child != null && child.length > tempIndex) {
                        val el = child[tempIndex] as HTMLDivElement
                        val scrollX = el.offsetLeft - parent.offsetLeft
                        parent.scrollTo(scrollX, 0)
                    }
                }
            }
        }
    }

    Box {

        AppTextBold {
            sx {
                width = 100.pct
                fontSize = responsive(
                    Breakpoint.xs to 16.px,
                    Breakpoint.md to 25.px
                )
            }
            text = props.productGroup.getLocalizedName()
        }

        val additionalEmptyBlocks: Int = (columns - products.size % columns) % columns

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(
                Breakpoint.xs to 8.px,
                Breakpoint.md to 16.px
            )
            sx {
                width = 100.pct
                scrollBehavior = ScrollBehavior.smooth
                overflow = Auto.auto
                marginTop = responsive(
                    Breakpoint.xs to 12.px,
                    Breakpoint.md to 16.px
                )
            }

            css(
                ClassName(CSS_CLASS_NO_SCROLLBAR),
                ClassName(CSS_CLASS_SNAP_X),
            ) {}

            ref = containerRef

            groupedByColumn.forEachIndexed { index, chunkedProducts ->
                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = styleInMobile(8.px, 16.px)
                    sx {
                        flexGrow = number(1.0)
                        flexShrink = number(0.0)
                        scrollSnapAlign = ScrollSnapAlign.start
                        width = 100.pct
                        if (index > 0) {
                            marginLeft = styleInMobile(8.px, 16.px)
                        }
                    }

                    chunkedProducts.forEach { product ->
                        Box {
                            sx {
                                width = 0.px
                                flexShrink = number(0.0)
                                flexGrow = number(1.0)
                            }
                            ProductItemView {
                                this.product = product
                            }
                        }
                    }

                    if (index == groupedByColumn.lastIndex) {
                        (0..<additionalEmptyBlocks).forEach {
                            Box {
                                sx {
                                    flexGrow = number(1.0)
                                    flexShrink = number(0.0)
                                    width = 0.px
                                }
                            }
                        }
                    }
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(8.px)

            sx {
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                marginTop = 8.px
            }

            val maxItems = 10
            val size = groupedByColumn.size.coerceAtMost(maxItems)
            (0..<size).forEach {
                Box {
                    sx {
                        width = 20.px
                        height = 4.px
                        borderRadius = 2.px
                        backgroundColor = if (it == currentChunkIndex) {
                            Colors.accentColor
                        } else {
                            Colors.borderPrimary
                        }
                    }
                }
            }
        }
    }
}