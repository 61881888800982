package uz.ferro.shop.pages.admin.order

import emotion.react.css
import mui.material.Paper
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableHead
import mui.material.TableRow
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useContext
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.model.PHOTO_SIZE_SMALL
import uz.ferro.shop.util.formatPrice
import uz.ferro.shop.util.formatPriceWithQuantity
import uz.ferro.shop.util.orZero
import uz.ferro.shop.util.withSuffix
import web.cssom.ObjectFit
import web.cssom.px

external interface OrderProductListProps : Props {
}

val OrderProductList = FC<OrderProductListProps> {
    val order = useContext(OrderContext)
    val locale = useContext(LocaleContext)
    val language = LanguageManager.currentLanguage

    Paper {
        sx {
            marginTop = 8.px
        }

        Table {
            TableHead {
                TableRow {
                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +""
                    }

                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +"Kod"
                    }
                    TableCell {
                        +"Nomi"
                    }
                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +"Chegirma"
                    }
                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +"Birlik narxi"
                    }
                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +"Miqdori"
                    }

                    TableCell {
                        sx {
                            width = 120.px
                        }
                        +"Narxi"
                    }
                }
            }
            TableBody {
                order.products.forEach { orderProduct ->
                    val product = orderProduct.product
                    TableRow {
                        hover = true
                        key = orderProduct.id.toString()

                        TableCell {
                            ReactHTML.img {
                                css {
                                    width = 128.px
                                    height = 128.px
                                    objectFit = ObjectFit.contain
                                }

                                val photoUrl = product.getPrimaryPhotoUrl(PHOTO_SIZE_SMALL)

                                if (photoUrl.isNotBlank()) {
                                    src = photoUrl
                                }
                            }
                        }

                        TableCell {
                            +product.externalId
                        }

                        TableCell {
                            +product.getLocalizedName()
                        }

                        TableCell {
                            if (orderProduct.discountPercent.orZero() > 0) {
                                +"-${orderProduct.discountPercent}%"
                            }
                        }

                        TableCell {
                            +orderProduct.formatPrice()
                        }

                        TableCell {
                            +orderProduct.quantity.withSuffix(product.unit?.getLocalizedName())
                        }

                        TableCell {
                            +orderProduct.formatPriceWithQuantity(orderProduct.quantity)
                        }
                    }
                }
            }
        }
    }
}