package uz.ferro.shop.pages.admin.document

import kotlinx.serialization.Serializable
import kotlinx.serialization.encodeToString
import uz.ferro.shop.api.jsonSerializer
import uz.ferro.shop.manager.DocumentManager
import uz.ferro.shop.manager.ProductManager
import uz.ferro.shop.model.Document
import uz.ferro.shop.model.DocumentType
import uz.ferro.shop.pages.category.CategoryManager

object PriceListGenerator {

    private const val PAGE_LINK_PREFIX = "https://ferro.uz/#/document-page"
    private const val PRODUCT_LINK_PREFIX = "https://ferro.uz/#/product"

    suspend fun generate(): String {
        val group = AccessKeyManager.createGroup()
        val items = mutableListOf<Item>()
        listOf(
            1005271,
            1005269,
            1000058
        ).forEach { categoryId ->
            val category = CategoryManager.getCategoryById(categoryId)
            val subCategories = CategoryManager.getSubCategories(categoryId)
                .sortedBy { it.name?.textUzCyr.orEmpty() }

            val subItems = mutableListOf<Item>()

            subCategories.forEach { subCat ->
                subItems.add(
                    Item(
                        type = "H4",
                        text = subCat.name?.textUzCyr.orEmpty().uppercase()
                    )
                )
                val products = ProductManager.getProductsByCategory(subCat.id!!)
                    .sortedBy { it.name?.textUzCyr.orEmpty() }
                val keys = AccessKeyManager.generate(group.id!!, products.map { it.id!! })
                products.forEach { product ->
                    val access = keys.first { it.targetId == product.id }
                    val link = "$PRODUCT_LINK_PREFIX/${product.id}?key=${access.key}"
                    subItems.add(
                        getLink(title = product.name?.textUzCyr.orEmpty(), href = link)
                    )
                }
            }

            val subPage = Page(
                title = category.name?.textUzCyr.orEmpty().uppercase(),
                items = subItems
            )

            val subDocument = Document(
                title = category.name?.textUzCyr.orEmpty(),
                type = DocumentType.JSON,
                content = jsonSerializer.encodeToString(subPage)
            )

            val subSaved = DocumentManager.saveDocument(subDocument)
            val doc = DocumentManager.saveDocument(subSaved)
            val href = "$PAGE_LINK_PREFIX/${doc.id}"
            items.add(getLink(title = category.name?.textUzCyr.orEmpty(), href = href))
        }

        val page = Page(
            title = "Маҳсулотлар",
            items = items
        )

        val allDoc = Document(
            title = "Маҳсулотлар",
            type = DocumentType.JSON,
            content = jsonSerializer.encodeToString(page)
        )

        val allDocSaved = DocumentManager.saveDocument(allDoc)

        return "$PAGE_LINK_PREFIX/${allDocSaved.id}"
    }
}

private fun getLink(title: String?, href: String): Item {
    return Item(
        type = "P",
        children = listOf(
            Item(
                type = "A",
                href = href,
                text = title.orEmpty()
            )
        )
    )
}

@Serializable
data class Page(
    val title: String = "",
    val items: List<Item> = emptyList()
)

@Serializable
data class Item(
    val type: String,
    val href: String? = null,
    val src: String? = null,
    val text: String? = null,
    val children: List<Item>? = null
)

