package uz.ferro.shop.pages.admin.home

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.icons.material.Add
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.FormControl
import mui.material.FormControlVariant
import mui.material.InputLabel
import mui.material.List
import mui.material.ListItemButton
import mui.material.ListItemText
import mui.material.MenuItem
import mui.material.Select
import mui.material.TextField
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.api.deletePageContentProductGroup
import uz.ferro.shop.api.savePageContentProductGroup
import uz.ferro.shop.api.useAllProductGroups
import uz.ferro.shop.manager.PageContentManager
import uz.ferro.shop.model.Component
import uz.ferro.shop.model.ComponentCategory
import uz.ferro.shop.model.ComponentType
import uz.ferro.shop.model.PageContent
import uz.ferro.shop.model.PageContentProductGroup
import uz.ferro.shop.model.ProductGroup
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.px
import web.html.HTMLInputElement

val AdminHomePage = FC<Props> {

    var mainCategories by useState<List<ComponentCategory>>(emptyList())
    var footerCategories by useState<List<ComponentCategory>>(emptyList())

    var homePageContent by useState(PageContent())
    val navigator = useContext(NavigationContext)
    var genericComponentState by useState<Component>()
    var footerComponentState by useState<Component>()
    val alertManager = useContext(AlertManagerContext)


    fun refreshData() {
        MainScope().launch {
            try {
                homePageContent = PageContentManager.getHomePageContent()
                val generalPage = PageContentManager.getGeneralContent()
                val component = generalPage.findComponent(ComponentType.GENERIC)
                val footerComponent = generalPage.findComponent(ComponentType.FOOTER)
                genericComponentState = component
                mainCategories = component?.categories.orEmpty()
                footerCategories = footerComponent?.categories.orEmpty()
                footerComponentState = footerComponent
            } catch (e: Exception) {
                alertManager.handleError(e)
            }
        }
    }

    useEffectOnce {
        refreshData()
    }

    var showEditProductGroupDialog by useState(false)
    var selectedPageContentProductGroup by useState<PageContentProductGroup>()
    var dialogSelectedProductGroup by useState<ProductGroup>()
    var dialogInputSort: Int? by useState(0)
    var allProductGroups = useAllProductGroups()

    Box {
        sx {
            padding = 16.px
            position = Position.absolute
            top = 0.px
            right = 0.px
            bottom = 0.px
            left = 0.px
            overflow = Overflow.scroll
        }

        Box {
            sx {

            }

            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                }

                InputLabel {
                    +"Kategoriyalar"
                }

                Button {
                    sx {
                        marginLeft = 200.px
                    }
                    variant = ButtonVariant.contained
                    Add()
                    onClick = {
                        genericComponentState?.id?.also {
                            navigator.openAdminNewComponentCategory(it)
                        }
                    }
                }
            }

            List {
                mainCategories.forEach { mc ->
                    ListItemButton {
                        ListItemText {
                            +mc.category?.name.localized()
                        }
                        onClick = {
                            genericComponentState?.id?.also {
                                navigator.openAdminEditComponentCategory(
                                    componentId = it,
                                    componentCategoryId = mc.id!!
                                )
                            }
                        }
                    }
                }
            }

            Box {
                sx {
                    display = Display.flex
                    flexDirection = FlexDirection.row
                }

                InputLabel {
                    +"Footer kategoriyalar"
                }

                Button {
                    sx {
                        marginLeft = 200.px
                    }
                    variant = ButtonVariant.contained
                    Add()
                    onClick = {
                        footerComponentState?.id?.also {
                            navigator.openAdminNewComponentCategory(it)
                        }
                    }
                }
            }

            List {
                footerCategories.forEach { mc ->
                    ListItemButton {
                        ListItemText {
                            +mc.category?.name.localized()
                        }
                        onClick = {
                            footerComponentState?.id?.also {
                                navigator.openAdminEditComponentCategory(it, mc.id!!)
                            }
                        }
                    }
                }
            }

            Dialog {
                fullWidth = true
                open = showEditProductGroupDialog
                onClose = { _, _ -> showEditProductGroupDialog = false }
                DialogTitle {
                    +"Maxsulotlar guruhi"
                }
                DialogContent {
                    val title = "Guruh"
                    FormControl {
                        sx {
                            marginTop = 16.px
                        }
                        fullWidth = true

                        if (selectedPageContentProductGroup == null) {
                            InputLabel {
                                +title
                            }
                            Select {
                                value = dialogSelectedProductGroup?.id.orZero()
                                label = ReactNode(title)
                                onChange = { evt, _ ->
                                    val id = evt.target.value
                                    val pg = allProductGroups.firstOrNull { it.id.toString() == id }
                                    dialogSelectedProductGroup = pg
                                }

                                MenuItem {
                                    value = 0
                                    +"--"
                                }
                                allProductGroups.forEach {
                                    MenuItem {
                                        value = it.id.toString()
                                        +it.name.localized()
                                    }
                                }
                            }
                        } else {
                            Typography {
                                +selectedPageContentProductGroup?.productGroup?.name.localized()
                            }
                        }

                        TextField {
                            sx {
                                marginTop = 16.px
                            }
                            label = ReactNode("Tartib raqami")
                            variant = FormControlVariant.outlined
                            value = dialogInputSort?.toString().orEmpty()
                            onChange = { event ->
                                event as ChangeEvent<HTMLInputElement>
                                val sort = event.target.value.toIntOrNull()
                                dialogInputSort = sort
                            }
                        }
                    }

                    DialogActions {
                        if (selectedPageContentProductGroup != null) {
                            Button {
                                onClick = {
                                    deletePageContentProductGroup(selectedPageContentProductGroup!!.id!!)
                                        .then {
                                            refreshData()
                                            showEditProductGroupDialog = false
                                        }
                                }
                                +"O'chirish"
                            }
                        }

                        Button {
                            onClick = { showEditProductGroupDialog = false }
                            +"Bekor"
                        }
                        Button {
                            onClick = {
                                if (selectedPageContentProductGroup != null) {
                                    val item = selectedPageContentProductGroup!!.copy(
                                        sort = dialogInputSort.orZero()
                                    )

                                    savePageContentProductGroup(item)
                                        .then {
                                            refreshData()
                                            showEditProductGroupDialog = false
                                        }
                                } else {
                                    if (dialogSelectedProductGroup != null) {
                                        val newItem = PageContentProductGroup(
                                            pageContent = PageContent(
                                                id = homePageContent.id
                                            ),
                                            productGroup = dialogSelectedProductGroup,
                                            sort = dialogInputSort.orZero()
                                        )
                                        savePageContentProductGroup(newItem).then {
                                            refreshData()
                                            showEditProductGroupDialog = false
                                        }
                                    }
                                }
                            }
                            +"Saqlash"
                        }
                    }
                }
            }

            Box {
                sx {
                    marginTop = 24.px
                    display = Display.flex
                    flexDirection = FlexDirection.row
                }

                InputLabel {
                    +"Tovar guruhlari"
                }

                Button {
                    sx {
                        marginLeft = 200.px
                    }
                    variant = ButtonVariant.contained
                    Add()
                    onClick = {
                        selectedPageContentProductGroup = null
                        dialogSelectedProductGroup = null
                        dialogInputSort = homePageContent.productGroups.orEmpty()
                            .maxOfOrNull { it.sort.orZero() }
                            .orZero() + 1
                        showEditProductGroupDialog = true
                    }
                }
            }

            List {
                sx {
                    marginTop = 16.px
                }
                homePageContent.productGroups.orEmpty().sortedBy { it.sort.orZero() }
                    .forEach { pcpg ->
                        ListItemButton {
                            ListItemText {
                                +pcpg.productGroup?.name.localized()
                            }
                            onClick = {
                                selectedPageContentProductGroup = pcpg
                                dialogInputSort = pcpg.sort.orZero()
                                showEditProductGroupDialog = true
                            }
                        }
                    }
            }
        }
    }
}