package uz.ferro.shop.pages.admin.file

import emotion.react.css
import js.core.asList
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.icons.material.AddCircle
import mui.icons.material.ArrowCircleLeft
import mui.icons.material.ArrowCircleRight
import mui.icons.material.DeleteForever
import mui.icons.material.Edit
import mui.material.Box
import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogContentText
import mui.material.DialogTitle
import mui.material.IconButton
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Tooltip
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.input
import react.useRef
import react.useState
import uz.ferro.shop.Colors
import uz.ferro.shop.api.uploadFile
import uz.ferro.shop.api.uploadFileSuspend
import uz.ferro.shop.model.DataFile
import uz.ferro.shop.model.PHOTO_SIZE_MEDIUM
import uz.ferro.shop.util.sourceUrl
import uz.ferro.shop.util.thumbnailUrl
import web.cssom.AlignItems
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.LineStyle
import web.cssom.ObjectFit
import web.cssom.Position
import web.cssom.Visibility
import web.cssom.pct
import web.cssom.px
import web.file.File
import web.html.HTMLInputElement
import web.html.InputType

external interface PhotoAttacherProps : Props {
    var allowMultiple: Boolean?
    var dataFile: DataFile?
    var onFileUpload: (DataFile) -> Unit
    var onMultipleFilesUpload: ((List<DataFile>) -> Unit)?
    var onDelete: () -> Unit
    var moveToPrev: (() -> Unit)?
    var moveToNext: (() -> Unit)?
}

val PhotoAttacher = FC<PhotoAttacherProps> { props ->
    var deleteConfirmDialogShown by useState(false)
    var isHovered by useState(false)

    fun onFilesPicked(files: List<File>) {
        MainScope().launch {
            val responses = mutableListOf<DataFile>()
            files.forEach { file ->
                val response = uploadFileSuspend(file)
                responses.add(response)
            }

            props.onMultipleFilesUpload?.invoke(responses)
        }
    }

    val onFileChange: (File) -> Unit = {
        uploadFile(it)
            .then { response ->
                props.onFileUpload(response)
            }
    }

    val currentPhoto = props.dataFile
    val inputRef = useRef<HTMLInputElement>(null)

    Dialog {
        sx {
            minWidth = 400.px
        }
        open = deleteConfirmDialogShown
        onClose = { _, _ -> deleteConfirmDialogShown = false }
        DialogTitle {
            +"O'chirish"
        }
        DialogContent {
            DialogContentText {
                +"O'chirishni tasdiqlaysizmi?"
            }
            DialogActions {
                Button {
                    onClick = { deleteConfirmDialogShown = false }
                    +"Bekor"
                }
                Button {
                    onClick = {
                        props.onDelete()
                        deleteConfirmDialogShown = false
                    }
                    +"O'chirish"
                }
            }
        }
    }

    Box {
        sx {
            display = Display.inlineBlock
            width = 150.px
            height = 150.px
            borderColor = Colors.defaultBorder
            borderStyle = LineStyle.solid
            borderWidth = 1.px
            position = Position.relative
        }

        onMouseOver = {
            isHovered = true
        }

        onMouseOut = {
            isHovered = false
        }

        input {
            this.ref = inputRef
            multiple = props.allowMultiple == true
            css {
                margin = 5.px
                position = Position.absolute
                visibility = Visibility.hidden
            }
            type = InputType.file
            accept = "image/*"
            onChange = { event ->
                if (props.allowMultiple == true) {
                    val files = event.target.files?.asList().orEmpty()
                    if (files.isNotEmpty()) {
                        onFilesPicked(files)
                    }
                } else {
                    val file = event.target.files?.item(0)
                    if (file != null) {
                        onFileChange(file)
                    }
                }
            }
        }

        if (currentPhoto != null) {
            img {
                css {
                    objectFit = ObjectFit.contain
                    height = 100.pct
                    width = 100.pct
                }

                src = if (currentPhoto.url.endsWith(".svg")) {
                    currentPhoto.sourceUrl()
                } else {
                    currentPhoto.thumbnailUrl(PHOTO_SIZE_MEDIUM)
                }
            }

            if (isHovered) {
                Box {
                    sx {
                        display = Display.flex
                        flexDirection = FlexDirection.row
                        position = Position.absolute
                        bottom = 0.px
                        right = 0.px
                    }

                    Tooltip {
                        title = ReactNode("O'zgartirish")
                        IconButton {
                            Edit {
                                htmlColor = Colors.iconSecondary
                            }
                            onClick = {
                                inputRef.current?.click()
                            }
                        }
                    }

                    Tooltip {
                        title = ReactNode("O'chirish")
                        IconButton {
                            DeleteForever {
                                htmlColor = Colors.iconSecondary
                            }
                            onClick = {
                                deleteConfirmDialogShown = true
                            }
                        }
                    }
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        justifyContent = JustifyContent.spaceBetween
                        position = Position.absolute
                        top = 0.px
                        left = 0.px
                        width = 100.pct
                    }

                    if (props.moveToPrev != null) {
                        Tooltip {
                            title = ReactNode("Chapga")
                            IconButton {
                                ArrowCircleLeft {
                                    htmlColor = Colors.iconSecondary
                                }
                                onClick = {
                                    props.moveToPrev?.invoke()
                                }
                            }
                        }
                    } else {
                        Box {
                            sx {
                                width = 20.px
                            }
                        }
                    }

                    if (props.moveToNext != null) {
                        Tooltip {
                            title = ReactNode("O'ngga")
                            IconButton {
                                ArrowCircleRight {
                                    htmlColor = Colors.iconSecondary
                                }
                                onClick = {
                                    props.moveToNext?.invoke()
                                }
                            }
                        }
                    }
                }
            }
        } else {
            Box {
                sx {
                    display = Display.flex
                    justifyContent = JustifyContent.center
                    alignItems = AlignItems.center
                    width = 100.pct
                    height = 100.pct
                }
                AddCircle {
                    htmlColor = Colors.iconSecondary
                    sx {
                        fontSize = 60.px
                        cursor = Cursor.pointer
                    }

                    onClick = {
                        inputRef.current?.click()
                    }
                }
            }
        }
    }
}
