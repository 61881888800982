package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class AccessKey(
    val id: Long?,
    val key: String,
    val type: AccessKeyType,
    val targetId: Long
)