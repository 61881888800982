package uz.ferro.shop.api

import tanstack.query.core.QueryKey
import tanstack.react.query.useQuery
import uz.ferro.shop.CATEGORIES_ALL_QUERY_KEY
import uz.ferro.shop.model.Category
import kotlin.js.Promise

fun useAllCategories(): Array<Category> {
    val result = useQuery<Array<Category>, Error, Array<Category>, QueryKey>(
        queryKey = CATEGORIES_ALL_QUERY_KEY,
        queryFn = { getCategoryList() }
    )
    return result.data ?: emptyArray()
}

fun getCategoryList(): Promise<Array<Category>> {
    return get("category/list")
}

fun getRootCategories(): Promise<Array<Category>> {
    return get("category/root")
}
