package uz.ferro.shop.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class SalesPerson(
    @SerialName("id")
    val id: Long = 0,

    @SerialName("name")
    val name: String = "",

    @SerialName("memo")
    val memo: String = "",

    @SerialName("isActive")
    val isActive: Boolean = true
)
