package uz.ferro.shop.pages.home

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.manager.AuthManager
import uz.ferro.shop.manager.PageContentManager
import uz.ferro.shop.model.ComponentType
import uz.ferro.shop.model.PageContent
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.search.SearchManager
import uz.ferro.shop.ui.banner.BannerGroup
import uz.ferro.shop.ui.category.HomeCategoryMenuMobile
import uz.ferro.shop.ui.productgroup.ProductGroupView
import uz.ferro.shop.util.styleInMobile
import web.cssom.px

val Home = FC<Props> {

    var homePageContent by useState(PageContent())
    val navigator = useContext(NavigationContext)
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()

    useEffectOnce {
        MainScope().launch {
            try {
                homePageContent = PageContentManager.getHomePageContent()
            } catch (e: Exception) {
                // no-op
            }

            try {
                AuthManager.checkAuthorization()
            } catch (e: Exception) {
                // no-op
            }

            try {
                SearchManager.initCache()
            } catch (e: Exception) {
                // no-op
            }
        }
    }

    MarketPage(
        collapseMainCatalogByDefault = false,
        stretchCategoryHeight = true,
        centerBlock = {
            Box {
                sx {
                    height = responsive(
                        Breakpoint.xs to 208.px,
                        Breakpoint.sm to 308.px,
                        Breakpoint.md to 408.px,
                        Breakpoint.lg to 508.px
                    )
                }

                val mainBannersType = if (isMobile) {
                    ComponentType.PRIMARY_BANNERS_MOBILE
                } else {
                    ComponentType.PRIMARY_BANNERS
                }
                BannerGroup {
                    banners = homePageContent.findComponent(mainBannersType)?.banners.orEmpty()
                    isEditMode = false
                    fitFirst = false
                }
            }

            if (isMobile) {
                HomeCategoryMenuMobile()
            }
        },

        fullBlock = {
            val groups = homePageContent.productGroups.orEmpty()
            val firstGroup = groups.firstOrNull()
            if (firstGroup?.productGroup != null) {
                ProductGroupView {
                    productGroup = firstGroup.productGroup
                }

                val secondaryBannersType = if (isMobile) {
                    ComponentType.SECONDARY_BANNERS_MOBILE
                } else {
                    ComponentType.SECONDARY_BANNERS
                }
                val secondaryBanners = homePageContent.findComponent(secondaryBannersType)?.banners.orEmpty()
                if (secondaryBanners.isNotEmpty()) {
                    val bannersGroupHeight = if (secondaryBanners.size == 1) {
                        responsive(
                            Breakpoint.xs to 100.px,
                            Breakpoint.sm to 180.px,
                            Breakpoint.md to 240.px,
                            Breakpoint.lg to 300.px
                        )
                    } else {
                        responsive(
                            Breakpoint.xs to 208.px,
                            Breakpoint.sm to 272.px,
                            Breakpoint.md to 360.px,
                            Breakpoint.lg to 416.px
                        )
                    }

                    Box {
                        sx {
                            marginTop = styleInMobile(20.px, 36.px)
                            height = bannersGroupHeight
                        }

                        BannerGroup {
                            banners = secondaryBanners
                            isEditMode = false
                            fitFirst = true
                        }
                    }
                }
            }

            val otherGroups = if (groups.size > 1) {
                groups.subList(1, groups.size)
            } else {
                emptyList()
            }

            Box {
                sx {
                    height = styleInMobile(20.px, 36.px)
                }
            }

            otherGroups.forEach { group ->
                if (group.productGroup != null) {
                    ProductGroupView {
                        productGroup = group.productGroup
                    }
                }
            }
        }
    ).invoke()
}