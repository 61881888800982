package uz.ferro.shop.pages.admin.help

import mui.material.Box
import mui.system.sx
import react.FC
import react.Props
import uz.ferro.shop.ui.AppText
import uz.ferro.shop.ui.AppTextSemiBoldLarge
import uz.ferro.shop.ui.ImageView
import uz.ferro.shop.util.thumbnailUrl
import web.cssom.Auto
import web.cssom.Display
import web.cssom.pct
import web.cssom.px

val AdminHelpAddProduct = FC<Props> {
    Box {
        sx {
            padding = 16.px
        }

        AppTextSemiBoldLarge {
            sx {
                display = Display.block
            }
            text = "Maxsulot qo‘shish"
        }

        AppText {
            sx {
                marginTop = 8.px
            }
            text = "Yangi maxsulot qo‘shish uchun admin sahifasida maxsulotlar bo'limini tanlaysiz va ochilgan oynada \"+\" tugmasini bosasiz"
        }

        ImageView {
            sx {
                width = 100.pct
                maxWidth = 800.px
                height = Auto.auto
                marginTop = 8.px
            }
            noBackgroundMode = true
            url = "2024_01_30_09_29_56_791_767677bb.png".thumbnailUrl(1280)
        }

        AppText {
            sx {
                marginTop = 24.px
            }
            text = "Maxsulot qo‘shish oynasida kerakli maydonlari to‘ldirib chiqasiz"
        }

        AppText {
            sx {
                marginTop = 16.px
            }
            text = "Agar maxsulot har hil o'cham uchun bosh maxsulot bo'lsa u holda Guruhning bosh tovari belgilanib qo'yishi kerak va Tashqi ID bo'sh qoldirilishi kerak."
        }

        AppText {
            sx {
                marginTop = 16.px
            }
            text = "Agar maxsulot aniq o'chamdagi bo'lsa Tashqi ID (SAP) kiritilishi kerak va kerakli bosh masulot tanlab qo'yilishi kerak."
        }

        ImageView {
            sx {
                width = 100.pct
                maxWidth = 800.px
                height = Auto.auto
                marginTop = 8.px
            }
            noBackgroundMode = true
            url = "2024_01_30_09_54_32_159_b67d86b8.png".thumbnailUrl(1280)
        }
    }
}