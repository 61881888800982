package uz.ferro.shop

const val KEY_AUTH_TOKEN = "auth_token"
const val KEY_ROLE = "role"
const val KEY_USER = "user"
const val KEY_USER_ID = "userId"
const val KEY_CART = "cart"
const val KEY_FAVORITES = "favorites"
const val KEY_LANGUAGE = "language"

const val FERRO_BRAND_ID = 1000045
const val PARAM_HISTORY = "history"
const val VALUE_NO_BACK = "noBack"

object Const {
    const val INFO_EMAIL = "info@ferro.uz"
    const val ORDER_PHONE = "+998 71 203 02 02"
    const val TASHKENT_DELIVERY_PHONE = "+998 99 441 20 30"
    const val WORKING_TIME = "{{days}} \n08:00-17:30"

    const val TELEGRAM = "https://t.me/ferrouz"
    const val TELEGRAM_ORDER_TITLE = "@FerroOnline"
    const val TELEGRAM_ORDER_LINK = "https://t.me/FerroOnline"
    const val INSTAGRAM = "https://www.instagram.com/ferro_orbita"
    const val FACEBOOK = "https://www.facebook.com/profile.php?id=100093974891289"

    const val LOCATION_TASHKENT_RU = "Город Ташкент, ТЦ Урикзор «Стройгород»"
    const val LOCATION_TASHKENT_UZ = "Toshkent, O‘rikzor, «Stroy Gorod»"
    const val LOCATION_TASHKENT_UZ_CYR = "Тошкент, Ўрикзор, «Строй Город»"
    const val STROY_GOROD_GEO = "https://yandex.uz/maps/-/CDqHjQOn"
    const val LOCATION_KOKAND = "Город Коканд, ТЦ Фарабий"

    const val CATEGORY_FASTENERS_ID = 1000058
    const val CATEGORY_CONSUMABLES_ID = 1005271
    const val CATEGORY_INSTRUMENTS_ID = 1005269
}

