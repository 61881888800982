package uz.ferro.shop.api

import tanstack.query.core.QueryKey
import tanstack.react.query.useMutation
import tanstack.react.query.useQuery
import tanstack.react.query.useQueryClient
import uz.ferro.shop.PROPERTY_VALUES_ALL_QUERY_KEY
import uz.ferro.shop.model.PropertyValue
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import kotlin.js.Promise

fun useAllPropertyValues(): List<PropertyValue> {
    val result = useQuery<List<PropertyValue>, Error, List<PropertyValue>, QueryKey>(
        queryKey = PROPERTY_VALUES_ALL_QUERY_KEY,
        queryFn = { getAllPropertyValues() }
    )
    return result.data.orEmpty()
}

private fun addPropertyValue(property: PropertyValue): Promise<PropertyValue> {
    return post(
        path = "property-value",
        body = property
    )
}

typealias SavePropertyValue = (PropertyValue) -> PropertyValue

private fun updatePropertyValue(property: PropertyValue): Promise<PropertyValue> {
    return put(
        path = "property-value",
        body = property
    )
}

fun savePropertyValue(propertyValue: PropertyValue): Promise<PropertyValue> {
    return if (propertyValue.id.orZero() > 0) {
        updatePropertyValue(propertyValue)
    } else {
        addPropertyValue(propertyValue)
    }
}

fun useSavePropertyValue(): SavePropertyValue {
    val client = useQueryClient()
    return useMutation<PropertyValue, Error, PropertyValue, QueryKey>(
        mutationFn = { property ->
            if (property.id.orZero() > 0) {
                updatePropertyValue(property)
            } else {
                addPropertyValue(property)
            }
        }
    ).mutate.unsafeCast<SavePropertyValue>()
}

fun getAllPropertyValues(): Promise<List<PropertyValue>> {
    return get<List<PropertyValue>>("property-value/list")
        .then { data ->
            data.sortedBy { it.property?.name.localized() }
        }
}
