package uz.ferro.shop.locale

import kotlinx.browser.localStorage
import org.w3c.dom.get
import org.w3c.dom.set
import uz.ferro.shop.KEY_LANGUAGE
import uz.ferro.shop.model.LocaleDict
import uz.ferro.shop.model.LocaleEn
import uz.ferro.shop.model.LocaleRu
import uz.ferro.shop.model.LocaleUz
import uz.ferro.shop.model.LocaleUzCyr

object LanguageManager {

    var currentLanguage: Language = Language.UZ
        set(value) {
            field = value
            localStorage[KEY_LANGUAGE] = value.name
        }

    init {
        val lang = localStorage[KEY_LANGUAGE].orEmpty()
        currentLanguage = Language.getByString(lang)
    }

    fun getCurrentDict(): LocaleDict {
        return when (currentLanguage) {
            Language.RU -> LocaleRu
            Language.UZ_CYR -> LocaleUzCyr
            Language.EN -> LocaleEn
            else -> LocaleUz
        }
    }
}