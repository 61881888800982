package uz.ferro.shop.model

enum class Country(val title: String) {
    UZ ("Uzbekistan"),
    CN("China"),
    RU("Russia"),
    IR("Iran");

    companion object {
        fun fromShortName(shortName: String): Country? {
            return entries.firstOrNull { it.name.lowercase() == shortName.lowercase() }
        }
    }
}