package uz.ferro.shop.util

import emotion.react.css
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.ResponsiveStyleValue
import mui.system.responsive
import react.PropsWithClassName
import web.cssom.ClassName

const val CSS_CLASS_NO_SCROLLBAR = "no-scrollbar"
const val CSS_CLASS_SNAP_X = "scroll-snap-x-mandatory"

inline fun PropsWithClassName.noScrollBars() {
    css(ClassName("no-scrollbar")) {}
}

inline fun PropsWithClassName.selectableItemBackground() {
    css(ClassName("selectable-item-background")) {}
}

inline fun PropsWithClassName.scrollSnapXMandatory() {
    css(ClassName("scroll-snap-x-mandatory")) {}
}

inline fun PropsWithClassName.hoverAccentColor() {
    css(ClassName("hover-accent-color")) {}
}

fun columnInMobile(): ResponsiveStyleValue<StackDirection> {
    return responsive(
        Breakpoint.xs to StackDirection.column,
        Breakpoint.md to StackDirection.row
    )
}

fun <T : Any, R> styleInMobile(mobile: T, desktop: T): R where R : ResponsiveStyleValue<T> {
    return responsive(
        Breakpoint.xs to mobile,
        Breakpoint.md to desktop
    )
}
