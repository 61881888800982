package uz.ferro.shop.pages.order

import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableHead
import mui.material.TableRow
import mui.system.responsive
import mui.system.sx
import react.FC
import react.useContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.model.displayStatus
import uz.ferro.shop.ui.AppTextBoldSmall
import uz.ferro.shop.ui.AppTextSemiBoldSmall
import uz.ferro.shop.util.formatPrice
import web.cssom.LineStyle
import web.cssom.TextAlign
import web.cssom.pct
import web.cssom.px


val OrderItemViewMobile = FC<OrderItemViewProps> { props ->
    val order = props.order
    val navigator = useContext(NavigationContext)
    val locale = useContext(LocaleContext)

    Stack {
        direction = responsive(StackDirection.column)
        sx {
            width = 100.pct
            borderStyle = LineStyle.solid
            borderColor = Colors.borderPrimary
            borderWidth = 1.px
            borderRadius = 5.px
            padding = 12.px
        }

        onClick = {
            navigator.openOrderDetails(order.id)
        }

        AppTextBoldSmall {
            text = "# ${order.id}"
        }

        Table {
            sx {
                marginTop = 12.px
            }
            TableHead {
                TableRow {
                    sx {
                        borderBottomStyle = LineStyle.solid
                        borderBottomColor = Colors.borderPrimary
                    }
                    TableCell {
                        sx {
                            textAlign = TextAlign.start
                            paddingTop = 12.px
                            paddingBottom = 12.px
                            paddingLeft = 0.px
                            paddingRight = 0.px
                        }
                        AppTextSemiBoldSmall {
                            text = locale.date
                        }
                    }

                    TableCell {
                        sx {
                            textAlign = TextAlign.center
                            paddingTop = 12.px
                            paddingBottom = 12.px
                            paddingLeft = 0.px
                            paddingRight = 0.px
                        }
                        AppTextSemiBoldSmall {
                            text = locale.inTotal
                        }
                    }

                    TableCell {
                        sx {
                            textAlign = TextAlign.end
                            paddingTop = 12.px
                            paddingBottom = 12.px
                            paddingLeft = 0.px
                            paddingRight = 0.px
                        }
                        AppTextSemiBoldSmall {
                            text = locale.status
                        }
                    }
                }
            }

            TableBody {
                TableRow {
                    TableCell {
                        sx {
                            textAlign = TextAlign.start
                            borderBottomStyle = LineStyle.hidden
                            padding = 0.px
                        }
                        AppTextSemiBoldSmall {
                            text = order.displayDate()
                        }
                    }

                    TableCell {
                        sx {
                            textAlign = TextAlign.center
                            borderBottomStyle = LineStyle.hidden
                            padding = 0.px
                        }


                        AppTextSemiBoldSmall {
                            text = order.total.formatPrice()
                        }
                    }

                    TableCell {
                        sx {
                            textAlign = TextAlign.end
                            borderBottomStyle = LineStyle.hidden
                            padding = 0.px
                        }

                        AppTextSemiBoldSmall {
                            text = order.displayStatus()
                        }
                    }
                }
            }
        }
    }
}