package uz.ferro.shop.pages.cart

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Breadcrumbs
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.CircularProgress
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.Link
import mui.material.LinkUnderline
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Table
import mui.material.TableBody
import mui.material.TableCell
import mui.material.TableHead
import mui.material.TableRow
import mui.material.Typography
import mui.system.responsive
import mui.system.sx
import react.VFC
import react.create
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.AppContext
import uz.ferro.shop.CartContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.icon.ChevronRightIcon16
import uz.ferro.shop.manager.OrderManager
import uz.ferro.shop.model.CartProduct
import uz.ferro.shop.model.OrderProduct
import uz.ferro.shop.model.PHOTO_SIZE_SMALL
import uz.ferro.shop.model.Product
import uz.ferro.shop.pages.MarketContext
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.AppTextBoldSmall
import uz.ferro.shop.ui.AppTextExtraBold
import uz.ferro.shop.ui.AppTextMedium
import uz.ferro.shop.ui.AppTextSemiBold
import uz.ferro.shop.util.columnInMobile
import uz.ferro.shop.util.formatPrice
import uz.ferro.shop.util.getActualPrice
import uz.ferro.shop.util.styleInMobile
import web.cssom.AlignContent
import web.cssom.AlignItems
import web.cssom.BackgroundRepeat
import web.cssom.BackgroundSize
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FontWeight
import web.cssom.GeometryPosition
import web.cssom.JustifyContent
import web.cssom.Length
import web.cssom.LineStyle
import web.cssom.None
import web.cssom.Overflow
import web.cssom.TextAlign
import web.cssom.TextOverflow
import web.cssom.TextTransform
import web.cssom.WhiteSpace
import web.cssom.em
import web.cssom.integer
import web.cssom.number
import web.cssom.pct
import web.cssom.pt
import web.cssom.px
import web.cssom.url

val CartPage = VFC {
    val locale = useContext(LocaleContext)

    MarketPage(
        collapseMainCatalogByDefault = true,
        centerBlock = {
            Box {
                sx {
                    marginLeft = styleInMobile(0.px, 30.px)
                }

                Breadcrumbs {
                    sx {
                        fontSize = 13.px
                    }

                    separator = ChevronRightIcon16.create()
                    Link {
                        color = Colors.textSecondary
                        underline = LinkUnderline.hover
                        key = "1"
                        href = "/"
                        onClick = {

                        }
                        +locale.main
                    }

                    Box {
                        sx {
                            color = Colors.textSecondary
                        }
                        +locale.cart
                    }
                }

                Box {
                    sx {
                        marginTop = 8.px
                        color = Colors.textPrimary
                        fontSize = styleInMobile(25.px, 31.px)
                        fontWeight = integer(700)
                    }
                    +locale.cart
                }
            }
        },
        fullBlock = {
            var cartProducts by useState(listOf<CartProduct>())
            val cartManager = useContext(CartContext)
            var totalUsdAmount by useState(0.0)
            var showOrderSuccess by useState(false)
            var showEmptyView by useState(false)
            var isLoading by useState(false)
            var hasNonAvailableProducts by useState(false)

            val marketContext = useContext(MarketContext)
            val navigator = useContext(NavigationContext)
            var showCheckoutDialog by useState(false)
            var showHasUnavailableDialog by useState(false)
            val appContext = useContext(AppContext)
            val isMobile = appContext.isMd.not()


            fun updateTotal() {
                totalUsdAmount = cartManager.cart.products.sumOf {
                    it.quantity * it.product.getActualPrice()
                }
            }

            fun loadData() {
                MainScope().launch {
                    isLoading = true
                    val cartData = cartManager.getCartWithSync().products.toList()
                    cartProducts = cartData
                    hasNonAvailableProducts = cartData.any { it.product.isNotAvailable }
                    updateTotal()
                    marketContext.onCartCountUpdated()
                    showEmptyView = cartProducts.isEmpty()
                    isLoading = false
                }
            }

            fun updateData() {
                val cartData = cartManager.cart.products.toList()
                cartProducts = cartData
                hasNonAvailableProducts = cartData.any { it.product.isNotAvailable }
                updateTotal()
                marketContext.onCartCountUpdated()
            }

            fun closeOrderSuccessDialog() {
                showOrderSuccess = false
            }

            fun startCheckout() {
                if (hasNonAvailableProducts) {
                    showHasUnavailableDialog = true
                } else {
                    showCheckoutDialog = true
                }
            }

            useEffectOnce {
                loadData()
            }

            Box {
                sx {
                    marginTop = 16.px
                }

                if (cartProducts.isNotEmpty()) {
                    if (isMobile) {
                        Stack {
                            direction = responsive(StackDirection.column)
                            spacing = responsive(16.px)
                            cartProducts.forEach { cartProduct ->
                                CartItemViewMobile {
                                    this.cartProduct = cartProduct
                                    this.refreshCallback = {
                                        updateData()
                                    }
                                    this.onTotalChanged = {
                                        updateTotal()
                                    }
                                }
                            }
                        }
                    } else {
                        Box {
                            sx {
                                borderRadius = 5.px
                                borderWidth = 1.px
                                borderColor = Colors.borderPrimary
                                borderStyle = LineStyle.solid
                            }

                            Table {
                                TableHead {
                                    TableRow {
                                        sx {
                                            backgroundColor = Colors.secondaryBackground
                                        }

                                        TableCell {
                                            sx {
                                                width = 0.px
                                            }
                                        }

                                        TableCell {
                                            AppTextBold {
                                                text = locale.naming
                                            }
                                        }

                                        TableCell {
                                            sx {
                                                width = 1.pct
                                            }
                                        }

                                        TableCell {
                                            sx {
                                                textAlign = TextAlign.center
                                                width = 0.px
                                            }
                                            AppTextBold {
                                                text = locale.price
                                            }
                                        }

                                        TableCell {
                                            sx {
                                                textAlign = TextAlign.center
                                                width = 0.px
                                            }
                                            AppTextBold {
                                                text = locale.measureUnit
                                            }
                                        }

                                        TableCell {
                                            sx {
                                                textAlign = TextAlign.center
                                                width = 0.px
                                            }
                                            AppTextBold {
                                                text = locale.quantity
                                            }
                                        }

                                        TableCell {
                                            sx {
                                                textAlign = TextAlign.center
                                                width = 0.px
                                            }
                                            AppTextBold {
                                                text = locale.inTotal
                                            }
                                        }

                                        TableCell {
                                            sx {
                                                width = 0.px
                                            }
                                        }
                                    }
                                }

                                TableBody {
                                    cartProducts.forEach { cartProduct ->
                                        CartItemView {
                                            this.cartProduct = cartProduct
                                            this.refreshCallback = {
                                                updateData()
                                            }
                                            this.onTotalChanged = {
                                                updateTotal()
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                    Stack {
                        direction = columnInMobile()
                        spacing = styleInMobile(24.px, 48.px)

                        sx {
                            marginTop = 48.px
                            if (isMobile) {
                                alignItems = AlignItems.center
                            }
                        }

                        if (isMobile.not()) {
                            Box {
                                sx {
                                    flexGrow = number(1.0)
                                }
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.column)

                            sx {
                                if (isMobile) {
                                    alignItems = AlignItems.center
                                }
                            }

                            AppTextSemiBold {
                                text = locale.totalAmount
                            }

                            AppTextExtraBold {
                                sx {
                                    marginTop = 8.px
                                }
                                size = 30
                                text = totalUsdAmount.formatPrice()
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.column)
                            spacing = responsive(16.px)
                            Button {
                                variant = ButtonVariant.contained
                                sx {
                                    textTransform = None.none
                                }
                                onClick = {
                                    startCheckout()
                                }
                                +locale.checkout
                            }

                            Link {
                                sx {
                                    color = Colors.accentColor
                                    fontWeight = integer(700)
                                    fontSize = 16.px
                                    cursor = Cursor.pointer
                                }
                                underline = LinkUnderline.always
                                onClick = {
                                    navigator.openPaymentAndDelivery()
                                }
                                +locale.termsOfPaymentAndDelivery
                            }
                        }
                    }
                } else {
                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            minHeight = 200.px
                            color = Colors.label
                            alignContent = AlignContent.center
                            justifyContent = JustifyContent.center
                            alignItems = AlignItems.center
                        }

                        if (isLoading) {
                            CircularProgress()
                        }

                        if (showEmptyView) {
                            +locale.cartIsEmpty
                        }
                    }
                }
            }

            Dialog {
                open = showHasUnavailableDialog
                onClose = { _, _ ->
                    showHasUnavailableDialog = false
                }
                DialogTitle {
                    +locale.unavailableProducts
                }
                DialogContent {
                    Box {
                        AppTextMedium {
                            size = 20
                            text = locale.unavailableProductsDescription
                        }
                    }

                    Box {
                        sx {
                            marginTop = styleInMobile(16.px, 20.px)
                        }
                        cartProducts.filter { it.product.isNotAvailable }.forEach { cartProduct ->
                            val product = cartProduct.product
                            val photoUrl = product.getPrimaryPhotoUrl(PHOTO_SIZE_SMALL)
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(12.px)

                                sx {
                                    marginTop = styleInMobile(12.px, 16.px)
                                }

                                Box {
                                    sx {
                                        width = 64.px
                                        height = 64.px
                                        flexShrink = number(0.0)
                                        borderStyle = LineStyle.solid
                                        borderWidth = 1.px
                                        borderRadius = 3.px
                                        borderColor = Colors.borderPrimary
                                        if (photoUrl.isNotBlank()) {
                                            backgroundImage = url(photoUrl)
                                        }
                                        backgroundPosition = GeometryPosition.center
                                        backgroundSize = BackgroundSize.contain
                                        backgroundRepeat = BackgroundRepeat.noRepeat
                                    }
                                }

                                Stack {
                                    direction = responsive(StackDirection.column)
                                    spacing = styleInMobile(4.px, 8.px)
                                    AppTextBoldSmall {
                                        text = product.getLocalizedName()
                                    }

                                    Box {
                                        sx {
                                            display = Display.flex
                                            alignItems = AlignItems.center
                                            width = Length.fitContent
                                            maxWidth = 100.pct
                                            backgroundColor = Colors.amountNotEnoughLabel
                                            borderRadius = 2.px
                                            color = Colors.white
                                            fontSize = styleInMobile(10.px, 12.px)
                                            fontWeight = integer(700)
                                            paddingLeft = 6.px
                                            paddingRight = 6.px
                                            height = 1.6.em
                                            textOverflow = TextOverflow.ellipsis
                                            whiteSpace = WhiteSpace.nowrap
                                            maxLines = integer(1)
                                            overflow = Overflow.hidden
                                            textTransform = TextTransform.uppercase
                                        }
                                        +locale.itemNotAvailable
                                    }
                                }
                            }
                        }
                    }

                    DialogActions {
                        Button {
                            sx {
                                textTransform = None.none
                            }
                            onClick = {
                                showHasUnavailableDialog = false
                            }
                            +locale.cancel
                        }

                        Button {
                            sx {
                                textTransform = None.none
                            }
                            onClick = {
                                val idList = cartProducts
                                    .filter { it.product.isNotAvailable }
                                    .mapNotNull { it.product.id }
                                cartManager.removeProducts(idList)
                                updateData()
                                showHasUnavailableDialog = false
                                if (cartManager.cart.products.size > 0) {
                                    showCheckoutDialog = true
                                }
                            }
                            +locale.delete
                        }
                    }
                }
            }

            Dialog {
                open = showCheckoutDialog
                onClose = { _, _ ->
                    showCheckoutDialog = false
                }
                DialogTitle {
                    +locale.checkout
                }
                DialogContent {
                    CreateOrderView {
                        onSendClick = { ord, callback ->
                            val order = ord.copy(
                                uuid = cartManager.cart.uuid,
                                products = cartProducts.map { cp ->
                                    OrderProduct(
                                        product = Product(id = cp.product.id),
                                        quantity = cp.quantity,
                                        productPrice = cp.product.price,
                                        discountPercent = cp.product.discountPercent
                                    )
                                }
                            )

                            MainScope().launch {
                                try {
                                    OrderManager.createOrder(order)
                                    cartManager.clear()
                                    updateData()
                                    callback(true)
                                    showCheckoutDialog = false
                                    showOrderSuccess = true
                                } catch (e: Exception) {
                                    callback(false)
                                }
                            }
                        }
                    }
                }
            }

            Dialog {
                fullWidth = true
                open = showOrderSuccess
                onClose = { _, _ -> closeOrderSuccessDialog() }
                DialogTitle {
                    +locale.orderSent
                }
                DialogContent {

                    Box {
                        sx {
                            padding = 16.px
                        }

                        Typography {
                            sx {
                                fontSize = 14.pt
                                fontWeight = FontWeight.bold
                                cursor = Cursor.pointer
                            }
                            +locale.orderSuccessMessage
                        }
                    }

                    DialogActions {
                        Button {
                            sx {
                                textTransform = None.none
                            }
                            onClick = {
                                closeOrderSuccessDialog()
                                navigator.openMain()
                            }
                            +locale.close
                        }
                    }
                }
            }
        }
    ).invoke()
}