package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class PageContent(
    val id: Long? = null,
    val navigationKey: String? = null,
    val navigationPath: String? = null,
    val title: String? = null,
    val category: Category? = null,
    val components: List<PageContentComponent> = emptyList(),
    val productGroups: List<PageContentProductGroup>? = null,
    val showSubcategories: Boolean? = null,
    val showProducts: Boolean? = null
) {


    fun findComponent(type: ComponentType): Component? {
        return components.firstOrNull { it.component?.type == type }?.component
    }
}
