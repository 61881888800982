package uz.ferro.shop.pages.about

import emotion.react.css
import mui.material.Box
import mui.material.Grid
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import uz.ferro.shop.AppContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.model.DictItem
import uz.ferro.shop.model.SalePoint
import uz.ferro.shop.pages.MAX_PAGE_AREA
import uz.ferro.shop.pages.MarketPage
import uz.ferro.shop.ui.AppText
import uz.ferro.shop.ui.AppTextBold
import uz.ferro.shop.ui.ImageView
import uz.ferro.shop.util.columnInMobile
import uz.ferro.shop.util.localizedOrDefault
import uz.ferro.shop.util.md
import uz.ferro.shop.util.sm
import uz.ferro.shop.util.sourceUrl
import uz.ferro.shop.util.styleInMobile
import uz.ferro.shop.util.thumbnailUrl
import uz.ferro.shop.util.xs
import web.cssom.AlignItems
import web.cssom.Auto
import web.cssom.BackgroundSize
import web.cssom.ClassName
import web.cssom.GeometryPosition
import web.cssom.TextAlign
import web.cssom.TextJustify
import web.cssom.WhiteSpace
import web.cssom.integer
import web.cssom.number
import web.cssom.pct
import web.cssom.px
import web.cssom.url

val AboutPage = FC<Props> {
    val locale = useContext(LocaleContext)
    val appContext = useContext(AppContext)
    val isMobile = appContext.isMd.not()

    MarketPage(
        hideCategory = true,
        hideMobileCatalog = true,
        fillWidthMode = true,
        fullBlock = {
            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(16.px)
                sx {
                    width = 100.pct
                }
                Stack {
                    direction = columnInMobile()
                    Box {
                        if (isMobile.not()) {
                            css(ClassName("center-view-padding")) {}
                        }
                        sx {
                            if (isMobile.not()) {
                                flexGrow = number(1.0)
                                flexShrink = number(1.0)
                            }
                            background = Colors.accentColor
                            width = styleInMobile(100.pct, 0.px)
                        }
                        Box {
                            sx {
                                width = 100.pct
                                paddingLeft = 16.px
                                paddingTop = styleInMobile(24.px, 72.px)
                                paddingRight = styleInMobile(16.px, 100.px)
                                paddingBottom = styleInMobile(24.px, 72.px)
                                color = Colors.accentContrastColor
                                textAlign = TextAlign.justify
                                textJustify = TextJustify.interWord
                                fontSize = styleInMobile(11.px, 20.px)
                                fontWeight = integer(500)
                            }
                            +text001.localizedOrDefault()
                        }
                    }

                    Box {
                        sx {
                            if (isMobile.not()) {
                                flexGrow = number(1.0)
                                flexShrink = number(1.0)
                            }
                            width = styleInMobile(100.pct, 0.px)
                        }
                        ImageView {
                            sx {
                                width = 100.pct
                                if (isMobile) {
                                    aspectRatio = number(1.8)
                                } else {
                                    height = 100.pct
                                }
                            }
                            isCovered = true
                            url = "2024_08_21_23_46_05_914_7c76c6ad.jpg".thumbnailUrl(1280)
                        }
                    }
                }

                Box {
                    sx {
                        width = 100.pct
                    }
                    Box {
                        sx {
                            width = 100.pct
                            maxWidth = MAX_PAGE_AREA
                            paddingLeft = 16.px
                            paddingRight = 16.px
                            marginLeft = Auto.auto
                            marginRight = Auto.auto
                        }

                        if (isMobile) {
                            Stack {
                                direction = responsive(StackDirection.column)
                                spacing = responsive(16.px)

                                Box {
                                    sx {
                                        width = 100.pct
                                    }

                                    AppText {
                                        text = text002.localizedOrDefault()
                                        sx {
                                            textAlign = TextAlign.justify
                                            textJustify = TextJustify.interWord
                                            fontSize = styleInMobile(11.px, 20.px)
                                        }
                                    }
                                }

                                Box {
                                    sx {
                                        width = 100.pct
                                    }

                                    ImageView {
                                        sx {
                                            width = 100.pct
                                            aspectRatio = number(602.0 / 395)
                                        }
                                        isCovered = true
                                        url = "2024_08_22_00_20_16_981_d8988dcd.jpeg".thumbnailUrl(1280)
                                    }
                                }
                            }
                        } else {
                            Stack {
                                direction = columnInMobile()
                                spacing = styleInMobile(16.px, 36.px)

                                Box {
                                    sx {
                                        if (isMobile.not()) {
                                            flexGrow = number(1.0)
                                            flexShrink = number(1.0)
                                        }
                                        width = styleInMobile(100.pct, 0.px)
                                    }

                                    ImageView {
                                        sx {
                                            width = 100.pct
                                            aspectRatio = number(1.524)
                                        }
                                        isCovered = true
                                        url = "2024_08_22_00_20_16_981_d8988dcd.jpeg".thumbnailUrl(1280)
                                    }
                                }

                                Box {
                                    sx {
                                        if (isMobile.not()) {
                                            flexGrow = number(1.0)
                                            flexShrink = number(1.0)
                                        }
                                        width = styleInMobile(100.pct, 0.px)
                                    }

                                    AppText {
                                        text = text002.localizedOrDefault()
                                        sx {
                                            fontSize = styleInMobile(11.px, 20.px)
                                            textAlign = TextAlign.justify
                                            textJustify = TextJustify.interWord
                                        }
                                    }
                                }
                            }
                        }


                        Stack {
                            direction = columnInMobile()
                            spacing = styleInMobile(16.px, 36.px)
                            sx {
                                marginTop = styleInMobile(16.px, 36.px)
                            }

                            Box {
                                sx {
                                    if (isMobile.not()) {
                                        flexGrow = number(1.0)
                                        flexShrink = number(1.0)
                                    }
                                    width = styleInMobile(100.pct, 0.px)
                                }

                                AppText {
                                    text = text003.localizedOrDefault()
                                    sx {
                                        fontSize = styleInMobile(11.px, 20.px)
                                        textAlign = TextAlign.justify
                                        textJustify = TextJustify.interWord
                                    }
                                }
                            }

                            Box {
                                sx {
                                    if (isMobile.not()) {
                                        flexGrow = number(1.0)
                                        flexShrink = number(1.0)
                                    }
                                    width = styleInMobile(100.pct, 0.px)
                                }

                                ImageView {
                                    sx {
                                        width = 100.pct
                                        aspectRatio = number(602.0 / 347)
                                    }
                                    isCovered = true
                                    url = "2024_11_12_05_31_19_258_9879dc9a.svg".sourceUrl()
                                }
                            }
                        }

                        Grid {
                            container = true
                            sx {
                                marginTop = styleInMobile(24.px, 24.px)
                                background = url("2024_08_22_05_48_14_518_d7bdabd9.svg".sourceUrl())
                                backgroundPosition = GeometryPosition.center
                                backgroundSize = BackgroundSize.cover
                                borderRadius = 10.px
                                paddingTop = styleInMobile(12.px, 0.px)
                                paddingBottom = styleInMobile(12.px, 0.px)
                            }

                            iconTexts.forEach { iconTextItem ->
                                Grid {
                                    item = true
                                    xs = 6
                                    sm = 6
                                    md = 3

                                    Stack {
                                        direction = responsive(StackDirection.column)
                                        spacing = styleInMobile(8.px, 16.px)
                                        sx {
                                            alignItems = AlignItems.center
                                            padding = styleInMobile(8.px, 24.px)
                                        }

                                        ImageView {
                                            sx {
                                                width = styleInMobile(48.px, 60.px)
                                                height = styleInMobile(48.px, 60.px)
                                            }
                                            url = iconTextItem.iconUrl.sourceUrl()
                                        }

                                        AppTextBold {
                                            sx {
                                                fontSize = styleInMobile(13.px, 20.px)
                                                whiteSpace = WhiteSpace.preLine
                                                textAlign = TextAlign.center
                                            }
                                            color = Colors.white
                                            text = iconTextItem.text.localizedOrDefault()
                                        }
                                    }
                                }
                            }
                        }

                        AppText {
                            text = text004.localizedOrDefault()
                            sx {
                                fontSize = styleInMobile(11.px, 20.px)
                                textAlign = TextAlign.justify
                                textJustify = TextJustify.interWord
                                marginTop = styleInMobile(16.px, 36.px)
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            spacing = responsive(36.px)

                            sx {
                                marginTop = styleInMobile(16.px, 36.px)
                            }

                            Box {
                                sx {
                                    if (isMobile.not()) {
                                        flexGrow = number(1.0)
                                        flexShrink = number(1.0)
                                    }
                                    width = styleInMobile(100.pct, 0.px)
                                }

                                ImageView {
                                    sx {
                                        width = 100.pct
                                        aspectRatio = number(602.0 / 395)
                                    }
                                    isCovered = true
                                    url = "2024_08_22_06_24_17_629_dd986aa8.jpeg".thumbnailUrl(1280)
                                }
                            }

                            if (isMobile.not()) {
                                Box {
                                    sx {
                                        flexGrow = number(1.0)
                                        flexShrink = number(1.0)
                                        width = 0.px
                                    }

                                    ImageView {
                                        sx {
                                            width = 100.pct
                                            aspectRatio = number(602.0 / 395)
                                        }
                                        isCovered = true
                                        url = "2024_08_22_06_25_02_098_a878da7b.jpeg".thumbnailUrl(1280)
                                    }
                                }
                            }
                        }

                        AppText {
                            text = text005.localizedOrDefault()
                            sx {
                                fontSize = styleInMobile(11.px, 20.px)
                                textAlign = TextAlign.justify
                                textJustify = TextJustify.interWord
                                marginTop = styleInMobile(16.px, 36.px)
                            }
                        }

                        Stack {
                            direction = responsive(StackDirection.row)
                            spacing = responsive(36.px)

                            sx {
                                marginTop = styleInMobile(16.px, 36.px)
                            }

                            Box {
                                sx {
                                    if (isMobile.not()) {
                                        flexGrow = number(1.0)
                                        flexShrink = number(1.0)
                                    }
                                    width = styleInMobile(100.pct, 0.px)
                                }

                                ImageView {
                                    sx {
                                        width = 100.pct
                                        aspectRatio = number(602.0 / 395)
                                    }
                                    isCovered = true
                                    url = "2024_08_22_06_25_55_784_c7a8a899.jpeg".thumbnailUrl(1280)
                                }
                            }

                            if (isMobile.not()) {
                                Box {
                                    sx {
                                        flexGrow = number(1.0)
                                        flexShrink = number(1.0)
                                        width = 0.px
                                    }

                                    ImageView {
                                        sx {
                                            width = 100.pct
                                            aspectRatio = number(602.0 / 395)
                                        }
                                        isCovered = true
                                        url = "2024_08_22_06_26_51_028_9897ca79.jpeg".thumbnailUrl(1280)
                                    }
                                }
                            }
                        }

                        Grid {
                            container = true
                            spacing = responsive(16.px)
                            sx {
                                marginTop = styleInMobile(16.px, 36.px)
                            }


                            salePoints.forEach { salePoint ->
                                Grid {
                                    item = true
                                    xs = 6
                                    sm = 6
                                    md = 3

                                    SalePointView {
                                        this.salePoint = salePoint
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    ).invoke()
}

private data class IconText(
    val iconUrl: String,
    val text: DictItem
)

private val iconTexts = listOf(
    IconText(
        iconUrl = "2024_08_22_06_13_08_319_6acab69b.svg",
        text = DictItem(
            textUzLat = "ULGURJI\nNARXLAR",
            textUzCyr = "УЛГУРЖИ\nНАРХЛАР",
            textRu = "ОПТОВЫЕ\nЦЕНЫ",
            textEn = "WHOLESALE\nPRICES"
        )
    ),
    IconText(
        iconUrl = "2024_08_22_06_13_43_141_8d76baca.svg",
        text = DictItem(
            textUzLat = "KENG\nASSORTIMENT",
            textUzCyr = "КEНГ\nАССОРТИМEНТ",
            textRu = "ШИРОКИЙ\nАССОРТИМЕНТ",
            textEn = "WIDE\nASSORTMENT",
        )
    ),
    IconText(
        iconUrl = "2024_08_22_06_14_03_199_b76986a7.svg",
        text = DictItem(
            textUzLat = "VILOYATLARGA YETKAZIB\nBERISH XIZMATI",
            textUzCyr = "ВИЛОЯТЛАРГА ЕТКАЗИБ\nБEРИШ ХИЗМАТИ",
            textRu = "ДОСТАВКА ПО\nВСЕЙ СТРАНЕ",
            textEn = "DELIVERY ALL OVER\nTHE COUNTRY"
        )
    ),
    IconText(
        iconUrl = "2024_08_22_06_14_22_373_da9b9c7c.svg",
        text = DictItem(
            textUzLat = "DOIMIY AKSIYA\nVA CHEGIRMALAR",
            textUzCyr = "ДОИМИЙ АКЦИЯ\nВА ЧEГИРМАЛАР",
            textRu = "ПОСТОЯННЫЕ\nАКЦИИ И СКИДКИ",
            textEn = "CONTINUOUS PROMOTIONS\nAND DISCOUNTS"
        ),
    )
)

private val salePoints = listOf(
    SalePoint(
        name = DictItem(
            textUzLat = "O‘rikzor Ombor-do‘kon 5/1",
            textUzCyr = "Ўрикзор Омбор-дўкон 5/1",
            textRu = "Урикзар Склад-магазин 5/1",
            textEn = "Orikzar Warehouse store 5/1"
        ),
        address = DictItem(
            textUzLat = "Toshkent shahri; O‘rikzor “Stroygorod” savdo markazi, 5/1",
            textUzCyr = "Тошкент шаҳри; Ўрикзор «Стройгород» савдо маркази, 5/1",
            textRu = "город Ташкент; Урикзар ТЦ «Стройгород», 5/1",
            textEn = "Tashkent city; Orikzar shopping mall «Stroygorod», 5/1"
        ),
        phone = "+998 97 726 80 80",
        phone2 = "+998 97 445 60 60"
    ),

    SalePoint(
        name = DictItem(
            textUzLat = "O‘rikzor Ombor-do‘kon 5/64",
            textUzCyr = "Ўрикзор Омбор-дўкон 5/64",
            textRu = "Урикзар Склад-магазин 5/64",
            textEn = "Orikzar Warehouse store 5/64"
        ),
        address = DictItem(
            textUzLat = "Toshkent shahri; O‘rikzor “Stroygorod” savdo markazi, 5/64",
            textUzCyr = "Тошкент шаҳри; Ўрикзор «Стройгород» савдо маркази, 5/64",
            textRu = "город Ташкент; Урикзар ТЦ «Стройгород», 5/64",
            textEn = "Tashkent city; Orikzar shopping mall «Stroygorod», 5/64"
        ),
        phone = "+998 97 758 40 40",
        phone2 = "+998 97 746 80 80"
    ),

    SalePoint(
        name = DictItem(
            textUzLat = "O‘rikzor Ombor-do‘kon 1/242",
            textUzCyr = "Ўрикзор Омбор-дўкон 1/242",
            textRu = "Урикзар Склад-магазин 1/242",
            textEn = "Orikzar Warehouse store 1/242"
        ),
        address = DictItem(
            textUzLat = "Toshkent shahri; O‘rikzor “Stroygorod” savdo markazi, 1/242",
            textUzCyr = "Тошкент шаҳри; Ўрикзор “Стройгород” савдо маркази, 1/242",
            textRu = "город Ташкент; Урикзар ТЦ «Стройгород», 1/242",
            textEn = "Tashkent city; Orikzar shopping mall «Stroygorod», 1/242"
        ),
        phone = "+998 97 424 60 60",
        phone2 = "+998 97 763 40 40"
    ),

    SalePoint(
        name = DictItem(
            textUzLat = "Qo‘qon Ombor-do‘kon",
            textUzCyr = "Қўқон Омбор-дўкон",
            textRu = "Коканд Склад-магазин",
            textEn = "Kokand Warehouse store"
        ),
        address = DictItem(
            textUzLat = "Qo‘qon shahri; Farobiy bozor yonida avto turargoh ichida",
            textUzCyr = "Қўқон шаҳри; Фаробий бозор ёнида авто тураргоҳ ичида",
            textRu = "город Коканд; На стоянке рядом с базаром Фарабий",
            textEn = "Kokand city; At the parking lot next to the Farabiy bazaar"
        ),
        phone = "+998 94 744 20 30",
        phone2 = "+998 94 655 20 30"
    ),

    SalePoint(
        name = DictItem(
            textUzLat = "Andijon Ombor-do‘kon",
            textUzCyr = "Андижон Омбор-дўкон",
            textRu = "Андижан Склад-магазин",
            textEn = "Andijan Warehouse store"
        ),
        address = DictItem(
            textUzLat = "Andijon shahri; Soy qurilish bozori (Trikotaj)",
            textUzCyr = "Андижон шаҳри; Сой қурилиш бозори (Трикотаж)",
            textRu = "город Андижан; Сой строительный рынок (Трикотаж)",
            textEn = "Andijan city; Soi construction market (Trikotazh)",
        ),
        phone = "+998 99 790 20 30",
        phone2 = "+998 99 403 20 30"
    ),

    SalePoint(
        name = DictItem(
            textUzLat = "Namangan Ombor-do‘kon",
            textUzCyr = "Наманган Омбор-дўкон",
            textRu = "Наманган Склад-магазин",
            textEn = "Namangan Warehouse store"
        ),
        address = DictItem(
            textUzLat = "Namangan shahri; Lola Jahon bozori, Boburshox ko‘chasi, 51",
            textUzCyr = "Наманган шаҳри; Лола Жаҳон бозори, Бобуршох кўчаси, 51",
            textRu = "город Наманган; базар Лола Жахона, улица Бобуршоха, 51",
            textEn = "Namangan city; Lola Jahon bazaar, Boburshokh street, 51",
        ),
        phone = "+998 97 746 90 90",
        phone2 = "+998 97 442 60 60"
    ),

    SalePoint(
        name = DictItem(
            textUzLat = "Samarqand Ombor-do‘kon",
            textUzCyr = "Самарқанд Омбор-дўкон",
            textRu = "Самарканд Склад-магазин",
            textEn = "Samarkand Warehouse store"
        ),
        address = DictItem(
            textUzLat = "Samarqand shahri; Rudakiy ko‘chasi, 172-uy",
            textUzCyr = "Самарқанд шаҳри; Рудакий кўчаси, 172-уй",
            textRu = "город Самарканд; улица Рудакий, 172",
            textEn = "Samarkand city; Rudakiy street, 172"
        ),
        phone = "+998 99 767 20 30",
        phone2 = "+998 99 570 20 30"
    ),

    SalePoint(
        name = DictItem(
            textUzLat = "Urganch Ombor-do‘kon",
            textUzCyr = "Урганч Омбор-дўкон",
            textRu = "Ургенч Склад-магазин",
            textEn = "Urgench Warehouse store"
        ),
        address = DictItem(
            textUzLat = "Urganch shahri; Hamid Olimjon ko‘chasi, 1/5",
            textUzCyr = "Урганч шаҳри; Ҳамид Олимжон кўчаси, 1/5",
            textRu = "город Ургенч; улица Хамида Олимджана, 1/5",
            textEn = "Urgench city; Hamid Olimjan street, 1/5"
        ),
        phone = "+998 97 756 80 80",
        phone2 = "+998 99 884 20 30"
    ),

    SalePoint(
        name = DictItem(
            textUzLat = "Jizzax Ombor-do‘kon",
            textUzCyr = "Жиззах Омбор-дўкон",
            textRu = "Джизак склад-магазин",
            textEn = "Jizzakh warehouse store"
        ),
        address = DictItem(
            textUzLat = "Jizzax shahri; Mustaqillik ko‘chasi, 79",
            textUzCyr = "Жиззах шаҳри; Мустақиллик кўчаси, 79",
            textRu = "город Джизак; улица Мустакиллик, 79",
            textEn = "Jizzakh city; Mustakillik, 79"
        ),
        phone = "+998 99 464 20 30",
        phone2 = "+998 99 872 30 20"
    )
)

private val text001 = DictItem(
    textUzLat = """FERRO - bu katta ambitsiyalarga va o‘z maqsadi sari intiladigan jamoaga ega kompaniyadir. Innovatsion yechimlari va sifatga sodiqligi uni butun mamlakat bo‘ylab ko'plab mijozlar uchun ishonchli hamkorga aylantiradi. Har bir qadamda ishonch, mijozlar oldidagi mas’uliyat va hamkorlarni qo‘llab-quvvatlash – bularning barchasi FERROni o‘z sohasida yetakchiga aylantiradi va kelajakka ishonch bilan qarash, yangi yo‘nalishlarni rivojlantirish va bozordagi o‘rnini mustahkamlash imkonini beradi.""",
    textUzCyr = """FERRO - бу катта амбицияларга ва ўз мақсади сари интиладиган жамоага эга компаниядир. Инновацион ечимлари ва сифатга содиқлиги уни бутун мамлакат бўйлаб кўплаб мижозлар учун ишончли ҳамкорга айлантиради. Ҳар бир қадамда ишонч, мижозлар олдидаги масъулият ва ҳамкорларни қўллаб-қувватлаш – буларнинг барчаси FERROни ўз соҳасида етакчига айлантиради ва келажакка ишонч билан қараш, янги йўналишларни ривожлантириш ва бозордаги ўрнини мустаҳкамлаш имконини беради.""",
    textRu = """FERRO — это компания с большими амбициями и целеустремленной командой. Ее инновационные решения и преданность качеству делают ее надежным партнером для многих клиентов по всей стране. Уверенность в каждом шаге, ответственность перед клиентами и партнерская поддержка — все это делает FERRO лидером в своей отрасли и позволяет ей уверенно смотреть в будущее, развивая новые направления и укрепляя свои позиции на рынке.""",
    textEn = """FERRO is a company with great ambitions and a goal-oriented team. Its innovative solutions and dedication to quality make it a reliable partner for many customers across the country. Confidence in every step, responsibility to customers and partner support - all this makes FERRO a leader in its industry and allows it to confidently look to the future, developing new directions and strengthening its position in the market."""
)

private val text002 = DictItem(
    textUzLat = """2002-yilda Nodirbek Quddusov Toshkentda kichik qurilish materiallari do‘konini ochgan va yillar davomida o‘zi va jamoaning mehnati sabab ulkan va farovon kompaniya – FERROga aylantirgan. Bugungi kunda FERRO mahkamlagichlar, instrumentlar va elektr aksessuarlar sohasida sifat va ishonchlilikning ramzidir. Kompaniya o‘zining yuqori standartlariga sodiq qolgan holda o‘z mijozlariga ushbu toifadagi  sifatli mahsulotlarni taqdim etishdan faxrlanadi. 2022-yilda kompaniya rivojlanishning yangi bosqichiga ko‘tarilib, o‘z faoliyatini kengaytirdi, Toshkent shahridagi markaziy ofis va butun respublika bo‘ylab to‘qqizta do‘kon tarmog‘iga ega bo‘ldi.""",
    textUzCyr = """2002-йилда Нодирбек Қуддусов Тошкентда кичик қурилиш материаллари дўконини очган ва йиллар давомида ўзи ва жамоанинг меҳнати сабаб улкан ва фаровон компания – ФЕРРОга айлантирган. Бугунги кунда ФЕРРО маҳкамлагичлар, инструментлар ва электр аксессуарлар соҳасида сифат ва ишончлиликнинг рамзидир. Компания ўзининг юқори стандартларига содиқ қолган ҳолда ўз мижозларига ушбу тоифадаги  сифатли маҳсулотларни тақдим этишдан фахрланади. 2022-йилда компания ривожланишнинг янги босқичига кўтарилиб, ўз фаолиятини кенгайтирди, Тошкент шаҳридаги марказий офис ва бутун республика бўйлаб тўққизта дўкон тармоғига эга бўлди.""",
    textRu = """В 2002 году Нодирбек Куддусов открыл в Ташкенте небольшой магазин строительных материалов и за годы работы благодаря работе себя и своего коллектива превратил его в огромную и процветающую компанию – FERRO. Сегодня FERRO является символом качества и надежности в сфере крепежа, инструментов и электроаксессуаров. Компания гордится тем, что предоставляет своим клиентам качественную продукцию в этой категории, оставаясь при этом верной своим высоким стандартам. В 2022 году компания вышла на новый этап развития, расширила свою деятельность, приобрела центральный офис в Ташкенте и сеть из девяти магазинов по всей стране.""",
    textEn = """In 2002, Nodirbek Kuddusov opened a small building materials store in Tashkent and over the years, thanks to the work of himself and his team, he turned it into a huge and prosperous company - FERRO. Today, FERRO is a symbol of quality and reliability in the field of fasteners, tools and electrical accessories. The company is proud to provide its customers with quality products in this category, while remaining true to its high standards. In 2022, the company entered a new stage of development, expanded its activities, acquired a central office in Tashkent and a network of nine stores throughout the country."""
)


private val text003 = DictItem(
    textUzLat = """FERROning rivojlangan infratuzilmasi Toshkent shahridagi uchta do‘konni va viloyatlar – Andijon, Samarqand, Namangan, Qo‘qon, Urganch va Jizzaxdagi oltita optom qurilish marketlarini o‘z ichiga oladi. Kompaniya tomonidan taklif etilayotgan barcha mahsulotlar sifat va ishonchlilikka urg'u berilgan holda ishlab chiqariladi. FERRO nafaqat mahsulotlarni sotadi, balki u o‘z mijozlarining hayotini osonlashtiradigan va qulayroq qiladigan yechimlarni yaratadi.""",
    textUzCyr = """FERROнинг ривожланган инфратузилмаси Тошкент шаҳридаги учта дўконни ва вилоятлар – Андижон, Самарқанд, Наманган, Қўқон, Урганч ва Жиззахдаги олтита оптом қурилиш маркетларини ўз ичига олади. Компания томонидан таклиф этилаётган барча маҳсулотлар сифат ва ишончлиликка урғу берилган ҳолда ишлаб чиқарилади. FERRO нафақат маҳсулотларни сотади, балки у ўз мижозларининг ҳаётини осонлаштирадиган ва қулайроқ қиладиган ечимларни яратади.""",
    textRu = """Развитая инфраструктура FERRO включает три магазина в Ташкенте и шесть оптовых строительных магазинов в регионах — Андижан, Самарканд, Наманган, Коканд, Урганч и Джиззах. Все продукты, предлагаемые компанией, производятся с акцентом на безупречное качество и надежность. FERRO не просто продает товары — она создает решения, которые делают жизнь своих клиентов проще и удобнее.""",
    textEn = """FERRO's developed infrastructure includes three stores in Tashkent and six wholesale construction stores in the regions - Andijan, Samarkand, Namangan, Kokand, Urganch and Jizzakh. All products offered by the company are manufactured with an emphasis on impeccable quality and reliability. FERRO does not just sell products - it creates solutions that make the lives of its customers easier and more convenient."""
)

private val text004 = DictItem(
    textUzLat = """FERRO omborining maydoni ikki ming kvadrat metrdan ortiqni tashkil etadi, bu esa kompaniyaga logistikani samarali boshqarish va mijozlarga tezkor xizmat ko‘rsatish imkonini beradi. Kompaniyaning hamkorlar tarmog‘i butun mamlakat bo‘ylab uch mingdan ortiqni tashkil etadi, bu esa mijozlarning ishonchining yuqori darajasidan dalolat beradi. Innovatsion yondashuv, halollik va tajriba FERRO kompaniyasiga O‘zbekiston bozorida ishonchli yetakchi o‘rinni egallash imkonini beradi.""",
    textUzCyr = """FERRO омборининг майдони икки минг квадрат метрдан ортиқни ташкил этади, бу эса компанияга логистикани самарали бошқариш ва мижозларга тезкор хизмат кўрсатиш имконини беради. Компаниянинг ҳамкорлар тармоғи бутун мамлакат бўйлаб уч мингдан ортиқни ташкил этади, бу эса мижозларнинг ишончининг юқори даражасидан далолат беради. Инновацион ёндашув, ҳалоллик ва тажриба FERRO компаниясига Ўзбекистон бозорида ишончли етакчи ўринни эгаллаш имконини беради.""",
    textRu = """Складские площади FERRO составляют более двух тысяч квадратных метров, что позволяет компании эффективно управлять логистикой и оперативно обслуживать своих клиентов. Сеть партнеров компании насчитывает более трех тысяч по всей стране, что свидетельствует о высокой степени доверия и удовлетворенности клиентов. Инновационный подход, честность и опыт позволяют FERRO уверенно занимать лидирующие позиции на рынке Узбекистана.""",
    textEn = "FERRO's warehouse space is more than two thousand square meters, which allows the company to effectively manage logistics and promptly serve its customers. The company's network of partners numbers more than three thousand throughout the country, which indicates a high degree of trust and customer satisfaction. An innovative approach, honesty and experience allow FERRO to confidently occupy a leading position in the market of Uzbekistan."
)

private
val text005 = DictItem(
    textUzLat = """Kompaniyaning assortimenti uch mingdan ortiq turli xil instrumentlarni o‘z ichiga oladi, asosiy e'tibor o‘z brendlari - FERRO va TISCO larga qaratgan. Kompaniya butun jarayonni qat'iy nazorat qiladi - materiallar va ishlab chiqarishga buyurtma berishdan tortib, mahsulotni omborga yetkazib berishgacha, bu yuqori sifatli mahsulotlarni kafolatlaydi. Ushbu yondashuv kompaniyaga eng yuqori sifatli instrumentlar, elektr aksessuarlar va mahkamlagichlarni taklif qilish orqali mijozlarning talablarini a’lo darajada bajarishga imkon beradi.""",
    textUzCyr = """Компаниянинг ассортименти уч мингдан ортиқ турли хил инструментларни ўз ичига олади, асосий эътибор ўз брендлари - FERRO ва TISCO ларга қаратган. Компания бутун жараённи қатъий назорат қилади - материаллар ва ишлаб чиқаришга буюртма беришдан тортиб, маҳсулотни омборга етказиб беришгача, бу юқори сифатли маҳсулотларни кафолатлайди. Ушбу ёндашув компанияга энг юқори сифатли инструментлар, электр аксессуарлар ва маҳкамлагичларни таклиф қилиш орқали мижозларнинг талабларини аъло даражада бажаришга имкон беради.""",
    textRu = """Ассортимент компании включает более трех тысяч различных позиций, основной акцент делается на собственные бренды - FERRO и TISCO. Компания строго контролирует весь процесс — от заказа материалов и производства, до доставки продукции на склад, что гарантирует высокое качество продукции. Этот подход позволяет компании не только соответствовать ожиданиям клиентов, но и превосходить их, предлагая инструменты, электроаксессуары и крепежи высочайшего качества.""",
    textEn = """The company's range includes more than three thousand different items, the main emphasis is on its own brands - FERRO and TISCO. The company strictly controls the entire process - from ordering materials and production to delivering products to the warehouse, which guarantees high quality products. This approach allows the company not only to meet customer expectations, but also to exceed them, offering tools, electrical accessories and fasteners of the highest quality."""
)
