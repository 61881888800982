package uz.ferro.shop.pages.admin.page

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.FormControlVariant
import mui.material.TextField
import mui.system.sx
import react.FC
import react.Props
import react.ReactNode
import react.dom.events.ChangeEvent
import react.dom.onChange
import react.router.dom.useSearchParams
import react.router.useParams
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.PARAM_HISTORY
import uz.ferro.shop.VALUE_NO_BACK
import uz.ferro.shop.manager.ComponentCategoryManager
import uz.ferro.shop.model.Component
import uz.ferro.shop.model.ComponentCategory
import uz.ferro.shop.navigator.NavigationPage
import uz.ferro.shop.pages.admin.AlertManagerContext
import uz.ferro.shop.pages.admin.category.CategoryPicker
import uz.ferro.shop.pages.admin.component.ComponentManager
import uz.ferro.shop.util.orZero
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.JustifyContent
import web.cssom.px
import web.html.HTMLInputElement

val AdminComponentCategory = FC<Props> {
    val componentPathParam = useParams()["componentId"] ?: return@FC
    val pathParam = useParams()["componentCategoryId"] ?: return@FC
    val historyParam = useSearchParams().component1()[PARAM_HISTORY]
    val componentId = componentPathParam.toIntOrNull() ?: return@FC
    val componentCategoryId = pathParam.toIntOrNull()
    val alertManager = useContext(AlertManagerContext)
    var componentCategory by useState(
        ComponentCategory(
            component = Component(id = componentId)
        )
    )
    val navigator = useContext(NavigationContext)

    fun goBack() {
        if (historyParam == VALUE_NO_BACK) {
            navigator.openPage(NavigationPage.ADMIN_HOME)
        } else {
            navigator.goBack()
        }
    }

    useEffectOnce {
        MainScope().launch {
            if (componentCategoryId != null) {
                componentCategory = ComponentCategoryManager.getComponentCategory(componentCategoryId)
            } else {
                val component = ComponentManager.getComponent(componentId)
                val sort = component.categories.orEmpty().maxOfOrNull { it.sort.orZero() }.orZero() + 1
                componentCategory = componentCategory.copy(
                    sort = sort
                )
            }
        }
    }

    Box {
        sx {
            padding = 16.px
            width = 400.px
        }

        CategoryPicker {
            selectedCategory = componentCategory.category
            onCategorySelect = { cat ->
                componentCategory = componentCategory.copy(category = cat)
            }
        }

        TextField {
            sx {
                marginTop = 16.px
            }
            label = ReactNode("Tartib raqami")
            variant = FormControlVariant.outlined
            value = componentCategory.sort.orZero()
            onChange = { event ->
                event as ChangeEvent<HTMLInputElement>
                componentCategory = componentCategory.copy(sort = event.target.value.toIntOrNull())
            }
        }

        Box {
            sx {
                marginTop = 16.px
                display = Display.flex
                flexDirection = FlexDirection.row
                justifyContent = JustifyContent.end
            }

            if (componentCategory.id != null) {
                Button {
                    sx {
                        marginRight = 16.px
                    }
                    variant = ButtonVariant.contained
                    onClick = {
                        MainScope().launch {
                            try {
                                ComponentCategoryManager.deleteComponentCategory(componentCategory.id!!)
                                goBack()
                            } catch (e: Exception) {
                                alertManager.handleError(e)
                            }
                        }
                    }
                    +"O'chirish"
                }
            }


            Button {
                variant = ButtonVariant.contained
                onClick = {
                    goBack()
                }
                +"Bekor"
            }

            Button {
                sx {
                    marginLeft = 16.px
                }
                variant = ButtonVariant.contained
                onClick = {
                    if (isValidComponentCategory(componentCategory)) {
                        MainScope().launch {
                            try {
                                ComponentCategoryManager.saveComponentCategory(componentCategory)
                                goBack()
                            } catch (e: Exception) {
                                alertManager.handleError(e)
                            }
                        }
                    }
                }
                +"Saqlash"
            }
        }
    }
}

private fun isValidComponentCategory(cc: ComponentCategory): Boolean {
    if (cc.component?.id == null) {
        return false
    }

    if (cc.category?.id == null) {
        return false
    }

    return true
}