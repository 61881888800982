package uz.ferro.shop.pages.admin.category

import emotion.react.css
import mui.material.FormControl
import mui.material.InputLabel
import mui.material.MenuItem
import mui.material.Select
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.div
import uz.ferro.shop.api.useAllCategories
import uz.ferro.shop.model.Category
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.orZero
import web.cssom.px

external interface CategoryPickerProps : Props {
    var selectedCategory: Category?
    var onCategorySelect: (Category?) -> Unit
}

val CategoryPicker = FC<CategoryPickerProps> { props ->
    val allItems = useAllCategories()
    val selectedItem = props.selectedCategory
    val onItemSelect = props.onCategorySelect

    div {
        css { marginTop = 20.px }
        if (allItems.isNotEmpty()) {
            FormControl {
                fullWidth = true
                InputLabel {
                    +"Bo'lim"
                }
                Select {
                    value = selectedItem?.id.orZero()
                    label = ReactNode("Bo'lim")
                    onChange = { evt, _ ->
                        val id = evt.target.value
                        val cat = allItems.firstOrNull { it.id.toString() == id }
                        onItemSelect(cat)
                    }

                    MenuItem {
                        value = 0
                        +"--"
                    }
                    allItems.forEach {
                        MenuItem {
                            value = it.id.toString()
                            +it.name.localized()
                        }
                    }
                }
            }
        }
    }
}